import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { filter, map, take } from "rxjs/operators";

import { PlayerActions, PlayerSelectors } from "../../../../store/player";
import { AppState } from "../../../../store/state";
import { Player } from "../../../player/interfaces/player";
import { PlayerService } from "../../../player/providers/player.service";
import { DialogService } from "../../../shared/providers/dialog.service";
import { GameService } from "../../services/game.service";
import { BASIC_BOOT_SCENE } from "../../game-engine/scenes-basic/basic.constants";

@Injectable({
  providedIn: "root",
})
export class PreviewPlayerService {
  playerId: number;
  scene: string;

  constructor(
    private playerService: PlayerService,
    private store: Store<AppState>,
    private gameService: GameService,
    private dialogService: DialogService
  ) {}

  changePlayer({ player, scene }: { player?: Player; scene?: string } = {}) {
    // TODO - figure out why scene is missing sometimes(losing reference?)
    this.scene = scene ?? BASIC_BOOT_SCENE;
    this.playerId = this.playerService.getMePlayerId();

    if (player) {
      this.playerId = player.id;
    }

    if (this.playerService.getActivePlayerId() !== this.playerId) {
      this.subscribePlayer();
      this.store.dispatch(new PlayerActions.FetchPlayer({ playerId: this.playerId }));
    }
  }

  subscribePlayer() {
    this.store
      .pipe(
        select(PlayerSelectors.selectPlayer),
        map(player => {
          return player.id;
        }),
        filter(playerId => playerId === this.playerId),
        take(1)
      )
      .subscribe(playerId => {
        this.dialogService.closeAll();
        this.gameService.restartScene(playerId, this.scene);
      });
  }
}
