import { EventDialog } from "../../../../../../../core/interfaces/event-dialog.interface";
import { EVENT_DIALOGS_NAMES_BUILDINGS } from "../../core/event-dialogs/event-names.const";
import { BuildingBuildComponent } from "../../../base/custom/dialogs/building-build/building-build.component";
import { BuildingSpecialComponent } from "../../../base/custom/dialogs/building-special/building-special.component";
import { BuildingUpgradeComponent } from "../../../base/custom/dialogs/building-upgrade/building-upgrade.component";
import {BuildingInfoComponent} from '../../../base/custom/dialogs/building-info/building-info.component';

export const EVENT_DIALOGS_BUILDINGS_CUSTOM: EventDialog = {
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_BUILD]: BuildingBuildComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_UPGRADE]: BuildingUpgradeComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_SPECIAL_UPGRADE]: BuildingSpecialComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_INFO]: BuildingInfoComponent
};
