import { LocationStrategy } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as R from "ramda";
import { LINKS } from "src/app/core/consts/core/links.const";
import { OwInject } from "src/app/core/decorators/ow-inject.decorator";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { ApiOptions } from "../../../../../../core/interfaces/api-options";
import { ApiService } from "../../../../../../core/providers/api.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { AUTH_ROUTING_ABSOLUTE_PATH } from "../../../../consts/core/routing-auth-absolute.const";
import { checkPasswordTheSame } from "../../../../helpers/validators-form-password-the-same.helper";
import { TERYT } from "./config/teryt.const";

@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
})
export class RegistrationComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(FormBuilder) fb: FormBuilder;
  @OwInject(Router) router: Router;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiService) apiService: ApiService;
  @OwInject(ActivatedRoute) activatedRoute: ActivatedRoute;
  @OwInject(LocationStrategy) locationStrategy: LocationStrategy;
  AUTH_ROUTING_ABSOLUTE_PATH = AUTH_ROUTING_ABSOLUTE_PATH;
  TERYT = TERYT;
  LINKS = LINKS;
  selectedFormEmploymentId = null;
  activeTemplate: number;
  edit: boolean;
  mandatoryVerification: boolean;
  currentUserSettings;
  form0: FormGroup;
  form: FormGroup;
  form3: FormGroup;
  form4: FormGroup;
  taxOffices;
  territories: any = this.TERYT;
  TEMPLATE = {
    FORM0: 0,
    FORM1: 1,
    FORM2: 2,
    FORM3: 3,
    FORM4: 4,
    FORM5: 5,
    FORM6: 6,
  };

  formEmployment = [
    { id: 1, title: EMPLOYMENT.ONE },
    { id: 2, title: EMPLOYMENT.TWO },
    { id: 3, title: EMPLOYMENT.THREE },
  ];

  statements = [
    { id: 1, title: STATEMENTS.ONE, checked: false },
    { id: 2, title: STATEMENTS.TWO, checked: false },
    { id: 3, title: STATEMENTS.THREE, checked: false, hideOnType: 3 },
  ];

  activeRedirect: boolean;
  invalidIBAN;
  invalidIBANLetters;

  ngOnInit() {
    this.subscribeRoutingParams();
    this.preventBackButton();
  }

  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }

  subscribeRoutingParams() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.edit = params.hasOwnProperty("edit");
      this.mandatoryVerification = params.hasOwnProperty("mandatoryVerification");

      if (this.mandatoryVerification) {
        this.dialogService.openAlert({
          title: "Nim przejdziesz dalej...",
          description: "Sprawdź czy podane dane są poprawne. Jeżeli nie, to możesz je teraz zmienić.",
        });
      }
    });
    this.getTaxOffices();
  }

  getTaxOffices() {
    this.getTaxOfficesRequest().subscribe(
      resp => {
        this.taxOffices = resp.map(item => {
          item.name = " " + item.name;
          return item;
        });
        this.mapTerritoriesName(this.territories);
        this.clearForm();
        if (this.edit) {
          this.getCurrentUserSettings();
          this.changeTemplate(this.TEMPLATE.FORM1);
        } else {
          this.changeTemplate(this.TEMPLATE.FORM0);
        }
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  mapTerritoriesName(arr) {
    for (let i = 0; i < arr.length; i++) {
      arr[i].name = " " + arr[i].name;
      if (arr[i].counties && arr[i].counties.length > 0) {
        this.mapTerritoriesName(arr[i].counties);
      }
      if (arr[i].communes && arr[i].communes.length > 0) {
        this.mapTerritoriesName(arr[i].communes);
      }
    }
  }

  backToGame() {
    this.activeRedirect = true;
    location.href = "/main/auth/game";
  }

  clearForm() {
    this.clearForm0();
    this.clearForm1();
    this.clearForm3();
    this.clearForm4();
  }

  clearForm0() {
    this.form0 = this.fb.group(
      {
        login: this.fb.control(null, [Validators.required]),
        domain: this.fb.control("@komputronik.pl"),
        password: this.fb.control(null, [
          Validators.required,
          Validators.pattern("^(?=.*?[\\W|_])(?=.*?[0-9])(?=.*?[A-ZĘÓĄŚŁŻŹĆŃ]).{8,}$"),
        ]),
        passwordRepeat: this.fb.control(null, [Validators.required]),
      },
      {
        validator: checkPasswordTheSame,
      }
    );
  }

  clearForm1() {
    this.form = this.fb.group({
      firstName: this.fb.control(null, [Validators.required]),
      lastName: this.fb.control(null, [Validators.required]),
      pesel: this.fb.control(null, [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(11),
        Validators.maxLength(11),
      ]),
      privateEmail: this.fb.control(null, [Validators.required, Validators.email]),
      phone: this.fb.control(null, [Validators.required, this.checkPhoneNumber]),
      bankAccount: this.fb.control(null, [Validators.required, this.checkBankAccount]),
    });
  }

  clearForm3() {
    this.form3 = this.fb.group({
      zipCode_1: this.fb.control(null, [Validators.required, Validators.pattern("^[0-9]{2}-[0-9]{3}$")]),
      city_1: this.fb.control(null, [Validators.required]),
      street_1: this.fb.control(null, [Validators.required]),
      houseNo_1: this.fb.control(null, [Validators.required]),
      localeNo_1: this.fb.control(null),
      district_1: this.fb.control({ id_voivodeship: 0, name: " " }, [Validators.required, this.checkVoivodeship]),
      county_1: this.fb.control({ id_county: 0, name: " " }, [Validators.required, this.checkCounty]),
      borough_1: this.fb.control({ id_commune: 0, name: " " }, [Validators.required, this.checkCommune]),
      tax_office: this.fb.control({ id: 0, name: " " }, [Validators.required, this.checkTaxOffice]),
    });
  }

  clearForm4() {
    this.form4 = this.fb.group({
      nip_2: this.fb.control(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
      company_2: this.fb.control(null, [Validators.required]),
      zipCode_2: this.fb.control(null, [Validators.required, Validators.pattern("^[0-9]{2}-[0-9]{3}$")]),
      city_2: this.fb.control(null, [Validators.required]),
      street_2: this.fb.control(null, [Validators.required]),
      houseNo_2: this.fb.control(null, [Validators.required]),
      localeNo_2: this.fb.control(null),
    });
  }

  changeTemplate(template: number) {
    this.activeTemplate = template;
  }

  submit() {
    const data = this.prepareLoginPasswordData();
    this.postLoginAndPassword(data).subscribe(
      () => {
        this.changeTemplate(this.TEMPLATE.FORM1);
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  getCurrentUserSettings() {
    this.getUserSettingsRequest().subscribe(
      resp => {
        this.currentUserSettings = resp;
        this.fillControlsWithBaseSettings();
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  getCounties() {
    const empty = [{ id_county: 0, name: "Aby uzupełnić, wybierz województwo" }];

    if (this.form3.get("district_1").value) {
      const currentVoivodeship = this.form3.get("district_1").value;
      if (currentVoivodeship.id_voivodeship !== 0) {
        return currentVoivodeship.counties;
      }
      return empty;
    }
    return empty;
  }

  getCommunes() {
    const empty = [{ id_commune: 0, name: "Aby uzupełnić, wybierz powiat" }];

    if (this.form3.get("county_1").value) {
      const currentCounty = this.form3.get("county_1").value;
      if (currentCounty.id_county !== 0) {
        return currentCounty.communes;
      }
      return empty;
    }
    return empty;
  }

  districtChanged(event) {
    if (this.form3.get("county_1").value.id_voivodeship !== event.id_voivodeship) {
      this.form3.get("county_1").patchValue({ id_county: 0, name: " " });
      this.form3.get("borough_1").patchValue({ id_commune: 0, name: " " });
    }
  }

  countyChanged(event) {
    if (this.form3.get("borough_1").value.id_county !== event.id_county) {
      this.form3.get("borough_1").patchValue({ id_commune: 0, name: " " });
    }
  }

  checkTaxOffice(control: AbstractControl): ValidationErrors | null {
    if (control.value.id === 0) {
      return {
        taxOfficeError: true,
      };
    }
    return null;
  }

  checkVoivodeship(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.id_voivodeship === 0) {
      return {
        voivodeshipError: true,
      };
    }
    return null;
  }

  checkCommune(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.id_commune === 0) {
      return {
        communeError: true,
      };
    }
    return null;
  }

  checkCounty(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.id_county === 0) {
      return {
        countyError: true,
      };
    }
    return null;
  }

  checkBankAccount(control: AbstractControl): ValidationErrors | null {
    if (control.value && !control.value.replace(/\s+/g, "").match(/^\d{26}$/)) {
      return {
        bankAccountError: true,
      };
    }
    return null;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return charCode === 86 || !(charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105));
  }

  onPaste(event) {
    if (event.clipboardData.getData("Text").match(/[^\d]/)) {
      event.preventDefault();
      this.invalidIBANLetters = true;
    } else {
      this.invalidIBANLetters = false;
    }
  }

  alertValidIBAN(event) {
    const isValid = this.isValidIBANNumber(event.target.value);
    this.invalidIBAN = isValid !== 1;
  }

  isValidIBANNumber(input) {
    input = "PL" + input;

    const CODE_LENGTHS = {
      AD: 24,
      AE: 23,
      AT: 20,
      AZ: 28,
      BA: 20,
      BE: 16,
      BG: 22,
      BH: 22,
      BR: 29,
      CH: 21,
      CR: 21,
      CY: 28,
      CZ: 24,
      DE: 22,
      DK: 18,
      DO: 28,
      EE: 20,
      ES: 24,
      FI: 18,
      FO: 18,
      FR: 27,
      GB: 22,
      GI: 23,
      GL: 18,
      GR: 27,
      GT: 28,
      HR: 21,
      HU: 28,
      IE: 22,
      IL: 23,
      IS: 26,
      IT: 27,
      JO: 30,
      KW: 30,
      KZ: 20,
      LB: 28,
      LI: 21,
      LT: 20,
      LU: 20,
      LV: 21,
      MC: 27,
      MD: 24,
      ME: 22,
      MK: 19,
      MR: 27,
      MT: 31,
      MU: 30,
      NL: 18,
      NO: 15,
      PK: 24,
      PL: 28,
      PS: 29,
      PT: 25,
      QA: 29,
      RO: 24,
      RS: 22,
      SA: 24,
      SE: 24,
      SI: 19,
      SK: 24,
      SM: 27,
      TN: 24,
      TR: 26,
      AL: 28,
      BY: 28,
      EG: 29,
      GE: 22,
      IQ: 23,
      LC: 32,
      SC: 31,
      ST: 25,
      SV: 28,
      TL: 23,
      UA: 29,
      VA: 22,
      VG: 24,
      XK: 20,
    };

    const iban = String(input)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, "");
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);

    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
      return false;
    }

    const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, letter => {
      return (letter.charCodeAt(0) - 55)?.toString();
    });

    return this.mod97(digits);
  }

  mod97(string) {
    let checksum = string.slice(0, 2),
      fragment;

    for (let offset = 2; offset < string.length; offset += 7) {
      fragment = String(checksum) + string.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
    }

    return checksum;
  }

  checkPhoneNumber(control: AbstractControl): ValidationErrors | null {
    if (control.value && !control.value.replace(/\s+/g, "").match(/^\d{9}$/)) {
      return {
        phoneNumberError: true,
      };
    }
    return null;
  }

  checkFormEmployment() {
    if (this.selectedFormEmploymentId === 1 || this.selectedFormEmploymentId === 2) {
      this.changeTemplate(this.TEMPLATE.FORM3);
    } else if (this.selectedFormEmploymentId === 3) {
      this.changeTemplate(this.TEMPLATE.FORM4);
    }

    if (
      (this.edit && this.currentUserSettings.type === this.selectedFormEmploymentId) ||
      (this.currentUserSettings.type < 3 && this.selectedFormEmploymentId < 3)
    ) {
      this.fillFormControlsWithCurrentUserSettings();
    }

    if (this.edit) {
      this.statements[1].checked = true;
    }
  }

  checkFormEmploymentBack() {
    this.statements.forEach(statement => {
      if (statement.hideOnType !== this.selectedFormEmploymentId) {
        this.changeTemplate(this.TEMPLATE.FORM3);
      } else {
        this.changeTemplate(this.TEMPLATE.FORM4);
      }
    });
  }

  getErrors(controlName) {
    return controlName.invalid && (this.mandatoryVerification ? true : controlName.dirty) && controlName.errors;
  }

  setFormEmploymentId(formEmployment) {
    if (this.selectedFormEmploymentId !== formEmployment.id) {
      this.clearForm3();
      this.clearForm4();
      this.statements.forEach(statement => {
        statement.checked = false;
      });
    }
    this.selectedFormEmploymentId = formEmployment.id;
  }

  submitStatement(statementsArray, statementId) {
    const searchStatement = statementsArray.find(statement => statement.id === statementId);
    searchStatement.checked = !(searchStatement.id === 3 && searchStatement.checked);
  }

  checkDataBeforePost() {
    if (this.edit) {
      this.openConfirmEditedUserData();
    } else {
      this.registration();
    }
  }

  registration() {
    const data = this.prepareRegistrationData();

    this.registrationRequest(data).subscribe(
      () => {
        this.changeTemplate(this.TEMPLATE.FORM6);
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  openConfirmEditedUserData() {
    this.dialogService.openConfirm(
      {
        title: "Uwaga",
        description: "Czy na pewno chcesz zapisać nowe dane?",
      },
      confirm => {
        if (confirm) {
          this.saveEditedDataUserSettings();
        }
      }
    );
  }

  saveEditedDataUserSettings() {
    const data = this.prepareEditUserData();

    this.postEditedUserSettingsRequest(data).subscribe(
      () => {
        this.dialogService.openAlert(
          {
            description: "Dane zostały zapisane poprawnie.",
            buttonClose: {
              label: "Ok",
            },
          },
          () => {
            this.backToGame();
          }
        );
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  fillControlsWithBaseSettings() {
    if (this.currentUserSettings) {
      const user = this.currentUserSettings;
      this.form.controls["privateEmail"].patchValue(user.private_email);
      this.form.controls["firstName"].patchValue(user.first_name);
      this.form.controls["lastName"].patchValue(user.last_name);
      this.form.controls["pesel"].patchValue(user.pesel);
      this.form.controls["phone"].patchValue(user.phone);
      this.form.controls["bankAccount"].patchValue(user.bank_account);
      this.selectedFormEmploymentId = user.type;
      this.fillFormControlsWithCurrentUserSettings();
    }
  }

  fillFormControlsWithCurrentUserSettings() {
    if (this.currentUserSettings) {
      const user = this.currentUserSettings;

      let currentTaxOffice = this.taxOffices.find(taxOffice => taxOffice.id === user.tax_office);
      if (!currentTaxOffice) {
        currentTaxOffice = { id: 0, name: " " };
      }

      let currentVoivodeShip: Record<string, any> = { id_voivodeship: 0, name: " " };
      let currentCounty: Record<string, any> = { id_county: 0, name: " " };
      let currentCommune: Record<string, any> = { id_commune: 0, name: " " };

      const voivodeship = this.territories.find(x => x.name.trim() === user.voivodeship);
      if (voivodeship) {
        currentVoivodeShip = voivodeship;
        currentCounty = voivodeship.counties.find(x => x.name.trim() === user.county);
        currentCommune = currentCounty.communes.find(x => x.name.trim() === user.commune);
      }

      this.statements[2].checked = user.agreed_electronic_pit;
      this.form3.controls["tax_office"].patchValue(currentTaxOffice);
      this.form3.controls["district_1"].patchValue(currentVoivodeShip);
      this.form3.controls["county_1"].patchValue(currentCounty);
      this.form3.controls["borough_1"].patchValue(currentCommune);
      this.form4.controls["nip_2"].patchValue(user.nip);
      this.form4.controls["company_2"].patchValue(user.name);

      if (user.type === 1 || user.type === 2) {
        this.form3.controls["zipCode_1"].patchValue(user.post_code);
        this.form3.controls["city_1"].patchValue(user.city);
        this.form3.controls["street_1"].patchValue(user.street);
        this.form3.controls["houseNo_1"].patchValue(user.home_number);
        this.form3.controls["localeNo_1"].patchValue(user.flat_number);
      } else if (user.type === 3) {
        this.form4.controls["zipCode_2"].patchValue(user.post_code);
        this.form4.controls["city_2"].patchValue(user.city);
        this.form4.controls["street_2"].patchValue(user.street);
        this.form4.controls["houseNo_2"].patchValue(user.home_number);
        this.form4.controls["localeNo_2"].patchValue(user.flat_number);
      }
    }
  }

  prepareLoginPasswordData() {
    const data: RegistrationLoginPasswordData = {
      email: this.form0.value.login + this.form0.value.domain,
      password: this.form0.value.password,
    };
    return data;
  }

  prepareRegistrationData() {
    const data: RegistrationRequestDataCustom = {
      privateEmail: this.form.value.privateEmail,
      type: this.selectedFormEmploymentId,
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      pesel: this.form.value.pesel,
      phone: this.form.value.phone,
      bankAccount: this.form.value.bankAccount,
      postCode: this.form3.value.zipCode_1 || this.form4.value.zipCode_2,
      city: this.form3.value.city_1 || this.form4.value.city_2,
      street: this.form3.value.street_1 || this.form4.value.street_2,
      homeNumber: this.form3.value.houseNo_1 || this.form4.value.houseNo_2,
      agreedElectronicPit: this.statements[2].checked,
      taxOffice: this.form3.value.tax_office.id,
      flatNumber: this.form3.value.localeNo_1 || this.form4.value.localeNo_2,
      voivodeship: this.form3.value.district_1.name.trim(),
      county: this.form3.value.county_1.name.trim(),
      commune: this.form3.value.borough_1.name.trim(),
      nip: this.form4.value.nip_2,
      name: this.form4.value.company_2,
      email: this.form0.value.login + this.form0.value.domain,
      password: this.form0.value.password,
    };
    if (this.form3.value.tax_office.id === 0) {
      data.taxOffice = null;
    }
    if (this.form3.value.district_1.id_voivodeship === 0) {
      data.voivodeship = null;
    }
    if (this.form3.value.county_1.id_county === 0) {
      data.county = null;
    }
    if (this.form3.value.borough_1.id_commune === 0) {
      data.commune = null;
    }
    return data;
  }

  prepareEditUserData() {
    const data: RegistrationRequestDataBase = {
      privateEmail: this.form.value.privateEmail,
      type: this.selectedFormEmploymentId,
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      pesel: this.form.value.pesel,
      phone: this.form.value.phone,
      bankAccount: this.form.value.bankAccount,
      postCode: this.form3.value.zipCode_1 || this.form4.value.zipCode_2,
      city: this.form3.value.city_1 || this.form4.value.city_2,
      street: this.form3.value.street_1 || this.form4.value.street_2,
      homeNumber: this.form3.value.houseNo_1 || this.form4.value.houseNo_2,
      agreedElectronicPit: this.statements[2].checked,
      taxOffice: this.form3.value.tax_office.id,
      flatNumber: this.form3.value.localeNo_1 || this.form4.value.localeNo_2,
      voivodeship: this.form3.value.district_1.name.trim(),
      county: this.form3.value.county_1.name.trim(),
      commune: this.form3.value.borough_1.name.trim(),
      nip: this.form4.value.nip_2,
      name: this.form4.value.company_2,
    };

    if (this.form3.value.tax_office.id === 0) {
      data.taxOffice = null;
    }
    if (this.form3.value.district_1.id_voivodeship === 0) {
      data.voivodeship = null;
    }
    if (this.form3.value.county_1.id_county === 0) {
      data.county = null;
    }
    if (this.form3.value.borough_1.id_commune === 0) {
      data.commune = null;
    }
    return data;
  }

  backToLogin() {
    this.router.navigate([AUTH_ROUTING_ABSOLUTE_PATH.LOGIN]);
  }

  // API
  registrationRequest(data: RegistrationRequestDataCustom) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true,
    };
    return this.apiService.post("registration", options);
  }

  getTaxOfficesRequest() {
    const options: ApiOptions = {
      sendRequestWithoutToken: true,
    };
    return this.apiService.get("tax-offices/options", options);
  }

  postLoginAndPassword(data: RegistrationLoginPasswordData) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true,
    };
    return this.apiService.post("registration/verify", options);
  }

  getUserSettingsRequest() {
    return this.apiService.get("me/profile");
  }

  postEditedUserSettingsRequest(data: RegistrationRequestDataBase) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
    };
    return this.apiService.patch("me/profile", options);
  }
}

interface RegistrationRequestDataBase {
  type: number;
  firstName: string;
  lastName: string;
  pesel: string;
  phone: string;
  bankAccount: string;
  postCode: string;
  city: string;
  street: string;
  homeNumber: string;
  agreedElectronicPit: boolean;
  taxOffice: number;
  flatNumber: string;
  voivodeship: string;
  county: string;
  commune: string;
  nip: string;
  name: string;
  privateEmail: string;
}

interface RegistrationRequestDataCustom extends RegistrationRequestDataBase {
  email: string;
  password: string;
}

interface RegistrationLoginPasswordData {
  email: string;
  password: string;
}

const EMPLOYMENT = {
  ONE: "Osoba fizyczna nieprowadząca działalności gospodarczej",
  TWO: "Osoba fizyczna prowadząca działalność gospodarczą - uczestnictwo nie ma związku z prowadzoną działalnością",
  THREE: "Osoba fizyczna prowadząca działalność gospodarczą - uczestnictwo ma związek z prowadzoną działalnością",
};

const STATEMENTS = {
  ONE: "Oświadczam, że powyższe dane są prawdziwe i aktualne",
  TWO:
    `Oświadczam, że zapoznałem/-am się i akceptuję warunki oraz zasady uczestnictwa w Programie Grywalizacyjnym ` +
    `„Komp Town – Misja Komputronikus” i Konkursie „Komp Town - Misja Komputronikus” określone w ` +
    `<a href="${LINKS.TERMS}" target="_blank">Regulaminie Programu Grywalizacyjnego „Komp Town – Misja Komputronikus” i Konkursie „Komp Town- Misja Komputronikus” </a> ` +
    `i zobowiązuję się do ich przestrzegania.`,
  THREE: "Wyrażam zgodę na otrzymanie deklaracji PIT-11 drogą elektroniczną w związku z przyznanymi w akcji nagrodami.",
};
