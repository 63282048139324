import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";

import { ApiOptions } from "../../../interfaces/api-options";
import { ApiService } from "../../../providers/api.service";
import { SynchronizeTimeService } from "../../../providers/synchronize-time.service";

@Injectable({
  providedIn: "root",
})
export class ApiTimesyncService {
  constructor(private apiService: ApiService, private synchronizeTimeService: SynchronizeTimeService) {}

  getTimesync() {
    const options: ApiOptions = {
      sendRequestWithoutToken: true,
    };

    return this.apiService.get("timesync", options).pipe(
      tap((serverTimeObject: { date: string }) => {
        this.synchronizeTimeService.setActualServerTime(serverTimeObject);
      })
    );
  }
}
