<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <div class="title">
      {{ 'transaction-history.title' | myTranslate }}
    </div>
  </div>

  <!-- CURRENCY BALANCE -->
  <div class="currency-balance">
    <p>
      <span>Aktualne saldo do wypłaty: &nbsp; {{ data.currency.balance }}zł</span>
      <ng-container *ngIf="userService.me['talars_left_for_redemption'] as talarsLeftForRedemption">
        (- {{ talarsLeftForRedemption }}zł)

        <i
          class="far fa-info-circle"
          (click)="openRedemptionInfo()"
        ></i>
      </ng-container>

      <ng-container *ngIf="data.currency['tax_threshold_values'] as taxThresholdValues">
        <ng-container *ngFor="let taxThresholdValue of (taxThresholdValues | keyvalue)">
          <br/>
          Jeśli jesteś w I progu podatkowym, podatek od nagród wypłaconych w {{ taxThresholdValue.key }} r. na dzień
          dzisiejszy wynosi {{ taxThresholdValue.value }} zł
        </ng-container>
      </ng-container>
    </p>
  </div>

  <ng-container *ngIf="items">
    <!-- ITEMS -->
    <div class="table loading-opacity" *ngIf="items">
      <div class="tr" *ngFor="let item of items">
        <div class="td">
          {{ item.transaction_at | date:'dd-MM-y'}}
        </div>
        <div class="td td-reason">
          <span>{{ item.reason }}</span>
        </div>
        <div class="td td-value">
          {{ item.value > 0 ? '+' : '' }}<!--
        -->{{ item.value | number | NumberWithSpaces }}
        </div>
        <div class="td">
          <m-ui-currency
            [item]="data.currency"
            [lack]="false"
            [stockView]="STOCK_VIEW.H"
            [hideValue]="true"
          ></m-ui-currency>
        </div>
      </div>
    </div>

    <!-- NO HISTORY -->
    <div class="no-item" *ngIf="items.length == 0">
      {{ 'transaction-history.no-transaction-history' | myTranslate }}
    </div>

    <!-- BOTTOM BAR -->
    <m-ui-navigation
      *ngIf="navigation.maxPage > 1"
      [navigation]="navigation"
      (pageChanged)="getCurrencyTransaction($event)"
    ></m-ui-navigation>
    <div class="bottom-bar withdraw">
      <!-- WITHDRAW TALARS -->
      <div class="bar-button base">
        <button
          class="base secondary"
          (click)="openAlertConfirm()"
          [disabled]="data.currency.balance == 0"
        >
          Wypłać
        </button>
      </div>
    </div>
  </ng-container>
</div>


