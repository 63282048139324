import { KeyValue } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as R from "ramda";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { OwDate } from "../../../../../../../../core/utility/ow-date.class";
import { OwPaginationDate } from "../../../../../../../../core/utility/ow-pagination.class";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { ApiBusinessRanksService } from "../../../../api/custom/services/api-business-ranks.service";
import { ApiCoreGameRanksService } from "../../../../api/custom/services/api-game-ranks.service";
import { EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM } from "../../../../consts/custom/event-dialogs/event-names.const";
import { RANK_TYPE } from "../../../../consts/custom/rank-type.const";
import { RankGame } from "../../../../interfaces/custom/rank-game.interface";
import { RANKS_TABS, RankType } from '../../../../interfaces/custom/rank-type.interface';
import { RankBase, RankBusiness } from "../../../../interfaces/custom/ranks.interface";

@Component({
  selector: "app-ranking-list",
  templateUrl: "./ranking-list.component.html",
})
export class RankingListComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiCoreGameRanksService) apiCoreGameRanksService: ApiCoreGameRanksService;
  @OwInject(ApiBusinessRanksService) apiBusinessRanksService: ApiBusinessRanksService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(MAT_DIALOG_DATA) data: { location: number, openRankType?: RANKS_TABS, showBackButton?: boolean };

  RANK_TYPE = RANK_TYPE;
  rankList: any[] = [];
  sliderActiveIndex = 0;
  activeRankType: RankType;
  owPaginationDate: OwPaginationDate;
  isLoading = false;
  _owDate: OwDate = new OwDate();
  set owDate(owDate: OwDate) {
    this._owDate = owDate;
    this.setPagination();
  }

  get owDate(): OwDate {
    return this._owDate;
  }

  RANKS_TYPES = {
    [RANKS_TABS.GAMES]: {
      label: "Świat gry",
      order: 1,
      type: RANK_TYPE.GAME,
      show: true,
    },
    [RANKS_TABS.BUSINESS]: {
      label: "Biznes",
      order: 2,
      type: RANK_TYPE.BUSINESS,
      show: true,
    },
  };
  config = {
    slidesPerView: 3,
    centeredSlides: false,
  };

  ngOnInit() {
    this.isLoading = false;
    this.clearDate();
    this.clearSliderActiveIndex();
    this.changeActiveRankType(this.RANKS_TYPES[this.data.openRankType ?? ''] ?? this.RANKS_TYPES.GAMES);
  }

  clearDate() {
    this.owDate = new OwDate();
  }

  clearRanks() {
    this.rankList = [];
  }

  clearSliderActiveIndex() {
    this.sliderActiveIndex = 0;
  }

  changeActiveRankType(activeRankType: RankType) {
    this.activeRankType = activeRankType;
    this.getRanks();
  }

  getRanks() {
    this.clearRanks();
    this.isLoading = true;
    switch (this.activeRankType) {
      case this.RANKS_TYPES.GAMES:
        this.gameRanks();
        break;

      case this.RANKS_TYPES.BUSINESS:
        this.businessRanks();
        break;
    }
  }

  gameRanks() {
    this.clearSliderActiveIndex();

    this.apiCoreGameRanksService.getGameRanks({ location: this.data.location }).subscribe(
      (resp: RankGame[]) => {
        this.rankList = resp;
        this.filterRankList();
        this.checkSwiperOptions();
        this.isLoading = false;
      },
      errResp => {
        this.rankList = [];
        this.dialogService.openAlertErrorApi({ errResp });
        this.isLoading = false;
      }
    );
  }

  businessRanks() {
    this.clearSliderActiveIndex();

    const { year, month } = this.owDate;

    this.apiBusinessRanksService.businessRanks({ year, month }).subscribe(
      (resp: RankBusiness[]) => {
        this.rankList = resp;
        this.rankList.forEach(x => x.type = x.is_branch_rank ? 'BRANCH' : 'PLAYER');
        this.checkSwiperOptions();
        this.isLoading = false;
      },
      errResp => {
        this.rankList = [];
        this.dialogService.openAlertErrorApi({ errResp });
        this.isLoading = false;
      }
    );
  }

  openRankDetails(rank: RankGame) {
    switch (this.activeRankType.type) {
      case RANK_TYPE.GAME:
        this.openRankDetailsGame(rank);
        break;

      case RANK_TYPE.BUSINESS:
        this.openRankDetailsBusiness(rank);
        break;
    }
  }

  openRankDetailsGame(rank: RankGame) {
    if (!rank.last_calculated_at) {
      this.dialogService.openAlert({
        description: "Ranking nie jest jeszcze gotowy. Spróbuj ponownie jutro!",
      });
      return;
    }

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM.RANKING_DETAILS_GAME,
      config: {
        data: {
          rankId: rank.rank_edition_id,
          rankGroup: rank.parameters && rank.parameters["rank_group"],
        },
      },
    });
  }

  openRankDetailsBusiness(rank: RankBase) {
    if (rank['contest_id']) {
      rank['type'] = null;
    }

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM.RANKING_DETAILS_BUSINESS,
      config: {
        data: {
          rank,
        },
      },
    });
  }

  openRankDescription(rank) {
    this.dialogService.openAlert({
      title: rank.name,
      description: rank.description,
    });
  }

  checkSwiperOptions() {
    this.config.centeredSlides = this.rankList.length <= 2;
  }

  keyAscOrder = (a: KeyValue<number, RankType>, b: KeyValue<number, RankType>): number => {
    return b.value.order > a.value.order ? -1 : a.value.order > b.value.order ? 1 : 0;
  };

  setPagination() {
    this.owPaginationDate = new OwPaginationDate({
      current: this.owDate,
    });
  }

  next() {
    this.owDate.add({ month: 1 });
    this.setPagination();
    this.getRanks();
  }

  prev() {
    this.owDate.subtract({ month: 1 });
    this.setPagination();
    this.getRanks();
  }

  filterRankList() {
    this.rankList = this.rankList.filter(rank => {
      const krsId = R.path(["parameters", "krs_id"], rank);

      return krsId ? this.playerService.player["krs_id"] === krsId : true;
    });
  }
}
