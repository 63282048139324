<ng-container *ngIf="socketState" [ngSwitch]="socketState.socketStatus">
  <!-- CONNECTING TO SERVER -->
  <ng-container *ngSwitchCase="SOCKET_STATUS.CONNECTING">
    <div class="background"></div>
    <p class="message">
      {{ "socket-messages.connecting-to-server" | myTranslate }}
    </p>
  </ng-container>

  <!-- SOCKET ERROR -->
  <ng-container *ngSwitchCase="SOCKET_STATUS.RECONNECT_ATTEMPT">
    <div class="background"></div>
    <p class="message">
      {{ "socket-messages.connecting-to-server-failed" | myTranslate : socketState }}
    </p>
  </ng-container>

  <!-- SOCKET DISCONNECTED -->
  <ng-container *ngSwitchCase="SOCKET_STATUS.DISCONNECTED">
    <div class="background"></div>
    <p class="message">
      {{ "socket-messages.connecting-to-server-terminated" | myTranslate }}
    </p>
  </ng-container>

  <!-- OTHER SESSION -->
  <ng-container *ngSwitchCase="SOCKET_STATUS.OTHER_SESSION">
    <div class="background"></div>
    <p class="message">
      {{ "socket-messages.multiple-instance" | myTranslate }}
    </p>
    <ng-container [ngTemplateOutlet]="buttonReloadTpl"></ng-container>
    <ng-container [ngTemplateOutlet]="buttonLogoutTpl"></ng-container>
  </ng-container>
</ng-container>

<ng-container *ngIf="infoMessageState" [ngSwitch]="infoMessageState.status">
  <!-- ERROR PRELOAD ASSETS MAP -->
  <ng-container *ngSwitchCase="INFO_MESSAGE_STATUS.ERROR_PRELOAD_ASSETS_MAP">
    <div class="background"></div>
    <p class="message">{{ "socket-messages.error-preload-assets-map" | myTranslate }}</p>
    <ng-container [ngTemplateOutlet]="buttonReloadTpl"></ng-container>
  </ng-container>

  <!-- GAME VERSION -->
  <ng-container *ngSwitchCase="INFO_MESSAGE_STATUS.DIFFERENT_VERSION_GAME">
    <div class="background"></div>
    <p class="message">{{ "socket-messages.game-version-changed" | myTranslate }}</p>
    <ng-container [ngTemplateOutlet]="buttonReloadTpl"></ng-container>
  </ng-container>
</ng-container>

<!-- BUTTON RELOAD TPL -->
<ng-template #buttonReloadTpl>
  <button (click)="reloadApp()" class="base primary text-uppercase">
    {{ "socket-messages.button-reload-game" | myTranslate }}
  </button>
</ng-template>

<!-- BUTTON LOGOUT TPL -->
<ng-template #buttonLogoutTpl>
  <button (click)="logout()" class="base primary text-uppercase">
    {{ "global.logout" | myTranslate }}
  </button>
</ng-template>
