import { InjectorVariable } from '../../../../../../core/utility/injector-instance.helper';
import { ParametersService } from '../../../../../../core/providers/parameters.service';

export function getOutcomeParameters(parameters: any[]) {
  const parametersService = InjectorVariable.instance.get<ParametersService>(ParametersService);

  return parametersService
    .getParametersFromValues(parameters)
    .filter(parameter => parameter.outcome > 0);
}

export function getNotOutcomeParameters(parameters: any[]) {
  const parametersService = InjectorVariable.instance.get<ParametersService>(ParametersService);

  return parametersService
    .getParametersFromValues(parameters)
    .filter(parameter => parameter.income > 0);
}
