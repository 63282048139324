import { StorageLocationVariableJs } from "../../../storage/core/location/location-variable-js.class";
import { STORAGE_LOCATION_TYPE } from "./location-type.const";
import { StorageLocationSession } from '../../../storage/core/location/location-session';
import { StorageLocationLocal } from '../../../storage/core/location/location-local';

export const STORAGE_DEFINITIONS = {
  SESSION_STORAGE: new StorageLocationSession({
    type: STORAGE_LOCATION_TYPE.SESSION_STORAGE,
  }),

  LOCAL_STORAGE: new StorageLocationLocal({
    type: STORAGE_LOCATION_TYPE.LOCAL_STORAGE,
  }),

  VARIABLE_JS: new StorageLocationVariableJs({
    type: STORAGE_LOCATION_TYPE.VARIABLE_JS,
  }),
};
