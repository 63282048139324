import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AbstractBuildingUpgradeComponent } from "../../../../abstract/core/abstract-building-upgrade.component";
import { upgradeDifferenceParameters } from "../../../../helpers/core/upgrade.helpers";
import { generateEachPages } from "../../../../../../../shared/helpers/generate-pages.helper";
import { getNotOutcomeParameters, getOutcomeParameters } from "../../../../helpers/custom/outcome-parameters.helper";

@Component({
  selector: "app-building-upgrade",
  templateUrl: "./building-upgrade.component.html",
})
export class BuildingUpgradeComponent extends AbstractBuildingUpgradeComponent implements OnInit, OnDestroy, AfterViewInit {
  notOutcomeParameters: any[];
  outcomeParameters: any[];

  notOutcomeParametersUpgrade;
  outcomeParametersUpgrade;

  outcomeParametersDiffParameters;
  outcomeParametersRequirements;

  ngOnInit() {
    this.subscribeBoardTile();
  }

  ngAfterViewInit() {
    this.setSwiper();
  }

  getUpgradeDifferenceParameters() {
    this.setParameters();

    this.diffParametersSlider.items = [];
    this.diffParametersSlider.pages = [];
    this.diffParametersSlider = generateEachPages({
      ...this.diffParametersSlider,
      items: upgradeDifferenceParameters(this.notOutcomeParameters, this.notOutcomeParametersUpgrade),
    });

    this.outcomeParametersDiffParameters = upgradeDifferenceParameters(this.outcomeParameters, this.outcomeParametersUpgrade);

    this.outcomeParametersRequirements = this.outcomeParametersDiffParameters.map(parameter => {
      return {
        ...parameter.upgrade,
        outcome: parameter.parameters.outcome_diff === 0 ? 0 : parameter.parameters.outcome_diff || parameter.upgrade.outcome,
      };
    });

    this.afterGetDataRequests();
  }

  setParameters() {
    this.notOutcomeParameters = getNotOutcomeParameters(this.buildingDetails.parameters);
    this.outcomeParameters = getOutcomeParameters(this.buildingDetails.parameters);

    this.notOutcomeParametersUpgrade = getNotOutcomeParameters(this.upgradeBuildingDetails.parameters);
    this.outcomeParametersUpgrade = getOutcomeParameters(this.upgradeBuildingDetails.parameters);
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
