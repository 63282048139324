import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { HudModule } from "../../game-ui/hud/module/core/hud.module";
import { ScreenToIsoComponent } from "./components/game-debug/components/screen-to-iso/screen-to-iso.component";
import { WorldInfoComponent } from "./components/game-debug/components/world-info/world-info.component";
import { GameDebugComponent } from "./components/game-debug/game-debug.component";
import { OnOffPipe } from "./components/game-debug/pipes/on-off.pipe";
import { MobileGameEnginePceComponent } from "./components/game-engine-pce/game-engine-pce.component";

@NgModule({
  declarations: [MobileGameEnginePceComponent, GameDebugComponent, ScreenToIsoComponent, WorldInfoComponent, OnOffPipe],
  imports: [CommonModule, HudModule, FormsModule, RouterModule],
  exports: [MobileGameEnginePceComponent],
})
export class GameEngineMobileModule {}
