export const MOCK_STATE_APPLICATION: any = {
  auth: {
    login: {
      error: null,
      isTokenRefreshing: false,
      isAuthenticating: false,
    },
    changePassword: {
      error: null,
      isChanging: false,
      tempToken: null,
    },
  },
  game: {
    ready: false,
    readyToStart: false,
    readyToLoad: false,
    isLoading: false,
    boardState: {
      isLoading: false,
      loaded: true,
      board: [
        {
          player_tile_id: 55200,
          state: "active",
          resource_left: null,
          player_building: {
            upgrading_from_building: null,
            player_mission: null,
            auto_production_amount: 0,
            player_building_id: 187242,
            upgrade_building_id: null,
            building_id: 1000,
            group_type: "normal",
            category_id: 1,
            group: "hidden",
            group_description: "",
            is_moveable: false,
            is_deletable: false,
            moveable_from_level: null,
            deletable_from_level: null,
            tile_type: {
              type_id: 1,
              width: 1,
              height: 1,
            },
            icon: "xxx",
            automatic_product: null,
            automatic_currency: null,
            level: 1,
            name: "Ukryty",
            additional_details: "",
            additional_board_data: [],
            built_at: "2021-02-03T09:41:45+01:00",
            status: 1,
            variant: null,
            last_production_recipe: null,
            player_building_disaster: null,
            visual_variant: 1,
          },
          tile_id: 157,
          x_pos: 640000,
          y_pos: 640000,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 0,
          currency_prices: [],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 158,
          x_pos: -2560,
          y_pos: 5760,
          z_pos: null,
          tile_type: {
            type_id: 3,
            width: 1,
            height: 1,
          },
          border_bitmask: 0,
          empty_icon: null,
          required_level: 28,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 159,
          x_pos: -1920,
          y_pos: 5760,
          z_pos: null,
          tile_type: {
            type_id: 3,
            width: 1,
            height: 1,
          },
          border_bitmask: 0,
          empty_icon: null,
          required_level: 28,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 160,
          x_pos: -1280,
          y_pos: 5760,
          z_pos: null,
          tile_type: {
            type_id: 3,
            width: 1,
            height: 1,
          },
          border_bitmask: 0,
          empty_icon: null,
          required_level: 28,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 161,
          x_pos: -640,
          y_pos: 5760,
          z_pos: null,
          tile_type: {
            type_id: 3,
            width: 1,
            height: 1,
          },
          border_bitmask: 0,
          empty_icon: null,
          required_level: 28,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 162,
          x_pos: 0,
          y_pos: 5760,
          z_pos: null,
          tile_type: {
            type_id: 3,
            width: 1,
            height: 1,
          },
          border_bitmask: 0,
          empty_icon: null,
          required_level: 28,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 163,
          x_pos: 640,
          y_pos: 5760,
          z_pos: null,
          tile_type: {
            type_id: 3,
            width: 1,
            height: 1,
          },
          border_bitmask: 0,
          empty_icon: null,
          required_level: 28,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 26,
          x_pos: -5120,
          y_pos: 3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 42,
          currency_prices: [
            {
              currency_id: 1,
              amount: 650,
            },
            {
              currency_id: 2,
              amount: 850,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 13,
          x_pos: -4480,
          y_pos: 3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 42,
          currency_prices: [
            {
              currency_id: 1,
              amount: 650,
            },
            {
              currency_id: 2,
              amount: 850,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 39,
          x_pos: -2560,
          y_pos: 3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 5,
          currency_prices: [
            {
              currency_id: 1,
              amount: 50,
            },
            {
              currency_id: 2,
              amount: 100,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 52,
          x_pos: -1920,
          y_pos: 3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 8,
          currency_prices: [
            {
              currency_id: 1,
              amount: 150,
            },
            {
              currency_id: 2,
              amount: 300,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 65,
          x_pos: -1280,
          y_pos: 3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 11,
          currency_prices: [
            {
              currency_id: 1,
              amount: 200,
            },
            {
              currency_id: 2,
              amount: 400,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 78,
          x_pos: -640,
          y_pos: 3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 13,
          currency_prices: [
            {
              currency_id: 1,
              amount: 300,
            },
            {
              currency_id: 2,
              amount: 500,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 91,
          x_pos: 0,
          y_pos: 3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 17,
          currency_prices: [
            {
              currency_id: 1,
              amount: 450,
            },
            {
              currency_id: 2,
              amount: 600,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 104,
          x_pos: 640,
          y_pos: 3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 22,
          currency_prices: [
            {
              currency_id: 1,
              amount: 550,
            },
            {
              currency_id: 2,
              amount: 750,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 117,
          x_pos: 1280,
          y_pos: 3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 99,
          currency_prices: [],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 130,
          x_pos: 1920,
          y_pos: 3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 0,
          currency_prices: [],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 143,
          x_pos: 2560,
          y_pos: 3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 27,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 156,
          x_pos: 3200,
          y_pos: 3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 0,
          currency_prices: [],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 25,
          x_pos: -5120,
          y_pos: 3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 42,
          currency_prices: [
            {
              currency_id: 1,
              amount: 650,
            },
            {
              currency_id: 2,
              amount: 850,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 12,
          x_pos: -4480,
          y_pos: 3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 42,
          currency_prices: [
            {
              currency_id: 1,
              amount: 650,
            },
            {
              currency_id: 2,
              amount: 850,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 38,
          x_pos: -2560,
          y_pos: 3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 5,
          currency_prices: [
            {
              currency_id: 1,
              amount: 50,
            },
            {
              currency_id: 2,
              amount: 100,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 51,
          x_pos: -1920,
          y_pos: 3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 7,
          currency_prices: [
            {
              currency_id: 1,
              amount: 100,
            },
            {
              currency_id: 2,
              amount: 200,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 64,
          x_pos: -1280,
          y_pos: 3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 11,
          currency_prices: [
            {
              currency_id: 1,
              amount: 200,
            },
            {
              currency_id: 2,
              amount: 400,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 77,
          x_pos: -640,
          y_pos: 3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 13,
          currency_prices: [
            {
              currency_id: 1,
              amount: 300,
            },
            {
              currency_id: 2,
              amount: 500,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 90,
          x_pos: 0,
          y_pos: 3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 17,
          currency_prices: [
            {
              currency_id: 1,
              amount: 450,
            },
            {
              currency_id: 2,
              amount: 600,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 103,
          x_pos: 640,
          y_pos: 3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 22,
          currency_prices: [
            {
              currency_id: 1,
              amount: 550,
            },
            {
              currency_id: 2,
              amount: 750,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 116,
          x_pos: 1280,
          y_pos: 3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 25,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 750,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 129,
          x_pos: 1920,
          y_pos: 3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 27,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 142,
          x_pos: 2560,
          y_pos: 3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 27,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 155,
          x_pos: 3200,
          y_pos: 3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 38,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 24,
          x_pos: -5120,
          y_pos: 2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 42,
          currency_prices: [
            {
              currency_id: 1,
              amount: 650,
            },
            {
              currency_id: 2,
              amount: 850,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 11,
          x_pos: -4480,
          y_pos: 2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 42,
          currency_prices: [
            {
              currency_id: 1,
              amount: 650,
            },
            {
              currency_id: 2,
              amount: 850,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 37,
          x_pos: -2560,
          y_pos: 2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 5,
          currency_prices: [
            {
              currency_id: 1,
              amount: 50,
            },
            {
              currency_id: 2,
              amount: 100,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 50,
          x_pos: -1920,
          y_pos: 2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 7,
          currency_prices: [
            {
              currency_id: 1,
              amount: 100,
            },
            {
              currency_id: 2,
              amount: 200,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 63,
          x_pos: -1280,
          y_pos: 2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 8,
          currency_prices: [
            {
              currency_id: 1,
              amount: 150,
            },
            {
              currency_id: 2,
              amount: 300,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 76,
          x_pos: -640,
          y_pos: 2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 13,
          currency_prices: [
            {
              currency_id: 1,
              amount: 300,
            },
            {
              currency_id: 2,
              amount: 500,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 89,
          x_pos: 0,
          y_pos: 2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 17,
          currency_prices: [
            {
              currency_id: 1,
              amount: 450,
            },
            {
              currency_id: 2,
              amount: 600,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 102,
          x_pos: 640,
          y_pos: 2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 22,
          currency_prices: [
            {
              currency_id: 1,
              amount: 550,
            },
            {
              currency_id: 2,
              amount: 750,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 115,
          x_pos: 1280,
          y_pos: 2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 25,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 750,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 128,
          x_pos: 1920,
          y_pos: 2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 27,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 141,
          x_pos: 2560,
          y_pos: 2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 27,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 154,
          x_pos: 3200,
          y_pos: 2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 38,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 23,
          x_pos: -5120,
          y_pos: 1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 15,
          currency_prices: [
            {
              currency_id: 2,
              amount: 250,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 10,
          x_pos: -4480,
          y_pos: 1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 42,
          currency_prices: [
            {
              currency_id: 2,
              amount: 250,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: 55161,
          state: "active",
          resource_left: null,
          player_building: {
            upgrading_from_building: null,
            player_mission: null,
            auto_production_amount: 0,
            player_building_id: 187245,
            upgrade_building_id: 2,
            building_id: 1,
            group_type: "normal",
            category_id: 1,
            group: "house",
            group_description: "Cichy i słoneczny dom. Budynek szczególnie lubiany przez mieszkańców ceniących prywatność.",
            is_moveable: false,
            is_deletable: false,
            moveable_from_level: null,
            deletable_from_level: null,
            tile_type: {
              type_id: 1,
              width: 1,
              height: 1,
            },
            icon: "house",
            automatic_product: null,
            automatic_currency: null,
            level: 1,
            name: "Dom",
            additional_details: "",
            additional_board_data: [],
            built_at: "2021-02-03T09:42:43+01:00",
            status: 1,
            variant: null,
            last_production_recipe: null,
            player_building_disaster: null,
            visual_variant: 1,
          },
          tile_id: 36,
          x_pos: -2560,
          y_pos: 1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 5,
          currency_prices: [
            {
              currency_id: 1,
              amount: 50,
            },
            {
              currency_id: 2,
              amount: 100,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 49,
          x_pos: -1920,
          y_pos: 1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 7,
          currency_prices: [
            {
              currency_id: 1,
              amount: 100,
            },
            {
              currency_id: 2,
              amount: 200,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 62,
          x_pos: -1280,
          y_pos: 1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 8,
          currency_prices: [
            {
              currency_id: 1,
              amount: 150,
            },
            {
              currency_id: 2,
              amount: 300,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 75,
          x_pos: -640,
          y_pos: 1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 13,
          currency_prices: [
            {
              currency_id: 1,
              amount: 300,
            },
            {
              currency_id: 2,
              amount: 500,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 88,
          x_pos: 0,
          y_pos: 1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 17,
          currency_prices: [
            {
              currency_id: 1,
              amount: 450,
            },
            {
              currency_id: 2,
              amount: 600,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 101,
          x_pos: 640,
          y_pos: 1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 22,
          currency_prices: [
            {
              currency_id: 1,
              amount: 550,
            },
            {
              currency_id: 2,
              amount: 750,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 114,
          x_pos: 1280,
          y_pos: 1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 25,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 750,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 127,
          x_pos: 1920,
          y_pos: 1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 27,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 140,
          x_pos: 2560,
          y_pos: 1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 27,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 153,
          x_pos: 3200,
          y_pos: 1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 38,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 22,
          x_pos: -5120,
          y_pos: 1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 15,
          currency_prices: [
            {
              currency_id: 2,
              amount: 250,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 9,
          x_pos: -4480,
          y_pos: 1280,
          z_pos: null,
          tile_type: {
            type_id: 2,
            width: 2,
            height: 2,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 8,
          currency_prices: [
            {
              currency_id: 1,
              amount: 150,
            },
            {
              currency_id: 2,
              amount: 300,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: 55158,
          state: "active",
          resource_left: null,
          player_building: {
            upgrading_from_building: null,
            player_mission: null,
            auto_production_amount: 0,
            player_building_id: 187248,
            upgrade_building_id: 2,
            building_id: 1,
            group_type: "normal",
            category_id: 1,
            group: "house",
            group_description: "Cichy i słoneczny dom. Budynek szczególnie lubiany przez mieszkańców ceniących prywatność.",
            is_moveable: false,
            is_deletable: false,
            moveable_from_level: null,
            deletable_from_level: null,
            tile_type: {
              type_id: 1,
              width: 1,
              height: 1,
            },
            icon: "house",
            automatic_product: null,
            automatic_currency: null,
            level: 1,
            name: "Dom",
            additional_details: "",
            additional_board_data: [],
            built_at: "2021-02-03T09:43:04+01:00",
            status: 1,
            variant: null,
            last_production_recipe: null,
            player_building_disaster: null,
            visual_variant: 1,
          },
          tile_id: 35,
          x_pos: -2560,
          y_pos: 1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 5,
          currency_prices: [
            {
              currency_id: 1,
              amount: 50,
            },
            {
              currency_id: 2,
              amount: 100,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: 55170,
          state: "active",
          resource_left: null,
          player_building: null,
          tile_id: 48,
          x_pos: -1920,
          y_pos: 1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 7,
          currency_prices: [
            {
              currency_id: 1,
              amount: 100,
            },
            {
              currency_id: 2,
              amount: 200,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: 55182,
          state: "active",
          resource_left: null,
          player_building: null,
          tile_id: 61,
          x_pos: -1280,
          y_pos: 1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 7,
          currency_prices: [
            {
              currency_id: 1,
              amount: 100,
            },
            {
              currency_id: 2,
              amount: 200,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 74,
          x_pos: -640,
          y_pos: 1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 13,
          currency_prices: [
            {
              currency_id: 1,
              amount: 300,
            },
            {
              currency_id: 2,
              amount: 500,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 87,
          x_pos: 0,
          y_pos: 1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 17,
          currency_prices: [
            {
              currency_id: 1,
              amount: 450,
            },
            {
              currency_id: 2,
              amount: 600,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 100,
          x_pos: 640,
          y_pos: 1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 21,
          currency_prices: [
            {
              currency_id: 1,
              amount: 550,
            },
            {
              currency_id: 2,
              amount: 750,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 113,
          x_pos: 1280,
          y_pos: 1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 25,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 750,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 126,
          x_pos: 1920,
          y_pos: 1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 27,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 139,
          x_pos: 2560,
          y_pos: 1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 27,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 152,
          x_pos: 3200,
          y_pos: 1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 38,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 21,
          x_pos: -5120,
          y_pos: 640,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 15,
          currency_prices: [
            {
              currency_id: 2,
              amount: 250,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 7,
          x_pos: -4480,
          y_pos: 640,
          z_pos: null,
          tile_type: {
            type_id: 2,
            width: 2,
            height: 2,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 20,
          currency_prices: [
            {
              currency_id: 1,
              amount: 550,
            },
            {
              currency_id: 2,
              amount: 700,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: 55155,
          state: "active",
          resource_left: null,
          player_building: {
            upgrading_from_building: null,
            player_mission: null,
            auto_production_amount: 0,
            player_building_id: 187263,
            upgrade_building_id: 2,
            building_id: 1,
            group_type: "normal",
            category_id: 1,
            group: "house",
            group_description: "Cichy i słoneczny dom. Budynek szczególnie lubiany przez mieszkańców ceniących prywatność.",
            is_moveable: false,
            is_deletable: false,
            moveable_from_level: null,
            deletable_from_level: null,
            tile_type: {
              type_id: 1,
              width: 1,
              height: 1,
            },
            icon: "house",
            automatic_product: null,
            automatic_currency: null,
            level: 1,
            name: "Dom",
            additional_details: "",
            additional_board_data: [],
            built_at: "2021-02-03T09:47:08+01:00",
            status: 1,
            variant: null,
            last_production_recipe: null,
            player_building_disaster: null,
            visual_variant: 1,
          },
          tile_id: 34,
          x_pos: -2560,
          y_pos: 640,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 5,
          currency_prices: [
            {
              currency_id: 1,
              amount: 50,
            },
            {
              currency_id: 2,
              amount: 100,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: 55167,
          state: "active",
          resource_left: null,
          player_building: null,
          tile_id: 47,
          x_pos: -1920,
          y_pos: 640,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 7,
          currency_prices: [
            {
              currency_id: 1,
              amount: 100,
            },
            {
              currency_id: 2,
              amount: 200,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: 55179,
          state: "active",
          resource_left: null,
          player_building: null,
          tile_id: 60,
          x_pos: -1280,
          y_pos: 640,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 7,
          currency_prices: [
            {
              currency_id: 1,
              amount: 100,
            },
            {
              currency_id: 2,
              amount: 200,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 73,
          x_pos: -640,
          y_pos: 640,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 13,
          currency_prices: [
            {
              currency_id: 1,
              amount: 300,
            },
            {
              currency_id: 2,
              amount: 500,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 86,
          x_pos: 0,
          y_pos: 640,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 17,
          currency_prices: [
            {
              currency_id: 1,
              amount: 450,
            },
            {
              currency_id: 2,
              amount: 600,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 99,
          x_pos: 640,
          y_pos: 640,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 21,
          currency_prices: [
            {
              currency_id: 1,
              amount: 550,
            },
            {
              currency_id: 2,
              amount: 750,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 112,
          x_pos: 1280,
          y_pos: 640,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 25,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 750,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 125,
          x_pos: 1920,
          y_pos: 640,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 27,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 138,
          x_pos: 2560,
          y_pos: 640,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 27,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 151,
          x_pos: 3200,
          y_pos: 640,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 38,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 20,
          x_pos: -5120,
          y_pos: 0,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 42,
          currency_prices: [
            {
              currency_id: 1,
              amount: 650,
            },
            {
              currency_id: 2,
              amount: 850,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 8,
          x_pos: -4480,
          y_pos: 0,
          z_pos: null,
          tile_type: {
            type_id: 2,
            width: 2,
            height: 2,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 16,
          currency_prices: [
            {
              currency_id: 1,
              amount: 400,
            },
            {
              currency_id: 2,
              amount: 550,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: 55152,
          state: "active",
          resource_left: null,
          player_building: {
            upgrading_from_building: null,
            player_mission: null,
            auto_production_amount: 0,
            player_building_id: 187221,
            upgrade_building_id: 104,
            building_id: 103,
            group_type: "normal",
            category_id: 1,
            group: "park",
            group_description:
              "Park to doskonałe miejsce dla rozrywki na świeżym powietrzu. Zielone tereny stanowią idealną równowagę dla budynków, zadbane parki zapewnią także bezpieczeństwo w mieście.",
            is_moveable: false,
            is_deletable: false,
            moveable_from_level: null,
            deletable_from_level: null,
            tile_type: {
              type_id: 1,
              width: 1,
              height: 1,
            },
            icon: "park",
            automatic_product: null,
            automatic_currency: null,
            level: 1,
            name: "Park",
            additional_details: "",
            additional_board_data: [],
            built_at: "2021-02-03T09:41:45+01:00",
            status: 1,
            variant: null,
            last_production_recipe: null,
            player_building_disaster: null,
            visual_variant: 1,
          },
          tile_id: 33,
          x_pos: -2560,
          y_pos: 0,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 46,
          currency_prices: [
            {
              currency_id: 2,
              amount: 500,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 46,
          x_pos: -1920,
          y_pos: 0,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 40,
          currency_prices: [
            {
              currency_id: 2,
              amount: 500,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: 55176,
          state: "active",
          resource_left: null,
          player_building: {
            upgrading_from_building: null,
            player_mission: null,
            auto_production_amount: 1500,
            player_building_id: 187224,
            upgrade_building_id: 165,
            building_id: 164,
            group_type: "auto-production",
            category_id: 1,
            group: "cityhall",
            group_description:
              "Ratusz to Twoja siedziba, z której podejmujesz decyzje. Rozbudowa ratusza zwiększa pojemność skarbca na srebrne talary oraz ich gromadzoną ilość w cyklu godzinowym.",
            is_moveable: false,
            is_deletable: false,
            moveable_from_level: null,
            deletable_from_level: null,
            tile_type: {
              type_id: 1,
              width: 1,
              height: 1,
            },
            icon: "cityhall",
            automatic_product: null,
            automatic_currency: {
              currency_id: 2,
            },
            level: 1,
            name: "Ratusz",
            additional_details: "",
            additional_board_data: {
              "auto-production-hourly-performance": 20,
              "storage-size": 1500,
            },
            built_at: "2021-02-03T09:41:45+01:00",
            status: 1,
            variant: null,
            last_production_recipe: null,
            player_building_disaster: null,
            visual_variant: 1,
          },
          tile_id: 59,
          x_pos: -1280,
          y_pos: 0,
          z_pos: null,
          tile_type: {
            type_id: 4,
            width: 2,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 99,
          currency_prices: [],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: 55188,
          state: "active",
          resource_left: null,
          player_building: {
            upgrading_from_building: null,
            player_mission: null,
            auto_production_amount: 0,
            player_building_id: 187230,
            upgrade_building_id: 2,
            building_id: 1,
            group_type: "normal",
            category_id: 1,
            group: "house",
            group_description: "Cichy i słoneczny dom. Budynek szczególnie lubiany przez mieszkańców ceniących prywatność.",
            is_moveable: false,
            is_deletable: false,
            moveable_from_level: null,
            deletable_from_level: null,
            tile_type: {
              type_id: 1,
              width: 1,
              height: 1,
            },
            icon: "house",
            automatic_product: null,
            automatic_currency: null,
            level: 1,
            name: "Dom",
            additional_details: "",
            additional_board_data: [],
            built_at: "2021-02-03T09:41:45+01:00",
            status: 1,
            variant: null,
            last_production_recipe: null,
            player_building_disaster: null,
            visual_variant: 1,
          },
          tile_id: 72,
          x_pos: -640,
          y_pos: 0,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 15,
          currency_prices: [
            {
              currency_id: 2,
              amount: 250,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: 55194,
          state: "active",
          resource_left: null,
          player_building: {
            upgrading_from_building: null,
            player_mission: null,
            auto_production_amount: 0,
            player_building_id: 187236,
            upgrade_building_id: 2,
            building_id: 1,
            group_type: "normal",
            category_id: 1,
            group: "house",
            group_description: "Cichy i słoneczny dom. Budynek szczególnie lubiany przez mieszkańców ceniących prywatność.",
            is_moveable: false,
            is_deletable: false,
            moveable_from_level: null,
            deletable_from_level: null,
            tile_type: {
              type_id: 1,
              width: 1,
              height: 1,
            },
            icon: "house",
            automatic_product: null,
            automatic_currency: null,
            level: 1,
            name: "Dom",
            additional_details: "",
            additional_board_data: [],
            built_at: "2021-02-03T09:41:45+01:00",
            status: 1,
            variant: null,
            last_production_recipe: null,
            player_building_disaster: null,
            visual_variant: 1,
          },
          tile_id: 85,
          x_pos: 0,
          y_pos: 0,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 15,
          currency_prices: [
            {
              currency_id: 2,
              amount: 250,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 98,
          x_pos: 640,
          y_pos: 0,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 14,
          empty_icon: null,
          required_level: 21,
          currency_prices: [
            {
              currency_id: 1,
              amount: 550,
            },
            {
              currency_id: 2,
              amount: 750,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 111,
          x_pos: 1280,
          y_pos: 0,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 14,
          empty_icon: null,
          required_level: 25,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 750,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 124,
          x_pos: 1920,
          y_pos: 0,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 26,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 137,
          x_pos: 2560,
          y_pos: 0,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 27,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 150,
          x_pos: 3200,
          y_pos: 0,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 1,
          currency_prices: [],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 19,
          x_pos: -5120,
          y_pos: -640,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 42,
          currency_prices: [
            {
              currency_id: 1,
              amount: 650,
            },
            {
              currency_id: 2,
              amount: 850,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 6,
          x_pos: -4480,
          y_pos: -640,
          z_pos: null,
          tile_type: {
            type_id: 2,
            width: 2,
            height: 2,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 15,
          currency_prices: [
            {
              currency_id: 1,
              amount: 550,
            },
            {
              currency_id: 2,
              amount: 700,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: 55149,
          state: "active",
          resource_left: null,
          player_building: {
            upgrading_from_building: null,
            player_mission: null,
            auto_production_amount: 0,
            player_building_id: 187254,
            upgrade_building_id: 152,
            building_id: 151,
            group_type: "production",
            category_id: 1,
            group: "sawmill",
            group_description: "Tartak zajmuje się obróbką drewna, które jest potrzebne do produkcji mebli.",
            is_moveable: false,
            is_deletable: false,
            moveable_from_level: null,
            deletable_from_level: null,
            tile_type: {
              type_id: 1,
              width: 1,
              height: 1,
            },
            icon: "sawmill",
            automatic_product: null,
            automatic_currency: null,
            level: 1,
            name: "Tartak",
            additional_details: "",
            additional_board_data: [],
            built_at: "2021-02-03T09:46:26+01:00",
            status: 1,
            variant: null,
            last_production_recipe: {
              currency_prizes: [],
              product_prizes: [
                {
                  product_id: 1,
                  category: {
                    storage_id: 1,
                    trading_currency_id: null,
                    id: 2,
                  },
                  name: "Drewno",
                  description:
                    "Odpowiednio obrobione i wyselekcjonowane kawałki drewna posłużą Ci do produkcji mebli oraz budowy budynk&oacute;w. Miejsce obr&oacute;bki: Tartak.",
                  icon: "wood",
                  amount: 20,
                },
              ],
              production_recipe_id: 1,
              alternative_icon: null,
              alternative_name: null,
            },
            player_building_disaster: null,
            visual_variant: 1,
          },
          tile_id: 32,
          x_pos: -2560,
          y_pos: -640,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 46,
          currency_prices: [
            {
              currency_id: 2,
              amount: 500,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: {
            status: "active",
            player_building_production_id: 152469,
            currency_prizes: [],
            product_prizes: [
              {
                product_id: 1,
                category: {
                  storage_id: 1,
                  trading_currency_id: null,
                  id: 2,
                },
                name: "Drewno",
                description:
                  "Odpowiednio obrobione i wyselekcjonowane kawałki drewna posłużą Ci do produkcji mebli oraz budowy budynk&oacute;w. Miejsce obr&oacute;bki: Tartak.",
                icon: "wood",
                amount: 20,
              },
            ],
            production_recipe_id: 1,
            alternative_icon: null,
            alternative_name: null,
            finishes_at: "2021-02-04T04:39:00+01:00",
            created_at: "2021-02-03T09:46:37+01:00",
          },
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: 55164,
          state: "active",
          resource_left: null,
          player_building: {
            upgrading_from_building: null,
            player_mission: null,
            auto_production_amount: 0,
            player_building_id: 187266,
            upgrade_building_id: 38,
            building_id: 37,
            group_type: "normal",
            category_id: 1,
            group: "market",
            group_description: "Ryneczek – nie ma lepszego miejsca na zaopatrzenie się w świeże warzywa i owoce. Rozwija usługi.",
            is_moveable: false,
            is_deletable: false,
            moveable_from_level: null,
            deletable_from_level: null,
            tile_type: {
              type_id: 1,
              width: 1,
              height: 1,
            },
            icon: "market",
            automatic_product: null,
            automatic_currency: null,
            level: 1,
            name: "Ryneczek",
            additional_details: "",
            additional_board_data: [],
            built_at: "2021-02-03T09:57:20+01:00",
            status: 1,
            variant: null,
            last_production_recipe: null,
            player_building_disaster: null,
            visual_variant: 1,
          },
          tile_id: 45,
          x_pos: -1920,
          y_pos: -640,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 40,
          currency_prices: [
            {
              currency_id: 2,
              amount: 500,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: 55173,
          state: "active",
          resource_left: null,
          player_building: {
            upgrading_from_building: null,
            player_mission: null,
            auto_production_amount: 0,
            player_building_id: 187251,
            upgrade_building_id: 122,
            building_id: 121,
            group_type: "normal",
            category_id: 1,
            group: "warehouse",
            group_description: "W magazynie znajdują się wszystkie wytworzone i kupione przez Ciebie produkty.",
            is_moveable: false,
            is_deletable: false,
            moveable_from_level: null,
            deletable_from_level: null,
            tile_type: {
              type_id: 1,
              width: 1,
              height: 1,
            },
            icon: "warehouse",
            automatic_product: null,
            automatic_currency: null,
            level: 1,
            name: "Magazyn",
            additional_details: "",
            additional_board_data: [],
            built_at: "2021-02-03T09:44:21+01:00",
            status: 1,
            variant: null,
            last_production_recipe: null,
            player_building_disaster: null,
            visual_variant: 1,
          },
          tile_id: 58,
          x_pos: -1280,
          y_pos: -640,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 14,
          empty_icon: null,
          required_level: 99,
          currency_prices: [],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: 55185,
          state: "active",
          resource_left: null,
          player_building: {
            upgrading_from_building: null,
            player_mission: null,
            auto_production_amount: 0,
            player_building_id: 187227,
            upgrade_building_id: 2,
            building_id: 1,
            group_type: "normal",
            category_id: 1,
            group: "house",
            group_description: "Cichy i słoneczny dom. Budynek szczególnie lubiany przez mieszkańców ceniących prywatność.",
            is_moveable: false,
            is_deletable: false,
            moveable_from_level: null,
            deletable_from_level: null,
            tile_type: {
              type_id: 1,
              width: 1,
              height: 1,
            },
            icon: "house",
            automatic_product: null,
            automatic_currency: null,
            level: 1,
            name: "Dom",
            additional_details: "",
            additional_board_data: [],
            built_at: "2021-02-03T09:41:45+01:00",
            status: 1,
            variant: null,
            last_production_recipe: null,
            player_building_disaster: null,
            visual_variant: 1,
          },
          tile_id: 71,
          x_pos: -640,
          y_pos: -640,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 14,
          empty_icon: null,
          required_level: 15,
          currency_prices: [
            {
              currency_id: 2,
              amount: 250,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: 55191,
          state: "active",
          resource_left: null,
          player_building: {
            upgrading_from_building: null,
            player_mission: null,
            auto_production_amount: 0,
            player_building_id: 187233,
            upgrade_building_id: 26,
            building_id: 25,
            group_type: "normal",
            category_id: 1,
            group: "bakery",
            group_description: "Wypieki to podstawa diety mieszkańców. Piekarnia rozwija zakres usług.",
            is_moveable: false,
            is_deletable: false,
            moveable_from_level: null,
            deletable_from_level: null,
            tile_type: {
              type_id: 1,
              width: 1,
              height: 1,
            },
            icon: "bakery",
            automatic_product: null,
            automatic_currency: null,
            level: 1,
            name: "Piekarnia",
            additional_details: "",
            additional_board_data: [],
            built_at: "2021-02-03T09:41:45+01:00",
            status: 1,
            variant: null,
            last_production_recipe: null,
            player_building_disaster: null,
            visual_variant: 1,
          },
          tile_id: 84,
          x_pos: 0,
          y_pos: -640,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 14,
          empty_icon: null,
          required_level: 15,
          currency_prices: [
            {
              currency_id: 2,
              amount: 250,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 97,
          x_pos: 640,
          y_pos: -640,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 14,
          empty_icon: null,
          required_level: 15,
          currency_prices: [
            {
              currency_id: 1,
              amount: 500,
            },
            {
              currency_id: 2,
              amount: 650,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 110,
          x_pos: 1280,
          y_pos: -640,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 14,
          empty_icon: null,
          required_level: 24,
          currency_prices: [
            {
              currency_id: 1,
              amount: 550,
            },
            {
              currency_id: 2,
              amount: 750,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 123,
          x_pos: 1920,
          y_pos: -640,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 14,
          empty_icon: null,
          required_level: 26,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 136,
          x_pos: 2560,
          y_pos: -640,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 27,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: 55197,
          state: "active",
          resource_left: null,
          player_building: {
            upgrading_from_building: null,
            player_mission: null,
            auto_production_amount: 0,
            player_building_id: 187239,
            upgrade_building_id: 214,
            building_id: 220,
            group_type: "special",
            category_id: 1,
            group: "closed_port",
            group_description: "Odbuduj port, aby otworzyć Komp Town na handel z innymi miastami.",
            is_moveable: false,
            is_deletable: false,
            moveable_from_level: null,
            deletable_from_level: null,
            tile_type: {
              type_id: 2,
              width: 2,
              height: 2,
            },
            icon: "burn_port",
            automatic_product: null,
            automatic_currency: null,
            level: 1,
            name: "Nieczynny Port",
            additional_details: "Odbuduj port, aby otworzyć KompTown na handel z innymi miastami.",
            additional_board_data: [],
            built_at: "2021-02-03T09:41:45+01:00",
            status: 1,
            variant: null,
            last_production_recipe: null,
            player_building_disaster: null,
            visual_variant: 1,
          },
          tile_id: 149,
          x_pos: 3840,
          y_pos: -640,
          z_pos: null,
          tile_type: {
            type_id: 2,
            width: 2,
            height: 2,
          },
          border_bitmask: 0,
          empty_icon: null,
          required_level: 1,
          currency_prices: [],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 18,
          x_pos: -5120,
          y_pos: -1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 42,
          currency_prices: [
            {
              currency_id: 1,
              amount: 650,
            },
            {
              currency_id: 2,
              amount: 850,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 5,
          x_pos: -4480,
          y_pos: -1280,
          z_pos: null,
          tile_type: {
            type_id: 2,
            width: 2,
            height: 2,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 21,
          currency_prices: [
            {
              currency_id: 1,
              amount: 550,
            },
            {
              currency_id: 2,
              amount: 700,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 31,
          x_pos: -2560,
          y_pos: -1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 5,
          currency_prices: [
            {
              currency_id: 1,
              amount: 50,
            },
            {
              currency_id: 2,
              amount: 100,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 44,
          x_pos: -1920,
          y_pos: -1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 5,
          currency_prices: [
            {
              currency_id: 1,
              amount: 50,
            },
            {
              currency_id: 2,
              amount: 100,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 57,
          x_pos: -1280,
          y_pos: -1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 5,
          currency_prices: [
            {
              currency_id: 1,
              amount: 50,
            },
            {
              currency_id: 2,
              amount: 100,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 70,
          x_pos: -640,
          y_pos: -1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 11,
          currency_prices: [
            {
              currency_id: 1,
              amount: 200,
            },
            {
              currency_id: 2,
              amount: 400,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 83,
          x_pos: 0,
          y_pos: -1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 16,
          currency_prices: [
            {
              currency_id: 1,
              amount: 400,
            },
            {
              currency_id: 2,
              amount: 550,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 96,
          x_pos: 640,
          y_pos: -1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 15,
          currency_prices: [
            {
              currency_id: 1,
              amount: 500,
            },
            {
              currency_id: 2,
              amount: 650,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 109,
          x_pos: 1280,
          y_pos: -1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 24,
          currency_prices: [
            {
              currency_id: 1,
              amount: 550,
            },
            {
              currency_id: 2,
              amount: 750,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 122,
          x_pos: 1920,
          y_pos: -1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 26,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 135,
          x_pos: 2560,
          y_pos: -1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 27,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 145,
          x_pos: 3200,
          y_pos: -1280,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 38,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 17,
          x_pos: -5120,
          y_pos: -1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 42,
          currency_prices: [
            {
              currency_id: 1,
              amount: 650,
            },
            {
              currency_id: 2,
              amount: 850,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 4,
          x_pos: -4480,
          y_pos: -1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 42,
          currency_prices: [
            {
              currency_id: 1,
              amount: 650,
            },
            {
              currency_id: 2,
              amount: 850,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 30,
          x_pos: -2560,
          y_pos: -1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 5,
          currency_prices: [
            {
              currency_id: 1,
              amount: 50,
            },
            {
              currency_id: 2,
              amount: 100,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 43,
          x_pos: -1920,
          y_pos: -1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 5,
          currency_prices: [
            {
              currency_id: 1,
              amount: 50,
            },
            {
              currency_id: 2,
              amount: 100,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 56,
          x_pos: -1280,
          y_pos: -1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 8,
          currency_prices: [
            {
              currency_id: 1,
              amount: 150,
            },
            {
              currency_id: 2,
              amount: 300,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 69,
          x_pos: -640,
          y_pos: -1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 11,
          currency_prices: [
            {
              currency_id: 1,
              amount: 200,
            },
            {
              currency_id: 2,
              amount: 400,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 82,
          x_pos: 0,
          y_pos: -1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 16,
          currency_prices: [
            {
              currency_id: 1,
              amount: 400,
            },
            {
              currency_id: 2,
              amount: 550,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 95,
          x_pos: 640,
          y_pos: -1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 15,
          currency_prices: [
            {
              currency_id: 1,
              amount: 500,
            },
            {
              currency_id: 2,
              amount: 650,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 108,
          x_pos: 1280,
          y_pos: -1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 23,
          currency_prices: [
            {
              currency_id: 1,
              amount: 550,
            },
            {
              currency_id: 2,
              amount: 750,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 121,
          x_pos: 1920,
          y_pos: -1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 26,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 134,
          x_pos: 2560,
          y_pos: -1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 27,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 147,
          x_pos: 3200,
          y_pos: -1920,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 38,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 16,
          x_pos: -5120,
          y_pos: -2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 42,
          currency_prices: [
            {
              currency_id: 1,
              amount: 650,
            },
            {
              currency_id: 2,
              amount: 850,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 3,
          x_pos: -4480,
          y_pos: -2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 42,
          currency_prices: [
            {
              currency_id: 1,
              amount: 650,
            },
            {
              currency_id: 2,
              amount: 850,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 29,
          x_pos: -2560,
          y_pos: -2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 5,
          currency_prices: [
            {
              currency_id: 1,
              amount: 50,
            },
            {
              currency_id: 2,
              amount: 100,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 42,
          x_pos: -1920,
          y_pos: -2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 7,
          currency_prices: [
            {
              currency_id: 1,
              amount: 100,
            },
            {
              currency_id: 2,
              amount: 200,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 55,
          x_pos: -1280,
          y_pos: -2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 8,
          currency_prices: [
            {
              currency_id: 1,
              amount: 150,
            },
            {
              currency_id: 2,
              amount: 300,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 68,
          x_pos: -640,
          y_pos: -2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 12,
          currency_prices: [
            {
              currency_id: 1,
              amount: 250,
            },
            {
              currency_id: 2,
              amount: 450,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 81,
          x_pos: 0,
          y_pos: -2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 16,
          currency_prices: [
            {
              currency_id: 1,
              amount: 400,
            },
            {
              currency_id: 2,
              amount: 550,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 94,
          x_pos: 640,
          y_pos: -2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 15,
          currency_prices: [
            {
              currency_id: 1,
              amount: 500,
            },
            {
              currency_id: 2,
              amount: 650,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 107,
          x_pos: 1280,
          y_pos: -2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 23,
          currency_prices: [
            {
              currency_id: 1,
              amount: 550,
            },
            {
              currency_id: 2,
              amount: 750,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 120,
          x_pos: 1920,
          y_pos: -2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 26,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 133,
          x_pos: 2560,
          y_pos: -2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 27,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 146,
          x_pos: 3200,
          y_pos: -2560,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 38,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 15,
          x_pos: -5120,
          y_pos: -3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 42,
          currency_prices: [
            {
              currency_id: 1,
              amount: 650,
            },
            {
              currency_id: 2,
              amount: 850,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 2,
          x_pos: -4480,
          y_pos: -3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 42,
          currency_prices: [
            {
              currency_id: 1,
              amount: 650,
            },
            {
              currency_id: 2,
              amount: 850,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 164,
          x_pos: -3840,
          y_pos: -3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 0,
          empty_icon: null,
          required_level: 42,
          currency_prices: [
            {
              currency_id: 1,
              amount: 650,
            },
            {
              currency_id: 2,
              amount: 850,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 28,
          x_pos: -2560,
          y_pos: -3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 5,
          currency_prices: [
            {
              currency_id: 1,
              amount: 50,
            },
            {
              currency_id: 2,
              amount: 100,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 41,
          x_pos: -1920,
          y_pos: -3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 7,
          currency_prices: [
            {
              currency_id: 1,
              amount: 100,
            },
            {
              currency_id: 2,
              amount: 200,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 54,
          x_pos: -1280,
          y_pos: -3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 8,
          currency_prices: [
            {
              currency_id: 1,
              amount: 150,
            },
            {
              currency_id: 2,
              amount: 300,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 67,
          x_pos: -640,
          y_pos: -3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 12,
          currency_prices: [
            {
              currency_id: 1,
              amount: 250,
            },
            {
              currency_id: 2,
              amount: 450,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 80,
          x_pos: 0,
          y_pos: -3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 16,
          currency_prices: [
            {
              currency_id: 1,
              amount: 400,
            },
            {
              currency_id: 2,
              amount: 550,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 93,
          x_pos: 640,
          y_pos: -3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 15,
          currency_prices: [
            {
              currency_id: 1,
              amount: 500,
            },
            {
              currency_id: 2,
              amount: 650,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 106,
          x_pos: 1280,
          y_pos: -3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 23,
          currency_prices: [
            {
              currency_id: 1,
              amount: 550,
            },
            {
              currency_id: 2,
              amount: 750,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 119,
          x_pos: 1920,
          y_pos: -3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 26,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 132,
          x_pos: 2560,
          y_pos: -3200,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 27,
          currency_prices: [
            {
              currency_id: 1,
              amount: 600,
            },
            {
              currency_id: 2,
              amount: 800,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 14,
          x_pos: -5120,
          y_pos: -3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 42,
          currency_prices: [
            {
              currency_id: 1,
              amount: 650,
            },
            {
              currency_id: 2,
              amount: 850,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "buyable",
          resource_left: null,
          player_building: null,
          tile_id: 1,
          x_pos: -4480,
          y_pos: -3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 42,
          currency_prices: [
            {
              currency_id: 1,
              amount: 650,
            },
            {
              currency_id: 2,
              amount: 850,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 27,
          x_pos: -2560,
          y_pos: -3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 0,
          currency_prices: [],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 40,
          x_pos: -1920,
          y_pos: -3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 7,
          currency_prices: [
            {
              currency_id: 1,
              amount: 100,
            },
            {
              currency_id: 2,
              amount: 200,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 53,
          x_pos: -1280,
          y_pos: -3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 40,
          currency_prices: [
            {
              currency_id: 2,
              amount: 500,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 66,
          x_pos: -640,
          y_pos: -3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 0,
          currency_prices: [],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 79,
          x_pos: 0,
          y_pos: -3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 16,
          currency_prices: [
            {
              currency_id: 1,
              amount: 400,
            },
            {
              currency_id: 2,
              amount: 550,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 92,
          x_pos: 640,
          y_pos: -3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 0,
          currency_prices: [],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 105,
          x_pos: 1280,
          y_pos: -3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 23,
          currency_prices: [
            {
              currency_id: 1,
              amount: 550,
            },
            {
              currency_id: 2,
              amount: 750,
            },
          ],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 118,
          x_pos: 1920,
          y_pos: -3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 99,
          currency_prices: [],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 131,
          x_pos: 2560,
          y_pos: -3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 0,
          currency_prices: [],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
        {
          player_tile_id: null,
          state: "disabled",
          resource_left: null,
          player_building: null,
          tile_id: 144,
          x_pos: 3200,
          y_pos: -3840,
          z_pos: null,
          tile_type: {
            type_id: 1,
            width: 1,
            height: 1,
          },
          border_bitmask: 15,
          empty_icon: null,
          required_level: 0,
          currency_prices: [],
          product_prices: [],
          resource_amount: null,
          production: null,
          open_player_island_id: null,
          player_island_id: 795,
          inner_island_player_production_building: null,
        },
      ],
      buildingsImagesPreloaded: false,
      lastBoardTileUpdate: null,
    },
    importantMessages: {
      messages: [],
      importantMessageDisplayed: false,
    },
    currentScene: "MAIN_MAIN_SCENE",
  },
  user: {
    isLoading: false,
    me: {
      enabled_players: [
        {
          id: 795,
          branch_name: "Jarosław, ul. Sikorskiego 2a Galeria Stara Ujeżdżalnia",
          title_id: {
            id: 1,
            rank_group: 1,
            name: "handlowiec",
            playable: true,
          },
        },
      ],
      is_shop_restricted: false,
      selected_player_id: 795,
      id: 320,
      external_id: "9C0212F7623EF3D1167866DC542C3AB98BF72E81FA62CF2A977B60E396DFA3E3",
      partner_id: null,
      previous_login_at: "2021-05-20T13:47:14+02:00",
      first_name: "Jucha",
      last_name: "Grzegorz",
      currency_balances: [],
      email: "grzegorz.jucha@komputronik.pl",
      username: "grzegorz.jucha",
      push_enabled: true,
      has_installed_mobile_app: false,
      talars_left_for_redemption: 0,
    },
    error: null,
  },
  player: {
    isLoading: false,
    player: {
      unread_messages: [],
      cleanliness: 0,
      has_new_badges: false,
      level_percent: 74,
      next_level_points: 984,
      region_id: 2,
      region_name: "Default",
      level_status: null,
      food_balance: 0,
      missions_to_collect: [{ player_mission_id: 109038, mission_type: 1 }],
      last_login_at: "2021-05-21T11:03:54+02:00",
      chosen_tasks_count: 0,
      closed_chests: [],
      id: 795,
      profile_image_url: null,
      external_id: "9C0212F7623EF3D1167866DC542C3AB98BF72E81FA62CF2A977B60E396DFA3E3",
      facebook_email: null,
      facebook_username: null,
      first_name: "Jucha",
      last_name: "Grzegorz",
      pinned_user_badge: null,
      email: "grzegorz.jucha@komputronik.pl",
      branch_name: "Jarosław, ul. Sikorskiego 2a Galeria Stara Ujeżdżalnia",
      branch_id: 42,
      farm_name: "Juszka",
      farm_value: 6560,
      missions_completed: 3,
      points_balance: 887,
      prestige_balance: 0,
      income: 0,
      level: 3,
      title_id: { id: 1, rank_group: 1, name: "handlowiec", playable: true },
      population_max: 130,
      opinion: 0,
      population_current: 130,
      population_delta: 0,
      product_balances: [{ product_id: 1, balance: 0 }],
      parameter_balances: [
        {
          type: "happiness_bonus",
          parameter_id: 2,
          key: "happiness",
          name: "Zadowolenie",
          tooltip: "<b>Utrzymuj zadowolenia na poziomie 100%, <br> spełniając w 100% bieżące potrzeby mieszkańców.</b>",
          position: 1,
          icon: "happiness.png",
          balance: 100,
          is_parameter_active: true,
          income: 100,
          outcome: 0,
          percentage: 1,
          population_multiplier: 1,
        },
        {
          type: "population_delta",
          parameter_id: 1,
          key: "population",
          name: "Populacja",
          tooltip:
            "<b>Poziom zasiedlenia budynków mieszkalnych zależy od poziomu zadowolenia mieszkańców. <br> Aktualna pojemność budynków: %population_max osób. <br> Migracja: %population_delta mieszkańców na godzinę</b>.",
          position: 2,
          icon: "population.png",
          balance: 130,
          is_parameter_active: true,
          income: 130,
          outcome: 0,
          percentage: null,
          population_multiplier: 1,
        },
        {
          type: "penalized_parameter",
          parameter_id: 3,
          key: "service",
          name: "Usługi",
          tooltip:
            "<b>Zapewnij mieszkańcom dostęp do usług różnego rodzaju, <br> budując budynki usługowe. <br> Dostępność usług: %current_income_value. <br> Zapotrzebowanie: %population_current. </b>",
          position: 3,
          icon: "service.png",
          balance: 190,
          is_parameter_active: true,
          income: 190,
          outcome: 0,
          percentage: 1.4615,
          population_multiplier: 1,
        },
        {
          type: "penalized_parameter",
          parameter_id: 4,
          key: "security",
          name: "Bezpieczństwo",
          tooltip:
            "<b>Dbaj o bezpieczeństwo mieszkańców, <br> stawiając policję czy straż pożarną.<br> Stopień bezpieczeństwa: %current_income_value. <br> Zapotrzebowanie: %population_current. </b>",
          position: 4,
          icon: "security.png",
          balance: 200,
          is_parameter_active: false,
          income: 200,
          outcome: 0,
          percentage: null,
          population_multiplier: 0,
        },
        {
          type: "penalized_parameter",
          parameter_id: 5,
          key: "architecture",
          name: "Zrównoważona architektura",
          tooltip:
            "<b>Rozwijaj architekturę miasta, <br> opracowując plany architektoniczne i stawiając nowoczesne budynki. <br> Nowoczesność architektury: %current_income_value. <br> Zapotrzebowanie: %population_current. </b>",
          position: 5,
          icon: "architecture.png",
          balance: 2100,
          is_parameter_active: false,
          income: 2100,
          outcome: 0,
          percentage: null,
          population_multiplier: 0,
        },
        {
          type: "penalized_parameter",
          parameter_id: 6,
          key: "city_cash",
          name: "Bogactwo",
          tooltip:
            "<b>Rozwijaj miasto ekonomicznie, <br> stawiając budynki związane z finansami. <br> Stopień bogactwa: %current_income_value. <br> Zapotrzebowanie: %population_current. </b>",
          position: 6,
          icon: "city_cash.png",
          balance: 1200,
          is_parameter_active: false,
          income: 1200,
          outcome: 0,
          percentage: null,
          population_multiplier: 0,
        },
      ],
      currency_balances: [
        {
          currency_id: 1,
          balance: 105610,
          type: "player",
          key: "brick",
          name: "Cegły",
          description: "Cegły",
          show_currency_history: true,
          is_gross: false,
          is_default_trading_currency: false,
          tooltip:
            "<b>Cegły to główna waluta w Twoim mieście, umożliwiająca budowanie oraz rozbudowywanie budynków.<br> Otrzymujesz je za realizację zadań biznesowych, quizów oraz misji w grze</b>",
          position: 1,
          iconUrlBig: "/main/assets/currency/big/brick.png?fe2662c8a9caeff7d3772538c8829900",
          iconUrl: "/main/assets/currency/basic/brick.png?579da0b1224f0cdecc4960148c3e7d9f",
        },
        {
          currency_id: 2,
          balance: 3851,
          type: "player",
          key: "coinsilver",
          name: "Srebrne talary",
          description: "Srebrne talary",
          show_currency_history: true,
          is_gross: false,
          is_default_trading_currency: true,
          tooltip:
            "<b>Srebrne talary to druga najważniejsza waluta w Komp Town. Dzięki niej stawiasz i rozbudowujesz budynki, tworzysz produkty oraz je kupujesz.<br> Talary otrzymujesz za realizację misji, czy sprzedaż produktów z magazynu </b>",
          position: 2,
          iconUrlBig: "/main/assets/currency/big/coinsilver.png?3ead6c13d3872b8ba40845025dd57aa2",
          iconUrl: "/main/assets/currency/basic/coinsilver.png?7e7a5e31fbb8aa1bfe2f25661f44fa12",
        },
      ],
      star: { star_id: 1, star_name: "Rok 1980", price_per_person: 100, cleaning_cost_multiplier: 1, correctly_star_id: 1 },
      active_surveys_without_answer: [],
      active_quizzes_without_answer: [{ location: 4, amount: 1 }],
      main_player_island: {
        player_island_id: 795,
        island_group_type: "1",
        config_name: null,
        camera_position_x: null,
        camera_position_y: null,
        camera_zoom: null,
      },
      gui_unlocks: [],
      tasks_setup: { year: 2021, month: 1 },
      real_time: "2021-05-21T11:25:14+0200",
    },
  },
  utility: {
    player: {
      activePlayerId: 795,
      mePlayerId: 795,
      hasMissionsToCollect: true,
      hasNewMessagesToRead: null,
      missionsToOpen: [],
      newProductsInStorage: [],
    },
    token: {
      isExchanging: false,
      tokenObject: {
        refresh_after: "2021-05-20T15:58:00+02:00",
        token:
          "MDMzZ416N8HWBmjX5mU5_GEfsekeVGc9bRsT2vhZz5T0AgPbv08HIUcenIMlf0HkHynaC3_fQXx9ClVzhVrhxM5zH4zgeYDjHkSl-Pi0DGqNH_dO8_m4bNxcJOGqYCvf.320",
        download_token: "4f839afd85b2556b1f7b0c6f3d88ca39.320",
        is_impersonate: false,
        expired_at: "2021-05-20T16:01:00+02:00",
        reauthorize_after: null,
      },
    },
  },
  primary: {
    isLoading: false,
    primary: {
      available_surveys: [
        {
          survey_id: 109,
          location: 1,
        },
        {
          survey_id: 34,
          location: 4,
        },
        {
          survey_id: 40,
          location: 4,
        },
        {
          survey_id: 51,
          location: 4,
        },
        {
          survey_id: 77,
          location: 4,
        },
        {
          survey_id: 93,
          location: 4,
        },
      ],
      available_quizzes: [
        {
          quiz_id: 14,
          location: 1,
        },
        {
          quiz_id: 17,
          location: 1,
        },
        {
          quiz_id: 103,
          location: 1,
        },
        {
          quiz_id: 106,
          location: 1,
        },
        {
          quiz_id: 11,
          location: 3,
        },
        {
          quiz_id: 20,
          location: 3,
        },
        {
          quiz_id: 23,
          location: 3,
        },
        {
          quiz_id: 43,
          location: 3,
        },
        {
          quiz_id: 46,
          location: 3,
        },
        {
          quiz_id: 47,
          location: 3,
        },
        {
          quiz_id: 50,
          location: 3,
        },
        {
          quiz_id: 2,
          location: 4,
        },
        {
          quiz_id: 8,
          location: 4,
        },
        {
          quiz_id: 26,
          location: 4,
        },
        {
          quiz_id: 28,
          location: 4,
        },
        {
          quiz_id: 31,
          location: 4,
        },
        {
          quiz_id: 37,
          location: 4,
        },
        {
          quiz_id: 54,
          location: 4,
        },
        {
          quiz_id: 57,
          location: 4,
        },
        {
          quiz_id: 83,
          location: 4,
        },
        {
          quiz_id: 87,
          location: 4,
        },
        {
          quiz_id: 90,
          location: 4,
        },
        {
          quiz_id: 99,
          location: 4,
        },
        {
          quiz_id: 111,
          location: 4,
        },
        {
          quiz_id: 114,
          location: 4,
        },
      ],
      is_available_gallery: false,
      gallery_max_upload_size_in_mb: 16,
      move_building_price: {
        currency_id: 2,
        amount: 50,
      },
      move_building_currency_price: {
        currency_id: 2,
        amount: 50,
      },
      move_building_product_price: null,
      currencies: [
        {
          type: "player",
          currency_id: 1,
          key: "brick",
          name: "Cegły",
          description: "Cegły",
          show_currency_history: true,
          is_gross: false,
          is_default_trading_currency: false,
          tooltip:
            "<b>Cegły to główna waluta w Twoim mieście, umożliwiająca budowanie oraz rozbudowywanie budynków.<br> Otrzymujesz je za realizację zadań biznesowych, quizów oraz misji w grze</b>",
          position: 1,
        },
        {
          type: "player",
          currency_id: 2,
          key: "coinsilver",
          name: "Srebrne talary",
          description: "Srebrne talary",
          show_currency_history: true,
          is_gross: false,
          is_default_trading_currency: true,
          tooltip:
            "<b>Srebrne talary to druga najważniejsza waluta w Komp Town. Dzięki niej stawiasz i rozbudowujesz budynki, tworzysz produkty oraz je kupujesz.<br> Talary otrzymujesz za realizację misji, czy sprzedaż produktów z magazynu </b>",
          position: 2,
        },
        {
          type: "user",
          currency_id: 3,
          key: "coingold",
          name: "Złote monety",
          description: "Złote monety",
          show_currency_history: true,
          is_gross: true,
          is_default_trading_currency: false,
          tooltip: "<b>Złote monety to wartość nagrody pieniężnej. Otrzymasz je za realizację zadań biznesowych</b>",
          position: 3,
        },
        {
          type: "player",
          currency_id: 5,
          key: "Diamenty",
          name: "Diamenty",
          description: "Diamenty",
          show_currency_history: true,
          is_gross: false,
          is_default_trading_currency: false,
          tooltip: "null",
          position: -1,
        },
      ],
      products: [
        {
          product_id: 1,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Drewno",
          description:
            "Odpowiednio obrobione i wyselekcjonowane kawałki drewna posłużą Ci do produkcji mebli oraz budowy budynk&oacute;w. Miejsce obr&oacute;bki: Tartak.",
          icon: "wood",
          visible_from_level: 1,
        },
        {
          product_id: 2,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Drzewo",
          description: "Dzięki drzewom będziesz m&oacute;gł stworzyć meble dla mieszkańc&oacute;w Komp Town.",
          icon: "tree",
          visible_from_level: 17,
        },
        {
          product_id: 3,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Krzew",
          description:
            "Krzew urozmaici wnętrza dom&oacute;w Twoich mieszkańc&oacute;w i wpłynie na ich relaks. Zapewni także zr&oacute;wnoważony rozw&oacute;j miasta. Miejsce produkcji: Centrum Ogrodnicze.",
          icon: "bush",
          visible_from_level: 16,
        },
        {
          product_id: 4,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Kwiat w doniczce",
          description: "Kwiat w doniczce stanie się ozdobą niejednego parapetu. Miejsce produkcji: Centrum Ogrodnicze.",
          icon: "flower_pot",
          visible_from_level: 20,
        },
        {
          product_id: 5,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Matryca",
          description:
            "Nowoczesna, OLED-owa matryca ekranu, kt&oacute;ra jest niezbędna do produkcji asortymentu RTV. Miejsce tworzenia: Fabryka podzespoł&oacute;w.",
          icon: "matrix",
          visible_from_level: 18,
        },
        {
          product_id: 6,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Płyta główna",
          description:
            "Płyta gł&oacute;wna to serce komputer&oacute;w i laptop&oacute;w. Bez niej nie ma mowy o produkcji tych sprzęt&oacute;w. Miejsce opracowania: Fabryka podzespoł&oacute;w.",
          icon: "motherboard",
          visible_from_level: 18,
        },
        {
          product_id: 7,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Podzespoły elektroniczne",
          description:
            "Podzespoły to elementy niezbędne do produkcji sprzętu elektronicznego. Miejsce produkcji : Fabryka podzepoł&oacute;w 2.0.",
          icon: "electronic",
          visible_from_level: 22,
        },
        {
          product_id: 8,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Procesor",
          description:
            "Procesor jest m&oacute;zgiem każdego komputera, laptopa i smarfonu. Stw&oacute;rz go, aby m&oacute;c je produkować. Miejsce opracowania: Laboratorium.",
          icon: "processor",
          visible_from_level: 17,
        },
        {
          product_id: 9,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Laptop",
          description:
            "Nowoczesny przenośny komputer, kt&oacute;ry umożliwia mobilną pracę i zabawę. Miejsce pozyskania: Port i fabryka elektroniki.",
          icon: "laptop",
          visible_from_level: 18,
        },
        {
          product_id: 10,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Komputer stacjonarny",
          description:
            "Desktopowy komputer, kt&oacute;rego wysokie parametry umożliwiają nowoczesną rozrywkę oraz wykonywanie pracy biurowej. Miejsce produkcji:Fabryka Elektroniki.",
          icon: "computer",
          visible_from_level: 99,
        },
        {
          product_id: 11,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Smartfon",
          description:
            "Smartfon to urządzenie o mocy zbliżonej do laptopa, kt&oacute;re oferuje nie mniej możliwości, a do tego jest bardzo poręczny i kompaktowy. Miejsce produkcji: Fabryka elektroniki lvl 3.",
          icon: "smartphone",
          visible_from_level: 25,
        },
        {
          product_id: 12,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Super TV",
          description:
            "Telewizor umożliwia oglądanie kanał&oacute;w o r&oacute;żnej tematyce oraz serwis&oacute;w streamingowych na dużym ekranie. Miejsce produkcji: Fabryka super elektroniki.",
          icon: "tv",
          visible_from_level: 25,
        },
        {
          product_id: 13,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Meble biurowe",
          description:
            "Solidnie wykonane biurowe meble, kt&oacute;re zapewnią odpowiednie warunki komfortu podczas pracy. Miejsce produkcji: Fabryka Mebli lvl 3 i wyżej",
          icon: "furniture_office",
          visible_from_level: 8,
        },
        {
          product_id: 14,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Meble kuchenne",
          description:
            "Podstawowy element wyposażenia kuchni, dzięki kt&oacute;remu rodziny mogą wsp&oacute;lnie przygotowywać i jeść posiłki. Miejsce produkcji: Fabryka Mebli lvl 2 i wyżej",
          icon: "furniture_kitchen",
          visible_from_level: 3,
        },
        {
          product_id: 15,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Stół i krzesło",
          description: "Zestaw meblowy niezbędny do odpoczynku czy spożycia posiłku. Miejsce produkcji: Fabryka Mebli",
          icon: "furniture_table",
          visible_from_level: 3,
        },
        {
          product_id: 16,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Pierwszy komputer",
          description:
            "Pierwszy komputer to sprzęt, kt&oacute;ry otworzył przed użytkownikami nowe możliwości w takich dziedzinach jak praca czy rozrywka. Miejsce pozyskania : port.",
          icon: "computer_old",
          visible_from_level: 10,
        },
        {
          product_id: 17,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Super procesor ",
          description:
            "Doskonały wynalazek naukowc&oacute;w z Kompbridge, kt&oacute;ry zmienił oblicze świata elektroniki. Sprzęt wyposażony w super procesor jest wydajny, ekologiczny i gwarantujący najlepsze osiągi. Miejsce produkcji: fabryka podzespoł&oacute;w lvl 4",
          icon: "processor_pro",
          visible_from_level: 34,
        },
        {
          product_id: 18,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Monitor ",
          description:
            "Monitor to Twoje okno na wirtualny świat, gdy zdecydujesz się na tradycyjny PC. Miejsce produkcji: Fabryka elektroniki.",
          icon: "monitor",
          visible_from_level: 32,
        },
        {
          product_id: 19,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Myszka",
          description:
            "Ultraprecyzyjna, ergonomiczna mysz komputerowa, będzie przedłużeniem Twojej ręki w świecie wirtualnym. Miejsce produkcji: Fabryka elektroniki oraz port od lvl2.",
          icon: "mouse",
          visible_from_level: 10,
        },
        {
          product_id: 20,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Super komputer",
          description:
            "Super komputer to sprzęt o najwyższej wydajności, jakości i komforcie użytkowania. Miejsce produkcji: Fabryka superelektroniki.",
          icon: "computer_pro",
          visible_from_level: 38,
        },
        {
          product_id: 21,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Super laptop",
          description:
            "Super laptop jest odpowiedzią na potrzeby użytkownik&oacute;w, kt&oacute;rzy potrzebowali zaawansowanych rozwiązań w przenośnym komputerze osobistym. Miejsce produkcji: fabryka superelektroniki lvl 2",
          icon: "laptop_pro",
          visible_from_level: 46,
        },
        {
          product_id: 22,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Super smartfon",
          description:
            "Super Smartfon to sprzęt dla fan&oacute;w niepozornych rozwiązań gabarytowych z ultrawydajnym wnętrzem. Miejsce produkcji: fabryka superelektroniki lvl 3.",
          icon: "smartphone_pro",
          visible_from_level: 48,
        },
        {
          product_id: 23,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Notatka",
          description: "To Tw&oacute;j &bdquo;klucz&rdquo; z przyszłości, kt&oacute;ry pomoże Ci wybudować nowoczesne, bogate miasto .",
          icon: "note",
          visible_from_level: 99,
        },
        {
          product_id: 24,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 2,
            name: "Produkty",
            description: "Produkty",
            icon: "produkty",
          },
          name: "Plan budowy",
          description: "Plan nowoczesnej architektury, kt&oacute;ry pozwoli na zr&oacute;wnoważony rozw&oacute;j Komp Town.",
          icon: "blueprint",
          visible_from_level: 99,
        },
        {
          product_id: 25,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 3,
            name: "Kadry",
            description: "Kadry",
            icon: "kadry",
          },
          name: "Doktor",
          description:
            "Doktor jest łącznikiem pomiędzy inżynierem i profesorem, jego doświadczenie i młodzieńczy zapał przyczynia się do ważnych odkryć naukowych. Miejsce kształcenia: Uczelnia.",
          icon: "scientist_doctor",
          visible_from_level: 8,
        },
        {
          product_id: 26,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 3,
            name: "Kadry",
            description: "Kadry",
            icon: "kadry",
          },
          name: "Inżynier",
          description:
            "Nie ma fabryki, laboratorium, kt&oacute;re mogłoby funkcjonować bez inżynier&oacute;w &ndash; to właśnie oni przekładają akademicką teorię na praktykę. Miejsce pozyskiwania: Uczelnia lvl 1 i wyżej",
          icon: "scientist_engineer",
          visible_from_level: 8,
        },
        {
          product_id: 27,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 3,
            name: "Kadry",
            description: "Kadry",
            icon: "kadry",
          },
          name: "Profesor",
          description:
            "Lata doświadczeń i nieograniczona wiedza &ndash; profesor to skromna postać, kt&oacute;ra stoi za wielkimi przełomami. Miejsce kształcenia: Uczelnia lvl 1 i wyżej",
          icon: "scientist_professor",
          visible_from_level: 99,
        },
        {
          product_id: 1000,
          category: {
            storage_id: 2,
            trading_currency_id: null,
            id: 4,
            name: "Invisible",
            description: "Invisible",
            icon: "invisible",
          },
          name: "Zadowolony klient",
          description: "Bezcenny uśmiech zadowolonego klienta, wdzięcznego za szybką dostawę sprzętu elektronicznego.",
          icon: "smiley_face",
          visible_from_level: 99,
        },
        {
          product_id: 1001,
          category: {
            storage_id: 2,
            trading_currency_id: null,
            id: 4,
            name: "Invisible",
            description: "Invisible",
            icon: "invisible",
          },
          name: "Zespół badawczy",
          description: "",
          icon: "research_team",
          visible_from_level: 99,
        },
        {
          product_id: 1002,
          category: {
            storage_id: 2,
            trading_currency_id: null,
            id: 4,
            name: "Invisible",
            description: "Invisible",
            icon: "invisible",
          },
          name: "Plan rozwoju miasta",
          description: "",
          icon: "blueprint",
          visible_from_level: 99,
        },
        {
          product_id: 1003,
          category: {
            storage_id: 2,
            trading_currency_id: null,
            id: 4,
            name: "Invisible",
            description: "Invisible",
            icon: "invisible",
          },
          name: "Nowy plan rozwoju miasta",
          description: "",
          icon: "blueprint",
          visible_from_level: 99,
        },
        {
          product_id: 1004,
          category: {
            storage_id: 2,
            trading_currency_id: null,
            id: 4,
            name: "Invisible",
            description: "Invisible",
            icon: "invisible",
          },
          name: "Plany prof. Kompetentnego",
          description: "",
          icon: "note",
          visible_from_level: 99,
        },
        {
          product_id: 1005,
          category: {
            storage_id: 2,
            trading_currency_id: null,
            id: 4,
            name: "Invisible",
            description: "Invisible",
            icon: "invisible",
          },
          name: "Plany rozwoju komputeryzacji",
          description: "",
          icon: "blueprint",
          visible_from_level: 99,
        },
        {
          product_id: 1006,
          category: {
            storage_id: 2,
            trading_currency_id: null,
            id: 4,
            name: "Invisible",
            description: "Invisible",
            icon: "invisible",
          },
          name: "Nowy plan rozwoju miasta",
          description: "",
          icon: "blueprint",
          visible_from_level: 99,
        },
        {
          product_id: 1007,
          category: {
            storage_id: 2,
            trading_currency_id: null,
            id: 4,
            name: "Invisible",
            description: "Invisible",
            icon: "invisible",
          },
          name: "Projekt AI",
          description: "",
          icon: "note",
          visible_from_level: 99,
        },
        {
          product_id: 1008,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 5,
            name: "Specjalne",
            description: "Specjalne",
            icon: "specjalne",
          },
          name: "Plan rozwoju miasta 2010",
          description: "",
          icon: "blueprint",
          visible_from_level: 99,
        },
        {
          product_id: 1009,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 5,
            name: "Specjalne",
            description: "Specjalne",
            icon: "specjalne",
          },
          name: "Plan rozwoju miasta 2015",
          description: "Plan rozwoju miasta na lata 2015-2020 odblokowuje możliwość budowy nowych obiekt&oacute;w.",
          icon: "blueprint",
          visible_from_level: 99,
        },
        {
          product_id: 1010,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 5,
            name: "Specjalne",
            description: "Specjalne",
            icon: "specjalne",
          },
          name: "Plan rozwoju miasta 2020",
          description: "Plan rozwoju miasta na lata 2020-2025 odblokowuje możliwość budowy nowych obiekt&oacute;w.",
          icon: "blueprint",
          visible_from_level: 99,
        },
        {
          product_id: 1011,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 5,
            name: "Specjalne",
            description: "Specjalne",
            icon: "specjalne",
          },
          name: "Certyfikat eko 1.0",
          description:
            "Certyfikat przyznawany za realizację misji z rozwojem energetyki odnawialnej. Certyfikat odblokowuje możliwość budowy fabryki podzespoł&oacute;w 2.0 oraz rozbudowy wielu innych budynk&oacute;w.",
          icon: "blueprint",
          visible_from_level: 99,
        },
        {
          product_id: 1012,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 5,
            name: "Specjalne",
            description: "Specjalne",
            icon: "specjalne",
          },
          name: "Certyfikat eko 2.0",
          description:
            "Certyfikat przyznawany za realizację misji z rozwojem energetyki odnawialnej. Certyfikat odblokowuje m.in. możliwość budowy fabryki elektroniki i rozbudowy apartamentowc&oacute;w do 5 poziomu.",
          icon: "blueprint",
          visible_from_level: 99,
        },
        {
          product_id: 1013,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 5,
            name: "Specjalne",
            description: "Specjalne",
            icon: "specjalne",
          },
          name: "Certyfikat eko 3.0",
          description:
            "Certyfikat przyznawany za realizację misji z rozwojem energetyki odnawialnej. Certyfikat odblokowuje m.in. możliwość rozbudowy muzeum do 4 poziomu.",
          icon: "blueprint",
          visible_from_level: 99,
        },
        {
          product_id: 1014,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 5,
            name: "Specjalne",
            description: "Specjalne",
            icon: "specjalne",
          },
          name: "Certyfikat eko 4.0",
          description: "",
          icon: "blueprint",
          visible_from_level: 99,
        },
        {
          product_id: 1015,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 5,
            name: "Specjalne",
            description: "Specjalne",
            icon: "specjalne",
          },
          name: "Certyfikat eko 5.0",
          description: "",
          icon: "blueprint",
          visible_from_level: 99,
        },
        {
          product_id: 1016,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 5,
            name: "Specjalne",
            description: "Specjalne",
            icon: "specjalne",
          },
          name: "Certyfikat eko 6.0",
          description: "",
          icon: "blueprint",
          visible_from_level: 99,
        },
        {
          product_id: 1017,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 5,
            name: "Specjalne",
            description: "Specjalne",
            icon: "specjalne",
          },
          name: "Certyfikat eko 7.0",
          description: "",
          icon: "blueprint",
          visible_from_level: 99,
        },
        {
          product_id: 1018,
          category: {
            storage_id: 1,
            trading_currency_id: null,
            id: 5,
            name: "Specjalne",
            description: "Specjalne",
            icon: "specjalne",
          },
          name: "Certyfikat eko 8.0",
          description: "",
          icon: "blueprint",
          visible_from_level: 99,
        },
        {
          product_id: 1100,
          category: {
            storage_id: 2,
            trading_currency_id: null,
            id: 4,
            name: "Invisible",
            description: "Invisible",
            icon: "invisible",
          },
          name: "Salon Komputronika na 4 poziom",
          description: "",
          icon: "",
          visible_from_level: 99,
        },
        {
          product_id: 1101,
          category: {
            storage_id: 2,
            trading_currency_id: null,
            id: 4,
            name: "Invisible",
            description: "Invisible",
            icon: "invisible",
          },
          name: "Plan rozbudowy fabryki superelektroniki",
          description: "Plan umożliwiający rozbudowę fabryki superelektroniki do 3 poziomu",
          icon: "blueprint",
          visible_from_level: 99,
        },
        {
          product_id: 1102,
          category: {
            storage_id: 2,
            trading_currency_id: null,
            id: 4,
            name: "Invisible",
            description: "Invisible",
            icon: "invisible",
          },
          name: "Plan rozbudowy fabryki superelektroniki",
          description: "Plan umożliwiający rozbudowę fabryki superelektroniki do 4 poziomu",
          icon: "blueprint",
          visible_from_level: 99,
        },
        {
          product_id: 1103,
          category: {
            storage_id: 2,
            trading_currency_id: null,
            id: 4,
            name: "Invisible",
            description: "Invisible",
            icon: "invisible",
          },
          name: "Salon Komputronika na 5 poziom",
          description: "",
          icon: "",
          visible_from_level: 99,
        },
      ],
      parameters: [
        {
          type: "population_delta",
          parameter_id: 1,
          key: "population",
          name: "Populacja",
          tooltip:
            "<b>Poziom zasiedlenia budynków mieszkalnych zależy od poziomu zadowolenia mieszkańców. <br> Aktualna pojemność budynków: %population_max osób. <br> Migracja: %population_delta mieszkańców na godzinę</b>.",
          position: 2,
        },
        {
          type: "happiness_bonus",
          parameter_id: 2,
          key: "happiness",
          name: "Zadowolenie",
          tooltip: "<b>Utrzymuj zadowolenia na poziomie 100%, <br> spełniając w 100% bieżące potrzeby mieszkańców.</b>",
          position: 1,
        },
        {
          type: "penalized_parameter",
          parameter_id: 3,
          key: "service",
          name: "Usługi",
          tooltip:
            "<b>Zapewnij mieszkańcom dostęp do usług różnego rodzaju, <br> budując budynki usługowe. <br> Dostępność usług: %current_income_value. <br> Zapotrzebowanie: %population_current. </b>",
          position: 3,
        },
        {
          type: "penalized_parameter",
          parameter_id: 4,
          key: "security",
          name: "Bezpieczństwo",
          tooltip:
            "<b>Dbaj o bezpieczeństwo mieszkańców, <br> stawiając policję czy straż pożarną.<br> Stopień bezpieczeństwa: %current_income_value. <br> Zapotrzebowanie: %population_current. </b>",
          position: 4,
        },
        {
          type: "penalized_parameter",
          parameter_id: 5,
          key: "architecture",
          name: "Zrównoważona architektura",
          tooltip:
            "<b>Rozwijaj architekturę miasta, <br> opracowując plany architektoniczne i stawiając nowoczesne budynki. <br> Nowoczesność architektury: %current_income_value. <br> Zapotrzebowanie: %population_current. </b>",
          position: 5,
        },
        {
          type: "penalized_parameter",
          parameter_id: 6,
          key: "city_cash",
          name: "Bogactwo",
          tooltip:
            "<b>Rozwijaj miasto ekonomicznie, <br> stawiając budynki związane z finansami. <br> Stopień bogactwa: %current_income_value. <br> Zapotrzebowanie: %population_current. </b>",
          position: 6,
        },
      ],
      spreads: [
        {
          id: 1,
          from: {
            type: "player",
            currency_id: 1,
            key: "brick",
            name: "Cegły",
            description: "Cegły",
            show_currency_history: true,
            is_gross: false,
            is_default_trading_currency: false,
            tooltip:
              "<b>Cegły to główna waluta w Twoim mieście, umożliwiająca budowanie oraz rozbudowywanie budynków.<br> Otrzymujesz je za realizację zadań biznesowych, quizów oraz misji w grze</b>",
            position: 1,
          },
          to: {
            type: "player",
            currency_id: 2,
            key: "coinsilver",
            name: "Srebrne talary",
            description: "Srebrne talary",
            show_currency_history: true,
            is_gross: false,
            is_default_trading_currency: true,
            tooltip:
              "<b>Srebrne talary to druga najważniejsza waluta w Komp Town. Dzięki niej stawiasz i rozbudowujesz budynki, tworzysz produkty oraz je kupujesz.<br> Talary otrzymujesz za realizację misji, czy sprzedaż produktów z magazynu </b>",
            position: 2,
          },
          spread: 100,
          required_level: 15,
        },
      ],
      stars: [
        {
          star_id: 1,
          star_name: "Rok 1980",
        },
        {
          star_id: 2,
          star_name: "Rok 1985",
        },
        {
          star_id: 3,
          star_name: "Rok 1985",
        },
        {
          star_id: 4,
          star_name: "Rok 1990",
        },
        {
          star_id: 5,
          star_name: "Rok 1995",
        },
        {
          star_id: 6,
          star_name: "Rok 2000",
        },
        {
          star_id: 7,
          star_name: "Rok 2005",
        },
        {
          star_id: 8,
          star_name: "Rok 2010",
        },
        {
          star_id: 9,
          star_name: "Rok 2015",
        },
        {
          star_id: 10,
          star_name: "Rok 2020",
        },
        {
          star_id: 11,
          star_name: "Rok 2025",
        },
        {
          star_id: 51,
          star_name: "Rok 1995",
        },
        {
          star_id: 71,
          star_name: "Rok 2005",
        },
        {
          star_id: 81,
          star_name: "Rok 2010",
        },
        {
          star_id: 91,
          star_name: "Rok 2015",
        },
        {
          star_id: 92,
          star_name: "Rok 2015",
        },
        {
          star_id: 93,
          star_name: "Rok 2015",
        },
        {
          star_id: 101,
          star_name: "Rok 2020",
        },
        {
          star_id: 111,
          star_name: "Rok 2025",
        },
        {
          star_id: 1995,
          star_name: "Rok 1995",
        },
        {
          star_id: 1996,
          star_name: "Rok 1995",
        },
        {
          star_id: 2000,
          star_name: "Rok 2000",
        },
        {
          star_id: 2005,
          star_name: "Rok 2005",
        },
        {
          star_id: 2010,
          star_name: "Rok 2010",
        },
        {
          star_id: 2015,
          star_name: "Rok 2015",
        },
        {
          star_id: 2020,
          star_name: "Rok 2020",
        },
        {
          star_id: 2025,
          star_name: "Rok 2025",
        },
      ],
      action_block_dates: [],
    },
    error: null,
  },
  socket: {
    isConnecting: true,
    socketStatus: 3,
  },
};
