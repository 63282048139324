import { ChangeDetectorRef, Directive, ElementRef, Injectable, ViewChild } from "@angular/core";
import { forkJoin, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { Swiper, SwiperOptions } from "swiper/types";

import { swiperInjectionStyles } from "../../../../../../../styles/swiper/swiper-injection-styles";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { ProductPlayerService } from "../../../../../player/providers/product-player.service";
import { generateEachPages } from "../../../../game-gui/utilities/slider.utility";
import { BuildingsService } from "../../../../services/buildings.service";
import { AbstractMessageDetailsBaseComponent } from "./abstract-message-details-base.component";

@Directive()
@Injectable()
export abstract class AbstractMessageDetailsLevelUpComponent extends AbstractMessageDetailsBaseComponent {
  @OwInject(BuildingsService) buildingsService: BuildingsService;
  @OwInject(ProductPlayerService) productPlayerService: ProductPlayerService;
  @OwInject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef;
  // END INJECTIONS

  loading: boolean;
  sliderUnlockedBuildingsActiveIndex;
  sliderUnlockedProductsActiveIndex;

  unlockedBuildings = {
    config: {
      itemPerPage: 3,
    },
    pages: [],
    items: [],
  };

  unlockedProducts = {
    config: {
      itemPerPage: 4,
    },
    pages: [],
    items: [],
  };

  //SWIPER 1
  swiper1: Swiper = null;
  initSwiper1 = false;
  @ViewChild("swiperRef1", { static: false })
  swiperRef1: ElementRef | undefined;
  currentSlideIndex1 = 0;
  config1: SwiperOptions = {
    slidesPerView: 1,
    initialSlide: 0,
    centeredSlides: true,
    grabCursor: true,
    pagination: {
      clickable: true,
    },
    injectStyles: [swiperInjectionStyles],
  };

  //SWIPER 2
  swiper2: Swiper = null;
  initSwiper2 = false;
  @ViewChild("swiperRef2", { static: false })
  swiperRef2: ElementRef | undefined;
  currentSlideIndex2 = 0;
  config2: SwiperOptions = {
    slidesPerView: 1,
    initialSlide: 0,
    centeredSlides: true,
    grabCursor: true,
    pagination: {
      clickable: true,
    },
    injectStyles: [swiperInjectionStyles],
  };

  getUnlocks() {
    this.loading = true;

    forkJoin([
      this.buildingsService.getUnlockedBuildingsByLevel(this.message.parameters.level),
      this.productPlayerService.unlockedProductsAtLevel(this.message.parameters.level),
    ])
      .pipe(
        catchError(() => {
          return of(null);
        })
      )
      .subscribe(([buildings, products]) => {
        this.unlockedBuildings.items = buildings;
        this.unlockedBuildings = generateEachPages(this.unlockedBuildings);

        this.unlockedProducts.items = products;
        this.unlockedProducts = generateEachPages(this.unlockedProducts);

        this.setSwiper("swiper1", "currentSlideIndex1", "swiperRef1", "initSwiper1");
        this.setSwiper("swiper2", "currentSlideIndex2", "swiperRef2", "initSwiper2");
        this.loading = false;
      });
  }

  setSwiper(swiper: string, idx: string, swiperRef: string, initSwiper: string) {
    this[initSwiper] = false;
    this[swiper] = null;
    this.changeDetectorRef.detectChanges();
    this[initSwiper] = true;
    this.changeDetectorRef.detectChanges();
    if (this[swiperRef]?.nativeElement) {
      this[swiper] = this[swiperRef].nativeElement.swiper;
      this[swiper].on("slideChange", swiper => {
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  prevSlide(swiper, idx) {
    this[swiper].slidePrev();
    this[idx] = this[swiper].activeIndex;
  }

  nextSlide(swiper, idx) {
    this[swiper].slideNext();
    this[idx] = this[swiper].activeIndex;
  }
}
