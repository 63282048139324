import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiOptions } from "../../../../../../../core/interfaces/api-options";
import { ApiService } from "../../../../../../../core/providers/api.service";
import { EVENT_DIALOGS_NAMES_MESSAGE } from "../../../consts/core/event-dialogs/event-names.const";
import { MOCK_MESSAGES_FILTER } from "../../../mock/consts/mock-messages.const";
import {
  DeleteMessageRequest,
  GetMessageDetailsRequest,
  GetMessagesFiltersRequest,
  GetMessagesRequest,
  PatchMarkReadMessageRequest,
} from "../interfaces";

@Injectable({
  providedIn: "root",
})
export class ApiMessageService {
  constructor(protected apiService: ApiService) {}

  getMessages(getMessagesRequest: GetMessagesRequest): Observable<any> {
    const options: ApiOptions = {
      params: {
        ...getMessagesRequest,
      },
      responseWithHeaders: true,
    };
    return this.apiService.get("messages", options, EVENT_DIALOGS_NAMES_MESSAGE.MESSAGES);
  }

  getMessageDetails({ playerMessageId }: GetMessageDetailsRequest) {
    return this.apiService.get(`messages/${playerMessageId}`, {}, EVENT_DIALOGS_NAMES_MESSAGE.MESSAGE_DETAILS_HIDDEN_REWARD);
  }

  getMessagesFilters({ filter }: GetMessagesFiltersRequest) {
    return this.apiService.get(`messages/${filter}`, {}, `${MOCK_MESSAGES_FILTER}_${filter}`);
  }

  deleteMessage({ messageId }: DeleteMessageRequest) {
    return this.apiService.patch(`messages/${messageId}/trash`);
  }

  markSeen({ messageId }: PatchMarkReadMessageRequest) {
    return this.apiService.patch(`messages/${messageId}/seen`);
  }

  markRead({ messageId }: PatchMarkReadMessageRequest) {
    const options: ApiOptions = {
      isNotShowLoading: true,
    };

    return this.apiService.patch(`messages/${messageId}/read`, options);
  }
}
