import { Component, OnDestroy, OnInit } from "@angular/core";
import { select } from "@ngrx/store";
import * as R from "ramda";
import { take } from "rxjs/operators";

import { unsubscribeObject } from "../../../../../../core/utility/unsubscribe-array";
import { UserSelectors } from "../../../../../../store/user";
import { AbstractPlayerChooseComponent } from "../../../../abstract/abstract-player-choose.component";

@Component({
  selector: "auth-player-choose",
  templateUrl: "./player-choose.component.html",
})
export class PlayerChooseComponent extends AbstractPlayerChooseComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.guiService.isSplashShow.next(true);
    this.checkReload();
    this.subs.user = this.store.pipe(select(UserSelectors.selectUser), take(1)).subscribe(user => {
      this.enabledPlayers = R.clone(user.enabled_players);
      this.selectedPlayerId = user.selected_player_id;
      this.changePlayer(this.findSelectedPlayer() || this.enabledPlayers[0]);
      this.checkPlayerLength();
    });
  }

  checkPlayerLength() {
    if (this.enabledPlayers.length === 1) {
      if (this.selectedPlayerId !== null) {
        this.redirectGame();
      } else {
        this.setDefaultPlayer();
      }
    } else {
      this.guiService.isSplashShow.next(false);
    }
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
