import { Component } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../../core/abstracts/abstract-inject-base.component';
import { NewEditionInvitationService } from '../../../../services/custom/new-edition-invitation.service';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../../../shared/providers/dialog.service';
import { ApiService } from '../../../../../../../../core/providers/api.service';

@Component({
  selector: 'app-new-edition-invitation',
  templateUrl: './new-edition-invitation.component.html',
})
export class NewEditionInvitationComponent extends AbstractInjectBaseComponent {
  @OwInject(NewEditionInvitationService) newEditionInvitationService: NewEditionInvitationService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiService) apiService: ApiService;

  agreements = [
    {
      active: false,
      text: `Wyrażam zgodę na udział w Programie Grywalizacyjnym "Komp Town – Nowy świat" i Konkursie "Komp Town – Nowy świat".`
    },
    {
      active: false,
      text: `Oświadczam, że zapoznałem/-am się i akceptuję warunki oraz zasady uczestnictwa w ` +
        `Programie Grywalizacyjnym "Komp Town – Nowy świat" i Konkursie "Komp Town – Nowy świat" określone w ` +
      `<a href="https://komptown.pl/api/files/download/14f0ac16-bf9f-4bc6-b622-bade507adb0f/Regulamin%20Komp%20Town%20-%20Nowy%20%C5%9Bwiat.pdf" target="_blank">` +
      `Regulaminie</a> Programu Grywalizacyjnego "Komp Town – Nowy świat" i Konkursu "Komp Town – Nowy świat"` +
      ` i zobowiązuję się do ich przestrzegania.`
    },
  ];

  get isValid(): boolean {
    return this.agreements.every(agreement => agreement.active);
  }

  askLater() {
    this.newEditionInvitationService.clearInvitationNotification();
    this.dialogService.closeActive();
  }

  accept() {
    if (this.isValid) {
      this.apiService.patch(`me/save-consent`).subscribe(() => {
        this.dialogService.closeActive();
      }, (errResp) => {
        this.dialogService.openAlertErrorApi({errResp});
      });
    }
  }

  closeWindow() {
    this.dialogService.closeActive();
  }

  changeAgreement(agreement: { active: boolean, text: string }) {
    agreement.active = !agreement.active;
  }
}
