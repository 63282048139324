import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { GameEngineMobileModule } from "../../../game/game-engine/mobile/game-engine-mobile.module";
import { GameGuiModule } from "../../../game/game-gui/game-gui.module";
import { GameUiMobileModule } from "../../../game/game-ui/game-ui-mobile.module";
import { HudModule } from "../../../game/game-ui/hud/module/core/hud.module";
import { GameAbstractModule } from "../../abstract/core/game-abstract.module";
import { CoreGameLoadedPlaceholderComponent } from "../../basic/core/components/game-loaded-placeholder/game-loaded-placeholder.component";
import { PlayGameComponent } from "../../basic/core/components/play-game/play-game.component";
import { GameRoutingModule } from "./game-routing-mobile.module";

@NgModule({
  imports: [CommonModule, GameAbstractModule, GameRoutingModule, GameUiMobileModule, GameEngineMobileModule, HudModule, GameGuiModule],
  declarations: [PlayGameComponent, CoreGameLoadedPlaceholderComponent],
  exports: [PlayGameComponent],
})
export class GameModule {}
