import { createSelector } from "@ngrx/store";

import { Player } from "../../modules/player/interfaces/player";
import { AppState } from "../state";
import { PlayerState } from "./state";

export const selectState = (state: AppState) => state.application.player;

const getIsLoading = (state: PlayerState): boolean => state.isLoading;
export const selectIsLoading = createSelector<AppState, object, boolean>(selectState, getIsLoading);

const getPlayer = (state: PlayerState): Player => state.player;
export const selectPlayer = createSelector<AppState, object, Player>(selectState, getPlayer);
