import { Directive, Injectable } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import { filter } from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { selectGameBoardTile } from "../../../../../../store/game/selectors";
import { AppState } from "../../../../../../store/state";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { AUTO_PRODUCTION_VALUE_ATTRIBUTE_NAME } from "../../../../constants";
import { BuildingDetailsConfig } from "../../../../game-engine/interfaces/building-details-config";
import { getBuildingAttribute } from "../../../../game-gui/helpers/buildings.helper";
import { BuildingsService } from "../../../../services/buildings.service";
import { STOCK_VIEW } from "../../../shared-ui/mobile/consts/stock-view.const";
import { BuildingData } from "../../interfaces/core/dialogs/building-data.interface";

@Directive()
@Injectable()
export abstract class AbstractBuildingAutoProductionComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractBuildingAutoProductionComponent>;
  @OwInject(MAT_DIALOG_DATA) data: BuildingData;
  @OwInject(BuildingsService) buildingsService: BuildingsService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(Store) store: Store<AppState>;

  STOCK_VIEW = STOCK_VIEW;
  buildingDetails: BuildingDetailsConfig;
  automaticCurrency: {
    currency_id: number;
    amount: number;
  };

  automaticProduct: {
    product_id: number;
    amount: number;
  };

  subs = {
    board: null,
  };

  subscribeBoardTile() {
    this.subs.board = this.store
      .pipe(
        select(selectGameBoardTile, { playerTileId: this.data.playerTileId }),
        filter(state => !!state)
      )
      .subscribe(tile => {
        this.getPlayerBuildingDetails(tile.player_building.player_building_id);
      });
  }

  getPlayerBuildingDetails(playerBuildingId: number) {
    this.buildingsService.getPlayerBuildingDetails(playerBuildingId).subscribe(buildingDetails => {
      this.buildingDetails = buildingDetails;
      this.setAutoProduction();
    });
  }

  setAutoProduction() {
    const automaticCurrency = this.buildingDetails.automatic_currency;
    const automaticProduct = this.buildingDetails.automatic_product;

    if (automaticCurrency) {
      const amount = getBuildingAttribute(AUTO_PRODUCTION_VALUE_ATTRIBUTE_NAME, this.buildingDetails.attributes).value || 0;

      this.automaticCurrency = {
        currency_id: automaticCurrency.currency_id,
        amount,
      };
    }

    if (automaticProduct) {
      const amount = getBuildingAttribute(AUTO_PRODUCTION_VALUE_ATTRIBUTE_NAME, this.buildingDetails.attributes).value || 0;

      this.automaticProduct = {
        product_id: automaticProduct.product_id,
        amount,
      };
    }
  }
}
