export const CUSTOM_EVENTS = {
  GUI: {
    HUD_PARAMETERS_2: {
      SHOW: "hud_parameters_2_show",
      HIDE: "hud_parameters_2_hide",
    },
    BUSINESS: {
      SHOW: "business_show",
      HIDE: "business_hide",
    },
  },
};
