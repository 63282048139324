import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { SharedModule } from "../../../../../shared/shared.module";
import { PreviewPlayerModule } from "../../../preview-player/module/custom/preview-player.module";
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import { BasicActionsComponent } from "../../basic/custom/components/basic-actions/basic-actions.component";
import { BusinessCardsComponent } from "../../basic/custom/components/business-cards/business-cards.component";
import { CardTaskComponent } from "../../basic/custom/components/card-task/card-task.component";
import { CardTaskVendorsComponent } from "../../basic/custom/components/card-task-vendors/card-task-vendors.component";
import { CardTaskDetailsComponent } from "../../basic/custom/dialogs/card-task-details/card-task-details.component";
import { ChooseTaskComponent } from "../../basic/custom/dialogs/choose-task/choose-task.component";
import { DashboardComponent } from "../../basic/custom/dialogs/dashboard/dashboard.component";
import { TaskCardsComponent } from "../../basic/custom/components/task-cards/task-cards.component";
import { TaskContestsComponent } from "../../basic/custom/components/task-contests/task-contests.component";
import { TaskVendorsComponent } from "../../basic/custom/components/task-vendors/task-vendors.component";
import { OwPerfectScrollbarModule } from "@oskarwegner/ow-perfect-scrollbar";
import { CardTaskVendorComponent } from "../../basic/custom/components/card-task-vendor/card-task.component";
import { CardTaskContestComponent } from "../../basic/custom/dialogs/card-task-contest/card-task-contest.component";
import { RomanizePipe } from "../../pipes/romanize.pipe";
import {ContractsDetailsComponent} from '../../basic/custom/dialogs/contracts-details/contracts-details.component';

@NgModule({
  declarations: [
    CardTaskComponent,
    CardTaskDetailsComponent,
    BusinessCardsComponent,
    ChooseTaskComponent,
    BasicActionsComponent,
    CardTaskVendorsComponent,
    DashboardComponent,
    TaskCardsComponent,
    TaskContestsComponent,
    TaskVendorsComponent,
    CardTaskVendorComponent,
    CardTaskContestComponent,
    ContractsDetailsComponent,
    RomanizePipe,
  ],
  exports: [BusinessCardsComponent],
  imports: [CommonModule, SharedUiMobileModule, SharedModule, PreviewPlayerModule, OwPerfectScrollbarModule, OwPerfectScrollbarModule],
})
export class BusinessModule {}
