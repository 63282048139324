import { UtilityPlayer } from "../../../utility/interfaces/utility-player";
import { TryUpdatePlayer } from "../../actions";

export function customHandleHasMissionsToCollect({
  action,
  state,
  coreValueHasMissionToCollect,
}: {
  action: TryUpdatePlayer;
  state: UtilityPlayer;
  coreValueHasMissionToCollect: boolean;
}) {
  return coreValueHasMissionToCollect;
}
