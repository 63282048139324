import { Directive, Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { tap } from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../core/abstracts/abstract-inject-base.component";
import { GAME_CONFIG } from "../../../core/config/custom/_parsed-game.config";
import { OwInject } from "../../../core/decorators/ow-inject.decorator";
import { AndroidService } from "../../../core/providers/android.service";
import { LoginActions } from "../../../store/auth/login";
import { AppState } from "../../../store/state";
import { UserActions } from "../../../store/user";
import { GuiService } from "../../game/services/gui.service";
import { DialogService } from "../../shared/providers/dialog.service";
import { AUTH_ROUTING_ABSOLUTE_PATH } from "../consts/core/routing-auth-absolute.const";
import { Credentials } from "../interfaces/credentials";

@Directive()
@Injectable()
export abstract class AbstractLoginComponent extends AbstractInjectBaseComponent {
  @OwInject(FormBuilder) fb: FormBuilder;
  @OwInject(Router) router: Router;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(Actions) actions$: Actions;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(GuiService) guiService: GuiService;
  @OwInject(AndroidService) androidService: AndroidService;

  AUTH_ROUTING_ABSOLUTE_PATH = AUTH_ROUTING_ABSOLUTE_PATH;
  GAME_CONFIG = GAME_CONFIG;
  form: FormGroup;

  subs = {
    actionUserSuccess: null,
    actionAuthSuccess: null,
  };

  afterConstructor() {
    this.form = this.fb.group({
      username: this.fb.control(null, [Validators.required]),
      password: this.fb.control(null, [Validators.required]),
      remember_me: this.fb.control(null),
    });
  }

  prepareData(): Credentials {
    return {
      username: this.form.value.username,
      password: this.form.value.password,
      remember_me: this.form.value.remember_me,
    };
  }

  login() {
    const data = this.prepareData();
    this.store.dispatch(new LoginActions.AuthStart(data));
  }

  subscribeAuthSuccess() {
    this.subs.actionAuthSuccess = this.actions$
      .pipe(
        ofType(LoginActions.ActionTypes.AUTH_SUCCESS),
        tap(() => {
          this.store.dispatch(new UserActions.FetchUser());
        })
      )
      .subscribe();
  }

  subscribeUserSuccess() {
    this.subs.actionUserSuccess = this.actions$
      .pipe(
        ofType(UserActions.ActionTypes.FETCH_USER_SUCCESS),
        tap(() => {
          setTimeout(() => {
            this.router.navigate(["/auth/player-choose"]);
          });
        })
      )
      .subscribe();
  }
}
