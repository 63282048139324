import { Directive, Injectable } from "@angular/core";

import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { CurrencyDetails } from "../../../../../../core/interfaces/currency";
import { ProductPlayerService } from "../../../../../player/providers/product-player.service";
import { MessageDetailsChestSuccess, ProductChest } from "../../interfaces/core/message-details-chest-success.interface";
import { AbstractMessageDetailsBaseComponent } from "./abstract-message-details-base.component";

@Directive()
@Injectable()
export abstract class AbstractMessageDetailsChestSuccessComponent extends AbstractMessageDetailsBaseComponent {
  message: MessageDetailsChestSuccess;
  @OwInject(ProductPlayerService) productPlayerService: ProductPlayerService;

  currencyDetails(value): CurrencyDetails {
    return value;
  }

  getProducts() {
    this.message.parameters.product_rewards = <ProductChest[]>(
      this.productPlayerService.getProducts(this.message.parameters.product_rewards || [])
    );
  }
}
