<!-- LOGO -->
<div class="logo"></div>

<loading></loading>

<div class="container loading-opacity">
  <p class="title">{{ "auth.forgot-password.title" | myTranslate }}:</p>

  <ng-container *ngIf="formSendSuccess; else formTpl">
    <!-- SEND SUCCESS INFO -->
    <p class="description" [innerHTML]="'auth.forgot-password.send-success-info' | myTranslate"></p>
  </ng-container>
  <ng-template #formTpl>
    <form [formGroup]="form">
      <!-- EMAIL FORM -->
      <div class="control">
        <div class="label-flex">
          <label>{{ "auth.username" | myTranslate }}:</label>
        </div>
        <input class="control-input no-ow-input-directive" formControlName="email" type="text" />
      </div>
    </form>
  </ng-template>

  <div class="buttons">
    <button class="base primary" (click)="onFormSubmit()" [disabled]="form.invalid" *ngIf="!formSendSuccess">
      {{ "auth.forgot-password.button-send" | myTranslate }}
    </button>

    <button class="base primary" [routerLink]="AUTH_ROUTING_ABSOLUTE_PATH.LOGIN">
      {{ "auth.forgot-password.button-back" | myTranslate }}
    </button>
  </div>
</div>
