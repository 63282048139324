import { CUSTOM_FEATURE_FLAG } from "../custom/feature-flags.config";

export const FEATURE_FLAG = {
  CHANGE_PASSWORD: true,
  BUSINESS: {
    BASIC_ACTION: true,
  },
  BUSINESS_CONTAINER_BOTTOM: true,
  HUD: {
    QUIZ: true,
    CANTOR: true,
    RANKS: true,
  },
  PHOTO_GALLERY: true,
  UNPLAYABLE_ALLOWED_ADD_PHOTOS: true,
  MAX_UPLOAD_FILE_SIZE: 32 * 1024 * 1024,
  GAME_DATE: false,
  ...CUSTOM_FEATURE_FLAG,
};
