import { Directive, Injectable, OnDestroy, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { of, pipe, timer } from 'rxjs';
import { debounce } from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../core/abstracts/abstract-inject-base.component";
import { INFO_MESSAGE_STATUS } from "../../../core/consts/core/info-message.constants";
import { SOCKET_STATUS } from "../../../core/consts/core/socket.constants";
import { OwInject } from "../../../core/decorators/ow-inject.decorator";
import { GlobalService } from "../../../core/providers/global.service";
import { unsubscribeObject } from "../../../core/utility/unsubscribe-array";
import { LoginActions } from "../../../store/auth/login";
import { SocketSelectors, SocketState } from "../../../store/socket";
import { AppState } from "../../../store/state";
import { UtilitySelectors } from "../../../store/utility";
import { InfoMessageState } from "../../../store/utility/state";
import { AuthService } from "../../auth/providers/auth.service";
import { GuiService } from "../../game/services/gui.service";

@Directive()
@Injectable()
export abstract class AbstractInfoMessageScreenComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  SOCKET_STATUS = SOCKET_STATUS;
  INFO_MESSAGE_STATUS = INFO_MESSAGE_STATUS;
  socketState: SocketState;
  infoMessageState: InfoMessageState;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(AuthService) authService: AuthService;
  @OwInject(GuiService) guiService: GuiService;

  subs = {
    socket: null,
    infoMessage: null,
  };

  ngOnInit() {
    this.subs.socket = this.store
      .pipe(
        select(SocketSelectors.selectState),
        pipe(
          debounce(socketState => {
            switch (socketState.socketStatus) {
              case SOCKET_STATUS.CONNECTING:
                return timer(500);
            }

            return of(true);
          })
        )
      )
      .subscribe(this.handleSocketEvents.bind(this));

    this.subs.infoMessage = this.store.pipe(select(UtilitySelectors.selectInfoMessage)).subscribe(this.handleInfoMessageEvents.bind(this));
  }

  handleSocketEvents(socketState: SocketState) {
    if (this.socketState && this.socketState.socketStatus === SOCKET_STATUS.OTHER_SESSION) {
      return;
    }

    this.socketState = socketState;
  }

  handleInfoMessageEvents(infoMessageState: InfoMessageState) {
    this.infoMessageState = infoMessageState;
  }

  reloadApp() {
    location.reload();
  }

  logout() {
    this.authService.logoutApi().subscribe(() => {
      this.guiService.isSplashShow.next(true);
      this.store.dispatch(new LoginActions.AuthLogout());
    });
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
