import "phaser";

import { select } from "@ngrx/store";
import * as R from "ramda";
import { Subscription } from "rxjs";
import { filter, take, tap } from "rxjs/operators";

import { EVENTS } from "../../../../../core/consts/core/events";
import { SetCurrentScene } from "../../../../../store/game/actions";
import { BoardState } from "../../../../../store/game/interfaces/board.state";
import { selectGameBoard } from "../../../../../store/game/selectors";
import { BoardViewMode } from "../../../constants";
import { IsoMapLayer } from "../../classes/core/IsoMapLayer";
import { MyGame } from "../../classes/core/MyGame";
import { MyScene } from "../../classes/core/MyScene";
import { Board } from "../../classes/custom/Board.class";
import { calculateMinZoomValue } from "../../utils/game.helper";
import { getWorldSceneConfig } from "../helpers/WorldScene.helper";
import { WORLD_BOARD_CENTER_OFFSET, WORLD_MAIN_SCENE, WORLD_SCENE_WORLD_HEIGHT, WORLD_SCENE_WORLD_WIDTH } from "../world.constants";

export class WorldMainScene extends MyScene {
  resizeTimeoutID: number;
  private globalEmitterSub: Subscription;

  constructor(sceneClassConfig?: Phaser.Types.Scenes.SettingsConfig) {
    sceneClassConfig = sceneClassConfig ? sceneClassConfig : { key: WORLD_MAIN_SCENE };
    super(sceneClassConfig);
  }

  init(data?: any) {
    this.passedData = data;
    this.setCurrentScene();
  }

  preload(): void {
    this.gameService = (this.game as MyGame).gameService;
  }

  provideSceneConfig() {
    return getWorldSceneConfig();
  }

  create(): void {
    const sceneConfig = this.provideSceneConfig();
    // @ts-ignore
    this.sceneConfig = sceneConfig;
    this.phaserEvents = new Phaser.Events.EventEmitter();
    this.setCameraControls();
    this.cameras.main.setBackgroundColor("#b7e6eb");
    this.cameras.main.setBounds(0, 0, WORLD_SCENE_WORLD_WIDTH, WORLD_SCENE_WORLD_HEIGHT);

    // @ts-ignore
    sceneConfig.backgroundImages.forEach(image => {
      const bgImage = this.add.image(image.x, image.y, image.key);
      bgImage.setDisplaySize(image.displayWidth, image.displayHeight);
      if (image.origin) {
        bgImage.setOrigin(image.origin.x, image.origin.y);
      }
    });

    if (!this.buildingsLayer) {
      this.buildingsLayer = new IsoMapLayer({ scene: this, name: "buildingsLayer" });
    }
    this.createBoard();

    this.topLayer = new IsoMapLayer({
      scene: this,
      depthIndex: 99,
      name: "top",
    });

    this.globalEmitterSub = this.gameService.globalService.globalEvents
      .pipe(
        tap(console.log),
        filter(event => event.name === EVENTS.GAME.CENTER_TILE)
      )
      .subscribe(event => this.focusOn(event.value));

    if (this.gameService.centerOn) {
      this.focusOn(this.gameService.centerOn);
    }

    this.setCamera();
    this.afterCreate();
  }

  afterCreate() {}

  setCamera() {
    this.cameras.main.centerOn(this.cameraStartPos.x, this.cameraStartPos.y);
    this.cameras.main.zoomTo(Math.max(0.4, calculateMinZoomValue(this.cameras.main)), 0);
  }

  createBoard() {
    this.gameService.store
      .pipe(
        select(selectGameBoard),
        filter(boardState => boardState.board.length > 0),
        take(1)
      )
      .subscribe((boardState: BoardState) => {
        const clonedBoard = R.clone(boardState.board);
        this.board = new Board(this, { offset: WORLD_BOARD_CENTER_OFFSET, yAxisSign: 1 });
        this.board.createBoard(clonedBoard, BoardViewMode.Ortogonal);
        this.gameService.store.dispatch(new SetCurrentScene(this.scene.key));
      });
  }

  destroyScene() {
    this.globalEmitterSub.unsubscribe();
    super.destroyScene();
  }

  resize() {
    super.resize();

    // clearTimeout(this.resizeTimeoutID);
    // this.resizeTimeoutID = setTimeout(() => {
    //   console.log('restart');
    //   this.destroyScene();
    //   this.scene.restart();
    // }, 1000);
    //
  }
}
