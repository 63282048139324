import { Component, ComponentFactoryResolver, ViewChild, ViewContainerRef } from "@angular/core";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { setDynamicsComponents } from "../../../../../../../../core/helpers/dynamic-component.helper";
import { AbstractPlayerDetailsComponent } from "../../../../abstract/core/abstract-player-details.component";
import { CORE_DYNAMIC_COMPONENTS } from "../../../../const/core/dynamic-components/player-details/dynamic-components.const";
import { CUSTOM_DYNAMIC_COMPONENTS } from "../../../../const/custom/dynamic-components/player-details/dynamic-components.const";

@Component({
  selector: "app-player-details",
  templateUrl: "./player-details.component.html",
})
export class PlayerDetailsComponent extends AbstractPlayerDetailsComponent {
  @OwInject(ComponentFactoryResolver) componentFactoryResolver: ComponentFactoryResolver;
  @ViewChild("otherViewTpl", { read: ViewContainerRef }) otherViewTpl: ViewContainerRef;

  setDynamicsComponents() {
    setDynamicsComponents.bind(this)({
      coreDynamicComponents: CORE_DYNAMIC_COMPONENTS,
      customDynamicComponents: CUSTOM_DYNAMIC_COMPONENTS,
    });
  }

  toggleOthersView() {
    super.toggleOthersView();

    setTimeout(() => {
      if (this.isOthersView) {
        this.setDynamicsComponents();
      }
    });
  }
}
