import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiService } from "../../../core/providers/api.service";
import { AssetsService } from "../../../core/providers/assets.service";
import { Product } from "../../player/interfaces/product.interface";
import { PlayerService } from "../../player/providers/player.service";

@Injectable({
  providedIn: "root",
})
export class ProductsService {
  promiseProductPlayer: Promise<any>;
  products: Product[] = [];

  constructor(public playerService: PlayerService, private apiClient: ApiService, private assetsService: AssetsService) {}

  /**
   * FUNCTION
   */
  setProductsDefinitions(products: Product[]) {
    this.products = products;
  }

  getProduct(_data: { product_id: number }) {
    let data = JSON.parse(JSON.stringify({ ..._data, ...this.getProductFromDictionary(_data) }));
    data = this.haveProduct(data, this.playerService.player.product_balances);

    return Object.assign({}, data);
  }

  getProductFromDictionary(product) {
    return this.products.find(p => p.product_id === product.product_id);
  }

  getProductImage(productIcon: string, suffix = ""): string {
    return this.assetsService.getAssetPath(`products/${productIcon}${suffix}.png`) as string;
  }

  private haveProduct(data: any, searchArray: any[]) {
    data.have = false;
    data.balance = 0;

    /**
     * If quantity passed as amount
     */
    if (data.hasOwnProperty("amount")) {
      data.quantity = data.amount;
    }

    /**
     * Check required level
     * @type {boolean}
     */
    data.allow_required_level = this.playerService.allowRequiredLevel(data.required_level);

    /**
     * Get product image from image service big
     */
    data.icon_url = this.getProductImage(data.icon, "-big");

    /**
     * Get product image from image service small
     */
    data.icon_url_small = this.getProductImage(data.icon, "-small");

    searchArray.forEach(product => {
      if (product.product_id === data.product_id) {
        data.balance = product.balance;

        /**
         * Have required amount
         */
        if (product.balance >= data.quantity) {
          data.have = true;
        }
      }
    });

    return data;
  }

  /**
   * API
   */
  productsPlayer(category?): Promise<any> {
    if (this.promiseProductPlayer) {
      return this.promiseProductPlayer;
    }

    return (this.promiseProductPlayer = new Promise((resolve, reject) => {
      const options = {
        params: {
          category: category,
        },
      };

      this.apiClient.request("GET", `products/player/${this.playerService.getActivePlayerId()}`, options).subscribe(
        resp => {
          resolve(resp);
          this.removePromise();
        },
        errResp => {
          reject(errResp);
          this.removePromise();
        }
      );
    }));
  }

  removePromise(): void {
    setTimeout(() => {
      delete this.promiseProductPlayer;
    }, 0);
  }

  productsCategories({ storage_id }: { storage_id: number }): Observable<any> {
    const options = {
      params: {
        storage_id,
      },
    };

    return this.apiClient.request("GET", "products/categories", options);
  }

  productsBuy(product_id, amount): Observable<any> {
    const options = {
      body: {
        amount: amount,
      },
    };

    return this.apiClient.request("POST", `products/${product_id}/buy`, options);
  }

  productsSell(product_id, amount): Observable<any> {
    const options = {
      body: {
        amount: amount,
      },
    };

    return this.apiClient.request("POST", `products/${product_id}/sell`, options);
  }

  getUnlockedProductsByLevel(level): Observable<any> {
    return this.apiClient.request("GET", `products/at-level/${level}`);
  }
}
