import { Component, OnDestroy, OnInit } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AbstractHudResourcesComponent } from "../../../../abstract/core/abstract-hud-resources.component";
import {select} from '@ngrx/store';
import {PrimarySelectors} from '../../../../../../../../store/primary';
import {take} from 'rxjs/operators';
import {selectPlayer} from '../../../../../../../../store/player/selectors';
import {UserSelectors} from '../../../../../../../../store/user';
import {combineLatest} from 'rxjs';
import {User} from '../../../../../../../user/interfaces/user';
import {Player} from '../../../../../../../player/interfaces/player';
import {CurrencyDefinition} from '../../../../../../../../core/interfaces/currency';
import * as R from 'ramda';

@Component({
  selector: "app-hud-resources",
  templateUrl: "./hud-resources.component.html",
})
export class HudResourcesComponent extends AbstractHudResourcesComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.subscribeUser();
    this.setParameters();
  }

  setParameters() {
    const currency$ = this.store.pipe(select(PrimarySelectors.selectCurrencies), take(1));
    const player$ = this.store.pipe(select(selectPlayer));
    const user$ = this.store.pipe(select(UserSelectors.selectUser));

    this.subs.combine = combineLatest([currency$, player$, user$]).subscribe(([currencies, player, user]) => {
      console.log('refreshed');
      this.user = user as User;
      this.player = player as Player;
      this.currencyDefinitions = currencies as CurrencyDefinition[];
      this.guiItems = player["gui_items"].map(guiItem => {
        let resourceBalance;

        // CURRENCY
        if (guiItem.currency_id) {
          const currency = this.currencyDefinitions.find(curr => curr.currency_id === guiItem.currency_id);
          resourceBalance = this.currencyService.getCurrencyBalance(guiItem, [
            ...player["currency_balances"],
            ...this.user["currency_balances"],
          ]);

          if (currency) {
            guiItem = {
              ...guiItem,
              type: currency.type,
              show_currency_history: currency.show_currency_history,
              currency: R.mergeDeepRight(resourceBalance, currency),
            };
          }
        }

        // PRODUCT
        if (guiItem.product_id) {
          resourceBalance = this.findProductBalance(guiItem, player["product_balances"]);
        }

        let balance = 0;
        if (resourceBalance) {
          balance = resourceBalance.balance;
        }
        guiItem = { ...guiItem, resourceBalance: balance };
        this.setTooltip(guiItem);

        return guiItem;
      });

      this.orderGuiItems();
    });
  }

  subscribeUser() {
    // empty, we moved this into setParameter
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
