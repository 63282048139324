import { Action } from "@ngrx/store";

import { Credentials } from "../../../modules/auth/interfaces/credentials";

export enum ActionTypes {
  AUTH_START = "[auth] start user authentication",
  AUTH_SUCCESS = "[auth] user authentication success",
  AUTH_FAIL = "[auth] user authentication failed",
  AUTH_LOGOUT = "[auth] start user logout",
  AUTH_LOGOUT_SUCCESS = "[auth] user logout success",
}

export class AuthStart implements Action {
  readonly type = ActionTypes.AUTH_START;

  constructor(public payload: Credentials) {}
}

export class AuthSuccess implements Action {
  readonly type = ActionTypes.AUTH_SUCCESS;

  constructor() {}
}

export class AuthFail implements Action {
  readonly type = ActionTypes.AUTH_FAIL;

  constructor(public payload: any) {}
}

export class AuthLogout implements Action {
  readonly type = ActionTypes.AUTH_LOGOUT;

  constructor() {}
}

export class AuthLogoutSuccess implements Action {
  readonly type = ActionTypes.AUTH_LOGOUT_SUCCESS;

  constructor() {}
}

export type Actions = AuthStart | AuthSuccess | AuthFail | AuthLogout | AuthLogoutSuccess;
