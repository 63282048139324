import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";

import { SharedModule } from "../../../../../shared/shared.module";
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import { EventShopAlertConfirm } from "../../basic/custom/dialogs/event-shop-alert-confirm/event-shop-alert-confirm.component";
import { EventShopDetails } from "../../basic/custom/dialogs/event-shop-details/event-shop-details";
import { EventShopList } from "../../basic/custom/dialogs/event-shop-list/event-shop-list";
import { EventShopProductDetails } from "../../basic/custom/dialogs/event-shop-product-details/event-shop-product-details";
import { OwPerfectScrollbarModule } from '@oskarwegner/ow-perfect-scrollbar';

@NgModule({
  declarations: [EventShopList, EventShopDetails, EventShopProductDetails, EventShopAlertConfirm],
  imports: [CommonModule, SharedModule, SharedUiMobileModule, ReactiveFormsModule, OwPerfectScrollbarModule],
  exports: [],
})
export class EventShopModule {}
