import { Component } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { TrainingMaterialsListComponent } from "../../dialogs/training-materials-list/training-materials-list.component";

@Component({
  selector: "app-training-materials-button",
  templateUrl: "./training-materials-button.component.html",
})
export class TrainingMaterialsButtonComponent extends AbstractInjectBaseComponent {
  @OwInject(DialogService) dialogService: DialogService;

  openTrainingMaterialsList() {
    this.dialogService.open(TrainingMaterialsListComponent);
  }
}
