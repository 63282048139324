import { Directive, Injectable, Input } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { FEATURE_FLAG } from "../../../../../../core/config/core/feature-flags.config";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { EventEmitterDialogsService } from "../../../../../../core/services/core/event-emitter-dialogs.service";
import { Player } from "../../../../../player/interfaces/player";
import { PlayerService } from "../../../../../player/providers/player.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { EVENT_DIALOGS_NAMES_HUD } from "../../const/core/event-dialogs/event-names.const";

@Directive()
@Injectable()
export abstract class AbstractHudPlayerComponent extends AbstractInjectBaseComponent {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(PlayerService) playerService: PlayerService;
  FEATURE_FLAG = FEATURE_FLAG;
  @Input() changeProfileImage = false;
  @Input() allowHover = true;
  @Input() player: Player;
  strokeDashOffset: number;
  valuePerOnePercent = 4.66;

  openPlayerDetails() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_HUD.PLAYER_DETAILS,
    });
  }

  setProfileImage(file) {
    this.playerService.setProfileImage(file).subscribe(() => {});
  }

  calcPlayerLevelProgress() {
    let strokeDashOffset = 0;
    const levelPercent = this.player.level_percent;
    if (Number.isInteger(levelPercent)) {
      strokeDashOffset = 578 - levelPercent * this.valuePerOnePercent;
    }
    this.strokeDashOffset = strokeDashOffset;
  }

  changeImage(event: EventTarget) {
    const eventObj: any = event;
    const target = eventObj.target;
    const files: FileList = target.files;
    const file = files[0];
    if (file) {
      this.setProfileImage(file);
    }
  }
}
