import { Component, OnDestroy, OnInit } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AbstractBuildingInfoComponent } from "../../../../abstract/core/abstract-building-info.component";
import {getNotOutcomeParameters} from '../../../../helpers/custom/outcome-parameters.helper';

@Component({
  selector: "app-building",
  templateUrl: "./building-info.component.html",
})
export class BuildingInfoComponent extends AbstractBuildingInfoComponent implements OnInit, OnDestroy {
  notOutcomeParameters: any[];

  ngOnInit() {
    this.subscribeBoardTile();
  }

  afterBuildingDetails() {
    this.setParameters();
  }

  setParameters() {
    this.notOutcomeParameters = getNotOutcomeParameters(this.buildingDetails.parameters);
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
