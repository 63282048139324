import { BusinessCardsComponent } from "../../../../../business/basic/custom/components/business-cards/business-cards.component";
import { HudMenuComponent } from "../../../../base/custom/components/hud-menu/hud-menu.component";

export const CUSTOM_DYNAMIC_COMPONENTS = {
  HUD_MENU: {
    component: HudMenuComponent,
    viewContainerRef: "hudMenuTpl",
  },
  BUSINESS: {
    component: BusinessCardsComponent,
    viewContainerRef: "dynamicsComponentTpl",
  },
};
