<!-- LOGO -->
<ng-container *ngIf="!guiService.isSplashShow.value">
  <div class="logo"></div>

  <loading></loading>

  <div class="container loading-opacity">
    <form [formGroup]="form" autocomplete="off">
      <!-- LOGIN FORM -->
      <div [class.error]="form.get('username').invalid && form.get('username').dirty && form.get('username').errors" class="control">
        <div class="label-flex">
          <label>{{ "auth.username" | myTranslate }}:</label>
        </div>
        <input autocomplete="off" class="control-input no-ow-input-directive" formControlName="username" type="text" />
      </div>

      <div [class.error]="form.get('password').invalid && form.get('password').dirty && form.get('password').errors" class="control">
        <div class="label-flex">
          <label>{{ "auth.password" | myTranslate }}:</label>
        </div>
        <input autocomplete="off" class="control-input no-ow-input-directive" formControlName="password" type="password" />
      </div>

      <div *ngIf="GAME_CONFIG.FLAGS.AUTH.LOGIN.REMEMBER_ME" class="control control-checkbox remember-me-container">
        <div class="custom-checkbox">
          <input autocomplete="off" formControlName="remember_me" id="remember_me" type="checkbox" />
          <span class="custom-checkbox-fill">
            <i class="fas fa-check"></i>
          </span>
        </div>

        <label for="remember_me">
          {{ "auth.remember-me" | myTranslate }}
        </label>
      </div>

      <div class="buttons flex-center">
        <button (click)="login()" [disabled]="form.invalid" class="base primary">
          {{ "auth.login.button-send" | myTranslate }}
        </button>
      </div>

      <!-- LOST PASSWORD -->
      <a class="description text-center margin-v2" [routerLink]="AUTH_ROUTING_ABSOLUTE_PATH.FORGOT_PASSWORD">
        <p>{{ "auth.login.forgot-password" | myTranslate }}</p>
      </a>

      <!-- HELPDESK -->
      <p class="description text-center margin-v1">
        {{ "global.helpdesk-email" | myTranslate }}
      </p>
    </form>
  </div>
  <footer>
    <div class="text">
      2023 © Oskar Wegner Sp. z o.o., ul.Storrady-Świętosławy 1B/311, 71-602 Szczecin <a href="polityka_prywatnosci" target="_blank" class="green policy">Polityka prywatności</a>
    </div>
  </footer>
</ng-container>
