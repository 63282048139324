import { createSelector } from "@ngrx/store";

import { selectAuthState } from "../selectors";
import { AuthState } from "../state";
import { LoginState } from "./state";

export const selectLoginState = createSelector(selectAuthState, (state: AuthState): LoginState => state.login);

const getIsAuthenticating = (state: LoginState): boolean => state.isAuthenticating;
export const selectIsAuthenticating = createSelector(selectLoginState, getIsAuthenticating);
