import { Component, OnInit } from "@angular/core";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { NotificationsService } from "../../../../../../services/custom/notifications.service";
import { AbstractQaListComponent } from "../../../../abstract/core/abstract-qa-list.component";
import { EVENT_DIALOGS_NAMES_QA } from "../../../../consts/core/event-dialogs/event-names.const";

@Component({
  selector: "app-qa-list-view",
  templateUrl: "./qa-list-view.component.html",
})
export class QaListViewComponent extends AbstractQaListComponent implements OnInit {
  @OwInject(NotificationsService) notificationsService: NotificationsService;

  activeFilter = 0;
  filters = [
    { location: 0, label: "Wszystko" },
    { location: 1, label: "Raty" },
    { location: 2, label: "Sprzęt" },
    { location: 3, label: "Polisa" },
    { location: 4, label: "Inne" },
  ];

  ngOnInit() {
    this.notificationsService.setNotificationReaded('qa').subscribe();
    this.data.location = 0;
    if (this.data.location === 0) {
      this.getQaList();
    }
  }

  afterQaList() {
    super.afterQaList();
    this.getCategoryName();
  }

  getScore(qa) {
    return Math.round((qa.last_attempt.player_points / qa.last_attempt.max_points) * 100);
  }

  openQaList(qa) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_QA.QA_LIST,
      config: {
        data: {
          location: 0,
          qa: qa,
          index: this.allQa.indexOf(qa.id),
          isOpenByQaListView: true,
          isShowBackButton: true,
        },
      },
    });
  }

  changeFilter(filter) {
    this.activeFilter = filter.location;
    this.data.location = filter.location;
    this.getQaList();
  }

  getCategoryName() {
    this.allQa.forEach(qa => {
      switch (qa.location) {
        case 1: {
          qa["category"] = "Raty";
          break;
        }
        case 2: {
          qa["category"] = "Sprzęt";
          break;
        }
        case 3: {
          qa["category"] = "Polisa";
          break;
        }
        case 4: {
          qa["category"] = "Inne";
          break;
        }
      }
    });
  }
}
