import { PrimaryGuard } from "../../core/guards/primary.guard";
import { SocketGuard } from "../../core/guards/socket.guard";
import { SyncGuard } from "../../core/guards/sync.guard";
import { APP_ROUTING_PATH } from "../routing-app.const";

export const CUSTOM_ROUTING_APP_CHILDREN = [
  {
    path: APP_ROUTING_PATH.GAME,
    canActivate: [SyncGuard],
    data: {
      syncGuards: [PrimaryGuard, SocketGuard],
    },
    loadChildren: () => import("../../modules/new-game/module/core/game.module").then(m => m.GameModule),
  },
];
