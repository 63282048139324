import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { PlayerSelectors } from "../../../../../../../../store/player";
import { AppState } from "../../../../../../../../store/state";
import { Player } from "../../../../../../../player/interfaces/player";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { HudTabsComponent } from "../../dialogs/hud-tabs/hud-tabs.component";

@Component({
  selector: "app-hud-player-level",
  templateUrl: "./hud-player-level.component.html",
})
export class HudPlayerLevelComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(Store) store: Store<AppState>;
  @OwInject(DialogService) dialogService: DialogService;
  @Input() disabled: boolean;

  player: Player;
  subs = {
    player: null,
  };

  ngOnInit() {
    this.subscribePlayer();
  }

  subscribePlayer() {
    this.subs.player = this.store.pipe(select(PlayerSelectors.selectPlayer)).subscribe(player => {
      this.player = player;
    });
  }

  openHudTabs() {
    this.dialogService.open(HudTabsComponent);
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
