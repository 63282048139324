<div class="global-dialog ow-dialog window-a primary no-transparent">
  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <div class="hud-tabs" *ngIf="activeTab">
    <div
      *ngFor="let tab of tabs"
      (click)="changeTab(tab)"
      class="tab"
      [class.active-tab]="activeTab.id === tab.id"
    >
      {{ tab.label }}
    </div>
  </div>

  <ng-container [ngSwitch]="activeTab.id">
    <hud-player-profile-details *ngSwitchCase="TABS.PLAYER_PROFILE"></hud-player-profile-details>
    <hud-star-missions *ngSwitchCase="TABS.MISSION_STARS"></hud-star-missions>
    <app-other-player-settings *ngSwitchCase="TABS.OTHERS"></app-other-player-settings>
  </ng-container>
</div>
