import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgbDropdownModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import * as moment from "moment";

import { MyTranslatePipe } from "../../core/pipes/my-translate.pipe";
import { ToHourPipe } from "../game/game-gui/directives/to-hour.pipe";
import { BuildingIconPipe } from "../game/game-gui/pipes/building-icon.pipe";
import { BuildingImagePipe } from "../game/game-gui/pipes/building-image.pipe";
import { SafePipe } from "../game/game-gui/pipes/safe.pipe";
import { DeviceRotateComponent } from "./components/device-rotate/device-rotate.component";
import { LoadingComponent } from "./components/loading/loading.component";
import { OwObjectSvgComponent } from "./components/ow-object-svg/ow-object-svg.component";
import { ProgressbarComponent } from "./components/progressbar/progressbar.component";
import { SplashScreenComponent } from "./components/splash-screen/splash-screen.component";
import { VideoComponent } from "./components/video/video.component";
import { CUSTOM_DECLARATIONS } from "./consts/custom/components.const";
import { CUSTOM_EXPORTS } from "./consts/custom/exports.const";
import { CUSTOM_IMPORTS } from "./consts/custom/imports.const";
import { CUSTOM_PROVIDERS } from "./consts/custom/providers.const";
import { BuildingLevelDirective } from "./directives/building-level.directive";
import { CloseDirective } from "./directives/close.directive";
import { CloseAllDirective } from "./directives/close-all.directive";
import { DownloadTokenDirective } from "./directives/download-token.directive";
import { IsSpecialBuildingDirective } from "./directives/is-special-building.directive";
import { OpenStorageDirective } from "./directives/open-storage-directive";
import { OwEllipsisDirective } from "./directives/ow-ellipsis.directive";
import { ScrollGradientDirective } from "./directives/scroll-gradient.directive";
import { SwiperDirective } from "./directives/swiper.directive";
import { AssetPipe } from "./pipes/asset.pipe";
import { SingleCurrencyPipe } from "./pipes/currency.pipe";
import { CurrencyGamePipe } from "./pipes/currency-game.pipe";
import { CurrencyGameBalancePipe } from "./pipes/currency-game-balance.pipe";
import { DownloadTokenPipe } from "./pipes/download-token.pipe";
import { FloorPipe } from "./pipes/floor.pipe";
import { NumberLengthPipe } from "./pipes/number-length.pipe";
import { NumberWithSpacesPipe } from "./pipes/number-with-spaces/number-with-spaces.pipe";
import { OWDurationPipe } from "./pipes/owduration.pipe";
import { OWDuration2Pipe } from "./pipes/owduration2.pipe";
import { ReplacePipe } from "./pipes/replace.pipe";
import { ResourceFromStringPipe } from "./pipes/resource-from-string.pipe";
import { AnalyticsService } from "./providers/analytics.service";
import { DialogService } from "./providers/dialog.service";
import {FilterLineBreaks} from './pipes/filter-line-breaks.pipe';
import {HtmlEntityPipe} from './pipes/html-entity.pipe';

moment.locale("pl");

@NgModule({
  imports: [CommonModule, MatDialogModule, NgbTooltipModule, NgbDropdownModule, MatTooltipModule, NgSelectModule, ...CUSTOM_IMPORTS],
  declarations: [
    NumberWithSpacesPipe,
    LoadingComponent,
    NumberLengthPipe,
    ScrollGradientDirective,
    CloseAllDirective,
    CloseDirective,
    FloorPipe,
    HtmlEntityPipe,
    ReplacePipe,
    AssetPipe,
    ToHourPipe,
    CurrencyGamePipe,
    SafePipe,
    BuildingIconPipe,
    BuildingImagePipe,
    MyTranslatePipe,
    SingleCurrencyPipe,
    DownloadTokenDirective,
    OWDurationPipe,
    ProgressbarComponent,
    CurrencyGameBalancePipe,
    OWDuration2Pipe,
    IsSpecialBuildingDirective,
    DownloadTokenPipe,
    SplashScreenComponent,
    OwEllipsisDirective,
    OwObjectSvgComponent,
    OpenStorageDirective,
    DeviceRotateComponent,
    VideoComponent,
    ResourceFromStringPipe,
    BuildingLevelDirective,
    SwiperDirective,
    FilterLineBreaks,
    ...CUSTOM_DECLARATIONS,
  ],
  providers: [
    DialogService,
    NumberWithSpacesPipe,
    NumberLengthPipe,
    ToHourPipe,
    AnalyticsService,
    MyTranslatePipe,
    AssetPipe,
    CurrencyGameBalancePipe,
    ...CUSTOM_PROVIDERS,
  ],
  exports: [
    CommonModule,
    MatDialogModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NumberWithSpacesPipe,
    LoadingComponent,
    NumberLengthPipe,
    ScrollGradientDirective,
    CloseAllDirective,
    CloseDirective,
    FloorPipe,
    HtmlEntityPipe,
    ReplacePipe,
    AssetPipe,
    MatTooltipModule,
    ToHourPipe,
    CurrencyGamePipe,
    SafePipe,
    BuildingIconPipe,
    MyTranslatePipe,
    SingleCurrencyPipe,
    DownloadTokenDirective,
    OWDurationPipe,
    ProgressbarComponent,
    CurrencyGameBalancePipe,
    OWDuration2Pipe,
    IsSpecialBuildingDirective,
    BuildingImagePipe,
    DownloadTokenPipe,
    SplashScreenComponent,
    OwEllipsisDirective,
    OwObjectSvgComponent,
    OpenStorageDirective,
    NgSelectModule,
    DeviceRotateComponent,
    ResourceFromStringPipe,
    BuildingLevelDirective,
    SwiperDirective,
    ...CUSTOM_EXPORTS,
    FilterLineBreaks,
  ],
})
export class SharedModule {}
