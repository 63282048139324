import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { tap } from "rxjs/operators";

import { ApiOptions } from "../../../core/interfaces/api-options";
import { ApiService } from "../../../core/providers/api.service";
import { TokenExchangeService } from "../../../core/providers/token-exchange.service";
import { AppState } from "../../../store/state";
import { UtilityActions } from "../../../store/utility";
import { ChangePassword } from "../interfaces/change-password";
import { Credentials } from "../interfaces/credentials";
import { Registration, RegistrationConfirm } from "../interfaces/registration";
import { ResetPasswordConfirm, ResetPasswordRequest } from "../interfaces/reset-password";
import { TokenObject } from "../interfaces/token-object";

@Injectable()
export class AuthService {
  logoutUrl: string;

  constructor(private apiService: ApiService, private store: Store<AppState>) {}

  logout() {
    this.apiService.removeToken();
  }

  doLogin(loginData: Credentials) {
    const options: ApiOptions = {
      body: {
        ...loginData,
      },
      sendRequestWithoutToken: true,
    };

    return this.apiService.post("auth/login", options);
  }

  changePassword(data: ChangePassword, token?: string) {
    let options: ApiOptions = {
      body: {
        ...data,
      },
    };

    if (token) {
      options = { ...options, ...{ headers: { "x-token": token } } };
    }

    return this.apiService.post("user/change-password", options);
  }

  resetPasswordRequest(data: ResetPasswordRequest) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true,
    };

    return this.apiService.post("password/reset", options);
  }

  resetPasswordConfirm(data: ResetPasswordConfirm) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true,
    };

    return this.apiService.post("password/reset/confirm", options);
  }

  registration(data: Registration) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true,
    };

    return this.apiService.post("registration", options);
  }

  registrationWrongData(data: Registration) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true,
    };

    return this.apiService.post("registration/wrong-data-notification", options);
  }

  registrationConfirm(data: RegistrationConfirm) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true,
    };

    return this.apiService.post("registration/confirm", options);
  }

  logoutApi() {
    TokenExchangeService.exchangeTimeoutStop();

    return this.apiService.delete("api-token/invalidate").pipe(
      tap(({ logout_url }: { logout_url: string }) => {
        this.logoutUrl = logout_url;
      })
    );
  }

  tempTokenExchange({ temp_token }): Promise<any> {
    const options = {
      body: {
        temp_token,
      },
      sendRequestWithoutToken: true,
    };
    return this.apiService.post("auth/temp-token-exchange", options).toPromise();
  }

  reauthorize() {
    return this.apiService.post("auth/reauthorize").pipe(
      tap((tokenObject: TokenObject) => {
        this.store.dispatch(new UtilityActions.SetToken(tokenObject));
      })
    );
  }

  validate() {
    return this.apiService.get("api-token/validate").pipe(
      tap((tokenObject: TokenObject) => {
        this.store.dispatch(new UtilityActions.SetToken(tokenObject));
      })
    );
  }
}
