<ow-perfect-scrollbar class="ps-bottom-border">
  <ng-container *ngIf="cardContest?.length">
    <div class="contest" *ngFor="let item of cardContest; let i = index" (click)="openContest(i)">
      <p class="name">{{item.name}}</p>
      <div class="awards">
        <m-ui-currency *ngFor="let currency of item.currency_prizes"
                       [stockView]="STOCK_VIEW.J"
                       [item]="currency"
                       [ignoreBalanceComparison]="true"
                       [lack]="false"
                       [lockOpenStorage]="true">
        </m-ui-currency>
        <m-ui-product *ngFor="let product of item.product_prizes"
                      [stockView]="STOCK_VIEW.J"
                      [item]="product"
                      [lack]="false"
                      [lockOpenStorage]="true">

        </m-ui-product>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="cardContest?.length === 0">
    <p class="no-results">Brak wyników</p>
  </ng-container>

  <ng-container *ngIf="cardContest == null">
    <loading [showAlways]="true"></loading>
  </ng-container>
</ow-perfect-scrollbar>

<!-- NAVIGATION BUTTONS -->
<div class="bottom-bar">
  <div class="bar-button long">
    <div class="pagination">
      <div class="buttons">
        <!-- PREV -->
        <button class="square small primary"
                [disabled]="blockPrev"
                (click)="!blockPrev && triggerPrev()">
          <i class="fas fa-angle-left"></i>
        </button>
      </div>

      <div class="pages">{{currentPage}}</div>

      <div class="buttons">
        <!-- NEXT -->
        <button
          [disabled]="blockNext" (click)="!blockNext && triggerNext()"
          class="square small primary">
          <i class="fas fa-angle-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>
