import { Injectable } from "@angular/core";
import { CanActivate, CanLoad, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter, map, take, withLatestFrom } from "rxjs/operators";

import { PLAYER_TITLES } from "../core/consts/core/player-titles";
import { AuthService } from "../modules/auth/providers/auth.service";
import { DialogService } from "../modules/shared/providers/dialog.service";
import { LoginActions } from "../store/auth/login";
import { AppState } from "../store/state";
import { UserSelectors } from "../store/user";
import { UtilitySelectors } from "../store/utility";

@Injectable({
  providedIn: "root",
})
export class ShopRestrictedGuard implements CanActivate, CanLoad {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private dialogService: DialogService,
    private authService: AuthService
  ) {}

  checkShopRestricted() {
    return this.store.pipe(
      withLatestFrom(this.store.pipe(select(UserSelectors.selectUser)), this.store.pipe(select(UtilitySelectors.selectActivePlayerId))),
      filter(([_, user, selectActivePlayerId]) => {
        return selectActivePlayerId !== null;
      }),
      take(1),
      map(([_, user, selectActivePlayerId]) => {
        if (user.is_shop_restricted) {
          if (user.selected_player_id) {
            const resultSelectedPlayer = user.enabled_players.find(player => player.id === selectActivePlayerId);

            if (resultSelectedPlayer.title_id.id === PLAYER_TITLES.PARTNER.id) {
              this.showDialogInfo();
              return false;
            }
          }
          this.router.navigate(["auth/shop"]);
        } else {
          return true;
        }
      })
    );
  }

  showDialogInfo() {
    this.dialogService.openAlert(
      {
        title: "Ups...",
        description: "Utraciłeś uprawnienia do logowania. Twoje konto zostało zablokowane.",
        buttonClose: { label: "Wyloguj" },
      },
      () => {
        this.authService.logoutApi().subscribe(() => {
          this.store.dispatch(new LoginActions.AuthLogout());
        });
      }
    );
  }

  canActivate(): Observable<boolean> {
    return this.checkShopRestricted();
  }

  canLoad(): Observable<boolean> {
    return this.checkShopRestricted();
  }
}
