import {
  BOARD_TILE_ALPHA,
  BOARD_TILE_WIDTH,
  BOARD_TILE_WITH_BUILDING_ALPHA,
  PLAYER_BUILDING_STATUS_BUILD_IN_PROGRESS,
  ProductionStatus,
  TILE_TYPE_POLYGON,
  TileTooltipType,
} from "../../../constants";
import { ISO_OBJECT_DEPTH_VALUE_FACTOR } from "../../../constants/iso.constants";
import { extractBuildingImageParametersFromAssetPath } from "../../../game-gui/helpers/buildings.helper";
import { Kt3GameService } from "../../../services/kt3-game.service";
import { getBuildingAnchor, getTileHoverAnchor, getTileHoverTextureName, getTileTypePolygon } from "../../utils/board.helper";
import { handleBuildingAnimation, triggerRocketLaunch } from "../../utils/building-animations.helper";
import { BoardTileCore } from "../core/BoardTileCore.class";

export class BoardTile extends BoardTileCore {
  beforeClickHandle(): any {
    if (this.playerBuildingData?.hasOwnProperty("warehouse_income") && this.playerBuildingData["warehouse_income"] > 0) {
      this.warehouseSaleCollect();
      return true;
    }
  }

  enableInteractivity() {
    if (this.hasBuilding && this.playerBuildingData.pixel_perfect) {
      return this.baseSprite.setInteractive({
        cursor: "pointer",
        pixelPerfect: true,
      });
    }

    const polygonForTileType = this.hasBuilding ? getTileTypePolygon(this.tileData.tile_type) : TILE_TYPE_POLYGON["1x1"];
    const interactiveShape = new Phaser.Geom.Polygon(polygonForTileType as any);
    this.setInteractive({
      cursor: "pointer",
      hitArea: interactiveShape,
      hitAreaCallback: Phaser.Geom.Polygon.Contains,
    });
  }

  warehouseSaleCollect?() {
    this.disableInteractive();
    (this.gameService as Kt3GameService).apiWarehouseService.warehouseSaleReceive().subscribe(
      () => {
        this.tooltip.niceDestroy();
        this.tooltip = null;
        try {
          this.setInteractive();
        } catch (error) {
          console.log(error);
          console.log(this);
        }
      },
      () => {
        this.setInteractive();
      }
    );
  }

  createActiveTile() {
    const emptyTileIcon = this.tileData.empty_icon;
    const tileTextureFrame = emptyTileIcon ? emptyTileIcon : getTileHoverTextureName(this.tileData.tile_type);
    this.baseSprite = this.scene.add.image(0, 0, "map-atlas", tileTextureFrame);
    this.add(this.baseSprite);
    const tileAnchor = getTileHoverAnchor(this.tileData.tile_type);
    if (this.hasBuilding) {
      this.baseSprite.setScale(this.tileData.tile_type.width, this.tileData.tile_type.height);
    }
    this.baseSprite.setOrigin(tileAnchor.x, tileAnchor.y);
    this.baseSprite.alpha = BOARD_TILE_ALPHA;
    this.handleInput();
  }

  handleBuildingStatus() {
    if (!this.gameService.playerService.isActiveMe) {
      return;
    }

    if (this.hasMission()) {
      this.createTooltip(TileTooltipType.MISSION);
    } else if (this.hasBuilding && this.playerBuildingData.auto_production_amount) {
      this.createTooltip(TileTooltipType.COLLECT);
    } else if (this.hasBuilding && this.isBuildInProgress()) {
      switch (this.playerBuildingData.status) {
        case PLAYER_BUILDING_STATUS_BUILD_IN_PROGRESS:
          this.createTooltip(TileTooltipType.UPGRADE);
          break;
      }
    } else if (this.tileData.production) {
      switch (this.tileData.production.status) {
        case ProductionStatus.InProgress:
          this.createTooltip(TileTooltipType.PRODUCTION);
          break;
        case ProductionStatus.Finished:
          this.createTooltip(TileTooltipType.PRODUCTION_FINISHED);
          break;
      }
    } else if (this.playerBuildingData["warehouse_income"]) {
      this.createTooltip(TileTooltipType.COLLECT);
    }
  }

  calculateDepth() {
    if (this.baseSprite) {
      let correction = 0;
      switch (this.tileData.tile_id) {
        case 124:
          correction = BOARD_TILE_WIDTH * ISO_OBJECT_DEPTH_VALUE_FACTOR;
          break;

        case 146:
          correction = -ISO_OBJECT_DEPTH_VALUE_FACTOR;
          break;

        case 122:
        case 123:
          correction = 100;
          break;
      }

      this.setDepth(this.depthIndex + (this.y + correction) * ISO_OBJECT_DEPTH_VALUE_FACTOR);
    }
  }

  /**
   * Handle tile with player building.
   * Search for building extensions.
   */
  handlePlayerBuilding() {
    if (!this.myScene.sceneConfig.isOrhto) {
      this.processRoads();
    }

    this.baseSprite = this.scene.add.sprite(0, 0, "");
    this.baseSprite.setOrigin(0.5, 1);
    this.add(this.baseSprite);

    let buildingImage = this.gameService.buildingsService.getBuildingImage(this.playerBuildingData, true);
    const buildingImageAsset = this.gameService.buildingsService.getBuildingImageAssetObject(this.playerBuildingData);

    const buildingExtensionsImage = this.gameService.buildingsService.getBuildingExtensions(this.playerBuildingData);
    if (buildingExtensionsImage) {
      const buildingImageParameters = extractBuildingImageParametersFromAssetPath(buildingExtensionsImage.path);

      const sprite = this.scene.add.sprite(-buildingImageParameters.x, -buildingImageParameters.y, null);
      if (buildingExtensionsImage.path.includes("infrastructure_warehouse")) {
        sprite.x = buildingImageParameters.x;
        sprite.y = -buildingImageParameters.y;
      }
      this.add(sprite);
      sprite.setOrigin(0);
      // sprite.setBlendMode(Phaser.BlendModes.COLOR_BURN);
      sprite.alpha = 0;

      // this.scene.add.tween({
      //   targets: sprite,
      //   duration: 600,
      //   alpha: 1,
      //   ease: 'Power2',
      //   yoyo: true,
      //   hold: 500,
      //   repeat: -1
      // });

      switch (buildingImageParameters.animation) {
        case "glow":
          setInterval(() => {
            let targetAlpha = 0;
            if (sprite.alpha === 0) {
              targetAlpha = 1;
            }

            this.scene.add.tween({
              targets: sprite,
              duration: 200,
              alpha: targetAlpha,
              ease: "Power2",
            });
          }, 1000);
          break;

        case "up":
          const rideElevator = (y: number, delay: number) => {
            this.scene.add.tween({
              targets: sprite,
              y: -y,
              duration: 5000 * (y / 180),
              ease: "Expo.easeInOut",
              onComplete: () => {
                setTimeout(() => {
                  rideElevator((Math.random() * 4 * (180 - 139)) / 5 + 139, Math.random() * 2);
                }, delay);
              },
            });
          };

          rideElevator(180, 1000);
          break;
      }

      this.gameService.loadGameImages(this.myScene, [buildingExtensionsImage.path]).subscribe(() => {
        setTimeout(() => {
          sprite.setTexture(buildingExtensionsImage.path);
          sprite.alpha = BOARD_TILE_WITH_BUILDING_ALPHA;
          // (this.scene as MainScene).customPipeline.setFloat2('u_resolution', this.baseSprite.width, this.baseSprite.height);
          // this.baseSprite.setPipeline(PIPELINE_NAME);
        }, 300);
      });
    }

    const constructionImage = this.handleConstructionImage(this.playerBuildingData);
    buildingImage = constructionImage ? constructionImage : buildingImage;

    if (buildingImage) {
      this.setTileImage(buildingImage);
      let buildingAnchor = getBuildingAnchor(this.playerBuildingData.icon, this.playerBuildingData.level);
      if (!buildingAnchor) {
        buildingAnchor = getTileHoverAnchor(this.tileData.tile_type);
      }
      this.baseSprite.setOrigin(buildingAnchor.x, buildingAnchor.y);
    }
    this.handleInput();
    this.handleBuildingStatus();
  }

  triggerRocketStart() {
    triggerRocketLaunch(this);
  }

  triggerAnimationStart() {
    handleBuildingAnimation(this);
  }
}
