<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <div [class.opacity-1]="requirementsStatus" [hidden]="!(buildingDetails && upgradeBuildingDetails)" class="content">
    <!-- TOP BAR BASE -->
    <div *ngIf="buildingDetails && upgradeBuildingDetails" class="top-bar">
      <!-- BUILDING NAME AND LEVEL -->
      <div class="title">
        {{ buildingDetails.name }}
      </div>
    </div>

    <!-- EXPERIENCE -->
    <m-ui-exp-up *ngIf="upgradeBuildingDetails" [experience]="upgradeBuildingDetails.exp_points"></m-ui-exp-up>

    <div *ngIf="buildingDetails && upgradeBuildingDetails"
         [innerHTML]="'building-upgrade.title-level-different' | myTranslate : [buildingDetails.level, upgradeBuildingDetails.level]"
         class="level loading-opacity"
    ></div>

    <swiper-container #swiperRef [config]="config" class="loading-opacity" init="false" swiperElement>
      <!-- UPGRADE DIFF PRODUCTION -->
      <swiper-slide *ngFor="let page of diffProductionsSlider.pages" class="page">
        <ng-container *ngFor="let production of page">
          <m-building-upgrade-diff-production
            *ngIf="production.parameters.quantity_diff !== 0"
            [normal]="production.normal"
            [parameters]="production.parameters"
            [upgrade]="production.upgrade"></m-building-upgrade-diff-production>
        </ng-container>
      </swiper-slide>

      <!-- UPGRADE DIFF PARAMETER -->
      <ng-container  *ngIf="diffParametersSlider?.pages?.length">
        <swiper-slide *ngFor="let page of diffParametersSlider.pages" class="page">
          <m-building-upgrade-diff-parameter
            *ngFor="let parameter of page"
            [normal]="parameter.normal"
            [parameters]="parameter.parameters"
            [upgrade]="parameter.upgrade"></m-building-upgrade-diff-parameter>
        </swiper-slide>
      </ng-container>


      <!-- UNLOCKED BUILDINGS -->
      <swiper-slide *ngIf="unlockedBuildings && unlockedBuildings.length" class="page unlocked-buildings">
        <!-- TITLE UNLOCKED -->
        <p class="title-small">
          {{ (unlockedBuildings.length > 1 ? "building-build.unlock-buildings" : "building-build.unlock-build") | myTranslate }}
        </p>

        <!-- BUILDINGS THUMBNAIL LIST -->
        <div class="flex-center">
          <m-building-thumbnail *ngFor="let building of unlockedBuildings" [building]="building"></m-building-thumbnail>
        </div>
      </swiper-slide>

      <!-- ADDITIONAL DETAILS -->
      <swiper-slide *ngIf="upgradeBuildingDetails?.additional_details" class="page">
        <div [innerHTML]="upgradeBuildingDetails.additional_details" class="inner-html-description"></div>
      </swiper-slide>
    </swiper-container>

    <div class="arrows-slider loading-opacity">
      <div (click)="prevSlide()" [class.hide]="currentSlideIndex <= 0" class="arrow-blue-left"></div>
      <div (click)="nextSlide()" [class.hide]="currentSlideIndex >= swiper?.slides.length - 1"
           class="arrow-blue-right"></div>
    </div>

    <!-- REQUIREMENTS -->
    <app-building-requirements
      *ngIf="upgradeBuildingDetails && outcomeParametersRequirements"
      class="loading-opacity"
      [separator]="'global.requirements' | myTranslate"
      [products]="upgradeBuildingDetails.build_product_prices"
      [currencies]="upgradeBuildingDetails.build_currency_prices"
      [playerLevel]="upgradeBuildingDetails.required_level"
      [population]="upgradeBuildingDetails.required_population"
      [requiredBuilding]="upgradeBuildingDetails.required_building"
      [star]="upgradeBuildingDetails.required_star"
      [parametersOutcome]="outcomeParametersRequirements"
      (requirementsStatus)="setRequirementsStatus($event)"
    ></app-building-requirements>

    <!-- BOTTOM BAR -->
    <div *ngIf="requirementsStatus" class="bottom-bar">
      <!-- BUILD -->
      <div class="bar-button base">
        <button (click)="upgrade()" [disabled]="!requirementsStatus.valid" class="base secondary">
          {{ upgradeBuildingDetails.upgrade_button_name || ("building-upgrade.button-upgrade" | myTranslate) }}
          <app-core-building-time [building]="upgradeBuildingDetails"></app-core-building-time>
        </button>
      </div>

      <!-- FAST ACTION -->
      <div
        *ngIf="upgradeBuildingDetails.fast_build_currency_prices.length || upgradeBuildingDetails.fast_build_product_prices.length"
        class="bar-button base">
        <button (click)="openFastUpgradeConfirm()" [disabled]="!requirementsStatus.valid"
                class="base secondary icon-left-side">
          <i class="far fa-bolt"></i>
          {{ "building-upgrade.button-fast-upgrade" | myTranslate }}
        </button>
      </div>
    </div>
  </div>
</div>
