import { Component } from "@angular/core";
import { Router } from "@angular/router";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { AUTH_ROUTING_ABSOLUTE_PATH } from "../../../../../../../auth/consts/core/routing-auth-absolute.const";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";

@Component({
  selector: "app-edit-user-settings",
  templateUrl: "./edit-user-settings.component.html",
})
export class EditUserSettingsComponent extends AbstractInjectBaseComponent {
  @OwInject(Router) router: Router;
  @OwInject(DialogService) dialogService: DialogService;
  AUTH_ROUTING_ABSOLUTE_PATH = AUTH_ROUTING_ABSOLUTE_PATH;

  openUserSettings() {
    this.dialogService.closeActive();
    this.router.navigate([AUTH_ROUTING_ABSOLUTE_PATH.REGISTRATION], {
      queryParams: {
        edit: 1,
      },
    });
  }
}
