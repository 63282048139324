import { Directive, Injectable, Type } from "@angular/core";
import { select, Store } from "@ngrx/store";
import * as moment from "moment";
import { forkJoin, of } from "rxjs";
import { catchError, distinctUntilChanged, map, tap } from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { FEATURE_FLAG } from "../../../../../../core/config/core/feature-flags.config";
import { EVENTS } from "../../../../../../core/consts/core/events";
import { CUSTOM_EVENTS } from "../../../../../../core/consts/custom/events";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { Primary } from "../../../../../../core/interfaces/primary";
import { GlobalService } from "../../../../../../core/providers/global.service";
import { EventEmitterDialogsService } from "../../../../../../core/services/core/event-emitter-dialogs.service";
import { OwDate } from "../../../../../../core/utility/ow-date.class";
import { OwPaginationDate } from "../../../../../../core/utility/ow-pagination.class";
import { PlayerSelectors } from "../../../../../../store/player";
import { AppState } from "../../../../../../store/state";
import { Player } from "../../../../../player/interfaces/player";
import { PlayerService } from "../../../../../player/providers/player.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { EVENT_DIALOGS_NAMES_CURRENCY_EXCHANGE } from "../../../currency-exchange/consts/core/event-dialogs/event-names.const";
import { EVENT_DIALOGS_NAMES_EVENT_SHOP } from "../../../event-shop/consts/custom/event-dialogs/event-names.const";
import { BranchPreviewComponent } from "../../../preview-player/basic/custom/dialogs/branch-preview/branch-preview.component";
import { EVENT_DIALOGS_NAMES_QA_CUSTOM } from "../../../qa/consts/custom/event-dialogs/event-names.const";
import { EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM } from "../../../rankings/consts/custom/event-dialogs/event-names.const";
import { RANK_LIST_LOCATION } from "../../../rankings/consts/custom/rank-list-location.const";
import { GetPlayerTasksRequest } from "../../api/custom/interfaces/get-player-tasks-request.interface";
import { ApiBusinessService } from "../../api/custom/services/api-business.service";
import { CardTaskVendorsComponent } from "../../basic/custom/components/card-task-vendors/card-task-vendors.component";
import { CARD_TEMPLATE } from "../../consts/custom/card-template.const";
import { prepareCardTask } from "../../helpers/custom";
import { CardTask, CardTaskResponse } from "../../interfaces/custom/card-task.interface";
import { AbstractBasicActionsComponent } from "./abstract-basic-actions.component";

@Directive()
@Injectable()
export abstract class AbstractBusinessCards extends AbstractInjectBaseComponent {
  @OwInject(ApiBusinessService) apiBusinessService: ApiBusinessService;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(Store) store: Store<AppState>;
  abstract dialogService: DialogService;
  abstract basicActionsComponent: Type<AbstractBasicActionsComponent>;
  abstract NUMBER_CARDS: number;

  player: Player;
  QA_LOCATION = 1;
  FEATURE_FLAG = FEATURE_FLAG;
  subs = {
    global: null,
    player: null,
  };
  hideCards: boolean;
  cardTasks: CardTask[];
  cardTasksVendors: CardTask[];
  owPaginationDate: OwPaginationDate;
  _owDate: OwDate = new OwDate();
  customLoading: boolean;
  availableQa: boolean;
  primary: Primary;
  startOwDate: OwDate;
  visible = true;

  set owDate(owDate: OwDate) {
    this._owDate = owDate;
    this.setPagination();
  }

  get owDate(): OwDate {
    return this._owDate;
  }

  subscribeGlobalEvents() {
    this.subs.global = this.globalService.globalEvents.subscribe(event => {
      switch (event.name) {
        case EVENTS.GUI.RELOAD_CARDS:
          this.getTasks();
          break;

        case CUSTOM_EVENTS.GUI.BUSINESS.SHOW:
          this.visible = true;
          break;

        case CUSTOM_EVENTS.GUI.BUSINESS.HIDE:
          this.visible = false;
          break;
      }
    });
  }

  subscribePlayer() {
    this.subs.player = this.store
      .pipe(
        select(PlayerSelectors.selectPlayer),
        tap(player => {
          this.player = player;
        }),
        map(player => {
          return player.id;
        }),
        distinctUntilChanged()
      )
      .subscribe(() => {
        const tasksSetup: { month: number; year: number } = this.playerService.player["tasks_setup"];
        this.startOwDate = new OwDate({
          month: tasksSetup.month,
          year: tasksSetup.year,
        });

        // this.getTasks();
      });
  }

  getTasks() {
    this.customLoading = true;
    this.setPagination();

    forkJoin([this.getTasksMonthly(), this.getTasksVendors()]).subscribe(() => {
      this.customLoading = false;
    });
  }

  getTasksMonthly() {
    const getPlayerTasksRequest: GetPlayerTasksRequest = {
      month: this.owDate.month,
      year: this.owDate.year,
    };

    return this.apiBusinessService.playerTasks(getPlayerTasksRequest).pipe(
      tap((resp: CardTaskResponse) => {
        this.clearCardTasks();
        this.cardTasks = prepareCardTask(resp.items);
        this.setCardTooltip("cardTasks");
        this.customLoading = false;
      }),
      catchError(() => {
        this.clearCardTasks();
        return of();
      })
    );
  }

  getTasksVendors() {
    const getPlayerTasksRequest: GetPlayerTasksRequest = {
      month: this.owDate.month,
      year: this.owDate.year,
    };

    return this.apiBusinessService.playerTasksVendors(getPlayerTasksRequest).pipe(
      tap((resp: CardTaskResponse) => {
        this.clearCardTasksVendors();
        this.cardTasksVendors = prepareCardTask(resp.items);
        this.setCardTooltip("cardTasksVendors");
        this.customLoading = false;
      }),
      catchError(() => {
        this.clearCardTasksVendors();
        return of();
      })
    );
  }

  setCardTooltip(card) {
    this[card].forEach(t => {
      switch (t.selected.card_template) {
        case CARD_TEMPLATE.SANTA:
          t.selected.tooltip = "Mini-gra";
          break;
        default:
          if (t.selected.last_import_at) {
            t.selected.tooltip = "Dane aktualne na " + moment(t.selected.last_import_at).format("DD-MM-yyyy");
          } else {
            t.selected.tooltip = "";
          }
          break;
      }
    });
  }

  clearCardTasksVendors() {
    this.cardTasksVendors = [];
  }

  clearCardTasks() {
    this.cardTasks = [];

    Array.from({ length: this.NUMBER_CARDS }).forEach((x, index) => {
      this.cardTasks[index] = null;
    });
  }

  toggleVisibleCards() {
    this.hideCards = !this.hideCards;
  }

  setPagination() {
    this.owPaginationDate = new OwPaginationDate({
      current: this.owDate,
    });
  }

  next() {
    this.owDate.add({ month: 1 });
    this.getTasks();
  }

  prev() {
    this.owDate.subtract({ month: 1 });
    this.getTasks();
  }

  openRankList() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM.RANKING_LIST,
      config: {
        data: {
          location: RANK_LIST_LOCATION.BASE,
        },
      },
    });
  }

  openCurrencyExchange() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_CURRENCY_EXCHANGE.CURRENCY_EXCHANGE,
    });
  }

  openBasicActions() {
    this.dialogService.open(this.basicActionsComponent);
  }

  openQaList() {
    if (!this.playerService.isActiveMe) {
      return;
    }

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_QA_CUSTOM.QA_LIST_CUSTOM,
    });
  }

  checkAvailableQa() {
    this.availableQa = this.primary.available_quizzes.some(qa => qa.location === this.QA_LOCATION);
  }

  openBranchPreview() {
    this.dialogService.open(BranchPreviewComponent);
  }

  openEventShopList() {
    if (!this.playerService.isActiveMe) {
      return;
    }

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_EVENT_SHOP.LIST,
    });
  }

  openCardTaskVendors() {
    this.dialogService.open(CardTaskVendorsComponent, {
      data: {
        cardTasksVendors: this.cardTasksVendors,
      },
    });
  }
}
