import { AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy, Optional, Renderer2, Self } from "@angular/core";
import { OwPerfectScrollbarComponent } from "@oskarwegner/ow-perfect-scrollbar";

import { unsubscribeObject } from "../../../core/utility/unsubscribe-array";

@Directive({
  selector: "[scrollGradient]",
})
export class ScrollGradientDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input() scrollGradient?: any;
  @Input() isRefreshScrollTop? = true;
  offsetHeight: number;
  interval: null | ReturnType<typeof setInterval>;
  scrollIndicatorClassName = "scroll-indicator";

  subs = {
    scrollUp: null,
    end: null,
  };

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Optional() @Self() public perfectScrollbar: OwPerfectScrollbarComponent
  ) {
    this.checkOffsetHeight();
  }

  ngAfterViewInit() {
    this.reset();
  }

  ngOnChanges() {
    this.reset();
  }

  getNewOffsetHeight() {
    return this.elementRef.nativeElement.querySelector(".ps-content");
  }

  setOffsetHeight() {
    if (this.elementRef) {
      this.offsetHeight = this.getNewOffsetHeight();
    }
  }

  checkOffsetHeight() {
    this.interval = setInterval(() => {
      if (this.offsetHeight !== this.getNewOffsetHeight()) {
        this.reset();
      }
    }, 500);
  }

  reset() {
    setTimeout(() => {
      this.setOffsetHeight();
      this.subscribeEnd();
      this.subscribeUp();

      if (this.perfectScrollbar && this.perfectScrollbar.directiveRef["instance"]) {
        if (this.isRefreshScrollTop) {
          this.perfectScrollbar.directiveRef.scrollToTop();
        }

        this.perfectScrollbar.directiveRef["instance"].update();

        if (this.perfectScrollbar.directiveRef["instance"].scrollbarYActive) {
          this.addGradientClass();
        }
      }
    }, 100);
  }

  subscribeEnd() {
    if (this.perfectScrollbar && !this.subs.end) {
      this.subs.end = this.perfectScrollbar.psYReachEnd.subscribe(() => {
        this.removeGradientClass();
      });
    }
  }

  subscribeUp() {
    if (this.perfectScrollbar && !this.subs.scrollUp) {
      this.subs.scrollUp = this.perfectScrollbar.psScrollUp.subscribe(() => {
        this.addGradientClass();
      });
    }
  }

  addGradientClass() {
    this.renderer.addClass(this.elementRef.nativeElement, this.scrollIndicatorClassName);
  }

  removeGradientClass() {
    this.renderer.removeClass(this.elementRef.nativeElement, this.scrollIndicatorClassName);
  }

  clearSubs() {
    unsubscribeObject(this.subs);
  }

  ngOnDestroy() {
    this.clearSubs();
    clearInterval(this.interval);
  }
}
