import { BOARD_TILE_HEIGHT, GAME_EVENTS, PHASER_CAMERA_ZOOM } from "../../../constants";
import { CenterMap } from "../../interfaces/shared";
import { calculateMinZoomValue } from "../../utils/game.helper";
import { BASIC_BOOT_SCENE } from "../basic.constants";
import { BasicMainScene } from "../core/Basic.main.scene";

export class CustomBasicMainScene extends BasicMainScene {
  displaySceneSize = {
    width: 0,
    height: 0,
  };

  create() {
    super.create();

    this.displaySceneSize =
      this.sceneConfig.other && this.sceneConfig.other.sceneSize
        ? {
            width: this.sceneConfig.other.sceneSize.width,
            height: this.sceneConfig.other.sceneSize.height,
          }
        : {
            width: this.sceneConfig.backgroundImages[0].displayWidth,
            height: this.sceneConfig.backgroundImages[0].displayHeight,
          };

    this.gameService.globalService.globalEvents.emit({
      name: "SCENE_CONFIG_CHANGE",
      value: this.sceneConfig,
    });
  }

  afterCreate() {
    super.afterCreate();
    if (this.sceneConfig.mapDirectoryName === "tech-tree") {
      this.cameras.main.centerToBounds();
      removeEventListener("wheel", this.onMouseWheel);
      this.cameras.main.zoom = Math.max(this.cameras.main.zoom, calculateMinZoomValue(this.cameras.main));
    }
  }

  setCamera() {
    if (this.sceneConfig.other && this.sceneConfig.other.sceneZoom) {
      this.cameraStartPos = this.sceneConfig.cameraStartPosition;
      const centerTarget = this.board ? this.board.boardTiles.find(boardTile => boardTile.tileData.is_center_camera) : null;
      let zoomValue = 0.4;
      if (centerTarget) {
        this.cameraStartPos = {
          x: centerTarget.x,
          y: centerTarget.y,
        };
        zoomValue = 0;
      }
      this.cameras.main.centerOn(this.cameraStartPos.x, this.cameraStartPos.y);
      this.cameras.main.setZoom(Math.max(zoomValue, 0.45));
      setTimeout(() => {
        this.phaserEvents.emit(PHASER_CAMERA_ZOOM, this.cameras.main.zoom);
      }, 50);
    } else {
      super.setCamera();
    }
  }

  resize() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      const w = window.innerWidth * window.devicePixelRatio;
      const h = window.innerHeight * window.devicePixelRatio;
      this.scale.resize(w, h);
      this.cameras.resize(w, h);

      if (this.cameras.main) {
        if (isFinite(calculateMinZoomValue(this.cameras.main))) {
          if (this.sceneConfig.other && this.sceneConfig.other.sceneZoom) {
            this.cameras.main.zoom = Math.max(this.cameras.main.zoom, this.sceneConfig.other.sceneZoom.max);
          } else {
            this.cameras.main.zoom = Math.max(this.cameras.main.zoom, calculateMinZoomValue(this.cameras.main));
          }
        } else {
          this.cameras.main.zoom = 0.5;
        }
      }
    }, 250);
  }

  focusOn(centerOn: CenterMap) {
    const targetTile = this.board.boardTiles.find(tile => tile.tileData.tile_id === centerOn.tile_id);

    if (!targetTile && this.gameService.lastPlayerIslandId !== centerOn.player_island_id) {
      this.gameService.centerOn = centerOn;
      this.gameService.prepareGameData(this.gameService.playerService.getActivePlayerId(), centerOn.player_island_id);
      this.gameService.globalService.globalEvents.emit({
        name: GAME_EVENTS.START_SCENE,
        value: BASIC_BOOT_SCENE,
        data: {
          sceneConfigKey: centerOn.config_name,
          playerIslandId: centerOn.player_island_id,
        },
      });
      return;
    }
    this.add.tween({
      targets: this.cameras.main,
      duration: 600,
      ease: "Power2",
      scrollX: targetTile.x - this.cameras.main.width / 2,
      scrollY: targetTile.y - this.cameras.main.height / 2 - BOARD_TILE_HEIGHT / 2 + 250,
      zoom:
        this.sceneConfig.other && this.sceneConfig.other.sceneZoom
          ? this.sceneConfig.other.sceneZoom.min
          : calculateMinZoomValue(this.cameras.main),
      onUpdate: () => this.phaserEvents.emit(PHASER_CAMERA_ZOOM, this.cameras.main.zoom),
    });
    this.gameService.centerOn = null;
  }
}
