import { ChangePasswordComponent } from "../../base/core/components/change-password/change-password.component";
import { ForgotPasswordComponent } from "../../base/core/components/forgot-password/forgot-password.component";
import { LoginComponent } from "../../base/core/components/login/login.component";
import { ReauthorizeComponent } from "../../base/core/components/reauthorize/reauthorize.component";
import { RegistrationAComponent } from "../../base/core/components/registration/variant-a/registration-a.component";
import { RegistrationConfirmAComponent } from "../../base/core/components/registration-confirm/variant-a/registration-confirm-a.component";
import { ResetPasswordComponent } from "../../base/core/components/reset-password/reset-password.component";
import { SocketConnectionErrorComponent } from "../../base/core/components/socket-connection-error/socket-connection-error.component";
import { CUSTOM_AUTH_ROUTING_COMPONENTS } from "../custom/routing-auth-components.const";

export const AUTH_ROUTING_COMPONENTS = {
  LOGIN: LoginComponent,
  CHANGE_PASSWORD: ChangePasswordComponent,
  FORGOT_PASSWORD: ForgotPasswordComponent,
  RESET_PASSWORD: ResetPasswordComponent,
  REAUTHORIZE: ReauthorizeComponent,
  SOCKET_ERROR_CONNECTION: SocketConnectionErrorComponent,
  REGISTRATION_A: RegistrationAComponent,
  REGISTRATION_CONFIRM_A: RegistrationConfirmAComponent,
  ...CUSTOM_AUTH_ROUTING_COMPONENTS,
};
