import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";

import { VirtualKeyboardMobileModule } from "../game/game-ui/virtual-keyboard/mobile/virtual-keyboard-mobile.module";
import { SharedModule } from "../shared/shared.module";
import { MockStoreDataComponent } from "./components/dialogs/mock-store-data/mock-store-data.component";
import { MockDialogListComponent } from "./components/mock-dialog-list/mock-dialog-list.component";

@NgModule({
  imports: [
      CommonModule,
    VirtualKeyboardMobileModule,
    // SwiperModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    SharedModule],
  declarations: [MockDialogListComponent, MockStoreDataComponent],
  exports: [MockDialogListComponent, MockStoreDataComponent],
})
export class MockDialogModule {}
