import { Component, OnInit } from "@angular/core";

import { GlobalEvent } from "../../../../../../../../core/interfaces/shared.interfaces";
import { UtilityActions } from "../../../../../../../../store/utility";
import { MISSION_TYPE } from "../../../../../mission/consts/core";
import { EVENT_DIALOGS_NAMES_MISSION } from "../../../../../mission/consts/core/event-dialogs/event-names";
import { EVENT_DIALOGS_NAMES_WAREHOUSE_CUSTOM } from "../../../../../warehouse/consts/custom/event-dialogs/event-names.const";
import { AbstractHudComponent } from "../../../../abstract/core/abstract-hud.component";

@Component({
  selector: "app-hud-custom",
  templateUrl: "./hud.component.html",
})
export class HudComponent extends AbstractHudComponent implements OnInit {
  ngOnInit() {
    this.subscribePlayer();
    this.subscribeUser();
    this.subs.globalEmitter = this.globalService.globalEvents.subscribe(this.handleGameEvents.bind(this));
    this.setDynamicsComponents();
  }

  openFunctional(event: GlobalEvent) {
    super.openFunctional(event);
    this.openWarehouseSale(event);
  }

  openWarehouseSale(event: GlobalEvent) {
    if (event.data.player_building.group === "eurocash_wholesaler") {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_WAREHOUSE_CUSTOM.WAREHOUSE_SALE,
      });
    }
  }

  openPlayerMission(event: GlobalEvent) {
    // DEFAULT MISSION
    if (event.value.player_mission_id) {
      const missionDetailsData = {
        mission: {
          player_mission_id: event.value.player_mission_id,
        },
      };

      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_MISSION.MISSION_DETAILS,
        config: {
          data: missionDetailsData,
        },
      });
    }
  }

  missionCompleted(event: GlobalEvent) {
    if (event.value.mission_type === MISSION_TYPE.ONE && event.value.mission_slot_id !== 1) {
      this.store.dispatch(new UtilityActions.SetHasMissionsToCollect(true));
    }

    if (event.value.mission_type === MISSION_TYPE.ONE) {
      this.store.dispatch(
        new UtilityActions.AddMissionToOpen({
          player_mission_id: event.value.player_mission_id,
          slot: {
            mission_slot_id: event.value.mission_slot_id,
            type: event.value.mission_type,
          },
        })
      );
    }
  }
}
