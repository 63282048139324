import { AfterViewInit, Component, OnChanges, OnInit } from "@angular/core";

import { AbstractQaSummaryComponent } from "../../../../abstract/core/abstract-qa-summary.component";

@Component({
  selector: "app-m-qa-summary",
  templateUrl: "./qa-summary.component.html",
})
export class MQaSummaryComponent extends AbstractQaSummaryComponent implements OnInit, OnChanges, AfterViewInit {
  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    super.ngOnInit();
  }

  ngOnChanges() {
    super.ngOnChanges();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
