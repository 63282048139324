import { Injectable } from "@angular/core";

import { ApiService } from "../../../../core/providers/api.service";

@Injectable({ providedIn: "root" })
export class NotificationsService {
  constructor(private apiService: ApiService) {}

  setNotificationReaded(type) {
    return this.apiService.patch(`/notifications/type/${type} `, {});
  }
}
