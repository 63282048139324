import { Component, OnInit } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { ApiService } from "../../../../../../../../core/providers/api.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";

@Component({
  selector: "app-training-materials-list",
  templateUrl: "./training-materials-list.component.html",
})
export class TrainingMaterialsListComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiService) apiService: ApiService;
  @OwInject(DialogService) dialogService: DialogService;

  trainingMaterials: TrainingMaterial[];

  ngOnInit() {
    this.getMaterialsList();
  }

  getMaterialsList() {
    this.getMaterialsListApi().subscribe(
      resp => {
        this.trainingMaterials = resp;
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  // API
  getMaterialsListApi() {
    return this.apiService.get(`/qa/file-list`);
  }
}

interface TrainingMaterial {
  name: string;
  url: string;
}
