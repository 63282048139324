<div class="ow-dialog dialog-mobile dialog-primary">
  <!-- DIALOG EXIT -->
  <m-ui-close-button [preventDefault]="true" (clicked)="closeOrCloseAll()"></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button [preventDefault]="true" (clicked)="close()" *ngIf="messageOpenDetailsData.fromList"></m-ui-back-button>

  <!-- LOADING -->
  <loading></loading>

  <!-- DIALOG INNER -->
  <div class="dialog-inner">
    <div>
      <!-- MESSAGE DATE -->
      <div class="message-date">
        {{ message.created_at | date : "yyyy-MM-dd-,HH:mm" }}
      </div>

      <!-- TITLE -->
      <p class="title">{{ message.title }}</p>

      <!-- IMAGE -->
      <div
        class="message-image"
        [style.background-image]="'url(' + (message.parameters.chest_product_icon + '-open.png' | asset : 'ui') + ')'"></div>

      <!-- DESCRIPTION -->
      <div class="description" [innerHTML]="message.description | safe : 'html'"></div>

      <!-- PRIZES HEADER -->
      <div class="section-header">
        <div class="section-name">{{ "messages.findings" | myTranslate }}</div>
      </div>

      <!-- PRIZES -->
      <div class="prizes">
        <!-- CURRENCIES -->
        <div class="cost" *ngFor="let currency of message.parameters.currency_rewards">
          <ng-container *ngIf="currency | singleCurrency as currencyDetails">
            <div class="icon big" [ngClass]="currencyDetails.key"></div>
            <span class="value">{{ currencyDetails.amount | NumberWithSpaces }}</span>
          </ng-container>
        </div>

        <!-- PRODUCTS -->
        <div class="cost" *ngFor="let product of message.parameters.product_rewards">
          <div class="icon big" [style.background-image]="'url(' + product.iconUrlBig + ')'"></div>
          <span class="value">{{ product.amount }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
