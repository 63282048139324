import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";

import { ApiOptions } from "../core/interfaces/api-options";
import { ApiService } from "../core/providers/api.service";

@Injectable({
  providedIn: "root",
})
export class ShopConfirmationsService {
  confirmations: ConfirmationDeilivery[] = null;

  constructor(public apiService: ApiService) {}

  shopConfirmations() {
    return this.apiService.get("shop/confirmations").pipe(
      tap((resp: ConfirmationDeilivery[]) => {
        this.confirmations = resp;
      })
    );
  }

  shopConfirmationsConfirm(confirmation_id: number, received_at: any) {
    const options: ApiOptions = {
      body: {
        received_at,
      },
    };

    return this.apiService.patch(`shop/confirmations/${confirmation_id}/confirm`, options);
  }

  shopConfirmationsDelay(confirmation_id: number) {
    return this.apiService.patch(`shop/confirmations/${confirmation_id}/delay`);
  }
}

export interface ConfirmationDeilivery {
  address: string;
  sent_date: string;
  prizes: [
    {
      name: string;
      amount: number;
      price: string;
    }
  ];
  confirmation_id: number;
  number: string;
}
