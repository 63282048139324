import { Component, OnDestroy, OnInit } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AbstractHudMenuComponent } from "../../../../abstract/core/abstract-hud-menu.component";
import { MenuButtonConfig } from "../../../../hud-menu-buttons/interfaces/core/menu-buttonts-config.interface";
import { select } from '@ngrx/store';
import { PlayerSelectors } from '../../../../../../../../store/player';
import {OwInject} from '../../../../../../../../core/decorators/ow-inject.decorator';
import {NewEditionInvitationService} from '../../../../services/custom/new-edition-invitation.service';
import {EVENT_DIALOGS_NAMES_HUD_CUSTOM} from '../../../../const/custom/event-dialogs/event-names.const';

@Component({
  selector: "app-hud-menu",
  templateUrl: "../../../core/components/hud-menu/hud-menu.component.html",
})
export class HudMenuComponent extends AbstractHudMenuComponent implements OnInit, OnDestroy {
  @OwInject(NewEditionInvitationService) newEditionInvitationService: NewEditionInvitationService;

  ngOnInit() {
    if (!this.isDialog) {
      this.subs.globalEmitter = this.globalService.globalEvents.subscribe(this.handleGameEvents.bind(this));
      this.subscribePlayer();
    } else {
      this.setMenu();
    }

    this.newEditionInvitationService.checkInvitationNotification();

    this.subs['businessOpening'] = this.gameService.guiService.isSplashShow.subscribe(res => {
      if (res === false) {
        this.showInvitationPanel();
      }
    })
  }

  showInvitationPanel() {
    // open after business panel
    setTimeout(() => {
      if (this.newEditionInvitationService.invitationNotification) {
        this.eventEmitterDialogsService.emitter.emit({
          name: EVENT_DIALOGS_NAMES_HUD_CUSTOM.NEW_EDITION_INVITATION,
        });
      }
    }, 10);

    if (this.subs['businessOpening']) {
      this.subs['businessOpening'].unsubscribe();
    }
  }

  subscribePlayer() {
    this.subs.player = this.store.pipe(select(PlayerSelectors.selectPlayer)).subscribe(player => {
      this.setMenu();
    });
  }

  handleMenuButtonEvent(button: MenuButtonConfig) {
    if (!this.playerService.isActiveMe && button.name !== 'warehouse') {
      return;
    }

    if (button.type === "group") {
      this.toggleGroupButtons(button);
    } else if (button.type === "button") {
      switch (button.click_event) {
        case "city":
          this.changeMapCustomService.goToCity();
          break;

        case "messages":
          this.openMessages();
          break;

        case "missions":
          this.openMissions();
          break;

        case "warehouse":
          this.openWarehouse();
          break;

        case "branch_map":
          this.changeMapCustomService.goToBranch();
          break;

        case "tech_tree":
          this.changeMapCustomService.goToTechTree();
          break;
      }
    }
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
