<div class="d">
  <div *ngFor="let region of (worldData$ | async)?.regions">
    Region: {{ region.name }} - {{ region.map_region_id }}<br />
    <br />

    Wyspy (boardy):<br />
    <ul>
      <li *ngFor="let island of region.islands" class="pointer" (click)="startScene(island.player_island_id, island.config_name)">
        Name: <b>{{ island.name }}</b> | GroupID: <b>{{ island.group_id }}</b> | GroupType: <b>{{ island.island_group_type }}</b> |
        PlayerIslandID: <b>{{ island.player_island_id }}</b> | Config name:
        <b>{{ island.config_name || "brak" }}</b>
      </li>
    </ul>
  </div>
</div>
