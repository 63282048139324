import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

import { EVENTS } from "../../../../../core/consts/core/events";
import { BUILDING_NAME_FONT_STYLES, PHASER_CAMERA_ZOOM, TILE_BUILDING_LEVEL_LAYER } from "../../../constants";
import { GameService } from "../../../services/game.service";
import { BUILDING_LEVELS_ATLAS } from "../../scenes-basic/basic.constants";
import { getCenterTilePosition } from "../../utils/board.helper";
import { mergeColors } from "../../utils/building-level-colors.helper";
import { keepScale1 } from "../../utils/utils";
import { BoardTile } from "../custom/BoardTile.class";
import { MyScene } from "./MyScene";

export class BuildingLevelLayerCore extends Phaser.GameObjects.Container {
  boardTile: BoardTile;
  gameService: GameService;
  maxZoomScaleValue = 2;
  buildingslevelcolor = mergeColors();
  scene: MyScene;
  subs$: Subscription;
  isDestroyed: boolean;

  // CORE FILE OVERRIDE
  constructor(scene: MyScene, tile: BoardTile | any) {
    super(scene, 0, 0);

    this.beforeCreate();
    this.scene = scene;
    this.gameService = scene.gameService;
    this.boardTile = tile;
    this.scene.add.existing(this);
    this.isDestroyed = false;

    this.createBuildingLevelLayer();
    if (this.scene.board) {
      this.listenVisiblityBuildingLevelLayer();
      this.listenCameraZoom();
    }
  }

  beforeCreate() {}

  listenCameraZoom() {
    keepScale1(this, this.scene.cameras.main.zoom * devicePixelRatio, this.maxZoomScaleValue);
    (this.scene as MyScene).phaserEvents.on(PHASER_CAMERA_ZOOM, (zoom: number) => {
      keepScale1(this, zoom * devicePixelRatio, this.maxZoomScaleValue);
    });
  }

  listenVisiblityBuildingLevelLayer() {
    this.gameService.globalService.globalEvents
      .pipe(filter(event => event.name === EVENTS.GAME.TOGGLE_BUILDINGS_LEVEL_LAYER))
      .subscribe(event => {
        if (this.scene && this.scene.board.isVisibleBuildingsLevelLayer) {
          this.show();
        } else {
          this.hide();
        }
      });
  }

  createBuildingLevelLayer() {
    const bg = this.scene.add.image(0, 0, BUILDING_LEVELS_ATLAS, this.getLevelColor(this.boardTile.playerBuildingData.level).bg);
    setTimeout(() => {
      const centerPosition = getCenterTilePosition(this.boardTile);
      this.setPosition(centerPosition.x, centerPosition.y);
    });
    const level = this.scene.add
      .text(0, 0, `${this.boardTile.playerBuildingData.level}`, {
        ...BUILDING_NAME_FONT_STYLES,
        fontSize: "28px",
        color: this.getLevelColor(this.boardTile.playerBuildingData.level).text,
      })
      .setOrigin(0.5, 0.5)
      .setStroke("#000000", 3);

    this.add([bg, level]);

    this.setDepth(TILE_BUILDING_LEVEL_LAYER);
    this.scene.board.isVisibleBuildingsLevelLayer ? this.show() : this.hide();
  }

  private getLevelColor(boardLevel: number) {
    const lvl = `lvl-${boardLevel}`;
    if (boardLevel === 0) {
      return this.buildingslevelcolor["lvl-1"];
    } else if (boardLevel > 10) {
      return this.buildingslevelcolor["lvl-10"];
    } else {
      return this.buildingslevelcolor[lvl];
    }
  }

  show() {
    this.setAlpha(1);
  }

  hide() {
    this.setAlpha(0);
  }

  destructor() {
    if (this.subs$) {
      this.subs$.unsubscribe();
    }
    this.isDestroyed = true;
    this.destroy();
  }
}
