<!-- LOGO -->
<div class="logo"></div>

<loading></loading>

<div class="container loading-opacity">
  <p class="description text-center">
    {{ "auth.socket-connection-error.connecting-to-server-terminated" | myTranslate }}
  </p>

  <div class="buttons flex-center">
    <button class="base primary" (click)="reconnect()">
      {{ "auth.socket-connection-error.button-reconnect" | myTranslate }}
    </button>
  </div>

  <!-- HELPDESK -->
  <p class="description text-center mt">
    {{ "global.helpdesk-email" | myTranslate }}
  </p>
</div>
