<div class="top-left-container">
  <!-- HUD PLAYER -->
  <app-hud-player [player]="player"></app-hud-player>

  <!-- HUD PARAMETERS -->
  <div class="hud-parameters">
    <template #hudParametersTpl></template>
  </div>
</div>

<!-- HUD RESOURCES -->
<div class="hud-resources">
  <template #hudResourcesTpl></template>
</div>

<div class="start-scene-container">
  <button mat-raised-button (click)="startMainScene()">Go to main scene</button>
  <button mat-raised-button (click)="startWorldScene()">Go to world scene</button>
</div>

<!-- HUD OFFSET TIME -->
<app-hud-offset-time [player]="player"></app-hud-offset-time>

<!-- HUD MENU -->
<div class="hud-menu">
  <template #hudMenuTpl></template>
</div>

<!-- OTHER DYNAMICS COMPONENT -->
<template #dynamicsComponentTpl></template>
