import * as R from "ramda";

import { GAME_CONFIG } from "../config/custom/_parsed-game.config";
import { editPluralText } from "./plural.helper";

const translations = require("../translations/custom/_translations.json");

export function translate(text: string, values?: any) {
  const language = GAME_CONFIG.LANGUAGE;

  if (typeof values !== "object") {
    values = [values];
  }

  const isValuesArray = Array.isArray(values);

  text = text.trim();
  const pathArray = text.split(".");
  let translation: string = R.path(pathArray, translations[language] || translations[GAME_CONFIG.LANGUAGE]);

  if (translation) {
    if (isValuesArray) {
      values.forEach((value, index) => {
        const valuePosition = translation.indexOf("%v");
        if (valuePosition > -1) {
          translation = translation.slice(0, valuePosition) + value + translation.slice(valuePosition + 2, translation.length);
        }
      });
    } else {
      const placeholders = getPlaceholderName(translation);
      for (const placeholder of placeholders) {
        const placeholderPathArray = placeholder.split(".");
        const valueToReplace: any = R.path(placeholderPathArray, values);
        translation = translation.replace(`%${placeholder}`, valueToReplace);
      }
    }
  }

  if (!translation) {
    translation = `** ${text}  **`;
  } else {
    translation = editPluralText(translation);
  }

  return translation;
}

function getPlaceholderName(translation: string) {
  const regExp = new RegExp(/%([a-zA-Z0-9._\-^ ]*)/, "g");
  const resultArray = translation.match(regExp);
  return resultArray.map(item => item.replace("%", ""));
}
