import { Injectable } from "@angular/core";

import { ApiService } from "../../../../../../../core/providers/api.service";
import { PlayerService } from "../../../../../../player/providers/player.service";

@Injectable({
  providedIn: "root",
})
export class ApiWarehouseService {
  constructor(
    public apiService: ApiService,
    public playerService: PlayerService
  ) {}

  warehouseSale() {
    return this.apiService.get(`warehouse/summary`);
  }

  warehouseSaleReceive() {
    return this.apiService.post(`warehouse/receive`);
  }
}
