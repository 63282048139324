import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2 } from "@angular/core";

import { ApiService } from "../../../core/providers/api.service";

@Directive({
  selector: "a, img, [innerHTML]",
})
export class DownloadTokenDirective implements AfterViewInit, OnChanges {
  link: string;
  pattern = /api\/files/;
  patternInnerHTML = /"(\S+api\/file\S+)"/gm;
  tagAttribute: string;
  @Input() src;
  @Input() href;
  innerHTML;

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private apiService: ApiService) {}

  ngOnChanges() {
    this.ngAfterViewInit();
  }

  ngAfterViewInit() {
    this.setTagAttribute();

    if (this.tagAttribute) {
      this.getLink();
    } else {
      this.checkRegexInnerHtmlApiFiles();
    }
  }

  setTagAttribute() {
    switch (this.elementRef.nativeElement.tagName) {
      case "A":
        this.tagAttribute = "href";
        break;

      case "IMG":
        this.tagAttribute = "src";
        break;
    }
  }

  checkRegexInnerHtmlApiFiles() {
    this.innerHTML = this.elementRef.nativeElement.innerHTML;
    if (this.innerHTML) {
      const result = this.innerHTML.match(this.patternInnerHTML);

      if (result) {
        result.forEach(url => {
          url = url.replace(/"/gm, "");
          const newUrl = this.addTokenToUrl(url);
          this.innerHTML = this.innerHTML.replace(url, newUrl);
        });

        this.renderer.setProperty(this.elementRef.nativeElement, "innerHTML", this.innerHTML);
      }
    }
  }

  getLink() {
    this.link = this[this.tagAttribute];

    if (this.link) {
      this.checkRegexApiFiles();
    }
  }

  checkRegexApiFiles() {
    const result = this.link.match(this.pattern);

    let url;
    if (result) {
      url = this.addTokenToUrl(this.link);
    }

    this.renderer.setAttribute(this.elementRef.nativeElement, this.tagAttribute, url || this.link);
  }

  addTokenToUrl(url) {
    const newUrl = new URL(url);
    newUrl.searchParams.set("download_token", this.apiService.getToken().download_token);
    return newUrl.href;
  }
}
