import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";

import { AuthService } from "../../../modules/auth/providers/auth.service";
import { ReauthorizeService } from "../../../modules/auth/services/core/reauthorize.service";
import { GuiService } from "../../../modules/game/services/gui.service";
import { LoginActions } from "../../../store/auth/login";
import { AppState } from "../../../store/state";
import { getToken } from "../../utility/token";

@Injectable({
  providedIn: "root",
})
export class InactiveMeasureService {
  timeInMinutes = 15;
  inactivityTimeout: null | ReturnType<typeof setTimeout>;
  events: string[] = ["click", "touchstart"];
  allowStart: boolean;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private reauthorizeService: ReauthorizeService,
    private authService: AuthService,
    private guiService: GuiService
  ) {}

  start() {
    this.allowStart = true;
    this.resetMeasure();
    this.startEventListeners();
  }

  stop() {
    this.allowStart = false;
    this.clearTimeout();
  }

  startEventListeners() {
    this.events.forEach(event => this.addEventListener(event));
  }

  addEventListener(event: string) {
    document.body.addEventListener(event, () => {
      this.resetMeasure();
    });
  }

  resetMeasure() {
    this.clearTimeout();

    if (this.allowStart) {
      this.inactivityTimeout = setTimeout(() => {
        this.actionTimeEndInactive();
      }, this.timeInMinutes * 60 * 1000);
    }
  }

  actionTimeEndInactive() {
    if (getToken().reauthorize_after) {
      this.reauthorizeService.redirectToReauthorizeAndDisconnect();
    } else {
      this.authService.logoutApi().subscribe(() => {
        this.guiService.isSplashShow.next(true);
        this.store.dispatch(new LoginActions.AuthLogout());
      });
    }
  }

  clearTimeout() {
    clearTimeout(this.inactivityTimeout);
  }
}
