import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";

import { GameEffects } from "../../../../store/game";
import { GameEngineModule } from "../../../game/game-engine/game-engine.module";
import { SharedModule } from "../../../shared/shared.module";

@NgModule({
  imports: [CommonModule, SharedModule, GameEngineModule, EffectsModule.forFeature([GameEffects])],
  exports: [SharedModule, GameEngineModule, EffectsModule],
  declarations: [],
})
export class GameAbstractModule {}
