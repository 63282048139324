import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "htmlEntity",
})
export class HtmlEntityPipe implements PipeTransform {
  transform(value: string): string {
    const span = document.createElement('span');

    return value
      .replace(/&[#A-Za-z0-9]+;/gi, (entity,position,text)=> {
        span.innerHTML = entity;
        return span.innerText;
      });
  }
}
