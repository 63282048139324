<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- TOP BAR BASE -->
  <ng-container *ngIf="buildingDetails">
    <div class="top-bar">
      <!-- BUILDING NAME AND LEVEL -->
      <div class="title">
        {{ "auto-production.title-level" | myTranslate : [buildingDetails.name, buildingDetails.level] }}
      </div>

      <!-- GROUP COUNT -->
      <div class="absolute-right">
        <m-ui-group-count [building]="buildingDetails"></m-ui-group-count>
      </div>
    </div>

    <!-- BUILDING ICON -->
    <div class="building-icon-container">
      <img [src]="buildingDetails | buildingIcon : false" />
    </div>

    <!-- BUILDING DESCRIPTION -->
    <div class="building-desc" owEllipsis [innerHTML]="buildingDetails.group_description"></div>

    <!-- PARAMETERS -->
    <div class="parameters">
      <!-- AUTO CURRENCY -->
      <m-ui-currency
        *ngIf="automaticCurrency"
        [item]="automaticCurrency"
        [other]="{ afterValue: '/h' }"
        [stockView]="STOCK_VIEW.B"
        [lack]="false"></m-ui-currency>

      <!-- AUTO PRODUCT -->
      <m-ui-product
        *ngIf="automaticProduct"
        [item]="automaticProduct"
        [other]="{ afterValue: '/h' }"
        [stockView]="STOCK_VIEW.B"
        [lack]="false"></m-ui-product>

      <ng-container *ngIf="buildingDetails.daily_product_productions">
        <m-ui-product
          *ngFor="let automaticProduct of buildingDetails.daily_product_productions"
          [item]="automaticProduct"
          [other]="{ afterValue: '/24h' }"
          [stockView]="STOCK_VIEW.B"
          [lack]="false"></m-ui-product>
      </ng-container>
    </div>

    <!-- ADDITIONAL DETAILS -->
    <div class="building-additional-desc" owEllipsis [innerHTML]="buildingDetails.additional_details"></div>
  </ng-container>
</div>
