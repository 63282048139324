import { createSelector } from "@ngrx/store";

import { CurrencyDefinition } from "../../core/interfaces/currency";
import { Primary } from "../../core/interfaces/primary";
import { ParameterDefinition } from "../../modules/game/interfaces/parameters";
import { AppState } from "../state";
import { PrimaryState } from "./state";

export const selectState = (state: AppState) => state.application.primary;

const getIsLoading = (state: PrimaryState): boolean => state.isLoading;
export const selectIsLoading = createSelector<AppState, object, boolean>(selectState, getIsLoading);

const getPrimary = (state: PrimaryState): Primary => state.primary;
export const selectPrimary = createSelector<AppState, object, Primary>(selectState, getPrimary);

const getCurrencies = (state: PrimaryState) => state.primary.currencies;
export const selectCurrencies = createSelector<AppState, object, CurrencyDefinition[]>(selectState, getCurrencies);

export const selectParameters = createSelector<AppState, object, ParameterDefinition[]>(
  selectState,
  (state: PrimaryState) => state.primary.parameters
);

export const selectDefaultTradingCurrencyId = createSelector(selectCurrencies, (currencyDefinitions: CurrencyDefinition[]) => {
  const tradingCurrencyDefinition = currencyDefinitions.find(currencyDefinition => {
    return currencyDefinition.is_default_trading_currency;
  });

  return tradingCurrencyDefinition ? tradingCurrencyDefinition.currency_id : null;
});
