import * as R from "ramda";

import { Message } from "../../modules/game/game-ui/message/interfaces/core/message.interface";
import { Actions, ActionTypes } from "./actions";
import { GameState } from "./state";

const initialState: GameState = {
  ready: false,
  readyToStart: false,
  readyToLoad: false,
  isLoading: false,
  boardState: {
    isLoading: false,
    loaded: false,
    board: [],
    buildingsImagesPreloaded: false,
    lastBoardTileUpdate: null,
    currentPlayerIslandId: null,
  },
  importantMessages: {
    messages: [],
    importantMessageDisplayed: false,
  },
  currentScene: null,
};

export function gameReducer(state = initialState, action: Actions): GameState {
  switch (action.type) {
    case ActionTypes.FETCH_BOARD:
      return {
        ...state,
        boardState: {
          ...state.boardState,
          playerIslandId: action.payload.playerIslandId,
        },
      };

    case ActionTypes.FETCH_BOARD_SUCCESS:
      const boardData = R.clone(action.payload);
      // OVERRIDE CORE FILE START (edited)
      // roads
      const management = boardData.find(x => x.player_building?.group === "management");
      if (management) {
        const roads = boardData.filter(x => x.player_building?.group.includes("road"));

        for (const road of roads) {
          road.player_building.level = management.player_building?.level;
        }
      }
      // OVERRIDE CORE FILE END
      return {
        ...state,
        boardState: {
          ...state.boardState,
          isLoading: false,
          loaded: true,
          board: boardData,
        },
      };

    case ActionTypes.RESET_BOARD:
      return {
        ...state,
        ready: false,
        boardState: initialState.boardState,
      };

    case ActionTypes.ENGINE_INIT_SUCCESS:
      return {
        ...state,
        readyToLoad: action.payload,
      };

    case ActionTypes.BOARD_TILE_UPDATE:
      if (action.payload.player_island_id && action.payload.player_island_id !== state.boardState.playerIslandId) {
        return state; // skip processing new tile data if player_island_id is null or different than currently loaded board
      }

      const newBoard = R.clone(state.boardState.board);
      const tileIndex = newBoard.findIndex(tile => tile.tile_id === action.payload.tile_id);

      if (tileIndex > -1) {
        newBoard[tileIndex] = R.clone({...action.payload, recently_updated: true});
      }

      const tileData = R.clone({...action.payload, recently_updated: true});

      return R.assocPath(
        ["boardState"],
        {
          ...state.boardState,
          board: newBoard,
          lastBoardTileUpdate: tileData,
        },
        state
      );

    case ActionTypes.PRELOAD_BOARD_BUILDINGS_IMAGES_SUCCESS:
      return {
        ...state,
        boardState: {
          ...state.boardState,
          buildingsImagesPreloaded: true,
        },
      };

    case ActionTypes.NEW_IMPORTANT_MESSAGE_RECEIVED:
      return R.assocPath(
        ["importantMessages", "messages"],
        handleNewImportantMessage(action.payload, R.clone(state.importantMessages.messages)),
        state
      );

    case ActionTypes.NEW_IMPORTANT_MESSAGE_OPENED:
      return R.assocPath(["importantMessages", "importantMessageDisplayed"], action.payload, state);

    case ActionTypes.GASTRO_INCOME_COLLECT:
      return state;

    case ActionTypes.SET_GAME_READY:
      return {
        ...state,
        ready: action.payload,
      };

    case ActionTypes.SET_CURRENT_SCENE:
      return {
        ...state,
        currentScene: action.payload,
      };

    case ActionTypes.NEW_IMPORTANT_MESSAGE_CLOSED:
      return R.assocPath(
        ["importantMessages"],
        {
          messages: R.clone(
            state.importantMessages.messages.filter(message => message.player_message_id != action.payload.player_message_id)
          ),
          importantMessageDisplayed: false,
        },
        state
      );

    case ActionTypes.SET_CURRENT_PLAYER_ISLAND_ID:
      return {
        ...state,
        boardState: {
          ...state.boardState,
          currentPlayerIslandId: action.payload,
        },
      };

    default:
      return state;
  }
}

function handleNewImportantMessage(newMessage: Message, currentMessages: Message[]): Message[] {
  currentMessages.unshift(newMessage);
  return currentMessages;
}
