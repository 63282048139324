<!-- LOGO -->
<div class="logo"></div>

<loading></loading>

<div class="container loading-opacity">
  <p class="title">{{ "auth.registration-a.title" | myTranslate }}:</p>

  <form [formGroup]="form" *ngIf="form">
    <!-- FIRST NAME -->
    <div class="control">
      <div class="label-flex">
        <label>{{ "auth.registration-a.form.first-name" | myTranslate }}:</label>
      </div>
      <input class="control-input" formControlName="firstName" type="text" />
    </div>

    <!-- LAST NAME -->
    <div class="control">
      <div class="label-flex">
        <label>{{ "auth.registration-a.form.last-name" | myTranslate }}:</label>
      </div>
      <input class="control-input" formControlName="lastName" type="text" />
    </div>

    <!-- EMAIL -->
    <div class="control">
      <div class="label-flex">
        <label>{{ "auth.registration-a.form.email" | myTranslate }}:</label>
      </div>
      <input class="control-input" formControlName="email" type="text" />
    </div>

    <!-- PHONE -->
    <div class="control">
      <div class="label-flex">
        <label>{{ "auth.registration-a.form.phone" | myTranslate }}:</label>
      </div>
      <input class="control-input" formControlName="phone" type="text" />
    </div>
  </form>

  <div class="buttons">
    <!-- BACK BUTTON -->
    <button class="base primary" [routerLink]="AUTH_ROUTING_ABSOLUTE_PATH.LOGIN">
      {{ "auth.registration-a.button-back" | myTranslate }}
    </button>

    <!-- REGISTRATION BUTTON -->
    <button class="base primary" (click)="registration()" [disabled]="form.invalid">
      {{ "auth.registration-a.form.button-send" | myTranslate }}
    </button>
  </div>
</div>
