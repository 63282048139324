export class StorageData {
  storageDefinitions;
  isEphemeralSession;

  constructor(storageDefinitions) {
    this.storageDefinitions = storageDefinitions;

    this.initialize();
  }

  initialize() {
    this.isEphemeralSession = sessionStorage?.getItem('isEphemeralSession') === 'true';
  }

  set(key, value, parameters) {
    return this.method("set", key, value, parameters);
  }

  get(key) {
    return this.method("get", key);
  }

  remove(key) {
    return this.method("remove", key);
  }

  clear() {
    return this.method("clear");
  }

  parseValue(value) {
    if (value == null) {
      return value;
    }

    if (typeof value === "string") {
      if (value.startsWith('{') || value.startsWith('[')) {
        return JSON.parse(value);
      }

      if (!isNaN(parseFloat(value))) {
        return parseFloat(value);
      }

      if (value === 'true' || value === 'false') {
        return value === 'true';
      }

      return value;
    }
  }

  method(method, ...args) {
    let storageDefinitions = this.storageDefinitions.filter(x => x.supported);
    if (this.isEphemeralSession) {
      storageDefinitions = this.storageDefinitions.filter(x => x.type !== "local_storage");
    }

    for (const storageDefinition of storageDefinitions) {
      const result = storageDefinition[method].apply(storageDefinition, args);
      return this.parseValue(result)
    }
  }
}
