import { CustomDialogEvent } from "../../../../../../../core/classes/custom-dialog-event.class";
import { EventDialog } from "../../../../../../../core/interfaces/event-dialog.interface";
import { CoreTransactionHistory } from "../../../base/core/dialogs/transaction-history/transaction-history.component";
import { TransactionHistory } from "../../../base/custom/dialogs/transaction-history/transaction-history.component";
import { EVENT_DIALOGS_NAMES_TRANSACTION_HISTORY } from "../../core/event-dialogs/event-names.const";

export const EVENT_DIALOGS_TRANSACTION_HISTORY_CUSTOM: EventDialog = {
  [EVENT_DIALOGS_NAMES_TRANSACTION_HISTORY.HISTORY]: new CustomDialogEvent(matDialogRef => {
    const currencyId = matDialogRef.data.currency.currency_id;
    return currencyId === 3 ? TransactionHistory : CoreTransactionHistory;
  }),
};
