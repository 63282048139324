export const MOCK_BOARD_KEY = "mock-board-key";

export const MOCK_BOARD = {
  api: [
    {
      player_tile_id: 55200,
      state: "active",
      resource_left: null,
      player_building: {
        upgrading_from_building: null,
        player_mission: null,
        auto_production_amount: 0,
        player_building_id: 187242,
        upgrade_building_id: null,
        building_id: 1000,
        group_type: "normal",
        category_id: 1,
        group: "hidden",
        group_description: "",
        is_moveable: false,
        is_deletable: false,
        moveable_from_level: null,
        deletable_from_level: null,
        tile_type: { type_id: 1, width: 1, height: 1 },
        icon: "xxx",
        automatic_product: null,
        automatic_currency: null,
        level: 1,
        name: "Ukryty",
        additional_details: "",
        additional_board_data: [],
        built_at: "2021-02-03T09:41:45+01:00",
        status: 1,
        variant: null,
        last_production_recipe: null,
        player_building_disaster: null,
        visual_variant: 1,
      },
      tile_id: 157,
      x_pos: 640000,
      y_pos: 640000,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 0,
      currency_prices: [],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 158,
      x_pos: -2560,
      y_pos: 5760,
      z_pos: null,
      tile_type: { type_id: 3, width: 1, height: 1 },
      border_bitmask: 0,
      empty_icon: null,
      required_level: 28,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 159,
      x_pos: -1920,
      y_pos: 5760,
      z_pos: null,
      tile_type: { type_id: 3, width: 1, height: 1 },
      border_bitmask: 0,
      empty_icon: null,
      required_level: 28,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 160,
      x_pos: -1280,
      y_pos: 5760,
      z_pos: null,
      tile_type: { type_id: 3, width: 1, height: 1 },
      border_bitmask: 0,
      empty_icon: null,
      required_level: 28,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 161,
      x_pos: -640,
      y_pos: 5760,
      z_pos: null,
      tile_type: { type_id: 3, width: 1, height: 1 },
      border_bitmask: 0,
      empty_icon: null,
      required_level: 28,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 162,
      x_pos: 0,
      y_pos: 5760,
      z_pos: null,
      tile_type: { type_id: 3, width: 1, height: 1 },
      border_bitmask: 0,
      empty_icon: null,
      required_level: 28,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 163,
      x_pos: 640,
      y_pos: 5760,
      z_pos: null,
      tile_type: { type_id: 3, width: 1, height: 1 },
      border_bitmask: 0,
      empty_icon: null,
      required_level: 28,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 26,
      x_pos: -5120,
      y_pos: 3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 42,
      currency_prices: [
        { currency_id: 1, amount: 650 },
        { currency_id: 2, amount: 850 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 13,
      x_pos: -4480,
      y_pos: 3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 42,
      currency_prices: [
        { currency_id: 1, amount: 650 },
        { currency_id: 2, amount: 850 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 39,
      x_pos: -2560,
      y_pos: 3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 5,
      currency_prices: [
        { currency_id: 1, amount: 50 },
        { currency_id: 2, amount: 100 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 52,
      x_pos: -1920,
      y_pos: 3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 8,
      currency_prices: [
        { currency_id: 1, amount: 150 },
        { currency_id: 2, amount: 300 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 65,
      x_pos: -1280,
      y_pos: 3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 11,
      currency_prices: [
        { currency_id: 1, amount: 200 },
        { currency_id: 2, amount: 400 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 78,
      x_pos: -640,
      y_pos: 3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 13,
      currency_prices: [
        { currency_id: 1, amount: 300 },
        { currency_id: 2, amount: 500 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 91,
      x_pos: 0,
      y_pos: 3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 17,
      currency_prices: [
        { currency_id: 1, amount: 450 },
        { currency_id: 2, amount: 600 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 104,
      x_pos: 640,
      y_pos: 3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 22,
      currency_prices: [
        { currency_id: 1, amount: 550 },
        { currency_id: 2, amount: 750 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 117,
      x_pos: 1280,
      y_pos: 3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 99,
      currency_prices: [],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 130,
      x_pos: 1920,
      y_pos: 3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 0,
      currency_prices: [],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 143,
      x_pos: 2560,
      y_pos: 3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 27,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 156,
      x_pos: 3200,
      y_pos: 3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 0,
      currency_prices: [],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 25,
      x_pos: -5120,
      y_pos: 3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 42,
      currency_prices: [
        { currency_id: 1, amount: 650 },
        { currency_id: 2, amount: 850 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 12,
      x_pos: -4480,
      y_pos: 3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 42,
      currency_prices: [
        { currency_id: 1, amount: 650 },
        { currency_id: 2, amount: 850 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 38,
      x_pos: -2560,
      y_pos: 3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 5,
      currency_prices: [
        { currency_id: 1, amount: 50 },
        { currency_id: 2, amount: 100 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 51,
      x_pos: -1920,
      y_pos: 3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 7,
      currency_prices: [
        { currency_id: 1, amount: 100 },
        { currency_id: 2, amount: 200 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 64,
      x_pos: -1280,
      y_pos: 3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 11,
      currency_prices: [
        { currency_id: 1, amount: 200 },
        { currency_id: 2, amount: 400 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 77,
      x_pos: -640,
      y_pos: 3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 13,
      currency_prices: [
        { currency_id: 1, amount: 300 },
        { currency_id: 2, amount: 500 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 90,
      x_pos: 0,
      y_pos: 3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 17,
      currency_prices: [
        { currency_id: 1, amount: 450 },
        { currency_id: 2, amount: 600 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 103,
      x_pos: 640,
      y_pos: 3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 22,
      currency_prices: [
        { currency_id: 1, amount: 550 },
        { currency_id: 2, amount: 750 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 116,
      x_pos: 1280,
      y_pos: 3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 25,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 750 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 129,
      x_pos: 1920,
      y_pos: 3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 27,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 142,
      x_pos: 2560,
      y_pos: 3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 27,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 155,
      x_pos: 3200,
      y_pos: 3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 38,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 24,
      x_pos: -5120,
      y_pos: 2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 42,
      currency_prices: [
        { currency_id: 1, amount: 650 },
        { currency_id: 2, amount: 850 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 11,
      x_pos: -4480,
      y_pos: 2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 42,
      currency_prices: [
        { currency_id: 1, amount: 650 },
        { currency_id: 2, amount: 850 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 37,
      x_pos: -2560,
      y_pos: 2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 5,
      currency_prices: [
        { currency_id: 1, amount: 50 },
        { currency_id: 2, amount: 100 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 50,
      x_pos: -1920,
      y_pos: 2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 7,
      currency_prices: [
        { currency_id: 1, amount: 100 },
        { currency_id: 2, amount: 200 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 63,
      x_pos: -1280,
      y_pos: 2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 8,
      currency_prices: [
        { currency_id: 1, amount: 150 },
        { currency_id: 2, amount: 300 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 76,
      x_pos: -640,
      y_pos: 2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 13,
      currency_prices: [
        { currency_id: 1, amount: 300 },
        { currency_id: 2, amount: 500 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 89,
      x_pos: 0,
      y_pos: 2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 17,
      currency_prices: [
        { currency_id: 1, amount: 450 },
        { currency_id: 2, amount: 600 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 102,
      x_pos: 640,
      y_pos: 2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 22,
      currency_prices: [
        { currency_id: 1, amount: 550 },
        { currency_id: 2, amount: 750 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 115,
      x_pos: 1280,
      y_pos: 2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 25,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 750 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 128,
      x_pos: 1920,
      y_pos: 2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 27,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 141,
      x_pos: 2560,
      y_pos: 2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 27,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 154,
      x_pos: 3200,
      y_pos: 2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 38,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 23,
      x_pos: -5120,
      y_pos: 1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 15,
      currency_prices: [{ currency_id: 2, amount: 250 }],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 10,
      x_pos: -4480,
      y_pos: 1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 42,
      currency_prices: [{ currency_id: 2, amount: 250 }],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: 55161,
      state: "active",
      resource_left: null,
      player_building: {
        upgrading_from_building: null,
        player_mission: null,
        auto_production_amount: 0,
        player_building_id: 187245,
        upgrade_building_id: 2,
        building_id: 1,
        group_type: "normal",
        category_id: 1,
        group: "house",
        group_description:
          "Cichy i s\u0142oneczny dom. Budynek szczeg\u00f3lnie lubiany przez mieszka\u0144c\u00f3w ceni\u0105cych prywatno\u015b\u0107.",
        is_moveable: false,
        is_deletable: false,
        moveable_from_level: null,
        deletable_from_level: null,
        tile_type: { type_id: 1, width: 1, height: 1 },
        icon: "house",
        automatic_product: null,
        automatic_currency: null,
        level: 1,
        name: "Dom",
        additional_details: "",
        additional_board_data: [],
        built_at: "2021-02-03T09:42:43+01:00",
        status: 1,
        variant: null,
        last_production_recipe: null,
        player_building_disaster: null,
        visual_variant: 1,
      },
      tile_id: 36,
      x_pos: -2560,
      y_pos: 1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 5,
      currency_prices: [
        { currency_id: 1, amount: 50 },
        { currency_id: 2, amount: 100 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 49,
      x_pos: -1920,
      y_pos: 1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 7,
      currency_prices: [
        { currency_id: 1, amount: 100 },
        { currency_id: 2, amount: 200 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 62,
      x_pos: -1280,
      y_pos: 1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 8,
      currency_prices: [
        { currency_id: 1, amount: 150 },
        { currency_id: 2, amount: 300 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 75,
      x_pos: -640,
      y_pos: 1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 13,
      currency_prices: [
        { currency_id: 1, amount: 300 },
        { currency_id: 2, amount: 500 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 88,
      x_pos: 0,
      y_pos: 1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 17,
      currency_prices: [
        { currency_id: 1, amount: 450 },
        { currency_id: 2, amount: 600 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 101,
      x_pos: 640,
      y_pos: 1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 22,
      currency_prices: [
        { currency_id: 1, amount: 550 },
        { currency_id: 2, amount: 750 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 114,
      x_pos: 1280,
      y_pos: 1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 25,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 750 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 127,
      x_pos: 1920,
      y_pos: 1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 27,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 140,
      x_pos: 2560,
      y_pos: 1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 27,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 153,
      x_pos: 3200,
      y_pos: 1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 38,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 22,
      x_pos: -5120,
      y_pos: 1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 15,
      currency_prices: [{ currency_id: 2, amount: 250 }],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 9,
      x_pos: -4480,
      y_pos: 1280,
      z_pos: null,
      tile_type: { type_id: 2, width: 2, height: 2 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 8,
      currency_prices: [
        { currency_id: 1, amount: 150 },
        { currency_id: 2, amount: 300 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: 55158,
      state: "active",
      resource_left: null,
      player_building: {
        upgrading_from_building: null,
        player_mission: null,
        auto_production_amount: 0,
        player_building_id: 187248,
        upgrade_building_id: 2,
        building_id: 1,
        group_type: "normal",
        category_id: 1,
        group: "house",
        group_description:
          "Cichy i s\u0142oneczny dom. Budynek szczeg\u00f3lnie lubiany przez mieszka\u0144c\u00f3w ceni\u0105cych prywatno\u015b\u0107.",
        is_moveable: false,
        is_deletable: false,
        moveable_from_level: null,
        deletable_from_level: null,
        tile_type: { type_id: 1, width: 1, height: 1 },
        icon: "house",
        automatic_product: null,
        automatic_currency: null,
        level: 1,
        name: "Dom",
        additional_details: "",
        additional_board_data: [],
        built_at: "2021-02-03T09:43:04+01:00",
        status: 1,
        variant: null,
        last_production_recipe: null,
        player_building_disaster: null,
        visual_variant: 1,
      },
      tile_id: 35,
      x_pos: -2560,
      y_pos: 1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 5,
      currency_prices: [
        { currency_id: 1, amount: 50 },
        { currency_id: 2, amount: 100 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: 55170,
      state: "active",
      resource_left: null,
      player_building: null,
      tile_id: 48,
      x_pos: -1920,
      y_pos: 1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 7,
      currency_prices: [
        { currency_id: 1, amount: 100 },
        { currency_id: 2, amount: 200 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: 55182,
      state: "active",
      resource_left: null,
      player_building: null,
      tile_id: 61,
      x_pos: -1280,
      y_pos: 1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 7,
      currency_prices: [
        { currency_id: 1, amount: 100 },
        { currency_id: 2, amount: 200 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 74,
      x_pos: -640,
      y_pos: 1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 13,
      currency_prices: [
        { currency_id: 1, amount: 300 },
        { currency_id: 2, amount: 500 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 87,
      x_pos: 0,
      y_pos: 1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 17,
      currency_prices: [
        { currency_id: 1, amount: 450 },
        { currency_id: 2, amount: 600 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 100,
      x_pos: 640,
      y_pos: 1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 21,
      currency_prices: [
        { currency_id: 1, amount: 550 },
        { currency_id: 2, amount: 750 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 113,
      x_pos: 1280,
      y_pos: 1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 25,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 750 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 126,
      x_pos: 1920,
      y_pos: 1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 27,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 139,
      x_pos: 2560,
      y_pos: 1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 27,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 152,
      x_pos: 3200,
      y_pos: 1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 38,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 21,
      x_pos: -5120,
      y_pos: 640,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 15,
      currency_prices: [{ currency_id: 2, amount: 250 }],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 7,
      x_pos: -4480,
      y_pos: 640,
      z_pos: null,
      tile_type: { type_id: 2, width: 2, height: 2 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 20,
      currency_prices: [
        { currency_id: 1, amount: 550 },
        { currency_id: 2, amount: 700 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: 55155,
      state: "active",
      resource_left: null,
      player_building: {
        upgrading_from_building: null,
        player_mission: null,
        auto_production_amount: 0,
        player_building_id: 187263,
        upgrade_building_id: 2,
        building_id: 1,
        group_type: "normal",
        category_id: 1,
        group: "house",
        group_description:
          "Cichy i s\u0142oneczny dom. Budynek szczeg\u00f3lnie lubiany przez mieszka\u0144c\u00f3w ceni\u0105cych prywatno\u015b\u0107.",
        is_moveable: false,
        is_deletable: false,
        moveable_from_level: null,
        deletable_from_level: null,
        tile_type: { type_id: 1, width: 1, height: 1 },
        icon: "house",
        automatic_product: null,
        automatic_currency: null,
        level: 1,
        name: "Dom",
        additional_details: "",
        additional_board_data: [],
        built_at: "2021-02-03T09:47:08+01:00",
        status: 1,
        variant: null,
        last_production_recipe: null,
        player_building_disaster: null,
        visual_variant: 1,
      },
      tile_id: 34,
      x_pos: -2560,
      y_pos: 640,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 5,
      currency_prices: [
        { currency_id: 1, amount: 50 },
        { currency_id: 2, amount: 100 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: 55167,
      state: "active",
      resource_left: null,
      player_building: null,
      tile_id: 47,
      x_pos: -1920,
      y_pos: 640,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 7,
      currency_prices: [
        { currency_id: 1, amount: 100 },
        { currency_id: 2, amount: 200 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: 55179,
      state: "active",
      resource_left: null,
      player_building: null,
      tile_id: 60,
      x_pos: -1280,
      y_pos: 640,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 7,
      currency_prices: [
        { currency_id: 1, amount: 100 },
        { currency_id: 2, amount: 200 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 73,
      x_pos: -640,
      y_pos: 640,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 13,
      currency_prices: [
        { currency_id: 1, amount: 300 },
        { currency_id: 2, amount: 500 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 86,
      x_pos: 0,
      y_pos: 640,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 17,
      currency_prices: [
        { currency_id: 1, amount: 450 },
        { currency_id: 2, amount: 600 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 99,
      x_pos: 640,
      y_pos: 640,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 21,
      currency_prices: [
        { currency_id: 1, amount: 550 },
        { currency_id: 2, amount: 750 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 112,
      x_pos: 1280,
      y_pos: 640,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 25,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 750 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 125,
      x_pos: 1920,
      y_pos: 640,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 27,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 138,
      x_pos: 2560,
      y_pos: 640,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 27,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 151,
      x_pos: 3200,
      y_pos: 640,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 38,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 20,
      x_pos: -5120,
      y_pos: 0,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 42,
      currency_prices: [
        { currency_id: 1, amount: 650 },
        { currency_id: 2, amount: 850 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 8,
      x_pos: -4480,
      y_pos: 0,
      z_pos: null,
      tile_type: { type_id: 2, width: 2, height: 2 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 16,
      currency_prices: [
        { currency_id: 1, amount: 400 },
        { currency_id: 2, amount: 550 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: 55152,
      state: "active",
      resource_left: null,
      player_building: {
        upgrading_from_building: null,
        player_mission: null,
        auto_production_amount: 0,
        player_building_id: 187221,
        upgrade_building_id: 104,
        building_id: 103,
        group_type: "normal",
        category_id: 1,
        group: "park",
        group_description:
          "Park to doskona\u0142e miejsce dla rozrywki na \u015bwie\u017cym powietrzu. Zielone tereny stanowi\u0105 idealn\u0105 r\u00f3wnowag\u0119 dla budynk\u00f3w, zadbane parki zapewni\u0105 tak\u017ce bezpiecze\u0144stwo w mie\u015bcie.",
        is_moveable: false,
        is_deletable: false,
        moveable_from_level: null,
        deletable_from_level: null,
        tile_type: { type_id: 1, width: 1, height: 1 },
        icon: "park",
        automatic_product: null,
        automatic_currency: null,
        level: 1,
        name: "Park",
        additional_details: "",
        additional_board_data: [],
        built_at: "2021-02-03T09:41:45+01:00",
        status: 1,
        variant: null,
        last_production_recipe: null,
        player_building_disaster: null,
        visual_variant: 1,
      },
      tile_id: 33,
      x_pos: -2560,
      y_pos: 0,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 46,
      currency_prices: [{ currency_id: 2, amount: 500 }],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 46,
      x_pos: -1920,
      y_pos: 0,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 40,
      currency_prices: [{ currency_id: 2, amount: 500 }],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: 55176,
      state: "active",
      resource_left: null,
      player_building: {
        upgrading_from_building: null,
        player_mission: null,
        auto_production_amount: 1500,
        player_building_id: 187224,
        upgrade_building_id: 165,
        building_id: 164,
        group_type: "auto-production",
        category_id: 1,
        group: "cityhall",
        group_description:
          "Ratusz to Twoja siedziba, z kt\u00f3rej podejmujesz decyzje. Rozbudowa ratusza zwi\u0119ksza pojemno\u015b\u0107 skarbca na srebrne talary oraz ich gromadzon\u0105 ilo\u015b\u0107 w cyklu godzinowym.",
        is_moveable: false,
        is_deletable: false,
        moveable_from_level: null,
        deletable_from_level: null,
        tile_type: { type_id: 1, width: 1, height: 1 },
        icon: "cityhall",
        automatic_product: null,
        automatic_currency: { currency_id: 2 },
        level: 1,
        name: "Ratusz",
        additional_details: "",
        additional_board_data: { "auto-production-hourly-performance": 20, "storage-size": 1500 },
        built_at: "2021-02-03T09:41:45+01:00",
        status: 1,
        variant: null,
        last_production_recipe: null,
        player_building_disaster: null,
        visual_variant: 1,
      },
      tile_id: 59,
      x_pos: -1280,
      y_pos: 0,
      z_pos: null,
      tile_type: { type_id: 4, width: 2, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 99,
      currency_prices: [],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: 55188,
      state: "active",
      resource_left: null,
      player_building: {
        upgrading_from_building: null,
        player_mission: null,
        auto_production_amount: 0,
        player_building_id: 187230,
        upgrade_building_id: 2,
        building_id: 1,
        group_type: "normal",
        category_id: 1,
        group: "house",
        group_description:
          "Cichy i s\u0142oneczny dom. Budynek szczeg\u00f3lnie lubiany przez mieszka\u0144c\u00f3w ceni\u0105cych prywatno\u015b\u0107.",
        is_moveable: false,
        is_deletable: false,
        moveable_from_level: null,
        deletable_from_level: null,
        tile_type: { type_id: 1, width: 1, height: 1 },
        icon: "house",
        automatic_product: null,
        automatic_currency: null,
        level: 1,
        name: "Dom",
        additional_details: "",
        additional_board_data: [],
        built_at: "2021-02-03T09:41:45+01:00",
        status: 1,
        variant: null,
        last_production_recipe: null,
        player_building_disaster: null,
        visual_variant: 1,
      },
      tile_id: 72,
      x_pos: -640,
      y_pos: 0,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 15,
      currency_prices: [{ currency_id: 2, amount: 250 }],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: 55194,
      state: "active",
      resource_left: null,
      player_building: {
        upgrading_from_building: null,
        player_mission: null,
        auto_production_amount: 0,
        player_building_id: 187236,
        upgrade_building_id: 2,
        building_id: 1,
        group_type: "normal",
        category_id: 1,
        group: "house",
        group_description:
          "Cichy i s\u0142oneczny dom. Budynek szczeg\u00f3lnie lubiany przez mieszka\u0144c\u00f3w ceni\u0105cych prywatno\u015b\u0107.",
        is_moveable: false,
        is_deletable: false,
        moveable_from_level: null,
        deletable_from_level: null,
        tile_type: { type_id: 1, width: 1, height: 1 },
        icon: "house",
        automatic_product: null,
        automatic_currency: null,
        level: 1,
        name: "Dom",
        additional_details: "",
        additional_board_data: [],
        built_at: "2021-02-03T09:41:45+01:00",
        status: 1,
        variant: null,
        last_production_recipe: null,
        player_building_disaster: null,
        visual_variant: 1,
      },
      tile_id: 85,
      x_pos: 0,
      y_pos: 0,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 15,
      currency_prices: [{ currency_id: 2, amount: 250 }],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 98,
      x_pos: 640,
      y_pos: 0,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 14,
      empty_icon: null,
      required_level: 21,
      currency_prices: [
        { currency_id: 1, amount: 550 },
        { currency_id: 2, amount: 750 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 111,
      x_pos: 1280,
      y_pos: 0,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 14,
      empty_icon: null,
      required_level: 25,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 750 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 124,
      x_pos: 1920,
      y_pos: 0,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 26,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 137,
      x_pos: 2560,
      y_pos: 0,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 27,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 150,
      x_pos: 3200,
      y_pos: 0,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 1,
      currency_prices: [],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 19,
      x_pos: -5120,
      y_pos: -640,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 42,
      currency_prices: [
        { currency_id: 1, amount: 650 },
        { currency_id: 2, amount: 850 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 6,
      x_pos: -4480,
      y_pos: -640,
      z_pos: null,
      tile_type: { type_id: 2, width: 2, height: 2 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 15,
      currency_prices: [
        { currency_id: 1, amount: 550 },
        { currency_id: 2, amount: 700 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: 55149,
      state: "active",
      resource_left: null,
      player_building: {
        upgrading_from_building: null,
        player_mission: null,
        auto_production_amount: 0,
        player_building_id: 187254,
        upgrade_building_id: 152,
        building_id: 151,
        group_type: "production",
        category_id: 1,
        group: "sawmill",
        group_description: "Tartak zajmuje si\u0119 obr\u00f3bk\u0105 drewna, kt\u00f3re jest potrzebne do produkcji mebli.",
        is_moveable: false,
        is_deletable: false,
        moveable_from_level: null,
        deletable_from_level: null,
        tile_type: { type_id: 1, width: 1, height: 1 },
        icon: "sawmill",
        automatic_product: null,
        automatic_currency: null,
        level: 1,
        name: "Tartak",
        additional_details: "",
        additional_board_data: [],
        built_at: "2021-02-03T09:46:26+01:00",
        status: 1,
        variant: null,
        last_production_recipe: {
          currency_prizes: [],
          product_prizes: [
            {
              product_id: 1,
              category: { storage_id: 1, trading_currency_id: null, id: 2 },
              name: "Drewno",
              description:
                "Odpowiednio obrobione i wyselekcjonowane kawa\u0142ki drewna pos\u0142u\u017c\u0105 Ci do produkcji mebli oraz budowy budynk&oacute;w. Miejsce obr&oacute;bki: Tartak.",
              icon: "wood",
              amount: 20,
            },
          ],
          production_recipe_id: 1,
          alternative_icon: null,
          alternative_name: null,
        },
        player_building_disaster: null,
        visual_variant: 1,
      },
      tile_id: 32,
      x_pos: -2560,
      y_pos: -640,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 46,
      currency_prices: [{ currency_id: 2, amount: 500 }],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: 55164,
      state: "active",
      resource_left: null,
      player_building: {
        upgrading_from_building: null,
        player_mission: null,
        auto_production_amount: 0,
        player_building_id: 187266,
        upgrade_building_id: 38,
        building_id: 37,
        group_type: "normal",
        category_id: 1,
        group: "market",
        group_description:
          "Ryneczek \u2013 nie ma lepszego miejsca na zaopatrzenie si\u0119 w \u015bwie\u017ce warzywa i owoce. Rozwija us\u0142ugi.",
        is_moveable: false,
        is_deletable: false,
        moveable_from_level: null,
        deletable_from_level: null,
        tile_type: { type_id: 1, width: 1, height: 1 },
        icon: "market",
        automatic_product: null,
        automatic_currency: null,
        level: 1,
        name: "Ryneczek",
        additional_details: "",
        additional_board_data: [],
        built_at: "2021-02-03T09:57:20+01:00",
        status: 1,
        variant: null,
        last_production_recipe: null,
        player_building_disaster: null,
        visual_variant: 1,
      },
      tile_id: 45,
      x_pos: -1920,
      y_pos: -640,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 40,
      currency_prices: [{ currency_id: 2, amount: 500 }],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: 55173,
      state: "active",
      resource_left: null,
      player_building: {
        upgrading_from_building: null,
        player_mission: null,
        auto_production_amount: 0,
        player_building_id: 187251,
        upgrade_building_id: 122,
        building_id: 121,
        group_type: "normal",
        category_id: 1,
        group: "warehouse",
        group_description: "W magazynie znajduj\u0105 si\u0119 wszystkie wytworzone i kupione przez Ciebie produkty.",
        is_moveable: false,
        is_deletable: false,
        moveable_from_level: null,
        deletable_from_level: null,
        tile_type: { type_id: 1, width: 1, height: 1 },
        icon: "warehouse",
        automatic_product: null,
        automatic_currency: null,
        level: 1,
        name: "Magazyn",
        additional_details: "",
        additional_board_data: [],
        built_at: "2021-02-03T09:44:21+01:00",
        status: 1,
        variant: null,
        last_production_recipe: null,
        player_building_disaster: null,
        visual_variant: 1,
      },
      tile_id: 58,
      x_pos: -1280,
      y_pos: -640,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 14,
      empty_icon: null,
      required_level: 99,
      currency_prices: [],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: 55185,
      state: "active",
      resource_left: null,
      player_building: {
        upgrading_from_building: null,
        player_mission: null,
        auto_production_amount: 0,
        player_building_id: 187227,
        upgrade_building_id: 2,
        building_id: 1,
        group_type: "normal",
        category_id: 1,
        group: "house",
        group_description:
          "Cichy i s\u0142oneczny dom. Budynek szczeg\u00f3lnie lubiany przez mieszka\u0144c\u00f3w ceni\u0105cych prywatno\u015b\u0107.",
        is_moveable: false,
        is_deletable: false,
        moveable_from_level: null,
        deletable_from_level: null,
        tile_type: { type_id: 1, width: 1, height: 1 },
        icon: "house",
        automatic_product: null,
        automatic_currency: null,
        level: 1,
        name: "Dom",
        additional_details: "",
        additional_board_data: [],
        built_at: "2021-02-03T09:41:45+01:00",
        status: 1,
        variant: null,
        last_production_recipe: null,
        player_building_disaster: null,
        visual_variant: 1,
      },
      tile_id: 71,
      x_pos: -640,
      y_pos: -640,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 14,
      empty_icon: null,
      required_level: 15,
      currency_prices: [{ currency_id: 2, amount: 250 }],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: 55191,
      state: "active",
      resource_left: null,
      player_building: {
        upgrading_from_building: null,
        player_mission: null,
        auto_production_amount: 0,
        player_building_id: 187233,
        upgrade_building_id: 26,
        building_id: 25,
        group_type: "normal",
        category_id: 1,
        group: "bakery",
        group_description: "Wypieki to podstawa diety mieszka\u0144c\u00f3w. Piekarnia rozwija zakres us\u0142ug.",
        is_moveable: false,
        is_deletable: false,
        moveable_from_level: null,
        deletable_from_level: null,
        tile_type: { type_id: 1, width: 1, height: 1 },
        icon: "bakery",
        automatic_product: null,
        automatic_currency: null,
        level: 1,
        name: "Piekarnia",
        additional_details: "",
        additional_board_data: [],
        built_at: "2021-02-03T09:41:45+01:00",
        status: 1,
        variant: null,
        last_production_recipe: null,
        player_building_disaster: null,
        visual_variant: 1,
      },
      tile_id: 84,
      x_pos: 0,
      y_pos: -640,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 14,
      empty_icon: null,
      required_level: 15,
      currency_prices: [{ currency_id: 2, amount: 250 }],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 97,
      x_pos: 640,
      y_pos: -640,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 14,
      empty_icon: null,
      required_level: 15,
      currency_prices: [
        { currency_id: 1, amount: 500 },
        { currency_id: 2, amount: 650 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 110,
      x_pos: 1280,
      y_pos: -640,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 14,
      empty_icon: null,
      required_level: 24,
      currency_prices: [
        { currency_id: 1, amount: 550 },
        { currency_id: 2, amount: 750 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 123,
      x_pos: 1920,
      y_pos: -640,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 14,
      empty_icon: null,
      required_level: 26,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 136,
      x_pos: 2560,
      y_pos: -640,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 27,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: 55197,
      state: "active",
      resource_left: null,
      player_building: {
        upgrading_from_building: null,
        player_mission: null,
        auto_production_amount: 0,
        player_building_id: 187239,
        upgrade_building_id: 214,
        building_id: 220,
        group_type: "special",
        category_id: 1,
        group: "closed_port",
        group_description: "Odbuduj port, aby otworzy\u0107 Komp Town na handel z innymi miastami.",
        is_moveable: false,
        is_deletable: false,
        moveable_from_level: null,
        deletable_from_level: null,
        tile_type: { type_id: 2, width: 2, height: 2 },
        icon: "burn_port",
        automatic_product: null,
        automatic_currency: null,
        level: 1,
        name: "Nieczynny Port",
        additional_details: "Odbuduj port, aby otworzy\u0107 KompTown na handel z innymi miastami.",
        additional_board_data: [],
        built_at: "2021-02-03T09:41:45+01:00",
        status: 1,
        variant: null,
        last_production_recipe: null,
        player_building_disaster: null,
        visual_variant: 1,
      },
      tile_id: 149,
      x_pos: 3840,
      y_pos: -640,
      z_pos: null,
      tile_type: { type_id: 2, width: 2, height: 2 },
      border_bitmask: 0,
      empty_icon: null,
      required_level: 1,
      currency_prices: [],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 18,
      x_pos: -5120,
      y_pos: -1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 42,
      currency_prices: [
        { currency_id: 1, amount: 650 },
        { currency_id: 2, amount: 850 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 5,
      x_pos: -4480,
      y_pos: -1280,
      z_pos: null,
      tile_type: { type_id: 2, width: 2, height: 2 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 21,
      currency_prices: [
        { currency_id: 1, amount: 550 },
        { currency_id: 2, amount: 700 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 31,
      x_pos: -2560,
      y_pos: -1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 5,
      currency_prices: [
        { currency_id: 1, amount: 50 },
        { currency_id: 2, amount: 100 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 44,
      x_pos: -1920,
      y_pos: -1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 5,
      currency_prices: [
        { currency_id: 1, amount: 50 },
        { currency_id: 2, amount: 100 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 57,
      x_pos: -1280,
      y_pos: -1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 5,
      currency_prices: [
        { currency_id: 1, amount: 50 },
        { currency_id: 2, amount: 100 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 70,
      x_pos: -640,
      y_pos: -1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 11,
      currency_prices: [
        { currency_id: 1, amount: 200 },
        { currency_id: 2, amount: 400 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 83,
      x_pos: 0,
      y_pos: -1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 16,
      currency_prices: [
        { currency_id: 1, amount: 400 },
        { currency_id: 2, amount: 550 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 96,
      x_pos: 640,
      y_pos: -1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 15,
      currency_prices: [
        { currency_id: 1, amount: 500 },
        { currency_id: 2, amount: 650 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 109,
      x_pos: 1280,
      y_pos: -1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 24,
      currency_prices: [
        { currency_id: 1, amount: 550 },
        { currency_id: 2, amount: 750 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 122,
      x_pos: 1920,
      y_pos: -1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 26,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 135,
      x_pos: 2560,
      y_pos: -1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 27,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 145,
      x_pos: 3200,
      y_pos: -1280,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 38,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 17,
      x_pos: -5120,
      y_pos: -1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 42,
      currency_prices: [
        { currency_id: 1, amount: 650 },
        { currency_id: 2, amount: 850 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 4,
      x_pos: -4480,
      y_pos: -1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 42,
      currency_prices: [
        { currency_id: 1, amount: 650 },
        { currency_id: 2, amount: 850 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 30,
      x_pos: -2560,
      y_pos: -1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 5,
      currency_prices: [
        { currency_id: 1, amount: 50 },
        { currency_id: 2, amount: 100 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 43,
      x_pos: -1920,
      y_pos: -1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 5,
      currency_prices: [
        { currency_id: 1, amount: 50 },
        { currency_id: 2, amount: 100 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 56,
      x_pos: -1280,
      y_pos: -1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 8,
      currency_prices: [
        { currency_id: 1, amount: 150 },
        { currency_id: 2, amount: 300 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 69,
      x_pos: -640,
      y_pos: -1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 11,
      currency_prices: [
        { currency_id: 1, amount: 200 },
        { currency_id: 2, amount: 400 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 82,
      x_pos: 0,
      y_pos: -1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 16,
      currency_prices: [
        { currency_id: 1, amount: 400 },
        { currency_id: 2, amount: 550 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 95,
      x_pos: 640,
      y_pos: -1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 15,
      currency_prices: [
        { currency_id: 1, amount: 500 },
        { currency_id: 2, amount: 650 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 108,
      x_pos: 1280,
      y_pos: -1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 23,
      currency_prices: [
        { currency_id: 1, amount: 550 },
        { currency_id: 2, amount: 750 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 121,
      x_pos: 1920,
      y_pos: -1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 26,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 134,
      x_pos: 2560,
      y_pos: -1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 27,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 147,
      x_pos: 3200,
      y_pos: -1920,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 38,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 16,
      x_pos: -5120,
      y_pos: -2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 42,
      currency_prices: [
        { currency_id: 1, amount: 650 },
        { currency_id: 2, amount: 850 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 3,
      x_pos: -4480,
      y_pos: -2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 42,
      currency_prices: [
        { currency_id: 1, amount: 650 },
        { currency_id: 2, amount: 850 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 29,
      x_pos: -2560,
      y_pos: -2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 5,
      currency_prices: [
        { currency_id: 1, amount: 50 },
        { currency_id: 2, amount: 100 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 42,
      x_pos: -1920,
      y_pos: -2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 7,
      currency_prices: [
        { currency_id: 1, amount: 100 },
        { currency_id: 2, amount: 200 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 55,
      x_pos: -1280,
      y_pos: -2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 8,
      currency_prices: [
        { currency_id: 1, amount: 150 },
        { currency_id: 2, amount: 300 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 68,
      x_pos: -640,
      y_pos: -2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 12,
      currency_prices: [
        { currency_id: 1, amount: 250 },
        { currency_id: 2, amount: 450 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 81,
      x_pos: 0,
      y_pos: -2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 16,
      currency_prices: [
        { currency_id: 1, amount: 400 },
        { currency_id: 2, amount: 550 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 94,
      x_pos: 640,
      y_pos: -2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 15,
      currency_prices: [
        { currency_id: 1, amount: 500 },
        { currency_id: 2, amount: 650 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 107,
      x_pos: 1280,
      y_pos: -2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 23,
      currency_prices: [
        { currency_id: 1, amount: 550 },
        { currency_id: 2, amount: 750 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 120,
      x_pos: 1920,
      y_pos: -2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 26,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 133,
      x_pos: 2560,
      y_pos: -2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 27,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 146,
      x_pos: 3200,
      y_pos: -2560,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 38,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 15,
      x_pos: -5120,
      y_pos: -3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 42,
      currency_prices: [
        { currency_id: 1, amount: 650 },
        { currency_id: 2, amount: 850 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 2,
      x_pos: -4480,
      y_pos: -3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 42,
      currency_prices: [
        { currency_id: 1, amount: 650 },
        { currency_id: 2, amount: 850 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 164,
      x_pos: -3840,
      y_pos: -3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 0,
      empty_icon: null,
      required_level: 42,
      currency_prices: [
        { currency_id: 1, amount: 650 },
        { currency_id: 2, amount: 850 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 28,
      x_pos: -2560,
      y_pos: -3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 5,
      currency_prices: [
        { currency_id: 1, amount: 50 },
        { currency_id: 2, amount: 100 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 41,
      x_pos: -1920,
      y_pos: -3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 7,
      currency_prices: [
        { currency_id: 1, amount: 100 },
        { currency_id: 2, amount: 200 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 54,
      x_pos: -1280,
      y_pos: -3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 8,
      currency_prices: [
        { currency_id: 1, amount: 150 },
        { currency_id: 2, amount: 300 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 67,
      x_pos: -640,
      y_pos: -3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 12,
      currency_prices: [
        { currency_id: 1, amount: 250 },
        { currency_id: 2, amount: 450 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 80,
      x_pos: 0,
      y_pos: -3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 16,
      currency_prices: [
        { currency_id: 1, amount: 400 },
        { currency_id: 2, amount: 550 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 93,
      x_pos: 640,
      y_pos: -3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 15,
      currency_prices: [
        { currency_id: 1, amount: 500 },
        { currency_id: 2, amount: 650 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 106,
      x_pos: 1280,
      y_pos: -3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 23,
      currency_prices: [
        { currency_id: 1, amount: 550 },
        { currency_id: 2, amount: 750 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 119,
      x_pos: 1920,
      y_pos: -3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 26,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 132,
      x_pos: 2560,
      y_pos: -3200,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 27,
      currency_prices: [
        { currency_id: 1, amount: 600 },
        { currency_id: 2, amount: 800 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 14,
      x_pos: -5120,
      y_pos: -3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 42,
      currency_prices: [
        { currency_id: 1, amount: 650 },
        { currency_id: 2, amount: 850 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "buyable",
      resource_left: null,
      player_building: null,
      tile_id: 1,
      x_pos: -4480,
      y_pos: -3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 42,
      currency_prices: [
        { currency_id: 1, amount: 650 },
        { currency_id: 2, amount: 850 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 27,
      x_pos: -2560,
      y_pos: -3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 0,
      currency_prices: [],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 40,
      x_pos: -1920,
      y_pos: -3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 7,
      currency_prices: [
        { currency_id: 1, amount: 100 },
        { currency_id: 2, amount: 200 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 53,
      x_pos: -1280,
      y_pos: -3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 40,
      currency_prices: [{ currency_id: 2, amount: 500 }],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 66,
      x_pos: -640,
      y_pos: -3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 0,
      currency_prices: [],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 79,
      x_pos: 0,
      y_pos: -3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 16,
      currency_prices: [
        { currency_id: 1, amount: 400 },
        { currency_id: 2, amount: 550 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 92,
      x_pos: 640,
      y_pos: -3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 0,
      currency_prices: [],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 105,
      x_pos: 1280,
      y_pos: -3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 23,
      currency_prices: [
        { currency_id: 1, amount: 550 },
        { currency_id: 2, amount: 750 },
      ],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 118,
      x_pos: 1920,
      y_pos: -3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 99,
      currency_prices: [],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 131,
      x_pos: 2560,
      y_pos: -3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 0,
      currency_prices: [],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
    {
      player_tile_id: null,
      state: "disabled",
      resource_left: null,
      player_building: null,
      tile_id: 144,
      x_pos: 3200,
      y_pos: -3840,
      z_pos: null,
      tile_type: { type_id: 1, width: 1, height: 1 },
      border_bitmask: 15,
      empty_icon: null,
      required_level: 0,
      currency_prices: [],
      product_prices: [],
      resource_amount: null,
      is_center_camera: false,
      production: null,
      open_player_island_id: null,
      player_island_id: 795,
      inner_island_player_production_building: null,
    },
  ],
};
