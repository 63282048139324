import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";

import { BuildingsModule } from "../../../buildings/module/core/buildings.module";
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import { HudAbstractModule } from "../../abstract/core/hud-abstract.module";
import { HudComponent } from "../../base/core/components/hud/hud.component";
import { HudMenuComponent } from "../../base/core/components/hud-menu/hud-menu.component";
import { HudOffsetTimeComponent } from "../../base/core/components/hud-offset-time/hud-offset-time.component";
import { HudParametersComponent } from "../../base/core/components/hud-parameters/hud-parameters.component";
import { HudPlayerComponent } from "../../base/core/components/hud-player/hud-player.component";
import { HudResourcesComponent } from "../../base/core/components/hud-resources/hud-resources.component";
import { HudMenuMoreComponent } from "../../base/core/dialogs/hud-menu-more/hud-menu-more.component";
import { MediaComponent } from "../../base/core/dialogs/media/media.component";
import { PlayerDetailsComponent } from "../../base/core/dialogs/player-details/player-details.component";
import { UserSettingsComponent } from "../../base/core/dialogs/user-settings/user-settings.component";
import { CUSTOM_DECLARATIONS } from "../../const/custom/components.const";
import { CUSTOM_IMPORTS } from "../../const/custom/imports.const";
import { CUSTOM_PROVIDERS } from "../../const/custom/providers.const";

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    HudAbstractModule,
    SharedUiMobileModule,
    ReactiveFormsModule,
    BuildingsModule,
    FormsModule,
    ...CUSTOM_IMPORTS,
  ],
  declarations: [
    HudResourcesComponent,
    HudComponent,
    HudOffsetTimeComponent,
    HudMenuComponent,
    HudParametersComponent,
    HudPlayerComponent,
    PlayerDetailsComponent,
    MediaComponent,
    UserSettingsComponent,
    HudMenuMoreComponent,
    ...CUSTOM_DECLARATIONS,
  ],
  exports: [HudComponent],
  providers: [...CUSTOM_PROVIDERS],
})
export class HudModule {}
