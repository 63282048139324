import { Actions, ActionTypes } from "./actions";
import { PlayerState } from "./state";

const initialState: PlayerState = {
  isLoading: false,
  player: null,
};

export function playerReducer(state = initialState, action: Actions): PlayerState {
  switch (action.type) {
    case ActionTypes.FETCH_PLAYER:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.FETCH_PLAYER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.TRY_UPDATE_PLAYER:
      return {
        ...state,
      };

    case ActionTypes.UPDATE_PLAYER:
      return {
        ...state,
        player: action.payload,
      };

    case ActionTypes.UPDATE_UNREAD_MESSAGES:
      return {
        ...state,
        player: {
          ...state.player,
          unread_messages: action.payload,
        },
      };

    default:
      return state;
  }
}
