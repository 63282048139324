import { Component, OnDestroy, OnInit } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AbstractCurrencyExchangeComponent } from "../../../../abstract/core/abstract-currency-exchange.component";

@Component({
  selector: "app-core-currency-exchange",
  templateUrl: "./currency-exchange.component.html",
})
export class CoreCurrencyExchangeComponent extends AbstractCurrencyExchangeComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.clearForm();
    this.subscribePrimary();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
