<loading></loading>
<loading [showAlways]="activeRedirect"></loading>

<div [class.is-loading]="activeRedirect">
  <div class="container loading-opacity" *ngIf="form">
    <ng-container [ngSwitch]="activeTemplate">
      <ng-container *ngSwitchCase="TEMPLATE.FORM0" [ngTemplateOutlet]="form0Tpl"></ng-container>
      <ng-container *ngSwitchCase="TEMPLATE.FORM1" [ngTemplateOutlet]="form1Tpl"></ng-container>
      <ng-container *ngSwitchCase="TEMPLATE.FORM2" [ngTemplateOutlet]="form2Tpl"></ng-container>
      <ng-container *ngSwitchCase="TEMPLATE.FORM3" [ngTemplateOutlet]="form3Tpl"></ng-container>
      <ng-container *ngSwitchCase="TEMPLATE.FORM4" [ngTemplateOutlet]="form4Tpl"></ng-container>
      <ng-container *ngSwitchCase="TEMPLATE.FORM5" [ngTemplateOutlet]="form5Tpl"></ng-container>
      <ng-container *ngSwitchCase="TEMPLATE.FORM6" [ngTemplateOutlet]="form6Tpl"></ng-container>
    </ng-container>
  </div>
</div>


<!-- (FORM 0) SET LOGIN & PASSWORD -->
<ng-template #form0Tpl>
  <!-- LOGO -->
  <div class="logo"></div>

  <form [formGroup]="form0" *ngIf="form0">
    <!-- LOGIN -->
    <p class="title">Twój login:</p>

    <div class="login">
      <ng-container *ngIf="form0.get('login') as login">
        <div class="control" [class.error]="getErrors(login)">
          <input class="control-input" formControlName="login" type="text"/>
          <div class="alert alert-danger" *ngIf="getErrors(login)">
            <span *ngIf="login.errors.required">Pole wymagane</span>
          </div>
        </div>
      </ng-container>
      <div class="control">
        <input style="pointer-events: none" class="control-input" formControlName="domain" type="text" readonly/>
      </div>
    </div>

    <!-- PASSWORD -->
    <p class="title password">Ustaw hasło:</p>
    <ng-container *ngIf="form0.get('password') as password">
      <div class="control" [class.error]="getErrors(password)">
        <div class="label-flex">
          <label>Nowe hasło</label>
        </div>
        <input class="control-input" formControlName="password" type="password"/>
        <div class="alert alert-danger" *ngIf="getErrors(password)">
          <span *ngIf="password.errors.required">Pole wymagane</span>
          <span *ngIf="password.errors.pattern">Hasło powinno zawierać minimum 8 znaków, <br> w tym 1 duża litera, 1 cyfra, 1 znak specjalny</span>
        </div>
      </div>
    </ng-container>

    <!-- PASSWORD REPEAT -->
    <ng-container *ngIf="form0.get('passwordRepeat') as passwordRepeat">
      <div class="control" [class.error]="getErrors(passwordRepeat)">
        <div class="label-flex">
          <label>Powtórz hasło</label>
        </div>
        <input class="control-input" formControlName="passwordRepeat" type="password"/>
        <div class="alert alert-danger" *ngIf="getErrors(passwordRepeat)">
          <span *ngIf="passwordRepeat.errors.required">Pole wymagane</span>
        </div>
        <p
          class="description invalid-form invalid-password error"
          *ngIf="form0.get('password').value && form0.get('passwordRepeat').value && form0.hasError('notSame')"
        >
          Podane hasła nie są identyczne.
        </p>
      </div>
    </ng-container>
  </form>

  <div class="buttons">
    <button
      class="base primary accept-form"
      (click)="submit()"
      [disabled]="form0.invalid"
    >
      Akceptuj
    </button>
  </div>
</ng-template>

<!-- (FORM 1) BASIC INFORMATION -->
<ng-template #form1Tpl>
  <!-- LOGO -->
  <div class="logo"></div>

  <p class="title">Dane podstawowe:</p>

  <form [formGroup]="form" *ngIf="form">
    <!-- FIRST NAME -->
    <ng-container *ngIf="form.get('firstName') as firstName">
      <div class="control" [class.error]="getErrors(firstName)">
        <div class="label-flex">
          <label>Imię</label>
        </div>
        <input class="control-input" formControlName="firstName" type="text"/>
        <div class="alert alert-danger" *ngIf="getErrors(firstName)">
          <span *ngIf="firstName.errors.required">Pole wymagane</span>
        </div>
      </div>
    </ng-container>

    <!-- LAST NAME -->
    <ng-container *ngIf="form.get('lastName') as lastName">
      <div class="control" [class.error]="getErrors(lastName)">
        <div class="label-flex">
          <label>Nazwisko</label>
        </div>
        <input class="control-input" formControlName="lastName" type="text"/>
        <div class="alert alert-danger" *ngIf="getErrors(lastName)">
          <span *ngIf="lastName.errors.required">Pole wymagane</span>
        </div>
      </div>
    </ng-container>

    <!-- PESEL -->
    <ng-container *ngIf="form.get('pesel') as pesel">
      <div class="control" [class.error]="getErrors(pesel)">
        <div class="label-flex">
          <label>PESEL</label>
        </div>
        <input class="control-input" formControlName="pesel" type="text"/>
        <div class="alert alert-danger" *ngIf="getErrors(pesel)">
          <span *ngIf="pesel.errors.minLength || pesel.errors.pattern">
            Pesel powinien zawierać 11 znaków i składać się z liczb
          </span>
          <span *ngIf="pesel.errors.required">Pole wymagane</span>
        </div>
      </div>
    </ng-container>

    <!-- PHONE -->
    <ng-container *ngIf="form.get('phone') as phone">
      <div class="control" [class.error]="getErrors(phone)">
        <div class="label-flex">
          <label>Numer telefonu</label>
        </div>
        <input class="control-input" formControlName="phone" type="text"/>
        <div class="alert alert-danger" *ngIf="getErrors(phone)">
          <span *ngIf="phone.errors.required">Pole wymagane</span>
          <span *ngIf="phone.errors.phoneNumberError">Numer telefonu powinien składać się max z 9 cyfr</span>
        </div>
      </div>
    </ng-container>

    <!-- EMAIL -->
    <ng-container *ngIf="form.get('privateEmail') as privateEmail">
      <div class="control" [class.error]="getErrors(privateEmail)">
        <div class="label-flex">
          <label>Adres e-mail prywatny</label>
        </div>
        <input class="control-input" formControlName="privateEmail" type="text"/>
        <div class="alert alert-danger" *ngIf="getErrors(privateEmail)">
          <span *ngIf="privateEmail.errors.email">Błędny adres e-mail</span>
          <span *ngIf="privateEmail.errors.required">Pole wymagane</span>
        </div>
      </div>
    </ng-container>

    <!-- BANK ACCOUNT NUMBER -->
    <ng-container *ngIf="form.get('bankAccount') as bankAccount">
      <div class="control" [class.error]="getErrors(bankAccount) || invalidIBAN">
        <div class="label-flex">
          <label>Numer konta bankowego</label>
        </div>
        <input
          class="control-input"
          formControlName="bankAccount"
          type="text"
          (focusout)="alertValidIBAN($event)"
          (keydown)="numberOnly($event)"
          (paste)="onPaste($event)"
        />
        <div class="alert alert-danger" *ngIf="invalidIBANLetters">
          <span>Skopiowany numer rachunku zawiera niedozwolony znak.</span>
        </div>
        <div class="alert alert-danger" *ngIf="getErrors(bankAccount)">
          <span *ngIf="bankAccount.errors.bankAccountError">Numer konta bankowego powinien składać się z 26 cyfr</span>
          <span *ngIf="bankAccount.errors.required">Pole wymagane</span>
        </div>
        <div class="alert alert-danger" *ngIf="invalidIBAN">
          <span>Nieprawidłowy numer konta bankowego</span>
        </div>
      </div>
    </ng-container>
  </form>

  <div class="buttons">
    <!-- EMPTY -->
    <div class="empty" *ngIf="!edit; else backToGameTpl"></div>

    <ng-template #backToGameTpl>
      <button class="base primary next" (click)="backToGame()">
        Anuluj
      </button>
    </ng-template>

    <!-- NEXT -->
    <button
      class="base primary next"
      (click)="changeTemplate(TEMPLATE.FORM2)"
      [disabled]="form.invalid || invalidIBAN"
    >
      Dalej
    </button>
  </div>
</ng-template>

<!-- (FORM 2) FORM OF EMPLOYMENT -->
<ng-template #form2Tpl>
  <!-- LOGO -->
  <div class="logo"></div>

  <p class="title">Forma zatrudnienia:</p>

  <div class="form-employ" *ngFor="let formEmployment of formEmployment">
    <button class="square checkbox no-before-shadow" (click)="setFormEmploymentId(formEmployment)">
      <i class="fas fa-circle" *ngIf="formEmployment.id === selectedFormEmploymentId"></i>
    </button>
    <p class="employee">{{ formEmployment.title }}</p>
  </div>
  <div class="buttons">
    <!-- BACK -->
    <button
      class="square normal primary prev"
      (click)="changeTemplate(TEMPLATE.FORM1)"
    >
      <i class="fas fa-angle-left"></i>
    </button>

    <!-- NEXT -->
    <button
      class="base primary next"
      (click)="checkFormEmployment()"
      [disabled]="!selectedFormEmploymentId"
    >
      Dalej
    </button>
  </div>
</ng-template>

<!-- (FORM 3) FORM OF EMPLOYMENT 1,2 -->
<ng-template #form3Tpl>
  <p class="title">Adres zamieszkania:</p>
  <form [formGroup]="form3" *ngIf="form3">
    <!-- ZIP CODE -->
    <ng-container *ngIf="form3.get('zipCode_1') as zipCode_1">
      <div class="control" [class.error]="getErrors(zipCode_1)">
        <div class="label-flex">
          <label>Kod pocztowy</label>
        </div>
        <input class="control-input" formControlName="zipCode_1" type="text"/>
        <div class="alert alert-danger" *ngIf="getErrors(zipCode_1)">
          <span *ngIf="zipCode_1.errors.pattern">Błędny kod pocztowy. Poprawna forma to 00-000</span>
          <span *ngIf="zipCode_1.errors.required">Pole wymagane</span>
        </div>
      </div>
    </ng-container>

    <!-- CITY -->
    <ng-container *ngIf="form3.get('city_1') as city_1">
      <div class="control" [class.error]="getErrors(city_1)">
        <div class="label-flex">
          <label>Miasto</label>
        </div>
        <input class="control-input" formControlName="city_1" type="text"/>
        <div class="alert alert-danger" *ngIf="getErrors(city_1)">
          <span *ngIf="city_1.errors.required">Pole wymagane</span>
        </div>
      </div>
    </ng-container>

    <!-- STREET -->
    <ng-container *ngIf="form3.get('street_1') as street_1">
      <div class="control" [class.error]="getErrors(street_1)">
        <div class="label-flex">
          <label>Ulica</label>
        </div>
        <input class="control-input" formControlName="street_1" type="text"/>
        <div class="alert alert-danger" *ngIf="getErrors(street_1)">
          <span *ngIf="street_1.errors.required">Pole wymagane</span>
        </div>
      </div>
    </ng-container>

    <div class="locale-number">
      <!-- HOUSE NUMBER -->
      <ng-container *ngIf="form3.get('houseNo_1') as houseNo_1">
        <div class="control" [class.error]="getErrors(houseNo_1)">
          <div class="label-flex">
            <label>Numer domu</label>
          </div>
          <input class="control-input" formControlName="houseNo_1" type="text"/>
          <div class="alert alert-danger" *ngIf="getErrors(houseNo_1)">
            <span *ngIf="houseNo_1.errors.required">Pole wymagane</span>
          </div>
        </div>
      </ng-container>

      <!-- LOCALE NUMBER -->
      <div class="control">
        <div class="label-flex">
          <label>Numer mieszkania</label>
        </div>
        <input class="control-input" formControlName="localeNo_1" type="text"/>
      </div>
    </div>

    <!-- DISTRICT -->
    <ng-container *ngIf="form3.get('district_1') as district_1">
      <div class="control" [class.error]="getErrors(district_1)">
        <div class="label-flex">
          <label>Województwo</label>
        </div>
        <ng-select
          [items]="territories"
          [formControlName]="'district_1'"
          [bindLabel]="'name'"
          [clearable]="false"
          [searchable]="true"
          (change)="districtChanged($event)"
        ></ng-select>

        <div class="alert alert-danger" *ngIf="getErrors(district_1)">
          <span *ngIf="district_1.errors.required">Pole wymagane</span>
        </div>
      </div>
    </ng-container>

    <!-- COUNTY -->
    <ng-container *ngIf="form3.get('county_1') as county_1">
      <div class="control" [class.error]="getErrors(county_1)">
        <div class="label-flex">
          <label>Powiat</label>
        </div>
        <ng-select
          [items]="getCounties()"
          [formControlName]="'county_1'"
          [bindLabel]="'name'"
          [clearable]="false"
          [searchable]="true"
          (change)="countyChanged($event)"
        ></ng-select>

        <div class="alert alert-danger" *ngIf="getErrors(county_1)">
          <span *ngIf="county_1.errors.required">Pole wymagane</span>
        </div>
      </div>
    </ng-container>

    <!-- BOROUGH -->
    <ng-container *ngIf="form3.get('borough_1') as borough_1">
      <div class="control" [class.error]="getErrors(borough_1)">
        <div class="label-flex">
          <label>Gmina</label>
        </div>

        <ng-select
          [items]="getCommunes()"
          [formControlName]="'borough_1'"
          [bindLabel]="'name'"
          [clearable]="false"
          [searchable]="true"
        ></ng-select>

        <div class="alert alert-danger" *ngIf="getErrors(borough_1)">
          <span *ngIf="borough_1.errors.required">Pole wymagane</span>
        </div>
      </div>
    </ng-container>

    <!-- TAX OFFICES -->
    <ng-container *ngIf="form3.get('tax_office') as taxOffice">
      <div class="control">
        <div class="label-flex">
          <label>Urząd skarbowy</label>
        </div>
        <ng-select
          [items]="taxOffices"
          [formControlName]="'tax_office'"
          [bindLabel]="'name'"
          [clearable]="false"
          [searchable]="true"
        ></ng-select>

        <div class="alert alert-danger" *ngIf="getErrors(taxOffice)">
          <span *ngIf="taxOffice.errors.required">Pole wymagane</span>
        </div>
      </div>
    </ng-container>
  </form>

  <div class="buttons">
    <!-- BACK -->
    <button
      class="square normal primary prev"
      (click)="changeTemplate(TEMPLATE.FORM2)"
    >
      <i class="fas fa-angle-left"></i>
    </button>

    <!-- NEXT -->
    <button
      class="base primary next"
      (click)="changeTemplate(TEMPLATE.FORM5)"
      [disabled]="form3.invalid"
    >
      Dalej
    </button>
  </div>
</ng-template>

<!-- (FORM 4) FORM OF EMPLOYMENT 3 -->
<ng-template #form4Tpl>
  <p class="title">Dane do faktury:</p>

  <form [formGroup]="form4" *ngIf="form4">
    <!-- NIP -->
    <ng-container *ngIf="form4.get('nip_2') as nip_2">
      <div class="control" [class.error]="getErrors(nip_2)">
        <div class="label-flex">
          <label>NIP</label>
        </div>
        <input class="control-input" formControlName="nip_2" type="text"/>

        <div class="alert alert-danger" *ngIf="getErrors(nip_2)">
          <span *ngIf="nip_2.errors.required">Pole wymagane</span>
          <span *ngIf="nip_2.errors.pattern">NIP powinien składać się z liczb</span>
        </div>
      </div>
    </ng-container>

    <!-- COMPANY NAME -->
    <ng-container *ngIf="form4.get('company_2') as company_2">
      <div class="control" [class.error]="getErrors(company_2)">
        <div class="label-flex">
          <label>Nazwa firmy</label>

        </div>
        <input class="control-input" formControlName="company_2" type="text"/>

        <div class="alert alert-danger" *ngIf="getErrors(company_2)">
          <span *ngIf="company_2.errors.required">Pole wymagane</span>
        </div>
      </div>
    </ng-container>

    <p class="sub-title">Adres firmy:</p>
    <!-- ZIP CODE 2 -->
    <ng-container *ngIf="form4.get('zipCode_2') as zipCode_2">
      <div class="control" [class.error]="getErrors(zipCode_2)">
        <div class="label-flex">
          <label>Kod pocztowy</label>
        </div>
        <input class="control-input" formControlName="zipCode_2" type="text"/>

        <div class="alert alert-danger" *ngIf="getErrors(zipCode_2)">
          <span *ngIf="zipCode_2.errors.required">Pole wymagane</span>
          <span *ngIf="zipCode_2.errors.pattern">Błędny kod pocztowy. Poprawna forma to 00-000</span>
        </div>
      </div>
    </ng-container>

    <!-- CITY 2 -->
    <ng-container *ngIf="form4.get('city_2') as city_2">
      <div class="control" [class.error]="getErrors(city_2)">
        <div class="label-flex">
          <label>Miasto</label>
        </div>
        <input class="control-input" formControlName="city_2" type="text"/>

        <div class="alert alert-danger" *ngIf="getErrors(city_2)">
          <span *ngIf="city_2.errors.required">Pole wymagane</span>
        </div>
      </div>
    </ng-container>

    <!-- STREET 2 -->
    <ng-container *ngIf="form4.get('street_2') as street_2">
      <div class="control" [class.error]="getErrors(street_2)">
        <div class="label-flex">
          <label>Ulica</label>
        </div>
        <input class="control-input" formControlName="street_2" type="text"/>

        <div class="alert alert-danger" *ngIf="getErrors(street_2)">
          <span *ngIf="street_2.errors.required">Pole wymagane</span>
        </div>
      </div>
    </ng-container>

    <div class="locale-number">
      <!-- HOUSE NUMBER -->
      <ng-container *ngIf="form4.get('houseNo_2') as houseNo_2">
        <div class="control" [class.error]="getErrors(houseNo_2)">
          <div class="label-flex">
            <label>Numer domu</label>
          </div>
          <input class="control-input" formControlName="houseNo_2" type="text"/>

          <div class="alert alert-danger" *ngIf="getErrors(houseNo_2)">
            <span *ngIf="houseNo_2.errors.required">Pole wymagane</span>
          </div>
        </div>
      </ng-container>

      <!-- LOCALE NUMBER -->
      <div class="control">
        <div class="label-flex">
          <label>Numer lokalu</label>
        </div>
        <input class="control-input" formControlName="localeNo_2" type="text"/>
      </div>
    </div>
  </form>

  <div class="buttons">
    <!-- BACK -->
    <button
      class="square normal primary prev"
      (click)="changeTemplate(TEMPLATE.FORM2)"
    >
      <i class="fas fa-angle-left"></i>
    </button>

    <!-- NEXT -->
    <button
      class="base primary next"
      (click)="changeTemplate(TEMPLATE.FORM5)"
      [disabled]="form4.invalid"
    >
      Dalej
    </button>
  </div>
</ng-template>

<!-- (FORM 5) STATEMENTS -->
<ng-template #form5Tpl>
  <!-- LOGO -->
  <div class="logo"></div>
  <div class="form-employ" *ngFor="let statement of statements">
    <ng-container *ngIf="statement.hideOnType !== selectedFormEmploymentId">
      <button
        class="square checkbox no-before-shadow"
        [disabled]="edit && statement.id === 2"
        (click)="submitStatement(statements, statement.id)"
      >
        <i class="fas fa-circle" *ngIf="statement.checked"></i>
      </button>
      <p class="employee" [class.opacity]="edit && statement.id === 2" [innerHTML]="statement.title"></p>
    </ng-container>
  </div>

  <div class="buttons">
    <!-- BACK -->
    <button
      class="square normal primary prev"
      (click)="checkFormEmploymentBack()"
    >
      <i class="fas fa-angle-left"></i>
    </button>

    <!-- NEXT -->
    <button
      class="base primary next"
      (click)="checkDataBeforePost()"
      [disabled]="!(statements[0].checked && statements[1].checked && (statements[2]['hideOnType'] === selectedFormEmploymentId ? true : statements[2].checked))"
    >
      <ng-container *ngIf="!edit">Rejestruj</ng-container>
      <ng-container *ngIf="edit">Zapisz</ng-container>
    </button>
  </div>
</ng-template>

<!-- (FORM 6) REGISTRATION SUCCESS -->
<ng-template #form6Tpl>
  <!-- LOGO -->
  <div class="logo"></div>

  <p class="desc">Dziękujemy za wypełnienie danych.</p>
  <p class="desc">Na Twój adres e-mail została wysłana wiadomość
    z linkiem aktywacyjnym. <br>
    Aby dokończyć rejestrację kliknij w link
    lub skopiuj go i wklej do przeglądarki internetowej.
  </p>
  <p class="desc">Jeśli e-mail z linkiem aktywacyjnym <br> do Ciebie nie dotarł, <br>
    prześlij wiadomość na adres <br>
    info@komptown.pl
  </p>
  <button class="base primary" (click)="backToLogin()">Powrót do logowania</button>
</ng-template>

