<ng-container *ngIf="playerService.player as player">
  <ng-container *ngIf="visible">
    <div class="options">
      <div class="center">
        <!-- CARD TASKS VENDORS -->
        <button
          class="business"
          (click)="openCardTaskVendors()"
          [matTooltip]="'Panel biznesowy'"
          matTooltipPosition="above"
          matTooltipShowDelay="300"
        >
          <img [src]="'business.png' | asset: 'ui'"/>
          <span>{{"business.panel" | myTranslate}} </span>
        </button>

        <!-- CURRENCY EXCHANGE -->
        <div
          class="click-space"
          (click)="openCurrencyExchange()"
          *ngIf="player.gui_unlocks['exchange']"
        >
          <button
            [matTooltip]="'currency-exchange.tooltip' | myTranslate"
            class="square small primary"
            matTooltipPosition="above"
            matTooltipShowDelay="300"
          >
            <img [src]="'exchange.png' | asset: 'ui'" />
          </button>
        </div>

        <!-- EVENT SHOPS -->
        <div
          class="click-space"
          (click)="openEventShopList()"
          *ngIf="player.gui_unlocks['event_shop']"
        >
          <button
            [matTooltip]="'Sklep eventowy'"
            class="square small primary"
            matTooltipPosition="above"
            matTooltipShowDelay="300"
            [disabled]="!playerService.isActiveMe"
          >
            <img [src]="'event_shop.png' | asset: 'ui'" />
          </button>
        </div>

        <!-- BRANCH PREVIEW -->
        <div
          class="click-space"
          (click)="openBranchPreview()"
          *ngIf="player.title_id.id === 2"
        >
          <button
            [matTooltip]="'branch-preview.tooltip' | myTranslate"
            class="square small primary"
            matTooltipPosition="above"
            matTooltipShowDelay="300"
          >
            <img [src]="'people.png' | asset: 'ui'" />
          </button>
        </div>

        <!-- QA -->
        <div
          class="click-space qa-btn"
          (click)="openQaList()"
          *ngIf="player.gui_unlocks['qa']"
        >
          <button
            matTooltip="Quizy"
            class="square small primary"
            matTooltipPosition="above"
            matTooltipShowDelay="300"
            [disabled]="!playerService.isActiveMe"
          >
            <img [src]="'quiz.png' | asset: 'ui'" />
          </button>

          <!-- NOTIFICATION -->
          <ng-container
            *ngIf="qaService.qaWithoutAnswer[QA_TYPE.QUIZ][LOCATION_QA] || qaService.qaWithoutAnswer[QA_TYPE.SURVEY][LOCATION_QA] || player?.gui_notifications?.qa"
            [ngTemplateOutlet]="notificationTpl"
          ></ng-container>
        </div>

        <!-- RANKS -->
        <div
          class="click-space"
          (click)="openRankList()"
          *ngIf="player.gui_unlocks['ranks']"
        >
          <button
            matTooltip="Rankingi"
            class="square small primary"
            matTooltipPosition="above"
            matTooltipShowDelay="300"
          >
            <img [src]="'rankings.png' | asset: 'ui'" />
          </button>
        </div>

        <!-- NAVIGATION -->
      </div>
    </div>


  </ng-container>
</ng-container>

<ng-template #notificationTpl>
  <div class="icon notification"></div>
</ng-template>
