import { Directive, Injectable } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as moment from "moment";
import { tap } from "rxjs/operators";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { SynchronizeTimeService } from "../../../../../../core/providers/synchronize-time.service";
import { EventEmitterDialogsService } from "../../../../../../core/services/core/event-emitter-dialogs.service";
import { PlayerService } from "../../../../../player/providers/player.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../shared-ui/mobile/consts/stock-view.const";
import { ApiQaService } from "../../api/core/services/api-qa.service";
import { EVENT_DIALOGS_NAMES_QA } from "../../consts/core/event-dialogs/event-names.const";
import { QA_TYPE } from "../../consts/core/qa-type.const";
import { Qa, QaAnswer, QaQuestion } from "../../interfaces/core/qa.interface";

@Directive()
@Injectable()
export abstract class AbstractQaDetailsComponent extends AbstractInjectBaseComponent {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiQaService) apiQaService: ApiQaService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractQaDetailsComponent>;
  @OwInject(SynchronizeTimeService) synchronizeTimeService: SynchronizeTimeService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(MAT_DIALOG_DATA) data: {
    qa: Qa,
    prevState: {
      location,
      type,
      eachQaId?: number,
    },
    isOpenFromMockUpTask?: boolean,
  };

  QA_TYPE = QA_TYPE;
  STOCK_VIEW = STOCK_VIEW;
  qa: Qa;
  currentQuestionIndex = 1;
  textAnswers: string[] = [];
  timer;
  intervalTimer;

  setCurrentQa() {
    this.qa = this.data.qa;

    if (this.qa.last_attempt) {
      this.setTimer();
      this.setTextAnswers();
    }
  }

  setTextAnswers() {
    const questions = this.qa.last_attempt.questions;
    for (let i = 0; i < questions.length; i++) {
      if (questions[i].is_open) {
        this.textAnswers[i] = questions[i].qa_text_answer;
      }
    }
  }

  nextQuestion() {
    const questions = this.qa.last_attempt.questions;
    if (this.currentQuestionIndex < questions.length) {
      this.currentQuestionIndex++;
    }
    this.setTextAnswers();
  }

  checkNextQuestion() {
    if (this.qa.last_attempt && !this.qa.last_attempt.finished_at) {
      this.checkQaQuestionHaveTextAnswer(this.nextQuestion.bind(this));
    } else if (this.qa.all_questions || this.qa.last_attempt.finished_at) {
      this.currentQuestionIndex++;
    }
  }

  checkFinishQa() {
    this.checkQaQuestionHaveTextAnswer(this.finishQa.bind(this));
  }

  checkQaQuestionHaveTextAnswer(callback) {
    const currentQuestion = this.qa.last_attempt.questions[this.currentQuestionIndex - 1];
    if (currentQuestion.is_open) {
      const textAnswer = this.textAnswers[this.currentQuestionIndex - 1];
      if (!textAnswer) {
        this.dialogService.openAlert({ description: "Odpowiedź wymagana" });
      } else if (currentQuestion.qa_text_answer === textAnswer) {
        callback();
      } else {
        this.submitTextAnswer(currentQuestion, textAnswer).subscribe(() => {
          callback();
        });
      }
    } else {
      callback();
    }
  }

  prevQuestion() {
    this.currentQuestionIndex--;
  }

  submitAnswer(question: QaQuestion, answer: QaAnswer) {
    this.apiQaService
      .submitQaAnswer({
        qa_id: this.qa.qa_id,
        qa_question_id: question.qa_question_id,
        qa_answer_id: answer.qa_answer_id,
        type: this.qa.type,
      })
      .subscribe(() => {
        this.selectedAnswer(question, answer);
      });
  }

  submitTextAnswer(question: QaQuestion, textAnswer) {
    return this.apiQaService
      .submitQaAnswer({
        qa_id: this.qa.qa_id,
        qa_question_id: question.qa_question_id,
        qa_text_answer: textAnswer,
        type: this.qa.type,
      })
      .pipe(
        tap(() => {
          question.qa_text_answer = textAnswer;
        })
      );
  }

  selectedAnswer(question: QaQuestion, answer: QaAnswer) {
    question.selected_answer = answer;
  }

  openFinishDialog(backToList: boolean) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_QA.QA_FINISH,
      config: {
        disableClose: true,
        data: {
          qa: this.qa,
          prevState: this.data.prevState,
          backToList,
        },
      },
    });
  }

  finishQa() {
    const finishQa = this.apiQaService.finishQa({
      qa_id: this.qa.qa_id,
      type: this.qa.type,
    });
    finishQa.subscribe(resp => {
      this.qa = resp;
      this.seeScore(true);
    });
  }

  seeScore(backToList: boolean) {
    this.closeWindow();
    setTimeout(() => {
      this.openFinishDialog(backToList);
    });
  }

  closeWindow() {
    this.matDialogRef.close();
  }

  setTimer() {
    this.intervalTimer = setInterval(() => {
      const actualDate = this.synchronizeTimeService.getActualLocalTime();
      const startedAt = moment(this.qa.last_attempt.started_at);
      this.timer = moment(actualDate).diff(moment(startedAt), "seconds");
      if (this.qa.last_attempt.finished_at) {
        clearInterval(this.intervalTimer);
      }
    }, 1000);
  }

  openExplanation(answer: QaAnswer) {
    this.dialogService.openAlert({
      description: answer.explanation,
    });
  }

  openImage(image: string) {
    this.dialogService.openAlertImage({
      image,
    });
  }

  clearInterval() {
    clearInterval(this.intervalTimer);
  }
}
