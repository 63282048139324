import {Component, OnInit} from '@angular/core';

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../../../shared-ui/mobile/consts/stock-view.const";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { ContestCard } from "../../../../interfaces/custom/card-task.interface";
import {ContractsDetailsComponent} from '../contracts-details/contracts-details.component';
import {RankingBusinessDetails} from '../../../../../rankings/base/custom/dialogs/rank-business-details/ranking-business-details.component';

@Component({
  selector: "app-card-task-contest",
  templateUrl: "./card-task-contest.component.html",
})
export class CardTaskContestComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MAT_DIALOG_DATA) data: {
    cardTask: ContestCard;
    cardTasks?: ContestCard[];
    year: number;
    month: number;
    index: number;
  };
  index = 0;

  ngOnInit() {
    this.index = this.data.index;
  }

  prev() {
    if (this.data.cardTasks[this.index - 1]) {
      this.index--;
    } else {
      this.index = this.data.cardTasks.length - 1;
    }
  }

  next() {
    if (this.data.cardTasks[this.index + 1]) {
      this.index++;
    } else {
      this.index = 0;
    }
  }

  openRankList() {
    this.dialogService.open(RankingBusinessDetails, {
      data: {
        rank: {
          task_definition_id: this.data.cardTasks[this.index]?.id,
          year: this.data.year,
          month: this.data.month,
          type: "CONTEST",
        },
      },
    });
  }

  protected readonly STOCK_VIEW = STOCK_VIEW;
}
