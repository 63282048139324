import { Actions, ActionTypes } from "./actions";
import { UserState } from "./state";

const initialState: UserState = {
  isLoading: false,
  me: null,
  error: null,
};

export function userReducer(state = initialState, action: Actions): UserState {
  switch (action.type) {
    case ActionTypes.FETCH_USER:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case ActionTypes.FETCH_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        me: null,
        error: action.payload,
      };

    case ActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        me: action.payload,
      };

    default:
      return state;
  }
}
