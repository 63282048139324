import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Swiper, SwiperOptions } from "swiper/types";

import { swiperInjectionStyles } from "../../../../../../../../../styles/swiper/swiper-injection-styles";
import { AbstractMissionsComponent } from "../../../../abstract/core/abstract-missions.component";

@Component({
  selector: "app-custom-missions",
  templateUrl: "../../../core/dialogs/missions/missions.component.html",
})
export class MissionsComponent extends AbstractMissionsComponent implements OnInit {
  swiper: Swiper;
  @ViewChild("swiperRef", { static: false })
  swiperRef: ElementRef | undefined;
  config: SwiperOptions = {
    slidesPerView: 1,
    initialSlide: 0,
    centeredSlides: true,
    grabCursor: true,
    pagination: {
      clickable: true,
    },
    injectStyles: [swiperInjectionStyles],
  };

  ngOnInit() {
    this.baseInit();
  }

  afterMissionsRequest() {
    this.filterWithoutSlotType(1);
    this.setCurrencyAndProducts();
    this.checkMissionComplete();
    this.setDiffTimes();
    this.sortMissions();
    this.normalMissions.pages = [];
    this.normalMissions.items = this.missions;
    this.generatePages();
    this.setSwiper();
  }

  filterWithoutSlotType(missionSlotType: number) {
    this.missions = this.missions.filter(mission => {
      return mission.slot.mission_slot_id !== missionSlotType || mission.conditions_completed === mission.conditions_count;
    });
  }

  sortMissions() {
    this.missions = this.missions.filter(mission => mission.slot.mission_slot_id > 1);
  }
}
