import { Injectable } from "@angular/core";

import { GlobalService } from "../../../../../../../core/providers/global.service";
import { GAME_EVENTS } from "../../../../../constants";
import { BASIC_BOOT_SCENE } from "../../../../../game-engine/scenes-basic/basic.constants";

@Injectable({
  providedIn: "root",
})
export class ChangeMapService {
  subs = {
    playerRegions: null,
  };

  constructor(public globalService: GlobalService) {
  }

  goToCity() {
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: BASIC_BOOT_SCENE,
    });
  }
}
