import { Injectable } from "@angular/core";

import { ApiService } from "../../../../../../../core/providers/api.service";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { EVENT_DIALOGS_NAMES_WAREHOUSE } from "../../../consts/core/event-dialogs/event-names.const";

@Injectable({
  providedIn: "root",
})
export class ApiStorageService {
  constructor(public apiService: ApiService, public playerService: PlayerService) {}

  storagePlayer({ playerId }: { playerId: number }) {
    playerId = playerId || this.playerService.getActivePlayerId();
    return this.apiService.get(`storage/player/${playerId}`);
  }

  productDetails({ productId }: { productId: number }) {
    return this.apiService.get(`products/${productId}`, {}, EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE_DETAILS);
  }

  storage() {
    return this.apiService.get(`storage`, {}, EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE);
  }
}
