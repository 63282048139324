import { Component } from "@angular/core";
import { CoreAbstractHistoryTransactionBaseComponent } from "../../../../abstract/core/abstract-history-transaction-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { ApiWithdrawTalarsService } from "../../../../api/custom/services/api-withdraw-talars.service";
import { UserService } from "../../../../../../../user/providers/user.service";
import { UserActions } from "../../../../../../../../store/user";
import { Store } from "@ngrx/store";
import { AppState } from "../../../../../../../../store/state";

@Component({
  selector: "app-transaction-history",
  templateUrl: "./transaction-history.component.html",
})
export class TransactionHistory extends CoreAbstractHistoryTransactionBaseComponent {
  @OwInject(ApiWithdrawTalarsService) apiWithdrawTalarsService: ApiWithdrawTalarsService;
  @OwInject(UserService) userService: UserService;
  @OwInject(Store) store: Store<AppState>;

  limit = 8;

  openAlertConfirm() {
    this.dialogService.openConfirm(
      {
        title: "Uwaga",
        description:
          "Upewnij się, że numer Twojego konta jest aktualny oraz sprawdź zadeklarowaną formę współpracy w celu prawidłowego rozliczenia podatku z tytułu otrzymanej nagrody. <br> Czy na pewno chcesz dokonać transkacji?",
      },
      confirm => {
        if (confirm) {
          this.withdrawCurrency();
        }
      }
    );
  }

  withdrawCurrency() {
    this.apiWithdrawTalarsService.postWithdrawTalars().subscribe(
      () => {
        this.store.dispatch(new UserActions.FetchUser());
        this.dialogService.closeAll();
        setTimeout(() => {
          this.dialogService.openAlert(
            {
              title: "Sukces!",
              description: "Na podany przez Ciebie numer rachunku wkrótce przelejemy środki.",
            },
            () => {
              this.openAlertInfo();
            }
          );
        });
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  openAlertInfo() {
    setTimeout(() => {
      this.dialogService.openAlert({
        title: "Ważne!",
        description:
          "Pamiętaj, jeśli zadeklarowałeś, że prowadzisz działalność, dolicz wartość nagrody do przychodu z działalności w miesiącu otrzymania nagrody. Natomiast jeśli pracujesz na etacie, do końca lutego 2025 otrzymasz od nas PIT 11 z wartością otrzymanych nagród w roku 2024, którą to wartość należy wykazać w rocznym rozliczeniu podatkowym.",
      });
    });
  }

  openRedemptionInfo() {
    this.dialogService.openAlert({
      description: "Kwota do potrącenia w kolejnym miesiącu z tytułu odstąpienia",
    });
  }
}
