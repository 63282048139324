import { Directive, Injectable } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { translate } from "../../../../../../core/helpers/translate.helper";
import { EventEmitterDialogsService } from "../../../../../../core/services/core/event-emitter-dialogs.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { EVENT_DIALOGS_NAMES_QA } from "../../consts/core/event-dialogs/event-names.const";
import { QA_TYPE } from "../../consts/core/qa-type.const";
import { Qa } from "../../interfaces/core/qa.interface";

@Directive()
@Injectable()
export abstract class AbstractQaFinishComponent extends AbstractInjectBaseComponent {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractQaFinishComponent>;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(MAT_DIALOG_DATA) data: {
    qa: Qa,
    prevState: {
      location,
      type,
      eachQaId,
    },
    backToList: boolean,
    isOpenFromMockUpTask?: boolean,
  };

  qa: Qa;
  separatorTitle: string;

  setQa() {
    this.qa = this.data.qa;
    this.setSeparatorTitle();
  }

  backToList() {
    if (this.data.backToList) {
      this.dialogService.closeAll();
      this.openQaList();
    } else {
      this.matDialogRef.close();
    }
  }

  setSeparatorTitle() {
    const translateKey = this.qa.type === QA_TYPE.SURVEY ? "qa.qa-finish.your-prize" : "qa.qa-finish.your-score";

    this.separatorTitle = translate(translateKey);
  }

  openQaList() {
    setTimeout(() => {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_QA.QA_LIST,
        config: {
          data: {
            qa: this.qa,
            prevState: this.data.prevState,
            eachQaId: this.data.prevState.eachQaId,
          },
        },
      });
    });
  }
}
