<div class="top-left-container">
  <!-- HUD PLAYER LEVEL -->
  <app-hud-player-level></app-hud-player-level>

  <!-- HUD PARAMETERS -->
  <div class="hud-parameters">
    <template #hudParametersTpl></template>
  </div>
</div>

<!-- HUD RESOURCES -->
<div class="hud-resources">
  <template #hudResourcesTpl></template>
</div>

<!-- HUD OFFSET TIME -->
<app-hud-offset-time [player]="player"></app-hud-offset-time>

<!-- HUD MENU -->
<div class="hud-menu">
  <template #hudMenuTpl></template>
</div>

<!-- OTHER DYNAMICS COMPONENT -->
<template #dynamicsComponentTpl></template>
