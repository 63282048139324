<!-- SEPARATOR -->
<ng-container *ngIf="separator">
  <m-ui-separator [title]="separator"></m-ui-separator>
</ng-container>

<!-- REQUIRED TEXT -->
<div class="texts-container" *ngIf="textsList.length">
  <span
    *ngFor="let item of textsList;
    let last = last"
    class="text"
    [class.text-error]="!item.valid"
  >
    {{ item.text }}{{ !last ? ',' : ''}}
  </span>
</div>

<div class="flex-center">
  <!-- CURRENCIES OR PRODUCTS -->
  <ng-container *ngIf="currencies || products">
    <m-ui-costs
      [currencies]="currencies"
      [products]="products"
      [stockView]="STOCK_VIEW.D"
      (eventEmitter)="checkCompleted($event)"
    ></m-ui-costs>
  </ng-container>

  <!-- PARAMETERS OUTCOME -->
  <div
    *ngIf="parametersOutcomeFormatted"
    class="parameters-outcome-costs"
  >
    <m-ui-parameter
      *ngFor="let parameter of parametersOutcomeFormatted"
      [item]="parameter"
      [stockView]="STOCK_VIEW.D"
      [lack]="true"
    ></m-ui-parameter>
  </div>
</div>
