import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { CurrencyService } from "../../../../../../../../core/providers/currency.service";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { UserService } from "../../../../../../../user/providers/user.service";
import { EVENT_DIALOGS_NAMES_TRANSACTION_HISTORY } from "../../../../../transaction-history/consts/core/event-dialogs/event-names.const";
import { EventShop } from "../../../../api/custom/interfaces/response/get-event-shops.interface";
import { EventShopProduct } from "../../../../api/custom/interfaces/response/get-event-shops-products.interface";
import { ApiEventShopService } from "../../../../api/custom/services/api-event-shop.service";
import { EVENT_DIALOGS_NAMES_EVENT_SHOP } from "../../../../consts/custom/event-dialogs/event-names.const";

@Component({
  selector: "event-shop-list",
  templateUrl: "./event-shop-details.html",
})
export class EventShopDetails extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiEventShopService) apiEventShopService: ApiEventShopService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MAT_DIALOG_DATA) data: { eventShop: EventShop };
  @OwInject(CurrencyService) currencyService: CurrencyService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(UserService) userService: UserService;

  products: EventShopProduct[];

  ngOnInit() {
    this.getEventShopsProducts();
  }

  getEventShopsProducts() {
    this.apiEventShopService.getEventShopsProducts({ event_shop_id: this.data.eventShop.event_shop_id }).subscribe(
      resp => {
        this.products = resp;
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  openHistoryTransaction(currency) {
    if (currency.show_currency_history) {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_TRANSACTION_HISTORY.HISTORY,
        config: {
          data: {
            currency,
            showBackBar: true,
          },
        },
      });
    }
  }

  openEventShopProductDetails(product: EventShopProduct) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_EVENT_SHOP.PRODUCT_DETAILS,
      config: {
        data: {
          eventShop: this.data.eventShop,
          product: product,
        },
      },
      callback: value => {
        if (value) {
          this.ngOnInit();
        }
      },
    });
  }
}
