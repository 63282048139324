import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";

import { environment } from "../../../environments/environment";
import { AUTH_ROUTING_ABSOLUTE_PATH } from "../../modules/auth/consts/core/routing-auth-absolute.const";
import { ReauthorizeService } from "../../modules/auth/services/core/reauthorize.service";
import { PlayerService } from "../../modules/player/providers/player.service";
import { LoginActions } from "../../store/auth/login";
import { SocketActions } from "../../store/socket";
import { AppState } from "../../store/state";
import { EVENTS } from "../consts/core/events";
import { GlobalEvent } from "../interfaces/shared.interfaces";
import { isTokenExpired } from "../utility/is-expired-token.helper";
import { getToken } from "../utility/token";
import { GlobalService } from "./global.service";
import { SynchronizeTimeService } from "./synchronize-time.service";

const io = require("socket.io-client");

const config = {
  autoConnect: false,
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 1000,
};

@Injectable({
  providedIn: "root",
})
export class WebSocketService {
  public socket;
  wasEverConnected: boolean;
  MAX_RECONNECT_ATTEMPTS = 5;
  globalEvents: EventEmitter<GlobalEvent> = this.globalService.globalEvents;

  constructor(
    private globalService: GlobalService,
    private synchronizeTimeService: SynchronizeTimeService,
    private playerService: PlayerService,
    private store: Store<AppState>,
    private router: Router,
    private reauthorizeService: ReauthorizeService
  ) {}

  createSocketInstance() {
    if (isTokenExpired()) {
      this.socket = null;
      this.store.dispatch(new LoginActions.AuthLogout());
    } else {
      // set-config is not parsing bool correctly
      this.socket = io(environment?.wsUrl != 'unknown' ? environment.wsUrl : `wss://${window.location.host}`, {
        ...config,
        auth: {
          token: getToken().token,
        },
      });
      this.overrideOnEventMethod();
      this.setSocketEventListeners();
      this.setSocketEventListenersManager();
    }
  }

  connect() {
    this.createSocketInstance();
    if (this.socket) {
      this.store.dispatch(new SocketActions.SocketConnecting());
      this.socket.connect();
    }
  }

  disconnect() {
    if (this.socket) {
      this.store.dispatch(new SocketActions.SocketDisconnected());
      this.socket.disconnect();
    }
  }

  overrideOnEventMethod() {
    const originalOnEvent = this.socket["onevent"];
    this.socket["onevent"] = packet => {
      const args = packet.data || [];
      originalOnEvent.call(this.socket, packet); // original call

      packet.data = ["*"].concat(args);
      originalOnEvent.call(this.socket, packet); // additional call to catch-all
    };
  }

  setSocketEventListeners() {
    this.socket.on(EVENTS.GLOBAL.TIMESYNC, data => {
      this.synchronizeTimeService.setActualServerTime(data);
    });

    this.socket.on(EVENTS.GLOBAL.RELOAD_GAME, data => {
      this.globalEvents.emit({ name: EVENTS.GLOBAL.RELOAD_GAME });
      this.disconnect();
    });

    this.socket.on(EVENTS.GLOBAL.UNAUTHORIZED, data => {
      this.store.dispatch(new LoginActions.AuthLogout());
    });

    this.socket.on(EVENTS.GLOBAL.OTHER_SESSION, (event, data) => {
      this.store.dispatch(new SocketActions.SocketOtherSession());
    });

    this.socket.on(EVENTS.GLOBAL.DISCONNECT, (event, data) => {
      if (event === "io client disconnect") {
        return;
      }

      this.store.dispatch(new SocketActions.SocketDisconnected());

      if (document.hidden) {
        this.logicRedirect();
      }
    });

    this.socket.on(EVENTS.GLOBAL.CONNECT, () => {
      this.store.dispatch(new SocketActions.SocketAuthenticated());
      this.setEverConnected();
    });

    this.socket.on(EVENTS.GLOBAL.CONNECT_ERROR, () => {
      this.store.dispatch(new LoginActions.AuthLogout());
    });

    this.socket.onAny((event, data) => {
      if (data && this.playerService.checkIsActiveMyById(data.player_id)) {
        const eventData = data.data;
        console.log("data", data, "event", event);
        this.emitSignal(event, eventData);
      }
    });
  }

  setSocketEventListenersManager() {
    this.socket.io.on(EVENTS.GLOBAL.RECONNECT_ATTEMPT, (attempt: number) => {
      this.store.dispatch(new SocketActions.SocketReconnectAttempt({ attempt }));

      if (attempt > this.MAX_RECONNECT_ATTEMPTS && this.wasEverConnected) {
        this.logicRedirect();
      }
    });
  }

  logicRedirect() {
    if (this.isAllowToReauthorize()) {
      this.reauthorizeService.redirectToReauthorizeAndDisconnect();
    } else {
      this.redirectAndDisconnect(AUTH_ROUTING_ABSOLUTE_PATH.SOCKET_ERROR_CONNECTION);
    }
  }

  isAllowToReauthorize() {
    return this.reauthorizeService.isAllowReauthorize();
  }

  redirectAndDisconnect(url: string) {
    this.disconnect();
    this.router.navigate([url]);
  }

  emitAuthenticate() {
    this.store.dispatch(new SocketActions.SocketAuthenticating());
    const token = getToken().token;

    this.socket.emit(EVENTS.GLOBAL.AUTHENTICATE, { token }, ({ error }: { error: boolean }) => {
      if (error) {
        this.store.dispatch(new LoginActions.AuthLogout());
      } else {
        this.store.dispatch(new SocketActions.SocketAuthenticated());
        this.setEverConnected();
      }
    });
  }

  emitSignal(signal, data) {
    const allowed = [
      EVENTS.GLOBAL.TILE_CHANGED,
      EVENTS.GLOBAL.UNREAD_COUNT,
      EVENTS.GLOBAL.NEW_IMPORTANT_MESSAGE,
      EVENTS.GLOBAL.SET_PLAYER, // 'player'
      EVENTS.GLOBAL.RELOAD_PLAYER,
      EVENTS.GLOBAL.MISSION_COMPLETED,
      EVENTS.GLOBAL.PRODUCTION_STARTED,
      EVENTS.GAME.REGION_UPDATE,
      EVENTS.GLOBAL.SHIP_UPDATE,
      EVENTS.GLOBAL.PLAYER_MISSION_SHOW_DETAILS,
      EVENTS.GLOBAL.NEW_PRODUCTS_AT_LEVEL,
      EVENTS.GLOBAL.PLAYER_MISSION_CONDITIONS_CHANGED,
    ];

    const aliases = {
      [EVENTS.GLOBAL.RELOAD_PLAYER]: EVENTS.GLOBAL.SET_PLAYER,
    };

    if (allowed.indexOf(signal) > -1) {
      if (aliases.hasOwnProperty(signal)) {
        signal = aliases[signal];
      }
      if (!data) {
        data = {};
      }
      this.globalEvents.emit({ name: signal, value: data });
    } else {
      console.log("Signal not allowed: " + signal);
    }
  }

  setEverConnected() {
    this.wasEverConnected = true;
  }
}
