import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { select, Store } from "@ngrx/store";
import * as moment from "moment";
import { Moment } from "moment";
import { Observable, of } from "rxjs";
import { catchError, take, tap } from "rxjs/operators";

import { APP_ROUTING_ABSOLUTE_PATH } from "../../consts/routing-app-absolute.const";
import { AUTH_ROUTING_ABSOLUTE_PATH } from "../../modules/auth/consts/core/routing-auth-absolute.const";
import { TokenObject } from "../../modules/auth/interfaces/token-object";
import { AuthService } from "../../modules/auth/providers/auth.service";
import { GuiService } from "../../modules/game/services/gui.service";
import { AuthLogout } from "../../store/auth/login/actions";
import { AppState } from "../../store/state";
import { UtilitySelectors } from "../../store/utility";
import { ApiTimesyncService } from "../api/timesync/services/api-timesync.service";
import { SynchronizeTimeService } from "../providers/synchronize-time.service";
import { isTokenExpired } from "../utility/is-expired-token.helper";
import { getToken } from "../utility/token";

@Injectable({
  providedIn: "root",
})
export class InitGuard implements CanActivate {
  static isSynchronizedTime: boolean = null;
  static isValidatedToken: boolean = null;
  resolve;
  tokenObject: TokenObject;
  state: RouterStateSnapshot;
  currentDate: Moment;

  constructor(
    private guiService: GuiService,
    private apiTimesyncService: ApiTimesyncService,
    private synchronizeTimeService: SynchronizeTimeService,
    private store: Store<AppState>,
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.state = state;

    const promise: Promise<boolean> = new Promise(resolve => {
      this.resolve = resolve;
    });

    this.tokenObject = getToken();
    const isTokenExist = this.tokenObject.token;

    this.guiService.isSplashShow.next(true);

    if (isTokenExist) {
      if (InitGuard.isSynchronizedTime) {
        this.checkTokenLogic();
      } else {
        this.apiTimesyncService
          .getTimesync()
          .pipe(
            tap(() => {
              InitGuard.isSynchronizedTime = true;
            }),
            catchError(() => {
              return of();
            })
          )
          .subscribe({
            complete: () => {
              this.checkTokenLogic();
            },
          });
      }
    } else {
      if (this.state.url.includes(APP_ROUTING_ABSOLUTE_PATH.AUTH)) {
        this.router.navigate([AUTH_ROUTING_ABSOLUTE_PATH.LOGIN]);
      } else {
        this.end(true);
      }
    }

    return promise;
  }

  end(value: boolean) {
    this.guiService.isSplashShow.next(false);
    this.resolve(value);
  }

  checkTokenLogic() {
    this.currentDate = moment(this.synchronizeTimeService.getActualLocalTime());

    if (isTokenExpired()) {
      this.store.dispatch(new AuthLogout());
    } else {
      if (InitGuard.isValidatedToken) {
        this.afterValidateRequest();
      } else {
        this.authService
          .validate()
          .pipe(
            tap(() => {
              InitGuard.isValidatedToken = true;
            })
          )
          .subscribe(() => {
            this.afterValidateRequest();
          });
      }
    }
  }

  afterValidateRequest() {
    this.store.pipe(select(UtilitySelectors.selectTokenObject), take(1)).subscribe(freshTokenObject => {
      const { reauthorize_after } = freshTokenObject;
      let isAllowReauthorize = false;

      if (reauthorize_after) {
        const reauthorizeAfterDate = moment(reauthorize_after);
        isAllowReauthorize = this.currentDate.isAfter(reauthorizeAfterDate);
      }

      if (isAllowReauthorize) {
        this.authService.reauthorize().subscribe(() => {
          this.afterValidateRequest();
        });
      } else {
        this.end(true);
      }
    });
  }
}
