import { Directive, Injectable, Input } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { translate } from "../../../../../core/helpers/translate.helper";

@Directive()
@Injectable()
export abstract class AbstractGroupCountComponent {
  event: {
    valid: boolean;
    message: string;
  } = {
    valid: false,
    message: "",
  };

  building: any;
  event$ = new BehaviorSubject(null);

  @Input("building") set Building(building) {
    if (building != null && typeof building === 'object') {
      this.building = building;
      this.checkValid();
      this.sendEvent();
    }
  }

  checkValid() {
    this.event.valid = this.building.group_count < this.building.group_limit;
    this.event.message = translate("group-count.building-group-limit", [this.building.group_limit]);
  }

  sendEvent() {
    this.event$.next(this.event);
  }
}
