<div class="global-dialog">
  <loading [classNames]="['white', 'stroke']" [size]="'big'"></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <div [hidden]="!(normalMissions.items.length)">
    <!-- NAVIGATION CENTER -->
    <m-ui-navigation-center
      *ngIf="swiper"
      [hidePrev]="swiper?.isBeginning"
      [hideNext]="swiper?.isEnd"
      (prevClicked)="prevSlide()"
      (nextClicked)="nextSlide()"></m-ui-navigation-center>

    <swiper-container *ngIf="initSwiper" #swiperRef swiperElement [config]="config" init="false">
      <swiper-slide class="missions" *ngFor="let page of normalMissions.pages">
        <div
          class="ow-dialog window-b primary"
          *ngFor="let mission of page"
          (click)="openMissionDetails(mission)"
          [class.mission-completed]="mission.conditions_count > 0 && mission.conditions_count === mission.conditions_completed">
          <!-- MISSION REJECTED (TIMER) CONDITION -->
          <ng-container *ngIf="mission.status === 'rejected'; else missionTpl">
            <p class="title">
              {{ "missions.next-mission-available" | myTranslate }}
            </p>
            <p class="title">
              {{ mission["diffTime"] === "--:--:--" ? mission["diffTime"] : (mission["diffTime"] | toHour) }}
            </p>
          </ng-container>

          <!-- MISSION TEMPLATE -->
          <ng-template #missionTpl>
            <!-- MISSION ICON -->
            <div class="mission-icon">
              <img [src]="mission.icon + '.png' | asset : 'missions/board-main'" />
            </div>

            <!-- NEW MISSION -->
            <div class="new" *ngIf="!mission.seen">
              <span>{{ "missions.new-mission-label" | myTranslate }}</span>
            </div>

            <!-- MISSION COMPLETED -->
            <div class="check-completed"></div>

            <!-- TITLE -->
            <p class="title">
              {{ mission.title }}
            </p>

            <!-- PRIZES -->
            <div class="prizes">
              <!-- EACH PRIZE -->
              <!-- POINT PRIZE -->
              <m-ui-stock-other
                *ngIf="mission.points_prize"
                [item]="{ icon: 'exp', amount: mission.points_prize }"
                [other]="{ value: '+' }"
                [lack]="false"
                [stockView]="STOCK_VIEW.A"></m-ui-stock-other>

              <!-- CURRENCIES PRIZE -->
              <m-ui-currency
                *ngFor="let currency of mission.currency_prizes"
                [item]="currency"
                [other]="{ value: '+' }"
                [lack]="false"
                [stockView]="STOCK_VIEW.A"></m-ui-currency>

              <!-- GIFT PRIZE -->
              <m-ui-stock-other
                *ngIf="mission.product_prizes_count"
                [item]="{ icon: 'gift', amount: mission.product_prizes_count }"
                [other]="{ value: '+' }"
                [lack]="false"
                [stockView]="STOCK_VIEW.A"></m-ui-stock-other>
            </div>
          </ng-template>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</div>
