import { Action } from "@ngrx/store";

import { ChangePassword } from "../../../modules/auth/interfaces/change-password";
import { TokenObject } from "../../../modules/auth/interfaces/token-object";

export enum ActionTypes {
  MUST_CHANGE = "[auth] must change password",
  CHANGE_START = "[auth] change password start",
  CHANGE_SUCCESS = "[auth] change password success",
  CHANGE_FAILED = "[auth] change password failed",
}

export class MustChange implements Action {
  readonly type = ActionTypes.MUST_CHANGE;

  constructor(public payload: { token: TokenObject }) {}
}

export class ChangeStart implements Action {
  readonly type = ActionTypes.CHANGE_START;

  constructor(public payload: { data: ChangePassword; tokenObject: TokenObject }) {}
}

export class ChangeSuccess implements Action {
  readonly type = ActionTypes.CHANGE_SUCCESS;

  constructor() {}
}

export class ChangeFailed implements Action {
  readonly type = ActionTypes.CHANGE_FAILED;

  constructor(public payload: any) {}
}

export type Actions = MustChange | ChangeStart | ChangeSuccess | ChangeFailed;
