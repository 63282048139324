import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";

import { WebSocketService } from "../../core/providers/websocket.service";
import { ActionTypes } from "./actions";

@Injectable()
export class SocketEffects {
  constructor(private actions$: Actions, private webSocketService: WebSocketService) {}

  socketConnect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActionTypes.SOCKET_CONNECT),
        tap(() => {
          this.webSocketService.connect();
        })
      ),
    { dispatch: false }
  );

  socketDisconnect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActionTypes.SOCKET_DISCONNECT),
        tap(() => {
          this.webSocketService.disconnect();
        })
      ),
    { dispatch: false }
  );
}
