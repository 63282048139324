import { select } from "@ngrx/store";
import * as R from "ramda";
import { Subscription } from "rxjs";
import { filter, take, tap } from "rxjs/operators";

import { EVENTS } from "../../../../../core/consts/core/events";
import { SetCurrentScene } from "../../../../../store/game/actions";
import { BoardState } from "../../../../../store/game/interfaces/board.state";
import { selectGameBoard } from "../../../../../store/game/selectors";
import { BOARD_TILE_HEIGHT, GAME_EVENTS, PHASER_CAMERA_ZOOM } from "../../../constants";
import { GameEvent } from "../../../interfaces/game.interfaces";
import { IsoMap } from "../../classes/core/IsoMap";
import { IsoMapLayer } from "../../classes/core/IsoMapLayer";
import { MyGame } from "../../classes/core/MyGame";
import { MyScene } from "../../classes/core/MyScene";
import { Board } from "../../classes/custom/Board.class";
import { CenterMap } from "../../interfaces/shared";
import { calculateMinZoomValue } from "../../utils/game.helper";
import { getMainSceneConfig } from "../helpers/MainScene.helper";
import { MAIN_BOOT_SCENE, MAIN_MAIN_SCENE, MAIN_SCENE_WORLD_HEIGHT, MAIN_SCENE_WORLD_WIDTH } from "../main.constants";

export class MainScene extends MyScene {
  waveStart: any;

  customPipeline: Phaser.Renderer.WebGL.WebGLPipeline;
  private globalEmitterSub: Subscription;
  private boardSubscription: Subscription;

  constructor() {
    super({
      key: MAIN_MAIN_SCENE,
    });
  }

  init(data?) {
    this.passedData = data;
    this.tiledMapKey = data.tiledMapKey;
    this.setCurrentScene();
  }

  preload(): void {
    this.events.on("resize", this.resize, this);
    this.gameService = (this.sys.game as MyGame).gameService;
    window["cameraObject"] = this.cameras.main;
  }

  create(): void {
    this.phaserEvents = new Phaser.Events.EventEmitter();

    const sceneConfig = getMainSceneConfig();

    this.cameras.main.setBackgroundColor(sceneConfig.backgroundColor);
    this.cameras.main.setBounds(-MAIN_SCENE_WORLD_WIDTH / 2, 0, MAIN_SCENE_WORLD_WIDTH, MAIN_SCENE_WORLD_HEIGHT);

    // @ts-ignore
    sceneConfig.backgroundImages.forEach(image => {
      const bgImage = this.add.image(image.x, image.y, image.key);
      bgImage.setDisplaySize(image.displayWidth, image.displayHeight);
      if (image.origin) {
        bgImage.setOrigin(image.origin.x, image.origin.y);
      }
    });

    this.isoMap = new IsoMap({
      scene: this,
    });

    this.isoMap.createFromData(this.tiledMapKey);
    this.buildingsLayer = this.isoMap.getLayerByName("buildings");

    if (!this.buildingsLayer) {
      this.buildingsLayer = new IsoMapLayer({ scene: this, name: "buildingsLayer" });
    }

    this.roadsLayer = new IsoMapLayer({ scene: this, name: "roadsLayer" });
    this.roadsLayer.depthIndex = this.buildingsLayer.depthIndex - 1;

    this.topLayer = new IsoMapLayer({
      scene: this,
      depthIndex: 99,
      name: "top",
    });

    this.cameras.main.roundPixels = true;

    this.boardSubscription = this.gameService.store
      .pipe(
        select(selectGameBoard),
        filter(boardState => boardState.board.length > 0),
        take(1)
      )
      .subscribe((boardState: BoardState) => {
        const clonedBoard = R.clone(boardState.board);
        // @ts-ignore
        this.board = new Board(this, { offset: sceneConfig.boardStartOffset });
        this.board.createBoard(clonedBoard);
        this.gameService.store.dispatch(new SetCurrentScene(this.scene.key));
      });

    this.setCameraControls();
    this.setCamera();

    setTimeout(() => {
      this.phaserEvents.emit(PHASER_CAMERA_ZOOM, this.cameras.main.zoom);
    }, 50);

    this.globalEmitterSub = this.gameService.globalService.globalEvents
      .pipe(
        tap(console.log),
        filter(event => event.name === EVENTS.GAME.CENTER_TILE)
      )
      .subscribe(event => this.focusOn(event.value));

    // enableWaves.bind(this)();
    // initPipeline.bind(this)();

    this.afterCreate();
  }

  setCamera() {
    this.cameras.main.centerOn(this.cameraStartPos.x, this.cameraStartPos.y);
    this.cameras.main.zoomTo(Math.max(0.4, calculateMinZoomValue(this.cameras.main)), 0);
  }

  afterCreate() {
    if (this.gameService.centerOn) {
      this.focusOn(this.gameService.centerOn);
    }
  }

  destroyScene() {
    super.destroyScene();
    this.globalEmitterSub.unsubscribe();
    this.boardSubscription.unsubscribe();
  }

  update(time, delta) {
    // if (this.customPipeline) {
    //   updatePipeline.bind(this)();
    // }
    // cullingMapObjects.bind(this)();
  }
}
