import * as R from "ramda";

import { CITY_SCENE_CONFIG_KEY } from "../consts/core/game-map.const";
import { CUSTOM_BRANCH_SCENE_CONFIG_KEY, CUSTOM_TECH_TREE_SCENE_CONFIG_KEY } from "../consts/custom/game-map-custom.const";
import { SceneConfigMap } from "../interfaces/scene-config.interface";
import { BASIC_SCENE_BASE_CONFIG } from "./basic-scene.base-config";

export const BASIC_SCENE_CUSTOM_CONFIG: SceneConfigMap = {
  // CREATE ALIAS IN register-custom.event-dialogs.service.ts for [CITY_SCENE_CONFIG_KEY] as default
  default: {
    ...R.clone(BASIC_SCENE_BASE_CONFIG),
    tiledStartOffset: {
      x: 0,
      y: 0,
    },
    tiledJsonPrefix: null,
    backgroundImages: [
      {
        filename: "map-0-0.jpg",
        x: 0,
        y: 0,
        key: "map-0-0",
        displayWidth: 4096,
        displayHeight: 4096,
      },
      {
        filename: "map-1-0.jpg",
        x: 4096,
        y: 0,
        key: "map-1-0",
        displayWidth: 4096,
        displayHeight: 4096,
      },
      {
        filename: "map-2-0.jpg",
        x: 8192,
        y: 0,
        key: "map-2-0",
        displayWidth: 1808,
        displayHeight: 4096,
      },
      {
        filename: "map-0-1.jpg",
        x: 0,
        y: 4096,
        key: "map-0-1",
        displayWidth: 4096,
        displayHeight: 904,
      },
      {
        filename: "map-1-1.jpg",
        x: 4096,
        y: 4096,
        key: "map-1-1",
        displayWidth: 4096,
        displayHeight: 904,
      },
      {
        filename: "map-2-1.jpg",
        x: 8192,
        y: 4096,
        key: "map-2-1",
        displayWidth: 1808,
        displayHeight: 904,
      },
    ],
    yAxisSign: 1,
    mapDirectoryName: "map",
    tiledMapFileName: null,
    islandGroupIdForBoard: "A",
    boardStartOffset: {
      x: 0,
      y: 0,
    },
    tileMenuAtlasDirectory: "tile-menu",
    gui_unlocks_button: CITY_SCENE_CONFIG_KEY, // this is needed for proper hud menu buttons display, please set key in custom config
    configKeyName: CITY_SCENE_CONFIG_KEY, // this is needed for proper hud menu buttons display, please set key in custom config
    cameraBounds: {
      x: 0,
      y: 0,
      width: 10000,
      height: 5000,
    },
    other: {
      sceneZoom: {
        max: 0.45,
        min: 0.7,
      },
      startZoom: 0.45,
      sceneSize: {
        width: 10000,
        height: 5000,
      },
    },
  },
  [CUSTOM_BRANCH_SCENE_CONFIG_KEY]: {
    ...R.clone(BASIC_SCENE_BASE_CONFIG),
    boardStartOffset: {
      x: 0,
      y: 0,
    },
    mapDirectoryName: "branch",
    tiledMapFileName: null,
    islandGroupIdForBoard: "B",
    backgroundImages: [
      {
        filename: "map-0-0.jpg",
        key: "map-0-0.jpg",
        displayWidth: 4096,
        displayHeight: 3500,
        x: 0,
        z: 0,
      },
      {
        filename: "map-1-0.jpg",
        key: "map-1-0.jpg",
        displayWidth: 2904,
        displayHeight: 3500,
        x: 4096,
        z: 0,
      },
    ],
    other: {
      isBranchMap: true,
      sceneZoom: {
        min: 0.7,
        max: 0.48,
      },
      startZoom: 0.48
    },
    yAxisSign: 1,
    cameraBounds: {
      x: 0,
      y: 0,
      width: 7000,
      height: 3500,
    },
    cameraStartPosition: {
      x: 3000,
      y: 1500,
    },
    gui_unlocks_button: CUSTOM_BRANCH_SCENE_CONFIG_KEY,
    configKeyName: CUSTOM_BRANCH_SCENE_CONFIG_KEY,
    tileTooltipConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileTooltipConfig,
      maxZoomScaleValue: 1,
    },
    tileMenuConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileMenuConfig,
      maxZoomScaleValue: 1,
    },
    tileHoverConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileHoverConfig,
      maxZoomScaleValue: 1,
    },
  },
  [CUSTOM_TECH_TREE_SCENE_CONFIG_KEY]: {
    ...R.clone(BASIC_SCENE_BASE_CONFIG),
    tiledStartOffset: {
      x: 0,
      y: 0,
    },
    isOrhto: true,
    tiledJsonPrefix: null,
    mapDirectoryName: "tech-tree",
    backgroundImages: [
      {
        filename: "map.png",
        key: "tech-tree-map",
        origin: {
          x: 0,
          y: 0,
        },
        displayWidth: 1920,
        displayHeight: 1080,
        x: 0,
        z: 0,
      },
      {
        filename: "window.png",
        key: "tech-tree-window",
        origin: {
          x: 0.5,
          y: 0.5,
        },
        displayWidth: 1008,
        displayHeight: 560,
        x: 1920 / 2,
        y: 1080 / 2,
      },
    ],
    tiledMapFileName: null,
    islandGroupIdForBoard: "C",
    boardStartOffset: {
      x: 320, // half of FHD res
      y: 178,
    },
    cameraStartPosition: {
      x: 0,
      y: 0,
    },
    other: {
      isTechTree: true,
      groupParameters: "GROUP_ONE",
    },
    yAxisSign: 1,
    cameraBounds: {
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
    },
    tileTooltipConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileTooltipConfig,
      maxZoomScaleValue: 1,
    },
    tileMenuConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileMenuConfig,
      maxZoomScaleValue: 1,
    },
    tileHoverConfig: {
      ...BASIC_SCENE_BASE_CONFIG.tileHoverConfig,
      maxZoomScaleValue: 1,
    },
    gui_unlocks_button: CUSTOM_TECH_TREE_SCENE_CONFIG_KEY,
    configKeyName: CUSTOM_TECH_TREE_SCENE_CONFIG_KEY,
  },
};
