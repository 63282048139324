import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

import { ApiService } from "../../core/providers/api.service";
import { TokenExchangeService } from "../../core/providers/token-exchange.service";
import { removeToken } from "../../core/utility/token";
import { TokenObject } from "../../modules/auth/interfaces/token-object";
import { PlayerService } from "../../modules/player/providers/player.service";
import { ActionTypes, FetchNewTokenFinish, SetToken, UpdateActivePlayerId, UpdateMePlayerId } from "./actions";

@Injectable()
export class UtilityEffects {
  constructor(
    private actions$: Actions,
    private playerService: PlayerService,
    private apiService: ApiService,
    private tokenExchangeService: TokenExchangeService,
    private store: Store<AppendMode>
  ) {}

  $updateMePlayerId = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.UPDATE_ME_PLAYER_ID),
      tap((action: UpdateMePlayerId) => {
        this.playerService.setMePlayerId(action.payload.playerId);
        if (action.payload.playerId) {
          this.tokenExchangeService.exchangeTimeoutStart();
        }
      }),
      map((action: UpdateMePlayerId) => {
        return new UpdateActivePlayerId({
          playerId: action.payload.playerId,
        });
      })
    )
  );

  $updateActivePlayerId: Observable<void | Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActionTypes.UPDATE_ACTIVE_PLAYER_ID),
        tap((action: UpdateActivePlayerId) => {
          this.playerService.setActivePlayerId(action.payload.playerId);
        })
      ),
    { dispatch: false }
  );

  setToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActionTypes.SET_TOKEN),
        tap((action: SetToken) => {
          this.apiService.setToken(action.payload);
          this.tokenExchangeService.exchangeTimeoutStart();
        })
      ),
    { dispatch: false }
  );

  removeToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActionTypes.REMOVE_TOKEN),
        tap(() => {
          removeToken();
        })
      ),
    { dispatch: false }
  );

  fetchNewTokenStart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActionTypes.FETCH_NEW_TOKEN_START),
        tap(() => {
          this.tokenExchangeService.exchangeToken().subscribe((tokenObject: TokenObject) => {
            this.store.dispatch(new FetchNewTokenFinish());
            this.store.dispatch(new SetToken(tokenObject));
          });
        })
      ),
    { dispatch: false }
  );
}
