import { TileHoverCore } from "../core/TileHoverCore";
import {BoardTile} from '../../custom/BoardTile.class';
import {BOARD_TILE_BUYABLE, TILE_HOVER_DEPTH} from '../../../../constants';
import {translate} from '../../../../../../core/helpers/translate.helper';
import {isNonInteractiveBuilding} from '../../../utils/game.helper';

export class TileHover extends TileHoverCore {

  show(target: BoardTile) {
    if (target.tileData.state === BOARD_TILE_BUYABLE) {
      this.showOnBuyable(target);
      return;
    }
    this.targetTile = target;
    this.myScene = target.myScene;
    this.gameService = this.myScene.gameService;
    this.offset = this.calculateOffset(target);
    const backgroundOffset = this.calculateBackgroundOffset();
    this.x = target.x + this.offset.x + backgroundOffset.x;
    this.y = target.y + this.offset.y + backgroundOffset.y;

    switch (target.playerBuildingData.icon) {
      case "rocket_1":
      case "rocket_2":
      case "rocket_3":
      case "rocket_4":
      case "rocket_5":
      case "rocket_x": {
        break;
      }

      default: {
        this.y = target.y - target.baseSprite.height / 2;
        break;
      }
    }

    this.building = target.playerBuildingData;
    this.depth = target.depth + 100 + TILE_HOVER_DEPTH;
    this.hideAutoprodObjects();
    this.processAutoProductionData();
    this.processLimitedResourceData();

    this.setValues();
    this.additionalTextObject.setText(translate("tile-menu.level-text", [this.building.level]));
    this.visible = true;
    this.belowTextObject.alpha = 0;
    this.autoproductionContainer.alpha = 1;
    this.additionalTextObject.alpha = this.building && this.building.hide_level_information_on_front ? 0 : 1;
    this.additionalTextObject.visible = !isNonInteractiveBuilding(this.targetTile.playerBuildingData.group_type);
  }
}
