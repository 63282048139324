import { Injectable } from "@angular/core";

import { AbstractEventDialogsService } from "../../../../../../core/abstracts/abstract-event-dialogs.service";
import { EVENT_DIALOGS_TRANSACTION_HISTORY } from "../../consts/core/event-dialogs/event-dialogs.const";
import { EVENT_DIALOGS_TRANSACTION_HISTORY_CUSTOM } from "../../consts/custom/event-dialogs/event-dialogs.const";

@Injectable({
  providedIn: "root",
})
export class TransactionHistoryEventDialogsService extends AbstractEventDialogsService {
  getEventDialogs() {
    return {
      coreEventDialogs: EVENT_DIALOGS_TRANSACTION_HISTORY,
      customEventDialogs: EVENT_DIALOGS_TRANSACTION_HISTORY_CUSTOM,
    };
  }
}
