import { Directive, Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { AbstractInjectBaseComponent } from "../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../core/decorators/ow-inject.decorator";
import { translate } from "../../../core/helpers/translate.helper";
import { DialogService } from "../../shared/providers/dialog.service";
import { AUTH_ROUTING_ABSOLUTE_PATH } from "../consts/core/routing-auth-absolute.const";
import { checkPasswordTheSame } from "../helpers/validators-form-password-the-same.helper";
import { ResetPasswordConfirm } from "../interfaces/reset-password";
import { AuthService } from "../providers/auth.service";

@Directive()
@Injectable()
export abstract class AbstractResetPasswordComponent extends AbstractInjectBaseComponent {
  @OwInject(FormBuilder) fb: FormBuilder;
  @OwInject(AuthService) authService: AuthService;
  @OwInject(ActivatedRoute) activatedRoute: ActivatedRoute;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(Router) router: Router;
  token: string;
  form: FormGroup;
  AUTH_ROUTING_ABSOLUTE_PATH = AUTH_ROUTING_ABSOLUTE_PATH;

  ngOnInit() {
    this.subscribeRoutingParams();
  }

  subscribeRoutingParams() {
    this.activatedRoute.params.subscribe(params => {
      if (params.hasOwnProperty("token")) {
        this.token = params["token"];
        this.clearForm();
      }
    });
  }

  clearForm() {
    this.form = this.fb.group(
      {
        token: this.fb.control(this.token, [Validators.required]),
        password: this.fb.control(null, [Validators.required]),
        passwordRepeat: this.fb.control(null, [Validators.required]),
      },
      {
        validator: checkPasswordTheSame,
      }
    );
  }

  onFormSubmit() {
    const data: ResetPasswordConfirm = {
      token: this.form.value.token,
      password: this.form.value.password,
    };

    this.authService.resetPasswordConfirm(data).subscribe(() => {
      this.dialogService.openAlert({
        title: translate("auth.reset-password.alert-success.title"),
        description: translate("auth.reset-password.alert-success.description"),
      });
      this.router.navigate([AUTH_ROUTING_ABSOLUTE_PATH.LOGIN]);
    });
  }
}
