import { Component } from "@angular/core";

import { GlobalService } from "../../../../../../../../core/providers/global.service";
import { GameService } from "../../../../../../services/game.service";
import { IsoSprite } from "../../../../../classes/core/IsoSprite";
import { TILE_MENU_ATLAS } from "../../../../../scenes-main/main.constants";
import { screenToIso } from "../../../../../utils/utils";

@Component({
  selector: "app-to-iso",
  templateUrl: "./screen-to-iso.component.html",
})
export class ScreenToIsoComponent {
  screenX = 0;
  screenY = 0;

  isoX: number;
  isoY: number;

  pointer;

  isOpen: boolean;

  constructor(public gameService: GameService, public globalService: GlobalService) {
    this.isOpen = Boolean(localStorage.getItem("screenToIsoOpen"));
  }

  toIso() {
    const toIso = screenToIso(this.screenX, this.screenY);
    this.isoX = toIso.x;
    this.isoY = toIso.y;

    if (this.pointer) {
      this.pointer.destroy();
      this.pointer = null;
    }
    this.pointer = new IsoSprite({
      scene: this.gameService.game.currentScene,
      x: this.isoX,
      y: this.isoY,
      key: TILE_MENU_ATLAS,
      frame: "cancel.png",
    });
  }

  toggle() {
    this.isOpen = !this.isOpen;
    localStorage.setItem("screenToIsoOpen", this.isOpen.toString());
  }
}
