import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";

import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AbstractBuildingSpecialComponent } from "../../../../abstract/core/abstract-building-special.component";
import { getNotOutcomeParameters, getOutcomeParameters } from "../../../../helpers/custom/outcome-parameters.helper";

@Component({
  selector: "app-building-special",
  templateUrl: "./building-special.component.html",
})
export class BuildingSpecialComponent extends AbstractBuildingSpecialComponent implements OnInit, OnDestroy, AfterViewInit {
  notOutcomeParameters: any[];
  outcomeParameters: any[];

  ngOnInit() {
    this.subscribeBoardTile();
  }

  setDifferentGroup() {
    super.setDifferentGroup();
    this.setParameters();
  }

  setParameters() {
    this.notOutcomeParameters = getNotOutcomeParameters(this.upgradeBuildingDetails.parameters);
    this.outcomeParameters = getOutcomeParameters(this.upgradeBuildingDetails.parameters);
  }

  ngAfterViewInit() {
    this.setSwiper();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
