<div class="global-dialog">
  <loading [classNames]="['white', 'stroke']" [size]="'big'"></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <div [hidden]="!(groups.items.length)">
    <div class="top-info">
      <p class="title">Główne wyzwania</p>
    </div>

    <!-- NAVIGATION CENTER -->
    <m-ui-navigation-center
      *ngIf="swiper"
      [hidePrev]="swiper?.isBeginning"
      [hideNext]="swiper?.isEnd"
      (prevClicked)="prevSlide()"
      (nextClicked)="nextSlide()"
    ></m-ui-navigation-center>

    <swiper-container *ngIf="initSwiper" #swiperRef [config]="config" init="false" swiperElement>
      <swiper-slide *ngFor="let page of groups.pages" class="collections">
        <div
          (click)="openMissionGroupDetails(group, index)"
          *ngFor="let group of page; let index = index"
          class="ow-dialog window-b primary loading-opacity"
        >
          <div [style.background-image]="'url(' + (group.icon + '.png' | asset : 'missions/big') + ')'"
               class="icon"></div>
          <div class="name">{{ group.name }}</div>

          <div class="bottom">
            <div class="score-box">
              <img [src]="'checklist_small.png' | asset : 'missions'"/>
              <div class="score-container">
                <div class="score">{{ group.completed_missions }}/{{ group.total_missions }}</div>
              </div>
            </div>

            <div class="progressbar-container">
              <div class="box">
                <div class="border"></div>
                <ng-container *ngIf="group.completed_missions">
                  <!-- FILL -->
                  <div [style.width.px]="(group.completed_missions / group.total_missions) * 100 * 0.5"
                       class="fill"></div>
                </ng-container>
              </div>

              <img *ngIf="group.is_reward_collected" [src]="'icon-check.png' | asset : 'ui'" class="check"/>

              <!-- BUTTON -->
              <div class="progress-icon">
                <img [src]="'chest_small.png' | asset : 'missions'"/>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper-container>

    <div class="bottom-info">
      <div class="icon"><i class="fas fa-exclamation"></i></div>
      Zrealizuj serie misji ukrytych w każdym<br/>
      wyzwaniu, aby odebrać skrzynie z nagrodami!
    </div>
  </div>
</div>
