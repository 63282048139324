import { registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import localePLExtra from "@angular/common/locales/extra/pl";
import localePL from "@angular/common/locales/pl";
import { Injector, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { PinchZoomModule } from "@meddv/ngx-pinch-zoom";
import { EffectsModule } from "@ngrx/effects";

import { AuthModule } from "../modules/auth/auth.module";
import { SharedUiMobileModule } from "../modules/game/game-ui/shared-ui/mobile/shared-ui-mobile.module";
import { MyMaterialModule } from "../modules/my-material/my-material.module";
import { SharedModule } from "../modules/shared/shared.module";
import { UserModule } from "../modules/user/user.module";
import { SocketEffects } from "../store/socket";
import { AlertComponent } from "./basic/core/dialogs/alert/alert.component";
import { AlertConfirmComponent } from "./basic/core/dialogs/alert-confirm/alert-confirm.component";
import { AlertImageComponent } from "./basic/core/dialogs/alert-image/alert-image.component";
import { CUSTOM_DECLARATIONS } from "./consts/custom/components.const";
import { CUSTOM_IMPORTS } from "./consts/custom/imports.const";
import { CUSTOM_PROVIDERS } from "./consts/custom/providers.const";
import { AuthGuard } from "./guards/auth.guard";
import { ApiService } from "./providers/api.service";
import { AssetsService } from "./providers/assets.service";
import { BuildVersionService } from "./providers/build-version.service";
import { DictionaryService } from "./providers/dictionary.service";
import { GlobalService } from "./providers/global.service";
import { LoadingService } from "./providers/loading.service";
import { MyTranslateService } from "./providers/my-translate.service";
import { ParametersService } from "./providers/parameters.service";
import { PrimaryService } from "./providers/primary.service";
import { SynchronizeTimeService } from "./providers/synchronize-time.service";
import { InjectorVariable } from "./utility/injector-instance.helper";
import { OwPerfectScrollbarModule } from "@oskarwegner/ow-perfect-scrollbar";

registerLocaleData(localePL, "pl", localePLExtra);

declare global {
  export interface Console {
    ow(...message: any): void;
  }
}

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    MyMaterialModule,
    AuthModule,
    UserModule,
    SharedModule,
    EffectsModule.forFeature([SocketEffects]),
    SharedUiMobileModule,
    PinchZoomModule,
    OwPerfectScrollbarModule,
    ...CUSTOM_IMPORTS,
  ],
  declarations: [AlertComponent, AlertConfirmComponent, AlertImageComponent, ...CUSTOM_DECLARATIONS],
  providers: [
    AssetsService,
    AuthGuard,
    ApiService,
    DictionaryService,
    GlobalService,
    SynchronizeTimeService,
    PrimaryService,
    ParametersService,
    { provide: LOCALE_ID, useValue: "pl" },
    MyTranslateService,
    LoadingService,
    BuildVersionService,
    ...CUSTOM_PROVIDERS,
  ],
})
export class CoreModule {
  constructor(private injector: Injector) {
    InjectorVariable.instance = this.injector;
  }
}
