import { Injectable } from "@angular/core";
import { CanActivate, CanLoad } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable, of, timer } from "rxjs";
import { catchError, debounce, filter, switchMap, take, tap, withLatestFrom } from "rxjs/operators";

import { PlayerActions, PlayerSelectors } from "../../store/player";
import { SocketActions, SocketSelectors } from "../../store/socket";
import { AppState } from "../../store/state";
import { SOCKET_STATUS } from "../consts/core/socket.constants";

@Injectable({
  providedIn: "root",
})
export class SocketGuard implements CanActivate, CanLoad {
  constructor(public store: Store<AppState>) {}

  getState(): Observable<any> {
    this.store.dispatch(new PlayerActions.FetchPlayer());

    return this.store.pipe(
      withLatestFrom(this.store.pipe(select(SocketSelectors.selectState)), this.store.pipe(select(PlayerSelectors.selectPlayer))),
      debounce(() => timer(0)),
      filter(([state, socketState, playerState]) => {
        // console.log(socketState, playerState);
        return !!playerState;
      }),
      tap(([state, socketState]) => {
        if ((!socketState.socketStatus || socketState.socketStatus === SOCKET_STATUS.DISCONNECTED) && !socketState.isConnecting) {
          this.store.dispatch(new SocketActions.SocketConnect());
        }
      }),
      filter(([state, socketState]) => {
        return socketState.socketStatus == SOCKET_STATUS.AUTHENTICATED;
      }),
      take(1)
    );
  }

  checkAuth(): Observable<boolean> {
    return this.getState().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  canActivate(): Observable<boolean> {
    return this.checkAuth();
  }

  canLoad(): Observable<boolean> {
    return this.checkAuth();
  }
}
