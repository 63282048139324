<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <div [hidden]="!buildingDetails">
    <!-- TOP BAR BASE -->
    <div class="top-bar">
      <!-- BUILDING NAME -->
      <div *ngIf="buildingDetails" class="title">
        {{ buildingDetails.name }}
      </div>

      <!-- GROUP COUNT -->
      <div class="absolute-right">
        <m-ui-group-count [building]="buildingDetails" #groupCount></m-ui-group-count>
      </div>
    </div>

    <div class="container loading-opacity">
      <div class="flex-center details-container">
        <swiper-container #swiperRef swiperElement [config]="config" init="false" class="swiper">
          <swiper-slide *ngIf="buildingDetails" >
            <div class="building-details-container">
              <!-- LEFT -->
              <div class="building-icon">
                <!-- BUILDING ICON -->
                <img [src]="buildingDetails | buildingIcon : false"/>
                <!-- EXPERIENCE -->
                <m-ui-exp-up [experience]="buildingDetails.exp_points"></m-ui-exp-up>
              </div>

              <!-- RIGHT -->
              <div>
                <!-- BUILDING DESCRIPTION -->
                <div class="building-desc" [innerHTML]="buildingDetails.group_description" owEllipsis></div>

                <ng-container *ngIf="buildingDetails" [ngSwitch]="buildingDetails.group_type">
                  <!-- BUILDING PRODUCTION OR CROP -->
                  <ng-container
                    *ngSwitchCase="
                      [BUILDING_TYPES.PRODUCTION, BUILDING_TYPES.CROP].includes(buildingDetails.group_type) && buildingDetails.group_type
                    ">
                    <m-building-allow-production
                      [buildingId]="buildingDetails.building_id"></m-building-allow-production>
                  </ng-container>

                  <ng-container *ngSwitchDefault>
                    <!-- PARAMETERS -->
                    <ng-container *ngTemplateOutlet="parametersTpl"></ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </swiper-slide>
          <!-- PARAMETERS SLIDE -->
          <swiper-slide *ngIf="[BUILDING_TYPES.PRODUCTION, BUILDING_TYPES.CROP].includes(buildingDetails?.group_type) && notOutcomeParameters?.length || unlockedBuildings && unlockedBuildings.length">
              <ng-container [ngSwitch]="buildingDetails?.group_type">
                <!-- BUILDING PRODUCTION OR CROP -->
                <div
                  class="parameters-slide"
                  *ngSwitchCase="
                    [BUILDING_TYPES.PRODUCTION, BUILDING_TYPES.CROP].includes(buildingDetails?.group_type) &&
                    buildingDetails.parameters.length &&
                    buildingDetails?.group_type
                  ">
                  <!-- TITLE PARAMETERS -->
                  <p class="title-small">
                    {{ "building-build.resources" | myTranslate }}
                  </p>
                  <!-- PARAMETERS -->
                  <ng-container *ngTemplateOutlet="parametersTpl"></ng-container>
                </div>
              </ng-container>
              <!-- UNLOCKED BUILDINGS -->
              <div class="unlocked-buildings" *ngIf="unlockedBuildings && unlockedBuildings.length">
                <!-- TITLE UNLOCKED -->
                <p class="title-small">
                  {{ (unlockedBuildings.length > 1 ? "building-build.unlock-buildings" : "building-build.unlock-build") | myTranslate }}
                </p>

                <!-- BUILDINGS THUMBNAIL LIST -->
                <div class="flex-center">
                  <m-building-thumbnail *ngFor="let building of unlockedBuildings"
                                        [building]="building"></m-building-thumbnail>
                </div>
              </div>
          </swiper-slide>
        </swiper-container>


        <div class="arrows-slider" *ngIf="buildingDetails">
          <div class="arrow-blue-left" (click)="prevSlide()" [class.hide]="currentSlideIndex <= 0"></div>
          <div class="arrow-blue-right" (click)="nextSlide()"
               [class.hide]="currentSlideIndex >= this.swiper?.slides.length - 1"></div>
        </div>
      </div>

      <!-- REQUIREMENTS -->
      <ng-container *ngIf="buildingDetails">
        <app-building-requirements
          *ngIf="outcomeParameters !== undefined"
          [separator]="'global.requirements' | myTranslate"
          [groupLimit]="groupCount"
          [products]="buildingDetails.build_product_prices"
          [currencies]="buildingDetails.build_currency_prices"
          [playerLevel]="buildingDetails.required_level"
          [population]="buildingDetails.required_population"
          [requiredBuilding]="buildingDetails.required_building"
          [star]="buildingDetails.required_star"
          [parametersOutcome]="outcomeParameters"
          (requirementsStatus)="setRequirementsStatus($event)"
        ></app-building-requirements>
      </ng-container>
    </div>

    <!-- BOTTOM BAR -->
    <div class="bottom-bar" *ngIf="requirementsStatus">
      <!-- BUILD -->
      <div class="bar-button base">
        <button class="base secondary" (click)="build()" [disabled]="!requirementsStatus.valid">
          {{ buildingDetails.upgrade_button_name || ("building-build.button-build" | myTranslate) }}
          <app-core-building-time [building]="buildingDetails"></app-core-building-time>
        </button>
      </div>

      <!-- FAST ACTION -->
      <div
        class="bar-button base"
        *ngIf="buildingDetails.fast_build_currency_prices.length || buildingDetails.fast_build_product_prices.length">
        <button class="base secondary icon-left-side" (click)="openFastBuildConfirm()" [disabled]="!requirementsStatus.valid">
          <i class="far fa-bolt"></i>
          {{ "building-build.button-fast-build" | myTranslate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- PARAMETERS -->
<ng-template #parametersTpl>
  <div class="parameters">
    <m-ui-parameter
      *ngFor="let parameter of notOutcomeParameters"
      [item]="parameter"
      [other]="{ amount: parameter.income }"
      [stockView]="STOCK_VIEW.A"
      [lack]="false"></m-ui-parameter>
  </div>
</ng-template>
