<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <div class="logo-icon">
    <img [src]="'invitation_2.png' | asset: 'ui'"/>
  </div>

  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <div class="title">
      Zaproszenie do nowej edycji gry
    </div>
  </div>

  <!-- EXIT BUTTON -->
  <m-ui-close-button [preventDefault]="true" (click)="closeWindow()"></m-ui-close-button>

  <!--  SUMMARY -->
  <div class="invitation-summary">
    <div class="summary-description-checkboxes">
      <div class="each-checkbox" *ngFor="let agreement of agreements">
        <button
          class="checkbox"
          [class.active]="agreement.active"
          (click)="changeAgreement(agreement)"
        ></button>
        <span [innerHTML]="agreement.text"></span>
      </div>
    </div>

    <div class="bottom-bar">
      <div class="bar-button base">
        <button
          class="base primary"
          (click)="askLater()"
        >
          Zapytaj później
        </button>
      </div>

      <div class="bar-button base">
        <button
          class="base secondary"
          [disabled]="!isValid"
          (click)="accept()"
        >
          Potwierdzam udział
        </button>
      </div>
    </div>
  </div>
</div>
