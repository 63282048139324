import { RegistrationComponent } from "../../base/custom/components/registration/registration.component";
import { RegistrationConfirmComponent } from "../../base/custom/components/registration-confirm/registration-confirm.component";

export const CUSTOM_ROUTING_AUTH_CHILDREN = [
  {
    path: "registration/confirm/:token",
    component: RegistrationConfirmComponent,
  },
  {
    path: "registration",
    component: RegistrationComponent,
  },
];
