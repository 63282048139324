import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType} from '@ngrx/effects';
import { Action, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";

import { omitErrorResponseHelper } from "../../../core/helpers/omit-error-response.helper";
import { ApiService } from "../../../core/providers/api.service";
import { Credentials } from "../../../modules/auth/interfaces/credentials";
import { TokenObject } from "../../../modules/auth/interfaces/token-object";
import { AuthService } from "../../../modules/auth/providers/auth.service";
import { PlayerService } from "../../../modules/player/providers/player.service";
import { DialogService } from "../../../modules/shared/providers/dialog.service";
import { AppState } from "../../state";
import { UtilityActions } from "../../utility";
import { ActionTypes, AuthFail, AuthStart, AuthSuccess } from "./actions";
import * as Sentry from '@sentry/angular-ivy';

@Injectable()
export class LoginEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private apiService: ApiService,
    private store: Store<AppState>,
    private dialogService: DialogService,
    private playerService: PlayerService
  ) {}

  $authStart: Observable<void | Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.AUTH_START),
      mergeMap((action: AuthStart) => {
        return this.login(<Credentials>action.payload);
      })
    )
  );

  $authLogout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActionTypes.AUTH_LOGOUT),
        tap(() => {
          this.authService.logout();
          Sentry.setUser(null);
          location.href = this.authService.logoutUrl || '/main';
        })
      ),
    { dispatch: false }
  );

  login(credentials: Credentials): Observable<AuthSuccess | AuthFail> {
    return this.authService.doLogin(credentials).pipe(
      tap((tokenObject: TokenObject) => {
        this.handleLogin(tokenObject);
      }),
      map(() => {
        return new AuthSuccess();
      }),
      catchError((error: any) => {
        return of(new AuthFail(omitErrorResponseHelper(error)));
      })
    );
  }

  handleLogin(tokenObject) {
    if (tokenObject) {
      this.store.dispatch(new UtilityActions.SetToken(tokenObject));
    }
  }
}
