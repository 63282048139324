import { createSelector } from "@ngrx/store";

import { selectAuthState } from "../selectors";
import { AuthState } from "../state";
import { PasswordState } from "./state";

export const selectChangePasswordState = createSelector(selectAuthState, (state: AuthState): PasswordState => state.changePassword);

const getIsChanging = (state: PasswordState): boolean => state.isChanging;
export const selectIsChanging = createSelector(selectChangePasswordState, getIsChanging);
