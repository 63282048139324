import { Action } from "@ngrx/store";

import { Message } from "../../modules/game/game-ui/message/interfaces/core/message.interface";
import { GastroIncomeCollectPayload } from "./interfaces/actions-payloads";
import { BoardTileState } from "./interfaces/board-tile.state";

export enum ActionTypes {
  FETCH_BOARD = "[game] fetch board",
  BOARD_TILE_UPDATE = "[game] update board tile data",
  GASTRO_INCOME_COLLECT = "[game] update gastro income data",
  PRELOAD_BOARD_BUILDINGS_IMAGES_SUCCESS = "[game] preload board buildings images success",
  FETCH_BOARD_SUCCESS = "[game] fetch board success",
  NEW_IMPORTANT_MESSAGE_RECEIVED = "[game] new important message received",
  NEW_IMPORTANT_MESSAGE_OPENED = "[game] new important message opened",
  NEW_IMPORTANT_MESSAGE_CLOSED = "[game] new important message closed",
  ENGINE_INIT_SUCCESS = "[game] game engine init success",
  SET_GAME_READY = "[game] set game ready",
  SET_CURRENT_SCENE = "[game] set current scene",
  RESET_BOARD = "[game] reset board",
  SET_CURRENT_PLAYER_ISLAND_ID = "[game] set current player island id",
  NULL_ACTION = "[game] null action",
}

export class FetchBoard implements Action {
  readonly type = ActionTypes.FETCH_BOARD;

  constructor(
    public payload?: {
      playerId: number;
      playerIslandId?: number;
    }
  ) {}
}

export class FetchBoardSuccess implements Action {
  readonly type = ActionTypes.FETCH_BOARD_SUCCESS;

  constructor(public payload: BoardTileState[]) {}
}

export class PreloadBoardBuildingsImagesSuccess implements Action {
  readonly type = ActionTypes.PRELOAD_BOARD_BUILDINGS_IMAGES_SUCCESS;

  constructor() {}
}

export class BoardTileUpdate implements Action {
  readonly type = ActionTypes.BOARD_TILE_UPDATE;

  constructor(public payload: BoardTileState) {}
}

export class GastroIncomeCollect implements Action {
  readonly type = ActionTypes.GASTRO_INCOME_COLLECT;

  constructor(public payload: GastroIncomeCollectPayload) {}
}

export class EngineInitSuccess implements Action {
  readonly type = ActionTypes.ENGINE_INIT_SUCCESS;

  constructor(public payload: boolean) {}
}

export class NewImportantMessageReceived implements Action {
  readonly type = ActionTypes.NEW_IMPORTANT_MESSAGE_RECEIVED;

  constructor(public payload: Message) {}
}

export class NewImportantMessageOpened implements Action {
  readonly type = ActionTypes.NEW_IMPORTANT_MESSAGE_OPENED;

  constructor(public payload: boolean) {}
}

export class NewImportantMessageClosed implements Action {
  readonly type = ActionTypes.NEW_IMPORTANT_MESSAGE_CLOSED;

  constructor(public payload: Message) {}
}

export class SetGameReady implements Action {
  readonly type = ActionTypes.SET_GAME_READY;

  constructor(public payload: boolean) {}
}

export class SetCurrentScene implements Action {
  readonly type = ActionTypes.SET_CURRENT_SCENE;

  constructor(public payload: string) {}
}

export class ResetBoard implements Action {
  readonly type = ActionTypes.RESET_BOARD;

  constructor() {}
}

export class SetCurrentPlayerIslandId implements Action {
  readonly type = ActionTypes.SET_CURRENT_PLAYER_ISLAND_ID;

  constructor(public payload: number) {}
}

export class NullAction implements Action {
  readonly type = ActionTypes.NULL_ACTION;

  constructor(public payload: BoardTileState[]) {}
}

export type Actions =
  | FetchBoard
  | FetchBoardSuccess
  | EngineInitSuccess
  | BoardTileUpdate
  | GastroIncomeCollect
  | PreloadBoardBuildingsImagesSuccess
  | NewImportantMessageReceived
  | NewImportantMessageOpened
  | NewImportantMessageClosed
  | SetGameReady
  | SetCurrentScene
  | ResetBoard
  | SetCurrentPlayerIslandId
  | NullAction;
