import { TileMenuButtonConfig } from "../../interfaces/tile-menu-config.interface";

export const CUSTOM_BUTTONS_CONFIG: { [buttonName: string]: TileMenuButtonConfig } = {};

export const TILE_TOOLTIP_OFFSET_BY_ICON = {
  "space_physiology": {
    x: 0,
    y: -80
  },
  "space_psychology": {
    x: 0,
    y: -80
  },
  "space_electronics": {
    x: 0,
    y: -80
  },
  "space_communication": {
    x: 0,
    y: -80
  },
  "space_biology": {
    x: 0,
    y: -80
  },
  "rocket_engines": {
    x: 0,
    y: -80
  },
  "recycling_technology": {
    x: 0,
    y: -80
  },
  "material_technology": {
    x: 0,
    y: -80
  },
  "mars_lander": {
    x: 0,
    y: -80
  },
  "life_support_technology": {
    x: 0,
    y: -80
  },
  "fundamental_physics": {
    x: 0,
    y: -80
  },
  "fuel_technology": {
    x: 0,
    y: -80
  },
  "food_production": {
    x: 0,
    y: -80
  },
  "colonization_technology": {
    x: 0,
    y: -80
  },
}
