import { Actions, ActionTypes } from "./actions";
import { PrimaryState } from "./state";

const initialState: PrimaryState = {
  isLoading: false,
  primary: null,
  error: null,
};

export function primaryReducer(state = initialState, action: Actions): PrimaryState {
  switch (action.type) {
    case ActionTypes.FETCH_PRIMARY_START:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.FETCH_PRIMARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        primary: action.payload,
        error: null,
      };

    case ActionTypes.FETCH_PRIMARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        primary: null,
        error: action.payload,
      };

    default:
      return state;
  }
}
