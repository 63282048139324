<loading></loading>

<ng-container *ngIf="!isLoading && (activeMissionDetails || allStarsCollected)">
  <div class="success" *ngIf="allStarsCollected">
    <p>Gratulacje!</p>
  </div>

  <div class="stars loading-opacity">
    <ng-container *ngFor="let groupMission of groupMissions">
      <div
        [matTooltip]="!groupMission.is_reward_collected && activeGroupMission?.mission_group_id !== groupMission.mission_group_id
          ? 'Zalicz poprzedni etap, aby rozpocząć zdobywanie tego statusu'
          : ''"
        [ngClass]="{
          'star-full': groupMission.is_reward_collected || allStarsCollected,
          'star-half': activeGroupMission?.mission_group_id === groupMission.mission_group_id
        }"
        class="star"
        matTooltipPosition="above"
      ></div>
    </ng-container>
  </div>

  <ng-container *ngIf="activeGroupMission">
    <div class="title loading-opacity">
      <span>{{ activeGroupMission?.name }}</span>
      Nowe opcje rozwoju po awansie na kolejny status
    </div>

    <div
      [innerHtml]="activeGroupMission?.description"
      class="description loading-opacity"
    ></div>

    <div class="empty-space"></div>

    <div class="missions loading-opacity">
      <div
        [innerText]="activeMissionDetails.description"
        class="missions-info"
        owEllipsis
      ></div>
      <ow-perfect-scrollbar>
        <div class="missions-stages">
          <div
            *ngFor="let condition of activeMissionDetails.conditions"
            [class.text-bold]="condition.completed_at"
            class="stage"
          >
            <div [class.stage-box-active]="condition.completed_at"
                 class="stage-box">
              <div class="box"></div>
              <span>{{ condition.name }}</span>
            </div>
            <ng-container [ngSwitch]="condition.target_type">
              <!-- INTEGER -->
              <ng-container *ngSwitchCase="MISSION_CONDITION_VALUE_TYPE.INTEGER">
                <span>{{ condition.value }} / {{ condition.target }}</span>
              </ng-container>

              <!-- PERCENTAGE -->
              <ng-container *ngSwitchCase="MISSION_CONDITION_VALUE_TYPE.PERCENTAGE">
                <span>{{ condition.value | percent:'1.2-2'}} / {{ condition.target | percent:'1.2-2' }}</span>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ow-perfect-scrollbar>
    </div>

    <!-- BOTTOM BAR -->
    <div class="bottom-bar">
      <!-- COLLECT -->
      <div class="bar-button base">
        <button
          (click)="completeStage()"
          [disabled]="!areConditionsComplete"
          class="base primary"
        >
          Zalicz etap
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="allStarsCollected">
    <div class="success">
      <div class="desc">
        Udało Ci zrealizować serię wyzwań i stworzyć miasto neutralne klimatycznie.
        <br>
        Ale to jeszcze nie koniec! Utrzymaj ten stan i walcz o najlepsze miejsce w rankingach.
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="!isLoading && !activeMissionDetails && !allStarsCollected">
  <div class="no-item">Brak misji</div>
</ng-container>

<!-- DIALOG EXIT -->
<m-ui-close-button></m-ui-close-button>
