<div class="global-dialog ow-dialog window-a primary">
    <!-- TOP BAR BASE -->
    <div class="top-bar base">
        <div class="title">
            {{ "messages.level-up" | myTranslate }}
        </div>
    </div>

    <!-- DIALOG EXIT -->
    <m-ui-close-button [preventDefault]="true" (clicked)="closeOrCloseAll()"></m-ui-close-button>

    <!-- BACK BAR -->
    <m-ui-back-button [preventDefault]="true" (clicked)="close()" *ngIf="messageOpenDetailsData.fromList"></m-ui-back-button>

    <div [hidden]="!(message)">
        <!-- IF MESSAGE EXIST -->
        <div class="height-100 relative">
            <loading></loading>

            <div class="flex-container loading-opacity">
                <div class="level-up-container">
                    <div class="level-up-top-wrapper">
                        <!-- LVL UP RIBBON -->
                        <div class="level-up-ribbon">
                            <div class="level">
                                <i class="fas fa-arrow-alt-up"></i>
                                {{ message.parameters.level }}
                            </div>
                        </div>

                        <!-- DESCRIPTION -->
                        <div class="inner-html-description" [innerHTML]="message.description | safe : 'html'"></div>
                    </div>

                    <div class="prizes" *ngIf="unlockedProducts.items.length > 0 || unlockedBuildings.items.length > 0">
                        <!-- UNLOCKED BUILDING -->

                        <div class="building-prizes" *ngIf="unlockedBuildings.items.length > 0" [class.margin-top]="unlockedProducts.items?.length === 0">
                            <!-- SEPARATOR-->
                            <m-ui-separator [title]="'messages.level-up-separator-unlocked-buildings-title' | myTranslate"></m-ui-separator>

                            <!-- SLIDER 1 -->
                            <swiper-container *ngIf="initSwiper1" #swiperRef1 swiperElement [config]="config1" init="false" class="swiper">
                                <swiper-slide class=" page" *ngFor="let page of unlockedBuildings.pages">
                                    <div *ngFor="let building of page">
                                        <div *ngIf="building" class="building-thumbnail-container">
                                            <img [src]="building | buildingIcon : false" placement="bottom" tooltipClass="delayed" container="body" />
                                            <p class="level">
                                                {{ building.name }}
                                            </p>
                                            <p class="level">lvl {{ building.level }}</p>
                                        </div>
                                    </div>
                                </swiper-slide>
                            </swiper-container>

                            <ng-container *ngIf="swiper1">
                                <div class="arrows-slider">
                                    <div class="arrow-blue-left" (click)="prevSlide('swiper1', 'currentSlideIndex1')"
                                         [class.hide]="swiper1?.isBeginning"></div>
                                    <div class="arrow-blue-right" (click)="nextSlide('swiper1', 'currentSlideIndex1')"
                                         [class.hide]="swiper1.isEnd"></div>
                                </div>
                            </ng-container>
                        </div>

                        <!-- UNLOCKED PRODUCTS -->
                        <div class="product-prizes" *ngIf="unlockedProducts.items.length > 0">
                            <!-- SEPARATOR-->
                            <m-ui-separator [title]="'messages.level-up-separator-unlocked-products-title' | myTranslate"></m-ui-separator>

                            <!-- SLIDER 2-->
                            <swiper-container *ngIf="initSwiper2" #swiperRef2 swiperElement [config]="config2" init="false">
                                <swiper-slide class="page" *ngFor="let page of unlockedProducts.pages">
                                    <div *ngFor="let product of page" class="product-container">
                                        <m-ui-product [item]="product" [lack]="false" [hideValue]="true"></m-ui-product>
                                        <span>{{ product.name }}</span>
                                    </div>
                                </swiper-slide>
                            </swiper-container>

                            <ng-container *ngIf="swiper2">
                                <div class="arrows-slider loading-opacity">
                                    <div class="arrow arrow-blue-left" (click)="prevSlide('swiper2', 'currentSlideIndex2')"
                                         [class.hide]="swiper2?.isBeginning"></div>
                                    <div class="arrow arrow-blue-right" (click)="nextSlide('swiper2', 'currentSlideIndex2')"
                                         [class.hide]="swiper2?.isEnd"></div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- MESSAGE TRASH -->
        <i
                class="fas fa-archive trash-icon"
                *ngIf="!message.deleted && message.deletable"
                (click)="moveToTrash()"
                [matTooltip]="'messages.archives' | myTranslate"
                matTooltipPosition="above"
                matTooltipShowDelay="300"></i>
        <div>
        </div>
