<div
  class="hud-player-level"
  (click)="!disabled && openHudTabs()"
  [ngbTooltip]="levelTooltipTpl"
  placement="bottom-left"
  container="body"
  tooltipClass="hud delayed"
>
  <div
    class="inner-circle"
    [style.background-image]="'url('+('rocket.png' | asset:'ui') +')'"
  ></div>

  <div class="level">
    {{ player?.level }}
  </div>
</div>

<ng-template #levelTooltipTpl>
  Punkty doświadczenia<br/>
  {{ player?.points_balance | NumberWithSpaces }} / {{ player?.next_level_points | NumberWithSpaces }}
</ng-template>
