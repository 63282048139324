import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";

import { omitErrorResponseHelper } from "../../../core/helpers/omit-error-response.helper";
import { ApiService } from "../../../core/providers/api.service";
import { ChangePassword } from "../../../modules/auth/interfaces/change-password";
import { TokenObject } from "../../../modules/auth/interfaces/token-object";
import { AuthService } from "../../../modules/auth/providers/auth.service";
import { DialogService } from "../../../modules/shared/providers/dialog.service";
import { AppState } from "../../state";
import { UtilityActions } from "../../utility";
import { ActionTypes, ChangeFailed, ChangeStart, ChangeSuccess, MustChange } from "./actions";

@Injectable()
export class PasswordEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private apiService: ApiService,
    private dialogService: DialogService,
    private store: Store<AppState>
  ) {}

  changePasswordMustChange$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActionTypes.MUST_CHANGE),
        tap((action: MustChange) => {
          this.router.navigate(["/change-password"]);
        })
      ),
    { dispatch: false }
  );

  changePasswordStart$: Observable<ChangeSuccess | ChangeFailed> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.CHANGE_START),
      mergeMap((action: ChangeStart) => {
        return this.changePassword(action.payload.data, action.payload.tokenObject);
      })
    )
  );

  changePassword(data: ChangePassword, tokenObject: TokenObject): Observable<ChangeSuccess | ChangeFailed> {
    return this.authService.changePassword(data).pipe(
      map(() => {
        return new ChangeSuccess();
      }),
      tap(() => {
        this.store.dispatch(new UtilityActions.SetToken(tokenObject));
      }),
      catchError((error: any) => {
        return of(new ChangeFailed(omitErrorResponseHelper(error)));
      })
    );
  }
}
