import { StorageLocationBase } from './location-base.class';

export class StorageLocationLocal extends StorageLocationBase {

  init() {
    super.init();
    this.supported = this.isSupported()
  }

  isSupported() {
    try {
      const key = "checkSupportedLocalStorage";
      localStorage.setItem(key, key);
      const val = localStorage.getItem(key);
      if (val !== key) {
        throw new Error('Local storage value is not equal to the provided one');
      }
      localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }

  get(key) {
    return localStorage.getItem(key);
  }

  set(key, value) {
    return localStorage.setItem(key, value);
  }

  remove(key) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }
}
