<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <!-- RANK NAME -->
    <div class="title">
      {{ 'branch-preview.title' | myTranslate }}
    </div>
  </div>

  <ow-perfect-scrollbar
    *ngIf="branchesPlayers"
    [scrollGradient]="branchesPlayers"
  >
    <table class="loading-opacity">
      <thead>
      <tr>
        <th></th>
        <th>{{ 'branch-preview.table.role' | myTranslate }}</th>
        <th>{{ 'branch-preview.table.level' | myTranslate }}</th>
        <th>{{ 'branch-preview.table.last-login' | myTranslate }}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let player of branchesPlayers">
        <td>
          {{ player.first_name }} {{ player.last_name }}
        </td>

        <td>
          {{ player.title_id.name }}
        </td>

        <td>
          {{ player.level }}
        </td>

        <td>
          {{ player['last_login_at'] ? (player['last_login_at'] | date:'y-MM-dd HH:mm') : '-' }}
        </td>

        <td>
          <button
            class="square small primary"
            (click)="previewPlayerService.changePlayer({player: player, scene: BASIC_BOOT_SCENE})"
            [disabled]="playerService.player.id === player.id"
          >
            <i class="fas fa-search"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </ow-perfect-scrollbar>
</div>
