<div
  class="ow-dialog window-d primary no-transparent"
  [style.max-width]="data.style?.maxWidth"
  [style.max-height]="data.style?.maxHeight"
  [style.height]="data.style?.height"
>
  <!-- DIALOG EXIT -->
  <m-ui-close-button
    [preventDefault]="true"
    (clicked)="close()"
    *ngIf="!data.buttonClose"
  ></m-ui-close-button>

  <!-- TITLE -->
  <p
    class="title"
    *ngIf="data.title"
    [innerHTML]="data.title | safe:'html'"
  ></p>

  <!-- DESCRIPTION -->
  <ow-perfect-scrollbar scrollGradient>
    <p
      class="description"
      [style.text-align]="data.style?.textAlign"
      *ngIf="data.description"
      [innerHTML]="data.description | safe:'html'"
    ></p>
  </ow-perfect-scrollbar>

  <!-- BUTTON CLOSE -->
  <div class="bottom-bar" *ngIf="data.buttonClose">
    <div class="bar-button base">
      <button class="base primary" (click)="matDialogRef.close(false)">
        {{ data.buttonClose.label }}
      </button>
    </div>
  </div>
</div>

