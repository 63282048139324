<div class="global-dialog ow-dialog window-a primary no-transparent">
  <!-- LOADING -->
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button *ngIf="isImpersonate"></m-ui-close-button>

  <!-- DIALOG INNER -->
  <div class="dialog-inner" *ngIf="chest">
    <div>
      <!-- TITLE -->
      <p class="title">
        {{ titleAcquired }}
      </p>

      <!-- IMAGE -->
      <div class="message-image" [style.background-image]="'url(' + (chest.icon + '.png' | asset : 'ui') + ')'"></div>

      <!-- DESCRIPTION -->
      <div class="description" [innerHTML]="descriptionAcquired | safe : 'html'"></div>
    </div>

    <!-- BUTTONS -->
    <div class="action-buttons">
      <!-- COLLECT -->
      <button class="mobile-base secondary text-uppercase" (click)="openAndClaim()">
        {{ buttonOpen }}
      </button>
    </div>
  </div>
</div>
