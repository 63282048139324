<ow-perfect-scrollbar class="ps-bottom-border">
  <ng-container *ngIf="cardTasks?.length">
    <card-task *ngFor="let item of cardTasks"
               [matTooltip]="item.selected?.last_import_at ? 'Dane aktualne na ' + (item.selected?.last_import_at | date: 'dd-MM-yyyy') : ''"
               [matTooltipPosition]="'above'"
               [matTooltipShowDelay]="300"
               [cardTask]="item"
               [cardTasks]="cardTasks"></card-task>
  </ng-container>

  <ng-container *ngIf="cardTasks?.length === 0">
    <p class="no-results">{{"Zadania na miesiąc "+currentPageMonthName.toLowerCase()+" dostępne wkrótce."}}</p>
  </ng-container>

  <ng-container *ngIf="cardTasks == null">
    <loading [showAlways]="true"></loading>
  </ng-container>
</ow-perfect-scrollbar>

<!-- NAVIGATION BUTTONS -->
<div class="bottom-bar">
  <div class="bar-button long">
    <div class="pagination">
      <div class="buttons">
        <!-- PREV -->
        <button class="square small primary"
                [disabled]="blockPrev"
                (click)="!blockPrev && triggerPrev()">
          <i class="fas fa-angle-left"></i>
        </button>
      </div>

      <div class="pages">{{currentPage}}</div>

      <div class="buttons">
        <!-- NEXT -->
        <button
          [disabled]="blockNext" (click)="!blockNext && triggerNext()"
          class="square small primary">
          <i class="fas fa-angle-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>





