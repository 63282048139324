<ng-container *ngIf="!isDialog">
  <div
    class="player-info-container"
    *ngIf="!playerService.isActiveMe"
  >
    <button
      class="square small primary prev"
      (click)="previewPlayerService.changePlayer()"
    >
      <i class="fas fa-angle-left"></i>
    </button>

    {{ playerService.player.first_name }} {{ playerService.player.last_name }}
  </div>

  <div
    class="main-buttons-container"
    *ngIf="playerService.player"
  >
    <ng-container *ngFor="let menuButton of menuButtons; let i = index">
      <ng-container
        *ngIf="menuButton.order <= MENU_BUTTONS_ORDER_DISPLAY || !otherButtonsIsActive"
        [ngTemplateOutlet]="buttonTpl"
        [ngTemplateOutletContext]="{button: menuButton}"
      ></ng-container>
    </ng-container>

    <!--MORE-->
    <ng-container
      *ngIf="otherButtonsIsActive"
      [ngTemplateOutlet]="moreButtonTpl"
    ></ng-container>
  </div>

  <!-- MORE BUTTON TPL -->
  <ng-template #moreButtonTpl>
    <div class="buttons-vertical">
      <button
        class="main-button"
        matTooltip="Więcej"
        [matTooltipShowDelay]="300"
        [matTooltipPosition]="isDialog ? 'below' : 'right'"
        (click)="openHudMenuMore()"
      >
        <img [src]="assetsService.assetsData['/ui/more.png'].path">
      </button>
    </div>
  </ng-template>
</ng-container>

<ng-container *ngIf="isDialog">
  <div
    class="main-buttons-container"
    *ngIf="playerService.player"
  >
    <ng-container *ngFor="let menuButton of menuButtons; let i = index">
      <ng-container
        *ngIf="menuButton.order > MENU_BUTTONS_ORDER_DISPLAY"
        [ngTemplateOutlet]="buttonTpl"
        [ngTemplateOutletContext]="{button: menuButton}"
      ></ng-container>
    </ng-container>
  </div>
</ng-container>


<ng-template
  #buttonTpl
  let-button="button"
>
  <div class="buttons-vertical {{button.icon}}">
    <button
      class="main-button"
      [class.active]="button.isVisible || isDialog"
      [class.inactive]="!playerService.isActiveMe && button.name !== 'warehouse'"
      [class.anim]="button.group_name"
      [matTooltip]="button.tooltip"
      [matTooltipPosition]="isDialog ? 'below' : 'right'"
      [matTooltipShowDelay]="300"
      (click)="handleMenuButtonEvent(button)"
    >
      <img [src]="button.icon | asset: 'ui'"/>

      <ng-container *ngIf="button.customNotification as notification">
        <ng-template
          *ngIf="notification.type === 'async' && (notification.observable | async)"
          [ngTemplateOutlet]="notificationTpl"
        ></ng-template>
      </ng-container>

      <div
        *ngIf="isDialog"
        class="button-name"
      >
        {{ button.tooltip }}
      </div>
    </button>

    <ng-container *ngFor="let menuButton of button.group_buttons">
      <ng-container
        [ngTemplateOutlet]="buttonTpl"
        [ngTemplateOutletContext]="{button: menuButton}"
      ></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #notificationTpl>
  <i class="icon notification"></i>
</ng-template>
