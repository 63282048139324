import { Injectable } from "@angular/core";

import { AssetsService } from "../../../core/providers/assets.service";

@Injectable({
  providedIn: "root",
})
export class WaterAttractionsService {
  constructor(private assetsService: AssetsService) {}

  getWaterAttractionAsset(icon: string, level: number): string {
    const waAssets = this.assetsService.getAssetsByFilenamePart(`wa_${icon}`, "water-attractions/");
    return waAssets ? waAssets.find(asset => asset.includes(`-${level}.png`)) : null;
  }
}
