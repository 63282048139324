import { Injector, Type } from "@angular/core";

export class OwInjector {
  constructor(private injector: Injector, private context: { di: any[] }) {}

  getInject<T>(injectionToken: Type<T>) {
    return this.injector.get(injectionToken);
  }

  getInjectors(injectionsToken: any[]) {
    injectionsToken.forEach(<T>(injectToken: { [key: string]: Type<T> }) => {
      if (typeof injectToken === "object") {
        Object.keys(injectToken).forEach(key => {
          this.context[key] = this.getInject(injectToken[key]);
        });
      }
    });
  }

  getInjectorsFromDI() {
    this.getInjectors(this.context.di || []);
  }
}
