import { Component, OnDestroy, OnInit } from "@angular/core";

import { orientationModes } from "../../../../../../core/consts/core/orientation-modes.enum";
import { getToken } from "../../../../../../core/utility/token";
import { unsubscribeObject } from "../../../../../../core/utility/unsubscribe-array";
import { AbstractLoginComponent } from "../../../../abstract/abstract-login.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent extends AbstractLoginComponent implements OnInit, OnDestroy {
  ngOnInit() {
    if (getToken().token) {
      this.guiService.isSplashShow.next(true);
      this.router.navigate(["/auth/player-choose"]);
    } else {
      this.androidService.setNativeAppOrientation(orientationModes.Auto);
      this.subscribeAuthSuccess();
      this.subscribeUserSuccess();
    }
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
