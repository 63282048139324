<div class="global-dialog" *ngIf="!isLogicNewTab">
  <div class="video-container">
    <iframe
      *ngIf="data.youtubeId"
      id="ytplayer"
      allowFullScreen="allowFullScreen"
      [src]="youtubeEmbed | safe : 'resourceUrl'"
      frameborder="0"></iframe>

    <!-- MOVIE -->
    <div *ngIf="data.src" id="flowplayer-movie" class="flowplayer"></div>

    <!-- DIALOG EXIT -->
    <div class="exit-bar">
      <button class="exit normal" (click)="close()">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
</div>
