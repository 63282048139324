<div class="ow-dialog window-a primary">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <!-- TITLE -->
    <div class="title">{{ "currency-exchange.title" | myTranslate }}</div>
  </div>

  <ng-container *ngIf="allSpreads">
    <div class="container loading-opacity">
      <div class="exchange">
        <div class="exchange-from" (click)="showTooltipCurrencyFrom()">
          <!-- TOOLTIP EXCHANGE FROM -->
          <div class="tooltip-exchange-from" *ngIf="activeTooltipCurrencyFrom">
            <m-ui-currency
              *ngFor="let currencyId of currenciesIdFrom"
              [item]="{ currency_id: currencyId }"
              [stockView]="STOCK_VIEW.B"
              [hideValue]="true"
              [observable]="true"
              [lack]="false"
              (click)="changeActiveCurrencyFrom(currencyId, $event)"
              class="ingredient">
            </m-ui-currency>
          </div>
          <ng-container *ngIf="activeCurrencyFromId">
            <m-ui-currency
              [item]="{ currency_id: activeCurrencyFromId }"
              [hideValue]="true"
              [stockView]="STOCK_VIEW.B"
              [observable]="true"
              [lack]="false"
              (itemChanged)="setMax($event.balance)"></m-ui-currency>
            <span class="spread" *ngIf="spread">
              {{ spread.spread < 100 ? (1 / spread.spread) * 100 : 1 }}
            </span>
          </ng-container>
          <i *ngIf="!activeCurrencyFromId" class="fas fa-question"></i>
        </div>

        <i class="fas fa-arrow-alt-right"></i>

        <div class="exchange-to" (click)="showTooltipCurrencyTo()" [class.cursor]="activeCurrencyFromId">
          <!-- TOOLTIP EXCHANGE TO -->
          <div class="tooltip-exchange-to" *ngIf="activeTooltipSpreadTo">
            <m-ui-currency
              *ngFor="let spread of spreadsTo"
              [item]="{ currency_id: spread.to.currency_id }"
              [stockView]="STOCK_VIEW.B"
              [hideValue]="true"
              [observable]="true"
              [lack]="false"
              (click)="changeSpread(spread, $event)"
              class="ingredient">
            </m-ui-currency>
          </div>
          <ng-container *ngIf="spread">
            <m-ui-currency
              [item]="{ currency_id: spread.to.currency_id }"
              [hideValue]="true"
              [stockView]="STOCK_VIEW.B"
              [lack]="false"></m-ui-currency>
            <span class="spread">
              {{ spread.spread < 100 ? 1 : spread.spread / 100 }}
            </span>
          </ng-container>
          <i *ngIf="!spread" class="fas fa-question"></i>
        </div>
      </div>

      <div class="form-container" *ngIf="form">
        <!-- FORM -->
        <form [formGroup]="form">
          <input
            type="number"
            min="0"
            max="999999999"
            formControlName="amount"
            [placeholder]="'currency-exchange.form.amount-placeholder' | myTranslate"
            [attr.disabled]="!spread ? true : null" />
        </form>

        <!-- SELL ALL -->
        <div class="control control-checkbox">
          <div class="custom-checkbox" [class.disabled]="!spread">
            <input type="checkbox" [(ngModel)]="isSellAllChecked" [disabled]="!spread" (click)="setAmount()" />
            <span class="custom-checkbox-fill">
              <i class="fas fa-check"></i>
            </span>
          </div>
          <label [class.disabled]="!spread">{{ "currency-exchange.form.button-sell-all" | myTranslate }}</label>
        </div>
      </div>

      <!-- SUMMARY -->
      <p class="summary" *ngIf="!form.controls['amount'].hasError('error')">
        {{ "currency-exchange.receive" | myTranslate : [!this.spread ? 0 : (spread.spread * this.amount) / 100] }}
      </p>

      <p class="summary" *ngIf="form.controls['amount'].hasError('error')">
        {{ form.controls["amount"].getError("error") }}
      </p>
    </div>

    <!-- BOTTOM BAR -->
    <div class="bottom-bar">
      <div class="bar-button base">
        <button class="base primary" (click)="exchangeConfirm()" [disabled]="form.invalid">
          {{ "currency-exchange.form.button-exchange" | myTranslate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
