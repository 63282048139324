export const MOCK_QA_LIST = {
  api: [
    {
      last_attempt: null,
      can_answer_from: "2021-06-02T12:11:05+02:00",
      can_answer_to: "2021-06-06T23:59:59+02:00",
      name: "Przydatne informacji o częściach PC, ZKK oraz monitorach",
      description:
        '<p>Cześć!&nbsp;😊 Mamy dla Was przydatne informacje na temat części komputerowych, monitorów oraz zestawów komputerowych. Miłej lektury oraz owocnej sprzedaży!&nbsp;😊</p><p>[pdf src="https://komptown.pl/api/files/download/ce1da786-0cd3-4ccf-88a3-06498894bc8c/062021%20miesi%C4%85%20cz%C4%99%C5%9Bci%20PC%20i%20komputer%C3%B3w_3.pdf"]</p>',
      location: 5,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 117,
      content_at_finish: null,
      max_attempts: 1,
      done_attempts: 0,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2020-07-07T14:52:33+02:00",
        questions: [
          {
            qa_question_id: 50,
            content: "Co wchodzi w zakres ubezpieczenia Life oferowanego przez Santander ?",
            answers: [
              {
                qa_answer_id: 146,
                content: "Śmierć Ubezpieczonego",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 149,
                content: "Poważne zachorowanie",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 152,
                content: "Śmierć Ubezpieczonego i poważne zachorowanie",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 152,
              content: "Śmierć Ubezpieczonego i poważne zachorowanie",
              is_correct: false,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 53,
            content: "Co wchodzi w zakres pakietu Bezpiecznego  „Pakietu Usług dodatkowych”  oferowanego przez Alior Bank ?",
            answers: [
              {
                qa_answer_id: 155,
                content: "Assistance PZU",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 158,
                content: "Alerty BIK",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 161, content: "Wakacje kredytowe", is_correct: false, image_url: null, explanation: null, type: "quiz" },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 158,
              content: "Alerty BIK",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 56,
            content: "Jaki jest miesięczny koszt pakietu Bezpiecznego w „Pakiecie Usług dodatkowych” oferowanego przez Alior Bank ?",
            answers: [
              {
                qa_answer_id: 164,
                content: "10 zł",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 167, content: "3 zł", is_correct: false, image_url: null, explanation: null, type: "quiz" },
              {
                qa_answer_id: 170,
                content: "5 zł",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: { qa_answer_id: 170, content: "5 zł", is_correct: true, image_url: null, explanation: null, type: "quiz" },
            qa_text_answer: null,
          },
        ],
        started_at: "2020-07-07T14:48:39+02:00",
        player_points: 2,
        max_points: 3,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 3000 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2020-07-07T10:39:00+02:00",
      can_answer_to: "2020-07-10T23:00:00+02:00",
      name: "O dodatkowych produktach kredytowych",
      description:
        '<p>Produkty dodatkowe sprzedawane w kredycie to nie tylko bezpieczeństwo finansowe Klient&oacute;w i ich rodzin.<br />\r\nZ jednej strony, to dostęp do nowoczesnych usług bankowych podczas całego okresu kredytowania.<br />\r\nZ drugiej zaś, to r&oacute;wnież spos&oacute;b na zwiększenie swojej premii za sprzedaż kredytu.</p>\r\n\r\n<p>Przejrzyj materiał i zacznij zarabiać więcej!</p>\r\n\r\n<p>[pdf src="https://komptown.pl/api/files/download/ecb2df7c-b5f7-4976-82e4-f0bb62f12183/O%20dodatkowych%20produktach%20kredytowych.pdf"]</p>',
      location: 1,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 14,
      content_at_finish: "<p>Dziękujemy za wypełnienie quizu!</p>",
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2020-07-14T21:55:35+02:00",
        questions: [
          {
            qa_question_id: 59,
            content:
              "Klient przychodzi do Salonu i oznajmia, że chce kupić konkretny laptop w ofercie 0%. Jaki proces obsługi przybliży mnie do dosprzedaży PUD/PUB/Life?",
            answers: [
              {
                qa_answer_id: 173,
                content: "Dosprzedaż produktów i usług powiązanych -> Spisanie wniosku -> Podsumowanie koszyka",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 176,
                content:
                  "Przeprowadzenie skróconego badania potrzeb -> Dosprzedaż produktów i usług powiązanych -> Spisanie wniosku z PUD/PUB/Life -> Podsumowanie koszyka",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 179,
                content: "Spisanie wniosku -> Podsumowanie koszyka",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 176,
              content:
                "Przeprowadzenie skróconego badania potrzeb -> Dosprzedaż produktów i usług powiązanych -> Spisanie wniosku z PUD/PUB/Life -> Podsumowanie koszyka",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 62,
            content:
              "Właśnie zaoferowałeś Klientowi pełny pakiet finansowany ratami (Cross, Konfiguracja, PUD/PUB/Life). Klient wyraża obiekcje co do wysokości raty. Co robisz?",
            answers: [
              {
                qa_answer_id: 182,
                content: "Proponuje, że dorzucę Klientowi na wpłatę własną",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 185,
                content: "Przepraszam Klienta",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 188,
                content:
                  "Przedstawiam raz jeszcze zalety wynikające ze wszystkich wybranych dodatkowych produktów i usług a następnie pytam czy faktycznie chce z czegoś zrezygnować.",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 188,
              content:
                "Przedstawiam raz jeszcze zalety wynikające ze wszystkich wybranych dodatkowych produktów i usług a następnie pytam czy faktycznie chce z czegoś zrezygnować.",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 65,
            content: "Co wnosi oferta ratalna zawierającą w sobie PUD/PUB?",
            answers: [
              {
                qa_answer_id: 191,
                content: "Klient otrzyma dodatkową ochronę od następstw nieszczęśliwych wypadków oraz pakiet Produktów bankowych",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 194,
                content: "Oferta nic nie wnosi; Klient otrzyma jedynie sms-y z informacjami marketingowymi",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 197,
                content: "Klient otrzyma pakiet Produktów Bankowych, w tym min. sms przypominający o terminie spłaty raty kredytu.",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 197,
              content: "Klient otrzyma pakiet Produktów Bankowych, w tym min. sms przypominający o terminie spłaty raty kredytu.",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2020-07-14T21:54:17+02:00",
        player_points: 3,
        max_points: 3,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 4500 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2020-07-13T09:15:00+02:00",
      can_answer_to: "2020-07-17T23:59:00+02:00",
      name: "Dodatkowe Produkty Bankowe – praktyka",
      description:
        '<p>Cześć,&nbsp;&nbsp;</p>\r\n\r\n<p>Wracamy z kolejną porcją wiedzy dotyczącą&nbsp;Produkt&oacute;w Dodatkowych, dostępnych w systemach Bankowych.&nbsp;&nbsp;W&nbsp;ostatnim czasie zanotowaliśmy olbrzymi wzrost zainteresowania klient&oacute;w&nbsp;naszą ofertą ratalną&nbsp;&ndash; w tym&nbsp;opcją&nbsp;&nbsp;&bdquo;10x0&rdquo;.&nbsp;Warto wykorzystać tę szansę przy&nbsp;dosprzedaży&nbsp;Produkt&oacute;w&nbsp;Dodatkowych (PUD, PUB, Life).&nbsp;&nbsp;</p>\r\n\r\n<p>W poprzednim quizie przypomnieliśmy wam podstawowe zasady działania tych produkt&oacute;w oraz możliwości ich włączania w systemach. Teraz czas na praktykę. Oczywiście zdajemy sobie sprawę z tego, że oferta&nbsp;&bdquo;10x0&rdquo;&nbsp;sprawia,&nbsp;że łatwo&nbsp;policzyć wartość jednej raty.&nbsp;Nie obawiaj się&nbsp;&bdquo;klienckiego kalkulatora&rdquo;.&nbsp;Dzięki&nbsp;dosprzedaży&nbsp;produkt&oacute;w powiązanych&nbsp;oddalisz chęć&nbsp;skrupulatnych wyliczeń po stronie Klienta. To z kolei ułatwi Tobie ofertowanie Pud/Pub/Life&nbsp;i&nbsp;pozwoli zarabiać&nbsp;więcej!<br />\r\n<br />\r\nZapoznaj się z zamieszczonym tu materiałem oraz rozwiąż test.<br />\r\nCegły w&nbsp;KompTown&nbsp;czekają. Prowizja r&oacute;wnież. Do boju!&nbsp;</p>\r\n\r\n<p>[pdf src="https://komptown.pl/api/files/download/32bbe2df-1aae-4451-83c5-1128e69040e0/pub%20pud%20life%20.pdf"]</p>',
      location: 1,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 17,
      content_at_finish: "<p>Dziękujemy</p>",
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2021-03-16T13:32:31+01:00",
        questions: [
          {
            qa_question_id: 216,
            content: "Od czego zaczynamy proces proponowania usługi „Ratonament”?",
            answers: [
              {
                qa_answer_id: 603,
                content: "Na starcie informujemy Klienta o istnieniu nowej usługi.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 606,
                content: "Słuchamy uważnie, co mówi Klient i zauważymy „sygnały” do zaproponowania ratonamentu.",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 609,
                content: "Wmawiamy Klientowi, że bez względu na to, gdzie pracuje, na pewno mu się przyda.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 606,
              content: "Słuchamy uważnie, co mówi Klient i zauważymy „sygnały” do zaproponowania ratonamentu.",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 219,
            content: "Która z poniższych wypowiedzi Klienta jest sygnałem do zaproponowania ratonamentu?",
            answers: [
              {
                qa_answer_id: 612,
                content: "Będę oglądał seriale na tym komputerze.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 615,
                content: "Ten sprzęt będzie wykorzystywany do nauki zdalnej mojego 8-letniego syna.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 618,
                content: "Będę robił na nim dużo projektów graficznych.",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 618,
              content: "Będę robił na nim dużo projektów graficznych.",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 222,
            content: "Która cecha ratonamentu nie zgadza się z rzeczywistością?",
            answers: [
              {
                qa_answer_id: 621,
                content: "Przewidywalność opłat – stała miesięczna rata za korzystanie z kompletnego sprzętu",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 624,
                content: "Umowę ratalną zawiera się w banku, natomiast trwa to bardzo szybko.",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 627,
                content: "Istnieje możliwość zwrotu lub odkupienia sprzętu (brak obowiązkowego wykupu jak w przypadku Leasingu).",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 624,
              content: "Umowę ratalną zawiera się w banku, natomiast trwa to bardzo szybko.",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2021-03-16T13:31:25+01:00",
        player_points: 3,
        max_points: 3,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 600 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2021-03-16T11:45:00+01:00",
      can_answer_to: "2021-03-19T23:55:00+01:00",
      name: "Ratonament – jak zaproponować?",
      description:
        '<p style="margin-left:48px">W quizie dowiecie się w jaki spos&oacute;b można zaproponować Klientowi usługę &bdquo;Ratonament&rdquo; oraz jakie korzyści dla obu stron posiada ta usługa.</p>\r\n\r\n<p style="margin-left:48px">[pdf src="https://komptown.pl/api/files/download/90ba71e1-a68d-4b8b-8a38-1475889df428/Ratonament.pdf"]</p>',
      location: 1,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 96,
      content_at_finish: "<p>Dziękujemy</p>",
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2021-04-14T13:16:50+02:00",
        questions: [
          {
            qa_question_id: 229,
            content: "Co robisz gdy Klient ma zamówienie raty 0% w salonie?",
            answers: [
              {
                qa_answer_id: 640,
                content: "Nic, wystawiam dokumenty i sprzedaje dokładnie jak w zamówieniu.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 643,
                content:
                  "Zadbam aby do zamówienia wybrał jak najwięcej produktów i usług dodatkowych i zaproponuję mniejszą ratę w promocyjnym kredycie 0,8%.",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 646,
                content: "Mówię, że nie mamy rat 0%.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 643,
              content:
                "Zadbam aby do zamówienia wybrał jak najwięcej produktów i usług dodatkowych i zaproponuję mniejszą ratę w promocyjnym kredycie 0,8%.",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 232,
            content: "Składałeś wniosek o kredyt dla Klienta na 0%, po czym dostał odmowę, co dalej ?",
            answers: [
              {
                qa_answer_id: 649,
                content: "Nic, dostał odmowę więc musi zapłacić gotówką.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 652,
                content: "Proszę, aby przyszedł z kimś, kto ma lepszą zdolność kredytową.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 655,
                content: "Przekonuje klienta, że raty 0% jest znacznie trudniej dostać, i w zamian proponuję promocyjne raty 0,8%.",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 655,
              content: "Przekonuje klienta, że raty 0% jest znacznie trudniej dostać, i w zamian proponuję promocyjne raty 0,8%.",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 235,
            content: "Klient wchodzi do salonu i z marszu mówi: Poproszę ten laptop w ratach 0%. Co robisz?",
            answers: [
              {
                qa_answer_id: 658,
                content:
                  "Pakuję sprzęt i wypełniam wniosek na „zerówki”. Dobrze, że ktokolwiek dzisiaj przyszedł do salonu – myślisz sobie.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 661,
                content: "Odpowiadam Klientowi, że nie mamy takich rat. Jaki Klient wyjdzie – trudno... I tak nie zarabiam na zerówkach.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 664,
                content:
                  "Klient jest w salonie, więc połowa sukcesu osiągnięta. Teraz pozostaje tylko zarzucić dobrze pytanie w stylu szach mat i przejść płynnie do nawet najprostszego badania potrzeb. Być może potrzeby Klienta są znacznie większe niż impuls, który ściągnął do po promocje „0%”",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 664,
              content:
                "Klient jest w salonie, więc połowa sukcesu osiągnięta. Teraz pozostaje tylko zarzucić dobrze pytanie w stylu szach mat i przejść płynnie do nawet najprostszego badania potrzeb. Być może potrzeby Klienta są znacznie większe niż impuls, który ściągnął do po promocje „0%”",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2021-04-14T13:16:05+02:00",
        player_points: 3,
        max_points: 3,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 2550 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2021-04-13T10:54:02+02:00",
      can_answer_to: "2021-04-17T00:00:00+02:00",
      name: "Jak zarabiać więcej na kredycie marżowym!",
      description:
        '<p>Cześć!</p><p>Kwiecień plecień...może gotówka, a może jednak rata? :)&nbsp;</p><p>Ogłaszamy, że w tym miesiącu skupiamy się na kredycie, a konkretnie na dosprzedaży rat większych niż "0%".&nbsp;</p><p>Klienci wiedzą, że taka promocja istnieje. Chętnie odwiedzą Wasze salony, więc teraz pozostaje tylko skupienie się na konwersji na odpowiednią składkę i koszyk usług.</p><p>Rzecz jasna, wesprzemy Was ciekawymi Quizami!&nbsp;</p><p>Powodzenia!&nbsp;</p><p>[pdf src="https://komptown.pl/api/files/download/7f3a4246-139c-4de8-8a1a-cb3edba11dcb/Quiz%201%20-%20kredyt.pdf"]</p>',
      location: 1,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 103,
      content_at_finish: null,
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2021-04-21T00:18:06+02:00",
        questions: [
          {
            qa_question_id: 238,
            content: "Klient już od wejścia pyta, czy mamy raty 0%, co robisz?",
            answers: [
              {
                qa_answer_id: 667,
                content: "mówię, że są i sprzedaje 0%",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 670,
                content: "mówię, że nie ma, szkoda zachodu o coś na czym nie zarabiam",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 673,
                content: "zaproponuję raty 0% gdy weźmie gwarancję",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 676,
                content:
                  "mówię, że mamy sporo promocji ratalnych, i że ofertę otrzymam i przedstawię po wybraniu sprzętu i wysyłce wniosku ratalnego do banku",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 676,
              content:
                "mówię, że mamy sporo promocji ratalnych, i że ofertę otrzymam i przedstawię po wybraniu sprzętu i wysyłce wniosku ratalnego do banku",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 241,
            content: "Klient jeszcze nie wybrał sprzętu, a już chciałby znać koszt kredytu przy kwocie x",
            answers: [
              {
                qa_answer_id: 679,
                content:
                  "przekonuję go, że najpierw trzeba dokładnie zbadać jego potrzeby i wysłać do banku wniosek zawierający wszystkie wybrane produkty i usługi",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 682,
                content: "wpisuję kwotę x do aplikacji kredytowej, uzyskuję ratę i ją przedstawiam",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 685,
                content: "biorę kalkulator i liczę",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 688,
                content: "informuję, że jak chce na już, to ma do dyspozycji stronę www",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 679,
              content:
                "przekonuję go, że najpierw trzeba dokładnie zbadać jego potrzeby i wysłać do banku wniosek zawierający wszystkie wybrane produkty i usługi",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 244,
            content: "Pojawiły się pierwsze obiekcje dotyczące rat, co robisz?",
            answers: [
              {
                qa_answer_id: 691,
                content: "przyznaję klientowi rację i proponuję coś innego",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 694,
                content: "znajduję prawdziwe źródło obiekcji i szukam rozwiązania",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 697,
                content: "powiem stanowczo: „nie ma Pan/Pani racji, prawdą jest to co mówię …”",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 700,
                content: "daję klientowi czas do namysłu i robię rezerwację towaru",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 694,
              content: "znajduję prawdziwe źródło obiekcji i szukam rozwiązania",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 247,
            content:
              "Klient powiedział, że rata kredytu jest za wysoka, zaproponowałeś kontrpropozycję i usłyszałeś „wygląda to już trochę lepiej”, co dalej?",
            answers: [
              {
                qa_answer_id: 703,
                content: "to oznacza, że klient zaakceptował ofertę i przechodzę do drukowania umowy",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 706,
                content: "poczekam aż sam powie, że mam wydrukować umowę",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 709,
                content: "przedstawię jeszcze lepszą ofertę i dowiem się, że jest to to, czego oczekiwał",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 712,
                content: "uzyskam jednoznaczną akceptację np. poprzez pytanie „czyli rozumiem, że akceptuje Pan ofertę.”",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 712,
              content: "uzyskam jednoznaczną akceptację np. poprzez pytanie „czyli rozumiem, że akceptuje Pan ofertę.”",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2021-04-21T00:16:49+02:00",
        player_points: 4,
        max_points: 4,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 2600 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2021-04-20T08:56:52+02:00",
      can_answer_to: "2021-04-24T00:00:00+02:00",
      name: "Raty cz.2",
      description:
        '<p>Cześć!</p><p>Nie do końca wiesz jak postępować podczas obiekcji klienta w sprawie rat > 0%? Czy może nie wiesz, co odpowiedzieć na pytanie „Czy macie raty 0%”? No to jak najszybciej zapoznaj się z materiałem&nbsp;😊&nbsp;</p><p>[pdf src="https://komptown.pl/api/files/download/9dcd86ba-04a9-4d17-88fc-425c2f39b0c7/Raty%20cz.2.pdf"]</p>',
      location: 1,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 106,
      content_at_finish: null,
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2021-04-27T20:11:11+02:00",
        questions: [
          {
            qa_question_id: 250,
            content:
              "Podziel się z nami, jak reagujesz w następującej sytuacji:\r\nW trakcie badania potrzeb wspomniałeś Klientowi o możliwości sfinansowania zakupów ratami. Klient, odpowiada charyzmatycznie: „jak zerówki, to się zastanowię...”. Co odpowiesz?",
            answers: [],
            is_open: true,
            type: "survey",
            selected_answer: null,
            qa_text_answer: "Odpowiem ze do tematu rat przejdziemy później ale na pewno dopasujemy odpowiednia ofertę dla Pana.",
          },
        ],
        started_at: "2021-04-27T20:09:50+02:00",
      },
      can_answer_from: "2021-04-27T08:58:12+02:00",
      can_answer_to: "2021-04-30T23:59:59+02:00",
      name: "Raty 0% vs Kredyt marżowy – najlepsze praktyki!",
      description:
        "<p><i>Cześć! Miesiąc z akcją specjalną, której celem był wzrost sprzedaży kredytów marżowych (&gt;0%) powoli dobiega końca. Przed Wami ostatnia misja, czyli ankieta. Chcemy, abyście podzielili się swoimi najlepszymi zwrotami we wskazanej sytuacji.</i></p>",
      location: 1,
      product_prizes: [],
      currency_prizes: [{ currency_id: 1, currency_type: "player", amount: 2500 }],
      qa_id: 109,
      content_at_finish: "<p>Dziękujemy za podzielenie się swoim doświadczeniem!&nbsp;😊&nbsp;</p>",
      type: "survey",
    },
    {
      last_attempt: {
        finished_at: "2020-06-26T10:28:07+02:00",
        questions: [
          {
            qa_question_id: 38,
            content: "Na którym etapie można zdobywać wiedzę o Kliencie w kontekście polisy?",
            answers: [
              {
                qa_answer_id: 110,
                content: "Przywitaniu Klienta",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 113,
                content: "Badaniu potrzeb",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 116,
                content: "Obie odpowiedzi są prawidłowe",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 116,
              content: "Obie odpowiedzi są prawidłowe",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 41,
            content: "Dzięki któremu argumentowi możesz najskuteczniej rozbić obiekcję dotyczącą ceny polisy ?",
            answers: [
              {
                qa_answer_id: 119,
                content: "Uzasadniając, że polisa pochodzi od uznanych ubezpieczalniami",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 122,
                content: "Porównując jej cenę do kosztu najprostszych napraw komputera",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 125,
                content: "Wspominając, że polisa to jakby AC dla samochodu",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 122,
              content: "Porównując jej cenę do kosztu najprostszych napraw komputera",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 44,
            content: "Z której metody możesz skorzystać by rozbić obiekcje Klienta ?",
            answers: [
              {
                qa_answer_id: 128,
                content: "Szach-mat, czyli Twój własny, skuteczny sposób na rozwianie wątpliwości Klienta",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 131,
                content: "Wyłącznie z propozycji które są zawarte w dokumencie",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 134,
                content: "Obie odpowiedzi są poprawne",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 128,
              content: "Szach-mat, czyli Twój własny, skuteczny sposób na rozwianie wątpliwości Klienta",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 47,
            content: "Co zyskasz dzięki metodzie kartki ?",
            answers: [
              {
                qa_answer_id: 137,
                content: "Klient spędzi dużo więcej czasu w Salonie",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 140,
                content: "W prosty sposób obrazujesz Klientowi naszą całą polisową ofertę",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 143,
                content: "Klient nie będzie w stanie samodzielnie określić, który wariant jest dla niego odpowiedni",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 140,
              content: "W prosty sposób obrazujesz Klientowi naszą całą polisową ofertę",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2020-06-26T10:27:08+02:00",
        player_points: 4,
        max_points: 4,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 6000 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2020-06-26T08:00:00+02:00",
      can_answer_to: "2020-06-29T23:59:00+02:00",
      name: "Sprzedaż polis w praktyce",
      description:
        '<p>Zapraszamy do zapoznania się z e-learningiem <strong>&bdquo;Sprzedaż polis w praktyce&rdquo;.</strong><br />\r\nZa chwilę zapoznasz się z materiałem, w kt&oacute;rym prezentujemy najlepsze praktyki sprzedaży polis.<br />\r\nNa bazie najlepszych zwrot&oacute;w z salon&oacute;w, wypracujesz swoje własne i unikatowe sprzedażowe flow.<br />\r\nSprzedawaj! Naturalnie!</p>\r\n\r\n<p>[pdf src="https://komptown.pl/api/files/download/20f71501-9549-4b6b-9f11-226f00ad04da/HD2%20Pilisa%20FINAL.pdf"]</p>',
      location: 3,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 11,
      content_at_finish: "<p>Dziękujemy</p>",
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2020-07-21T11:45:02+02:00",
        questions: [
          {
            qa_question_id: 68,
            content: "Co robisz, gdy Klient po raz pierwszy odniesie się do niższej kwoty likwidacji niż na fakturze?",
            answers: [
              {
                qa_answer_id: 200,
                content: "Uspokajam go. Informuję, że zgodnie z OWU został zaproponowany sprzęt o nie gorszych parametrach",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 203,
                content: "Informuję, że istnieje możliwość dopłaty do wymienianego sprzętu",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 206,
                content: "Informuję o możliwości złożenia odwołania do ubezpieczyciela",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 200,
              content: "Uspokajam go. Informuję, że zgodnie z OWU został zaproponowany sprzęt o nie gorszych parametrach",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 71,
            content: "Jaka jest dla Ciebie największa zaleta likwidacji polisy ?",
            answers: [
              {
                qa_answer_id: 209,
                content: "Możliwość obsługi Klienta",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 212,
                content: "Możliwość sprzedaży kolejnej polisy",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 215,
                content: "Możliwość wyszukania numeru kontrahenta ubezpieczyciela na Sharepoint",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 212,
              content: "Możliwość sprzedaży kolejnej polisy",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 74,
            content: "Co robisz gdy Klient ostatecznie, kategorycznie nie zgadza się na niższą kwotę likwidacji niż ta z faktury ?",
            answers: [
              {
                qa_answer_id: 218,
                content: "Informuję o możliwości dopłaty",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 221,
                content: "Informuję o możliwości przedłużenia gwarancji na nowy sprzęt",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 224,
                content: "Informuję o możliwości złożenia odwołania",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 224,
              content: "Informuję o możliwości złożenia odwołania",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2020-07-21T11:43:18+02:00",
        player_points: 3,
        max_points: 3,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 4500 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2020-07-21T09:00:00+02:00",
      can_answer_to: "2020-07-24T23:59:00+02:00",
      name: "OWU w Gwarancji Beztroski",
      description:
        '<p><em>Podstawowa wiedza w zakresie &bdquo;OWU&rdquo; poszczeg&oacute;lnych ubezpieczycieli to obowiązek każdego Doradcy.<br />\r\nZnajomość tego zagadnienia, pozwala Tobie na każdym kroku sprzedaży na swobodnie rozmawiać z Klientem o polisie.<br />\r\nZobacz jakie to proste, przejrzyj materiał i zacznij zarabiać więcej!</em></p>\r\n\r\n<p>[pdf src="https://komptown.pl/api/files/download/49cf51b0-1ef3-46ae-bbc2-aeb571f53555/OWU%20Prezentacja.pdf"]</p>',
      location: 3,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 20,
      content_at_finish: "<p>Dziękujemy</p>",
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2020-07-28T14:02:08+02:00",
        questions: [
          {
            qa_question_id: 77,
            content: "Podczas prezentacji oferty za pomocą kartki, muszę pamiętać o tym, aby:",
            answers: [
              {
                qa_answer_id: 227,
                content: "Zaprezentować ofertę również w postaci ratalnej",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 230,
                content: "Rysować równe strzałki",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 233,
                content: "Przedstawić tylko jedną ofertę",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 227,
              content: "Zaprezentować ofertę również w postaci ratalnej",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 80,
            content: "Przedstawiłeś klientowi za pomocą metody kartki wariant GN36 lub GN48. Klient odmówił, co dalej:",
            answers: [
              {
                qa_answer_id: 236,
                content: "Przyjmiesz płatność, wydasz sprzęt i zakończysz sprzedaż",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 239,
                content: "Zapytasz o przyczynę, obalisz obiekcję lub zaproponujesz PG24 i uzyskasz akceptację",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 242,
                content: "Zapytasz: „czy jest Pan/Pani pewna?”",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 239,
              content: "Zapytasz o przyczynę, obalisz obiekcję lub zaproponujesz PG24 i uzyskasz akceptację",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 83,
            content:
              "Klient kupuje komputer stacjonarny. Który z wariantów jest najlepszym, możliwym do zrealizowania i da Ci najwięcej złotych monet w Komp Town?",
            answers: [
              {
                qa_answer_id: 245,
                content: "GN 60",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 248, content: "GN 48", is_correct: true, image_url: null, explanation: null, type: "quiz" },
              {
                qa_answer_id: 251,
                content: "PG 24",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: { qa_answer_id: 248, content: "GN 48", is_correct: true, image_url: null, explanation: null, type: "quiz" },
            qa_text_answer: null,
          },
        ],
        started_at: "2020-07-28T14:01:08+02:00",
        player_points: 3,
        max_points: 3,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 4500 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2020-07-28T08:15:00+02:00",
      can_answer_to: "2020-07-31T23:59:00+02:00",
      name: "Dostępne warianty Gwarancji Beztroski",
      description:
        '<p><em>Z poprzedniego szkolenia znasz już &bdquo;OWU&rdquo;.<br />\r\nPorozmawiajmy zatem jakie warianty mamy do dyspozycji.</em></p>\r\n\r\n<p><em>PU &ndash; Przypadkowe Uszkodzenia</em></p>\r\n\r\n<p><em>PG -&nbsp; Przedłużona Gwarancja</em></p>\r\n\r\n<p><em>GN &ndash; Przypadkowe Uszkodzenia + Przedłużona Gwarancja&nbsp;</em></p>\r\n\r\n<p><em>Jak widać, w zależności od potrzeb Klienta możemy dobrać optymalny wariant &ndash; pozostaje tylko proponować te możliwości naszym Klientom :)</em></p>\r\n\r\n<p>[pdf src="https://komptown.pl/api/files/download/17419a36-06fa-4b90-a360-059dac764706/Quiz%207%20-%20materia%C5%82.pdf"]</p>',
      location: 3,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 23,
      content_at_finish: "<p>Dziękujemy za udział w quizie</p>",
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2020-09-23T11:21:26+02:00",
        questions: [
          {
            qa_question_id: 127,
            content: "Hospitalizacja ubezpieczonego na skutek NNW bez minimum dobowego jest (Santander):",
            answers: [
              {
                qa_answer_id: 361,
                content: "w każdym wariancie",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 364,
                content: "tylko w wariancie IV",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 367, content: "tylko w wariancie V", is_correct: true, image_url: null, explanation: null, type: "quiz" },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 367,
              content: "tylko w wariancie V",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 130,
            content: "W jakim przypadku ubezpieczony otrzyma 100% salda zadłużenia?",
            answers: [
              {
                qa_answer_id: 370,
                content: "Śmierć ubezpieczonego",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 373,
                content: "Trwała i całkowita niezdolność do pracy",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 376,
                content: "W obu wyżej wymienionych przypadkach",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 376,
              content: "W obu wyżej wymienionych przypadkach",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 133,
            content: "Jaki zakres ochrony (cechy) w pierwszej kolejności zaproponujesz studentowi?",
            answers: [
              {
                qa_answer_id: 379,
                content: "Na wypadek śmierci ubezpieczonego",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 382,
                content: "Ochrona w sytuacji czasowej niezdolność do pracy",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 385,
                content: "Na wypadek hospitalizacja ubezpieczonego na skutek choroby",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 382,
              content: "Ochrona w sytuacji czasowej niezdolność do pracy",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2020-09-23T11:20:17+02:00",
        player_points: 3,
        max_points: 3,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 4500 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2020-09-22T09:45:00+02:00",
      can_answer_to: "2020-09-25T23:59:00+02:00",
      name: "Ubezpieczenie Life w praktyce",
      description:
        '<p>Jedziemy dalej z akcją &bdquo;DODATKOWE&rdquo; w ramach kt&oacute;rej przybliżamy Wam dodatkowe produkty bankowe w kredycie.</p>\r\n\r\n<p>Tym razem czas na &bdquo;LIFE&rdquo;. Znajdziecie w nim ciekawe sposoby prezentowania tego produktu.<br />\r\nByć może część z nich będziecie mogli wykorzystać już dzisiaj.</p>\r\n\r\n<p>[pdf src="https://komptown.pl/api/files/download/1c5cb815-19e1-4b1f-a800-aae7426aff42/QUIZ%2012.pdf"]&nbsp;</p>',
      location: 3,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 43,
      content_at_finish: "<p>Dziękujemy za wzięcie udziału w quizie.</p>",
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2020-10-07T20:37:57+02:00",
        questions: [
          {
            qa_question_id: 136,
            content: "Co zawiera w sobie pakiet Pełen GN?",
            answers: [
              {
                qa_answer_id: 388,
                content: "Ochronę przed awarią sprzętu oraz przed przypadkowym uszkodzeniem",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 391,
                content: "Ochrona przed awarią sprzętu",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 394,
                content: "Ochrona przed przypadkowym uszkodzeniem",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 388,
              content: "Ochronę przed awarią sprzętu oraz przed przypadkowym uszkodzeniem",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 139,
            content:
              "Klient przebywa w podróży zagranicznej. Podczas podróży został uszkodzony sprzęt chroniony Gwarancją Beztroski. Co w tym wypadku powinien zrobić Klient:",
            answers: [
              {
                qa_answer_id: 397,
                content: "Zgłosić się do Salonu i poprosić handlowca o pomoc",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 400,
                content: "W tym wypadku nie można ubiegać się o naprawę",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 403,
                content: "Zgłosić zdarzenie do ubezpieczyciela",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 403,
              content: "Zgłosić zdarzenie do ubezpieczyciela",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 142,
            content: "Pakiet Pełen GN chroni przed:",
            answers: [
              {
                qa_answer_id: 406,
                content: "Kradzieżą zwykłą",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 409,
                content: "Kradzieżą z włamaniem/rabunkiem",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 412, content: "Zagubieniem", is_correct: false, image_url: null, explanation: null, type: "quiz" },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 409,
              content: "Kradzieżą z włamaniem/rabunkiem",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2020-10-07T20:37:42+02:00",
        player_points: 3,
        max_points: 3,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 2400 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2020-10-06T15:30:00+02:00",
      can_answer_to: "2020-10-09T23:59:00+02:00",
      name: "Pełny pakiet GN – wiedza produktowa",
      description:
        '<p>W październiku odpalamy akcję specjalną <strong>Pełny pakiet (GN).</strong> Oznacza to, że tym samym rozpoczynamy cykl Quiz&oacute;w poświęconych temu zakresowi. Na początek kilka podstawowych informacji w zakresie wiedzy produktowej.</p>\r\n\r\n<p>&nbsp;</p>\r\n\r\n<p>[pdf src="https://komptown.pl/api/files/download/82875a18-27b6-439d-b6c7-c5d20b9164ee/Pe%C5%82ny%20pakiet%20GN.pdf"]&nbsp;</p>',
      location: 3,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 46,
      content_at_finish: "<p>Dziękujemy</p>",
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2020-10-14T17:44:19+02:00",
        questions: [
          {
            qa_question_id: 143,
            content: "Wymagane nasycenia GN w październiku to:",
            answers: [
              {
                qa_answer_id: 413,
                content: ">40%, >50%, <70%",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 416,
                content: ">30%, >50%, >70%",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 419, content: ">40%, >50%, >70%", is_correct: true, image_url: null, explanation: null, type: "quiz" },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 419,
              content: ">40%, >50%, >70%",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 146,
            content: "Efekt skali w przypadku nasycenia GN dotyczy sytuacji, w której:",
            answers: [
              {
                qa_answer_id: 422,
                content: "Zawsze omawiam z Klientem OW",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 425,
                content: "Proponuję najniższe warianty GB",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 428,
                content:
                  "Przy każdej możliwej sytuacji edukuję Klienta w zakresie GN, rozmawiam z nim o tym wariancie. Im więcej „zasieję”, tym więcej zbiorę.",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 428,
              content:
                "Przy każdej możliwej sytuacji edukuję Klienta w zakresie GN, rozmawiam z nim o tym wariancie. Im więcej „zasieję”, tym więcej zbiorę.",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 149,
            content: "Proponując GN, warto posiłkować się znanymi i sprawdzonymi technikami, np.:",
            answers: [
              {
                qa_answer_id: 431,
                content: "Zdechła ryba",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 434,
                content: "Dobry-zły policjant",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 437, content: "PCK i SPIN zawsze spoko", is_correct: true, image_url: null, explanation: null, type: "quiz" },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 437,
              content: "PCK i SPIN zawsze spoko",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2020-10-14T17:43:29+02:00",
        player_points: 3,
        max_points: 3,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 2400 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2020-10-13T14:45:00+02:00",
      can_answer_to: "2020-10-16T23:59:00+02:00",
      name: "Pakiet GN – Analiza",
      description:
        '<p>Trochę liczb i danych na temat nasycenia GN w połowie rozgrywki.</p>\r\n\r\n<p>[pdf src="https://komptown.pl/api/files/download/66d44486-b4c1-4fa2-a996-98760173856d/Quiz.pdf"]</p>\r\n\r\n<p>&nbsp;</p>',
      location: 3,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 47,
      content_at_finish: "<p>Dziękujemy</p>",
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2020-10-20T17:33:46+02:00",
        questions: [
          {
            qa_question_id: 152,
            content: "Strategia „drzwiami w twarz” polega na:",
            answers: [
              {
                qa_answer_id: 440,
                content: "Zaproponowaniu najniższego pakietu Gwarancji Beztroski",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 443,
                content: "To innymi słowy Badanie Potrzeb Klienta",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 446,
                content:
                  "Rozpoczęciu sprzedaży od przedstawienia Pakietu Pełnego jako najwyższego pakietu ubezpieczenia i ewentualne zejście do niższych wariantów",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 446,
              content:
                "Rozpoczęciu sprzedaży od przedstawienia Pakietu Pełnego jako najwyższego pakietu ubezpieczenia i ewentualne zejście do niższych wariantów",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 155,
            content:
              "Otrzymanie zgody od Klienta na zakup najniższego pakietu Gwarancji Beztroski jest początkiem rozmów do sprzedaży GN. Jest to efekt zastosowania strategii:",
            answers: [
              {
                qa_answer_id: 449,
                content: "SOK",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 452,
                content: "Drzwiami w twarz",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 455, content: "Stopa w drzwiach", is_correct: true, image_url: null, explanation: null, type: "quiz" },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 455,
              content: "Stopa w drzwiach",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 158,
            content: "Klient nie ma przy sobie gotówki, aby opłacić najwyższy pakiet Gwarancji Beztroski. Co zaproponujesz?",
            answers: [
              {
                qa_answer_id: 458,
                content: "Raty 0% z Pakietem Pełnym",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 461,
                content: "Dorzucę Klientowi, aby tylko zrobić plan",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 464,
                content: "Zaprzestanę sprzedaży Gwarancji Beztroski",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 458,
              content: "Raty 0% z Pakietem Pełnym",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2020-10-20T17:33:12+02:00",
        player_points: 3,
        max_points: 3,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 2400 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2020-10-20T13:45:00+02:00",
      can_answer_to: "2020-10-23T23:59:00+02:00",
      name: "Pakiet GN – Jak to sprzedaż?",
      description:
        '<p><em>Zgodnie z wcześniejszymi materiałami, budowanie zasadności wyboru Gwarancji Beztroski rozpoczynamy już podczas rozmowy handlowej, a konkretniej na etapie BADANIA POTRZEB. W tym materiale prezentujemy zestawienie przykładowych kotwic sprzedażowych z badania potrzeb z ich p&oacute;źniejszym prezentowaniem</em></p>\r\n\r\n<p>[pdf src="https://komptown.pl/api/files/download/5fa91c70-fff0-480c-b950-2c64651d1329/QUIZ%2015.pdf"]</p>',
      location: 3,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 50,
      content_at_finish: "<p>Dziękujemy</p>",
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2020-06-11T11:42:38+02:00",
        questions: [
          {
            qa_question_id: 2,
            content: 'Klient: "Potrzebuję szybki, multimedialny komputer dla całej rodziny”.',
            answers: [
              {
                qa_answer_id: 2,
                content:
                  "Powiem: „Super, skoro zamierza Pan/Pani używać sprzęt rodzinnie, to proponuję, aby wybrać sprzęt wraz z pakietem bezpiecznym…”",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 5,
                content: "Na pewno będzie to komputer stacjonarny, złożę go z części",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 8,
                content: "Mamy tu i7, 320ssd, GeForce 1050 …",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 2,
              content:
                "Powiem: „Super, skoro zamierza Pan/Pani używać sprzęt rodzinnie, to proponuję, aby wybrać sprzęt wraz z pakietem bezpiecznym…”",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 5,
            content: "Sprzęt będzie użytkowany mobilnie, w biurze do pracy, a w domu do gier.",
            answers: [
              {
                qa_answer_id: 11,
                content: "Na pewno będzie do tego faktura.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 14,
                content:
                  "Powiem: „Sprzęt będzie użytkowany min. do gier, dlatego aby działały one płynnie, skonfiguruję system, kartę graficzną i dokonam aktualizacji.”",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 17,
                content: "Powiem: „A czy chce Pan/Pani do tego polisę?",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 14,
              content:
                "Powiem: „Sprzęt będzie użytkowany min. do gier, dlatego aby działały one płynnie, skonfiguruję system, kartę graficzną i dokonam aktualizacji.”",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 8,
            content: "Komputer przeznaczony dla 14 letniej Córki – robienie lekcji on-line.",
            answers: [
              {
                qa_answer_id: 20,
                content: "Zapytam: „Czy nie chce Pan/Pani pakietu start”",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 23,
                content: "Na pewno sama da sobie radę z pierwszym uruchomieniem.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 26,
                content:
                  "Powiem: „Zatem sprzęt będzie użytkowany przez córkę. Dlatego też niezbędnym jest skonﬁgurowanie na miejscu laptopa z antywirusem tak, żeby dziecko było w Internecie bezpieczne już od momentu pierwszego uruchomienia komputera…”",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 26,
              content:
                "Powiem: „Zatem sprzęt będzie użytkowany przez córkę. Dlatego też niezbędnym jest skonﬁgurowanie na miejscu laptopa z antywirusem tak, żeby dziecko było w Internecie bezpieczne już od momentu pierwszego uruchomienia komputera…”",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 11,
            content: "Klient przyszedł po odbiór zamówionego sprzętu i w zamówieniu nic nie ma o dodatkowych usługach.",
            answers: [
              {
                qa_answer_id: 29,
                content: "Skoro nie ma usługi w zamówieniu, zapewne klient niczego dodatkowego nie chce.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 32,
                content: "Jest kolejka o nic nie będę pytał, obsłużę kolejnego klienta.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 35,
                content:
                  "Skorzystam z cennika usług stojącego obok mnie i przedstawię korzyści wynikające z wykonania dowolnej usługi przez profesjonalistę jakim jestem.",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 35,
              content:
                "Skorzystam z cennika usług stojącego obok mnie i przedstawię korzyści wynikające z wykonania dowolnej usługi przez profesjonalistę jakim jestem.",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2020-06-11T11:41:51+02:00",
        player_points: 4,
        max_points: 4,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 6000 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2020-06-09T09:45:00+02:00",
      can_answer_to: "2020-06-12T23:59:00+02:00",
      name: "Najlepsze praktyki w sprzedaży Usług Komplementarnych2tst",
      description:
        '<p>Zapraszamy Cię do zapoznania się z nowym e-learningiem z Usług Komplementarnych.<br />\r\nPrezentujemy w nim najlepsze praktyki dotyczące skutecznego doradztwa wybranych usług konfiguracyjnych.</p>\r\n\r\n<p>[pdf src="https://komptown.pl/api/files/download/192c3e0e-8006-4098-ba02-a0341cfe2b66/HD2%20Us%C5%82ugi%20Komplementarne%20%28Final%29.pdf"]</p>\r\n\r\n<p>[youtube id="SNVNyRSg3Pk"]</p>',
      location: 4,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 2,
      content_at_finish: "<p>Dziękujemy za wypełnienie&nbsp;</p>",
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2020-06-19T20:10:58+02:00",
        questions: [
          {
            qa_question_id: 26,
            content: "Która z poniższych sytuacji jest najbardziej optymalna i naturalna aby zapytać Klienta, czy chciałby wziąć Leasing:",
            answers: [
              {
                qa_answer_id: 74,
                content: "Zaraz po przywitaniu klienta",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 77,
                content: "Gdy dowiemy się, że zakupiony produkt ma być na firmę",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 80,
                content: "Na końcu sprzedaży podczas finalizacji",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 77,
              content: "Gdy dowiemy się, że zakupiony produkt ma być na firmę",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 29,
            content: "Dowiedziałeś się, że sprzęt będzie brany za gotówkę, co powiesz klientowi?",
            answers: [
              {
                qa_answer_id: 83,
                content: "Nic, gotówka, więc nie ma sensu rozmowy na temat leasingu",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 86,
                content: "Jak zapyta to powiem również o leasingu",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 89,
                content: "Wyjaśnię, że mamy korzystną alternatywę w postaci Leasingu, a decyzję możemy otrzymać w 10 minut",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 89,
              content: "Wyjaśnię, że mamy korzystną alternatywę w postaci Leasingu, a decyzję możemy otrzymać w 10 minut",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 32,
            content: "Na czym polegają korzyści podatkowe leasingu?",
            answers: [
              {
                qa_answer_id: 92,
                content:
                  "Dzięki leasingowi klient otrzymuje możliwość wliczania całości opłat leasingowych w koszty uzyskania przychodu (wkład własny oraz wykup również jest kosztem).",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 95,
                content: "Na rozliczeniu w picie",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 98,
                content: "Klient płaci mniejsze podatki.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 92,
              content:
                "Dzięki leasingowi klient otrzymuje możliwość wliczania całości opłat leasingowych w koszty uzyskania przychodu (wkład własny oraz wykup również jest kosztem).",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 35,
            content: "Jedną z korzyści leasingu, które możesz poruszyć w czas prezentacji produktu jest:",
            answers: [
              {
                qa_answer_id: 101,
                content:
                  "Klient może wziąć w leasing sprzęty już od pierwszego dnia prowadzenia działalności gospodarczej bez żadnych zaświadczeń ZUS/US, weksli, poręczeń itp.",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 104,
                content: "Możliwość wymiany sprzętu na nowsze technologicznie produkty już po 10 latach.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 107,
                content: "Dostęp do najnowszych nowinek technologicznych z koniecznością angażowania dużych środków własnych na ich zakup.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 101,
              content:
                "Klient może wziąć w leasing sprzęty już od pierwszego dnia prowadzenia działalności gospodarczej bez żadnych zaświadczeń ZUS/US, weksli, poręczeń itp.",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2020-06-19T20:10:39+02:00",
        player_points: 4,
        max_points: 4,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 6000 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2020-06-19T14:30:00+02:00",
      can_answer_to: "2020-06-22T23:59:00+02:00",
      name: "Sprzedaż leasingu w praktyce",
      description:
        '<p>Szkolenie, kt&oacute;re jest przed Tobą dotyczy skutecznego proponowania produktu finansowego, jakim jest leasing. Pamiętaj! Im części zaproponujesz Klientowi taki spos&oacute;b finansowania zakup&oacute;w, tym bardziej zwiększysz swoją szansę na obfitą sprzedaż. To z kolei, pozytywnie wpływa na Twoje zarobki.</p>\r\n\r\n<p>[pdf src="https://komptown.pl/api/files/download/8a7d91e7-affa-4967-8203-20084fc4d638/HD2%20Leasing%20FINAL.pdf"]</p>',
      location: 4,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 8,
      content_at_finish: "<p>Dziękujemy</p>",
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2020-08-04T21:40:47+02:00",
        questions: [
          {
            qa_question_id: 86,
            content: "Jaka wartość w skali Mohs reprezentuje twardość folii ClearPlex?",
            answers: [
              {
                qa_answer_id: 254,
                content: "7H",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 257, content: "8H", is_correct: false, image_url: null, explanation: null, type: "quiz" },
              {
                qa_answer_id: 260,
                content: "9H",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: { qa_answer_id: 260, content: "9H", is_correct: true, image_url: null, explanation: null, type: "quiz" },
            qa_text_answer: null,
          },
          {
            qa_question_id: 89,
            content: "Najpopularniejszy ploter Express pozwala na docinanie folii dla urządzeń o przekątnych:",
            answers: [
              {
                qa_answer_id: 263,
                content: "4,45” | 7,4” | 10,8” | 13,7”",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 266,
                content: "4,45” | 7,4” | 10,8” | 13,6”",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 269,
                content: "4,46” | 7,4” | 10,8” | 13,7”",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 263,
              content: "4,45” | 7,4” | 10,8” | 13,7”",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 92,
            content: "Klient zakupił folię, a ta z jakiś powodów odkleiła się. Jak długo działa gwarancja?",
            answers: [
              {
                qa_answer_id: 272,
                content: "Na folie nie ma gwarancji",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 275, content: "1 rok", is_correct: false, image_url: null, explanation: null, type: "quiz" },
              {
                qa_answer_id: 278,
                content: "2 lata",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: { qa_answer_id: 278, content: "2 lata", is_correct: true, image_url: null, explanation: null, type: "quiz" },
            qa_text_answer: null,
          },
        ],
        started_at: "2020-08-04T21:39:40+02:00",
        player_points: 3,
        max_points: 3,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 4500 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2020-08-04T08:30:00+02:00",
      can_answer_to: "2020-08-07T23:59:00+02:00",
      name: "Folie ClearPlex",
      description:
        '<p><em>Przed Tobą pierwszy quiz związany z akcją &bdquo;OKLEJANIE&rdquo;.<br />\r\nNa pierwszy rzut bierzemy wiedzę produktową. Będzie o foliach ochronnych i trochę o ploterach.</em></p>\r\n\r\n<p>[pdf src="https://komptown.pl/api/files/download/0d06b603-23d6-487b-8d56-49e122f68c0a/OKLEJANIE%20QUIZ1%20-%20Prezentacja.pdf"]</p>',
      location: 4,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 26,
      content_at_finish: null,
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2020-08-12T09:19:49+02:00",
        questions: [
          {
            qa_question_id: 94,
            content: "Z czego warto skorzystać w trakcie czyszczenia matrycy przed klejeniem?",
            answers: [
              {
                qa_answer_id: 280,
                content: "z suszarki",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 283,
                content: "z płynu do montażu",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 286, content: "z ręcznika papierowego", is_correct: false, image_url: null, explanation: null, type: "quiz" },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 283,
              content: "z płynu do montażu",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 97,
            content: "Co robisz, gdy po klejeniu widzisz, że pod matrycą znajduje się drobinka kurzu?",
            answers: [
              {
                qa_answer_id: 289,
                content: "odrywasz folię i reklamujesz ją w systemie",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 292,
                content: "informujesz Klienta, że to normalne. Jest dużo kurzu w galerii i trudno o idealne naklejenie.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 295,
                content: "odklejasz fragment folii, pozbywasz się kurzu i naklejasz folię ponownie",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 295,
              content: "odklejasz fragment folii, pozbywasz się kurzu i naklejasz folię ponownie",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 100,
            content:
              "W trakcie korzystania z urządzenia, Klienci często wyciągają/wkładają smartphone do/z etui. Aby nie doszło do odklejenia folii, warto zaproponować Klientowi wykrojenie folii w opcji:",
            answers: [
              {
                qa_answer_id: 298,
                content: "smartphone friendly",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 301,
                content: "case firendly",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 304, content: "Edge to edge", is_correct: false, image_url: null, explanation: null, type: "quiz" },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 301,
              content: "case firendly",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2020-08-12T09:19:09+02:00",
        player_points: 3,
        max_points: 3,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 4500 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2020-08-11T08:00:00+02:00",
      can_answer_to: "2020-08-14T23:59:00+02:00",
      name: "Folie ClearPlex - instruktaż",
      description:
        '<p><em>Przed Tobą drugi quiz związany z akcją &bdquo;OKLEJANIE&rdquo;.<br />\r\nPoznasz w nim sprawdzoną metodę klejenia folii oraz dowiesz się, co zrobić w razie nieudanego oklejania.</em></p>\r\n\r\n<p>[pdf src="https://komptown.pl/api/files/download/f3b5a91a-8dc9-4875-950f-99ed868b44a2/OKLEJANIE%20QUIZ%202%20-%20Prezentacja.pdf"]</p>',
      location: 4,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 28,
      content_at_finish: null,
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2020-08-18T16:02:24+02:00",
        questions: [
          {
            qa_question_id: 103,
            content:
              "1.\tZamówienie internetowe - zauważyłeś, że klient nie ma ochrony na ekran, w którym momencie najlepiej zaproponować montaż folii?",
            answers: [
              {
                qa_answer_id: 307,
                content: "Na samym początku rozmowy przed odbiorem zamówienia.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 310,
                content: "W momencie gdy pakujesz sprzęt i masz odrobinę dodatkowego czasu.",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 313,
                content: "Jak klient już zapłaci za zamówienie.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 310,
              content: "W momencie gdy pakujesz sprzęt i masz odrobinę dodatkowego czasu.",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 106,
            content: "2.\tKlient ma obiekcie co do ceny usługi aplikacji folii lub wartości całego koszyka, co robisz?",
            answers: [
              {
                qa_answer_id: 316,
                content:
                  "Wyraź zrozumienie, jednocześnie wskazując, że ewentualne uszkodzenia ekranu mogą powstać w dowolnym momencie, i odwlekanie montażu może nieść ze sobą niepożądane skutki.",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 319,
                content: "Przechodzisz do dalszej części aktu sprzedaży.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 322,
                content: 'Proponujesz montaż za miesiąc, po "wypłacie".',
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 316,
              content:
                "Wyraź zrozumienie, jednocześnie wskazując, że ewentualne uszkodzenia ekranu mogą powstać w dowolnym momencie, i odwlekanie montażu może nieść ze sobą niepożądane skutki.",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 109,
            content: "3.\tKiedy powinieneś zaproponować usługę montażu folii?",
            answers: [
              {
                qa_answer_id: 325,
                content: "Przy każdej możliwej sytuacji",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 328,
                content: "Tylko wtedy, jak zauważysz, że telefon Klienta jest bez ochrony",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 331,
                content: "Tylko przy sprzedaży nowego telefonu.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 328,
              content: "Tylko wtedy, jak zauważysz, że telefon Klienta jest bez ochrony",
              is_correct: false,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2020-08-18T16:01:55+02:00",
        player_points: 2,
        max_points: 3,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 4500 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2020-08-18T12:30:00+02:00",
      can_answer_to: "2020-08-21T23:59:00+02:00",
      name: "Folie ClearPlex – obsługa sprzedażowa",
      description:
        '<p>Jesteś już po 2 quizach, z kt&oacute;rych dowiedziałeś się o najważniejszych cechach folii oraz w jaki spos&oacute;b poprawnie ją zamontować.</p>\r\n\r\n<p>W tym quizie przedstawimy Ci przykłady zastosowania tej wiedzy w praktyce!</p>\r\n\r\n<p>[pdf src="https://komptown.pl/api/files/download/5eacaf96-d6a5-4938-a549-964b8d0d8832/OKLEJANIE%20QUIZ%203%20-%20Prezentacja.pdf"]</p>',
      location: 4,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 31,
      content_at_finish: "<p>Dziękujemy</p>",
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2020-08-25T20:02:22+02:00",
        questions: [
          {
            qa_question_id: 112,
            content: "Przedstaw nam najskuteczniejszy zwrot, który stosujesz przy dosprzedaży folii/szkła.",
            answers: [],
            is_open: true,
            type: "survey",
            selected_answer: null,
            qa_text_answer:
              "Widzę ze Pana telefon wymaga lekkiego liftingu, polecam folie clearplex, ktora nie tylko zabezpieczy Pana telefon ale nada mu jeszcze elegancki wyglad",
          },
        ],
        started_at: "2020-08-25T20:00:24+02:00",
      },
      can_answer_from: "2020-08-25T09:45:00+02:00",
      can_answer_to: "2020-08-28T23:59:00+02:00",
      name: "Dzielimy się praktykami!",
      description:
        '<p><em>Wypełnij ankietę i podziel się z pozostałymi pracownikami swoimi najlepszymi sposobami sprzedaży folii ClearPlex oraz szkła hartowanego.</em></p>\r\n\r\n<p><em>Możesz przedstawić sw&oacute;j szach-mat przy dosprzedaży folii/szkła do ZSI, jak r&oacute;wnież pochwalić się technikami wspominania o tych produktach w standardowym procesie sprzedaży.</em></p>\r\n\r\n<p><em>Najlepsze praktyki zostaną zebrane i udostępnione całej sieci w formie specjalnie przygotowanego dokumentu.</em></p>\r\n\r\n[pdf src="https://komptown.pl/api/files/download/c703135a-60fd-43e9-ba6b-a1d9325234c5/quiza%204%20preska.pdf"]',
      location: 4,
      product_prizes: [],
      currency_prizes: [{ currency_id: 1, currency_type: "player", amount: 4500 }],
      qa_id: 34,
      content_at_finish: null,
      type: "survey",
    },
    {
      last_attempt: {
        finished_at: "2020-09-09T10:15:32+02:00",
        questions: [
          {
            qa_question_id: 115,
            content: "Jak można określić  wykorzystanie dosprzedaży usług i produktów do sprzedaży pud/pub?",
            answers: [
              {
                qa_answer_id: 334,
                content: "Gdzie kucharek 6 tam nie ma co jeść",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 337,
                content: "Dwie pieczenie na jednym ogniu",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 340,
                content: "Lepszy wróbel w garści niż gołąb na dachu",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 337,
              content: "Dwie pieczenie na jednym ogniu",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 118,
            content:
              "Co jest kluczowe dla przeprowadzenia badania potrzeb Klienta, który podszedł do lady i orzekł, że „chce sprzęt na raty” ?",
            answers: [
              {
                qa_answer_id: 343,
                content: "Poinformowanie o aktualnych promocjach 0%",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 346,
                content: "Szybkie przyniesienie sprzętu z zaplecza",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 349,
                content: "Zaproszenie Klienta do wystawki i przeprowadzenie badania potrzeb",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 349,
              content: "Zaproszenie Klienta do wystawki i przeprowadzenie badania potrzeb",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 121,
            content:
              "#E-learning  -> Jak poradzić sobie z obiekcją o wysokim koszcie kredytu by jednocześnie utrzymać wysoki poziom prowizyjny ?",
            answers: [
              {
                qa_answer_id: 352,
                content: "Zastosować grę wariantami",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 355,
                content: "Zaproponować Klientowi raty 0%",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 358,
                content: "Zrezygnować z PUD/PUB ponieważ „Klienci tego nie chcą”",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 352,
              content: "Zastosować grę wariantami",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2020-09-09T10:15:03+02:00",
        player_points: 3,
        max_points: 3,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 4500 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2020-09-08T13:45:00+02:00",
      can_answer_to: "2020-09-11T23:59:00+02:00",
      name: "Dodatkowe produkty bankowe",
      description:
        '<p><em>We wrześniowej odsłonie Quiz&oacute;w koncentrujemy się na sprzedaży PUD/PUB/LIFE. W tym materiale skupiamy się na dodatkach PUD/PUB.</em></p>\r\n\r\n<p>[pdf src="https://komptown.pl/api/files/download/458fdd73-f524-4c3f-90ff-6ab85dce01c6/QUIZ%2012.pdf"]</p>',
      location: 4,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 37,
      content_at_finish: null,
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2020-09-15T09:27:14+02:00",
        questions: [
          {
            qa_question_id: 124,
            content: "Przedstaw nam najskuteczniejszy zwrot, który pomaga Tobie skutecznie zaproponować PUD/PUB",
            answers: [],
            is_open: true,
            type: "survey",
            selected_answer: null,
            qa_text_answer:
              "Dzieki takiemu zabezpieczeniu nie zapomni Pan/Pani o terminie raty, dzieki temu bedzie kozna wziasc kolejne kredyty, terminowasc splat jest bardzo wazna dla.banku.",
          },
        ],
        started_at: "2020-09-15T09:24:18+02:00",
      },
      can_answer_from: "2020-09-15T08:30:00+02:00",
      can_answer_to: "2020-09-18T23:59:00+02:00",
      name: "Dzielimy się praktykami! Vol.2",
      description:
        "<p><em>Wypełnij ankietę i podziel się swoimi najlepszymi sposobami proponowania dodatkowych produkt&oacute;w bankowych. </em></p>\r\n\r\n<p><em>Znasz skuteczny spos&oacute;b &bdquo;zaszczepiania&rdquo; PUB/PUD już na etapie badania potrzeb?<br />\r\nA może stosujesz jakiś sprawdzony zwrot, po wypowiedzeniu kt&oacute;rego Klient chętniej zgodzi się na PUB/PUD?&nbsp; </em></p>\r\n\r\n<p><em>Jeśli tak - podziel się z pozostałymi! Najlepsze praktyki zostaną zebrane i udostępnione całej sieci w formie specjalnie przygotowanego dokumentu. </em></p>",
      location: 4,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 40,
      content_at_finish: null,
      type: "survey",
    },
    {
      last_attempt: {
        finished_at: "2020-10-27T12:10:10+01:00",
        questions: [
          {
            qa_question_id: 159,
            content: "Po jaki model laptopa/smartphone’a (kod max) najczęściej przychodzą do mnie Klienci:",
            answers: [],
            is_open: true,
            type: "survey",
            selected_answer: null,
            qa_text_answer: "Lt-std-i15-acr-0133/Gs-t-hua-0734",
          },
          {
            qa_question_id: 162,
            content: "Na jaki produkt HIT przekierowujesz takiego Klienta ?",
            answers: [],
            is_open: true,
            type: "survey",
            selected_answer: null,
            qa_text_answer: "LT-std-i15-acr-0133",
          },
          {
            qa_question_id: 165,
            content:
              "Wskaż etap procesu sprzedaży na jakim rozpoczynasz przekierowanie oraz przedstaw swój zwrot, jaki stosujesz aby przekierowanie było skuteczne:",
            answers: [],
            is_open: true,
            type: "survey",
            selected_answer: null,
            qa_text_answer:
              "Podczas badania potrzeb, prosze spojrzec w lewo, mamy tutaj najlepszy model, ktory odpowiada Pani oczekiwaniom",
          },
        ],
        started_at: "2020-10-27T12:04:05+01:00",
      },
      can_answer_from: "2020-10-27T08:00:00+01:00",
      can_answer_to: "2020-10-28T23:59:00+01:00",
      name: "Dzielimy się praktykami! Vol.3",
      description:
        "<p><em>Cześć!</em></p>\r\n\r\n<p><em>Wczoraj opublikowaliśmy materiały szkoleniowe dotyczące misji &bdquo;Przekierowanie&rdquo;.&nbsp;Zależy nam na wykorzystaniu strategii produkt&oacute;w &bdquo;HIT&rdquo;, w celu zaproponowania Klientom przede wszystkim tych właśnie wysokomarżowych produkt&oacute;w. </em></p>\r\n\r\n<p><em>W związku z tym, mamy dla Was zadanie. Wypełnijcie ankietę i podzielcie się najlepszymi praktykami z pozostałymi handlowcami. Dzięki temu, szybko zbudujemy skuteczne narzędzie, z kt&oacute;rego mogą korzystać wszyscy. Do dzieła! </em></p>",
      location: 4,
      product_prizes: [],
      currency_prizes: [{ currency_id: 1, currency_type: "player", amount: 2400 }],
      qa_id: 51,
      content_at_finish: null,
      type: "survey",
    },
    {
      last_attempt: {
        finished_at: "2020-11-03T14:26:27+01:00",
        questions: [
          {
            qa_question_id: 168,
            content: "Co to jest ZSCW?",
            answers: [
              {
                qa_answer_id: 465,
                content:
                  "To procedura sprzedaży, która pozwala min. sprzedać produkt aktualnie niedostępny w salonie. Jest on wysyłany z Magazynu Centralnego",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 468,
                content: "To dosprzedaż usług",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 471,
                content: "To skrót od Zrób Sobie Czas Wolny",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 465,
              content:
                "To procedura sprzedaży, która pozwala min. sprzedać produkt aktualnie niedostępny w salonie. Jest on wysyłany z Magazynu Centralnego",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 171,
            content: "Do salonu wchodzi Klient i mówi, że chce Laptop „X”, którego akurat nie masz na stanie. Co robisz?",
            answers: [
              {
                qa_answer_id: 474,
                content: "Przepraszam Klienta",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 477,
                content: "Mówię Klientowi, żeby przyszedł za jakiś czas – może wówczas produkt już będzie",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 480,
                content: "Mówię Klientowi, że produkt może mieć już nawet w ciągu 24h z wysyłką do domu",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 480,
              content: "Mówię Klientowi, że produkt może mieć już nawet w ciągu 24h z wysyłką do domu",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 174,
            content:
              "Klient nie zgadza się na taki sposób wysyłki produktu do domu. Za wszelka cenę chce mieć produkt tu i teraz. Co robisz?",
            answers: [
              {
                qa_answer_id: 483,
                content: "Jeszcze raz przepraszam Klienta",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 486,
                content: "Mówię Klientowi „To bardzo mi przykro. Do widzenia”",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 489,
                content: "Poznam główną potrzebę Klienta dotyczącą produktu i spróbuję przekierować go na inny produkt",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 489,
              content: "Poznam główną potrzebę Klienta dotyczącą produktu i spróbuję przekierować go na inny produkt",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2020-11-03T14:25:51+01:00",
        player_points: 3,
        max_points: 3,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 2100 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2020-11-03T11:15:00+01:00",
      can_answer_to: "2020-11-06T23:59:00+01:00",
      name: "ZSCW – Wstęp do zagadnienia",
      description:
        '<p style="margin-left:48px">W listopadzie odpalamy akcję specjalną, kt&oacute;rej celem jest zwiększenie udziału sprzedaży ZSCW.<br />\r\nOznacza to, że tym samym rozpoczynamy cykl Quiz&oacute;w poświęconych temu zakresowi.<br />\r\nNa początek kilka podstawowych informacji oraz przypomnienie samego zagadnienia</p>\r\n\r\n<p style="margin-left:48px">[pdf src="https://komptown.pl/api/files/download/93febe35-628e-4f05-9cb2-71c2412df90d/zscw%20quiz%201.pdf"]</p>',
      location: 4,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 54,
      content_at_finish: null,
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2020-11-10T11:45:15+01:00",
        questions: [
          {
            qa_question_id: 177,
            content: 'Co trzeba zrobić, aby usługa "pakiet start" przy ZSCW została wykonana przed wysyłką zamówienia:',
            answers: [
              {
                qa_answer_id: 492,
                content: "Zadzwonić na magazyn.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 495,
                content: "Napisać wiadomość na montaż.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 498,
                content: "Wystarczy dodać indeks usługi do zamówienia.",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 498,
              content: "Wystarczy dodać indeks usługi do zamówienia.",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 180,
            content: "Który przykładowy numer wpisujemy podczas wystawiania polisy do ZSCW jako Identyfikator dokumentu sprzedaży:",
            answers: [
              {
                qa_answer_id: 501,
                content: "ZSCW-235104/2020/11/5",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 504,
                content: "FF-321000/2020/11/04585",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 507, content: "86/1886219", is_correct: false, image_url: null, explanation: null, type: "quiz" },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 501,
              content: "ZSCW-235104/2020/11/5",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 183,
            content: 'W jakiej zakładce znajduje się wybór kodu promocji "ZSCW GRATIS 500" oraz "ZSCW GRATIS 2000":',
            answers: [
              {
                qa_answer_id: 510,
                content: "Dane zamówienia.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 513,
                content: "Miejsce odbioru.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 516, content: "Realizacja.", is_correct: true, image_url: null, explanation: null, type: "quiz" },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 516,
              content: "Realizacja.",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2020-11-10T11:43:44+01:00",
        player_points: 3,
        max_points: 3,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 2100 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2020-11-10T10:00:00+01:00",
      can_answer_to: "2020-11-13T23:59:00+01:00",
      name: "ZSCW – Jak ogarnąć to w systemie?",
      description:
        '<p style="margin-left:48px">Jak pewnie wiecie, w listopadzie akcją specjalną w grywalizacji jest zwiększenie udziału sprzedaży ZSCW.&nbsp;</p>\r\n\r\n<p style="margin-left:48px">Przed Wami już drugi Quiz w ramach merytorycznego wsparcia sił sprzedaży.</p>\r\n\r\n<p style="margin-left:48px">Tym razem na warsztat bierzemy instruktaż w zakresie poprawnego wystawienia dokument&oacute;w ZSCW w r&oacute;żnych wariantach.</p>\r\n\r\n<p style="margin-left:48px">[pdf src="https://komptown.pl/api/files/download/e5bef10c-8277-43f8-857a-2efa90159fb9/zscw%20quiz%202.pdf"]</p>',
      location: 4,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 57,
      content_at_finish: null,
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2020-11-17T15:07:49+01:00",
        questions: [
          {
            qa_question_id: 185,
            content:
              "Wyobraź sobie sytuację, w której podchodzi do Ciebie Klient i chce zakupić produkt, którego akurat nie masz  na stanie magazynu sklepu. Podaj przykład zwrotu, który pomaga Tobie zaproponować Klientowi sprzedaż tego produkt z wysyłką z MC",
            answers: [],
            is_open: true,
            type: "survey",
            selected_answer: null,
            qa_text_answer:
              "Gdy klinet pyta a nie mam produktu to mowie, ze ten produkt moze byc juz u Pana jutro u Pana/Pani w domu, prosze podac adres ja juz nadaje kuriera.",
          },
        ],
        started_at: "2020-11-17T15:05:56+01:00",
      },
      can_answer_from: "2020-11-17T08:30:00+01:00",
      can_answer_to: "2020-11-20T23:59:00+01:00",
      name: "Dzielimy się praktykami! Vol.4",
      description:
        "<p><em>ZSCW na p&oacute;łmetku! Obecnie sytuacja wygląda następująco:</em><br />\r\n-<em> tylko 3 salony idą na wynik powyżej 10% (Brawo August&oacute;w!); </em><br />\r\n-<em> 4 salony są powyżej 5%; </em><br />\r\n-<em> 6 salon&oacute;w jest powyżej 3%</em><br />\r\n-<em> aż 57 salon&oacute;w jest poniżej 3%...</em></p>\r\n\r\n<p><em>Wiemy, że stać Was na więcej. To dobry moment na podzielenie się najlepszymi praktykami w proponowaniu tego jakże skutecznego sposobu finalizacji sprzedaży. Dzięki temu, szybko zbudujemy skuteczne narzędzie, z kt&oacute;rego mogą korzystać wszyscy. Do dzieła!</em></p>",
      location: 4,
      product_prizes: [],
      currency_prizes: [{ currency_id: 1, currency_type: "player", amount: 2100 }],
      qa_id: 77,
      content_at_finish: null,
      type: "survey",
    },
    {
      last_attempt: {
        finished_at: "2020-11-25T12:54:23+01:00",
        questions: [
          {
            qa_question_id: 191,
            content: "Czy w swojej codziennej pracy wykorzystujesz jeden ze wskazanych w dokumencie zwrotów?",
            answers: [
              {
                qa_answer_id: 518,
                content: "Tak",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 521, content: "Nie", is_correct: true, image_url: null, explanation: null, type: "quiz" },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: { qa_answer_id: 518, content: "Tak", is_correct: true, image_url: null, explanation: null, type: "quiz" },
            qa_text_answer: null,
          },
        ],
        started_at: "2020-11-25T12:54:17+01:00",
        player_points: 1,
        max_points: 1,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 2100 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2020-11-25T12:30:00+01:00",
      can_answer_to: "2020-11-27T23:59:00+01:00",
      name: "ZSCW – najlepsze praktyki",
      description:
        '<p><em>Wracamy do Was z podsumowaniem ankiety &bdquo;ZSCW &ndash; najlepsze praktyki vol.3&rdquo;. Po raz kolejny wykazaliście się dużym zaangażowaniem, a zbi&oacute;r ankiet zbliżył się do 120 szt. Mega!!! Jeszcze raz wielkie dzięki wszystkim, kt&oacute;rzy podeszli do tematu.</em></p>\r\n\r\n<p><em>W niniejszym argumenterze prezentujemy <strong>10 najlepszych &bdquo;szach-mat&oacute;w&rdquo; przy proponowaniu ZSCW.</strong></em></p>\r\n\r\n<p><br />\r\n<em>Przed Wami ostatni tydzień akcji z &bdquo;ZSCW&rdquo; &ndash; wykorzystajcie potencjał tego narzędzia, jako doskonały element domykania sprzedaży. Aby jeszcze bardziej zachęcić Was do pracy z &bdquo;ZSCW&rdquo;, postanowiliśmy przyznać Wam cegły za zapoznanie się z tym właśnie materiałem. Cegły otrzymasz po zakończonym quizie.</em></p>\r\n\r\n<p><em>Zatem miłej lektury i do boju! 😊</em></p>\r\n\r\n<p>[pdf src="https://komptown.pl/api/files/download/26b529c9-03f2-4f80-8b99-f73cbca0c82a/Najlepsze%20praktyki%20vol.3%20-%20ZSCW.pdf"]</p>',
      location: 4,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 83,
      content_at_finish: null,
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2020-12-16T15:11:37+01:00",
        questions: [
          {
            qa_question_id: 192,
            content: "Gdybym miał przenieść jedno ze świątecznych dań do Komp Town, to byłoby to:",
            answers: [
              {
                qa_answer_id: 522,
                content: "Karp w galarecie",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 525, content: "Pierogi", is_correct: true, image_url: null, explanation: null, type: "quiz" },
              {
                qa_answer_id: 528,
                content: "Barszcz z uszkami",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 528,
              content: "Barszcz z uszkami",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2020-12-16T15:11:24+01:00",
        player_points: 1,
        max_points: 1,
        currency_prizes_for_answers: [{ currency_id: 3, amount: 200 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2020-12-16T09:15:00+01:00",
      can_answer_to: "2020-12-18T23:59:00+01:00",
      name: "Świąteczny bonus",
      description:
        '<p>Wieść o rozwoju Twojego miasta dotarła nawet do Laponii. Święty Mikołaj postanowił zobaczyć Komp Town oraz jego sławę. Jak to już bywa z wizytą Mikołaja &ndash; praktycznie nikt nie zauważył jego obecności. Natomiast, na dow&oacute;d swojego uznania tego co ujrzał, postanowił zostawić upominek. To 200 złotych talar&oacute;w. Warunkiem ich pobrania jest otwarcie w dniach 16-18 grudnia świątecznej kartki oraz złożenie nietypowego życzenia.</p>\r\n\r\n<p>[pdf src="https://komptown.pl/api/files/download/c6619533-737b-4499-802a-e480efd23253/Kartka%20%C5%9Awiateczna.pdf"]</p>',
      location: 4,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 84,
      content_at_finish: null,
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2021-02-09T12:42:49+01:00",
        questions: [
          {
            qa_question_id: 195,
            content: "1.\tCo nie jest cechą naszej nowej folii do montażu:",
            answers: [
              {
                qa_answer_id: 531,
                content: "Zwiększona odporność na uderzenia, upadki i zarysowania",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 534,
                content: "Długotrwała ochrona dzięki samoregenerującej się strukturze",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 537,
                content: "Twardość folii 9h",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 540,
                content: "Gładki ekran z oleofobową powłoką",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 537,
              content: "Twardość folii 9h",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 198,
            content: "Montaż folii dla smartphone’ów i tabletów dotyczy:",
            answers: [
              {
                qa_answer_id: 543,
                content: "Tylko przodu urządzenia (ekran)",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 546,
                content: 'Tylko tył urządzenia ("plecki")',
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 549,
                content: 'Każdej powierzchni urządzania: ekran, "plecki" oraz ścianek bocznych urządzenia',
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 552,
                content: "Te folie są tylko na etui",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 549,
              content: 'Każdej powierzchni urządzania: ekran, "plecki" oraz ścianek bocznych urządzenia',
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 201,
            content: "Postawa proaktywna w kontekście proponowania folii przejawia się w:",
            answers: [
              {
                qa_answer_id: 555,
                content: "Nakładaniu folii ochronnej biegnąc",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 558,
                content: "Szukaniu możliwości do proponowania folii każdemu Klientowi, który odwiedzi nasz salon",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 561,
                content: "Wspominaniu Klientowi o folii tylko na końcu procesu sprzedaży",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 564,
                content: "Biciu rekordów w docinaniu folii na ploterze",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 558,
              content: "Szukaniu możliwości do proponowania folii każdemu Klientowi, który odwiedzi nasz salon",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2021-02-09T12:41:58+01:00",
        player_points: 3,
        max_points: 3,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 2700 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2021-02-09T11:30:00+01:00",
      can_answer_to: "2021-02-12T23:59:00+01:00",
      name: "Montaż folii – jak to „ugryźć”?",
      description:
        '<p style="margin-left:48px">W tym miesiącu skupiamy się na sprzedaży folii nowego dystrybutora oraz na sprzedaży konkretnych usług serwisowych.</p>\r\n\r\n<p style="margin-left:48px">Zaczynamy od folii. W tym materiale znajdziesz informacje na temat specyfikacji produktu oraz poznasz korzyści, jakie gwarantują te mocne folie.</p>\r\n\r\n<p style="margin-left:48px">[pdf src="https://komptown.pl/api/files/download/b03f8c20-060b-4bfd-918d-0d37f8cc38e8/Monta%C5%BC_Folii.pdf"]</p>',
      location: 4,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 87,
      content_at_finish: null,
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2021-02-16T15:29:42+01:00",
        questions: [
          {
            qa_question_id: 204,
            content: "W skład usług serwisowych nie wchodzi:",
            answers: [
              {
                qa_answer_id: 567,
                content: "Montaż procesora",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 570,
                content: "Usługa Reaktywacja MAX",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 573,
                content: "Komputer Usługa BEZPIECZNY",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 576,
                content: "Montaż części komputerowych",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 573,
              content: "Komputer Usługa BEZPIECZNY",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 207,
            content: "W celu znalezienia potencjalnej potrzeby usługi serwisowej, na etapie badania potrzeb:",
            answers: [
              {
                qa_answer_id: 579,
                content: "Zbadaj powód zakupu sprzętu",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 582,
                content: "Zaproponuj zamiast zakupu naprawę starego komputera",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 585,
                content: "Nic nie mów, powiesz o usłudze na końcu",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 588,
                content: "Zaproponuj czyszczenie nowego komputera.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 579,
              content: "Zbadaj powód zakupu sprzętu",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 210,
            content:
              "Zależnie od sytuacji, proponujemy różne usługi serwisowe. Co możesz zaproponować po sfinalizowaniu zakupu, ale jeszcze przed wyjściem klienta?",
            answers: [
              {
                qa_answer_id: 591,
                content: "Powrót z komputerem który wcześniej uległ awarii w celu przeprowadzenia diagnozy.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 594,
                content: "Można zaproponować czyszczenie komputerów z kurzu.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 597,
                content: "Wymianę dysku na SSD dla starszych komputerów.",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 600,
                content: "Wszystkie powyższe odpowiedzi są prawidłowe",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 600,
              content: "Wszystkie powyższe odpowiedzi są prawidłowe",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2021-02-16T15:29:09+01:00",
        player_points: 3,
        max_points: 3,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 2700 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2021-02-16T12:15:00+01:00",
      can_answer_to: "2021-02-19T23:59:00+01:00",
      name: "Usługi serwisowe – jak je sprzedać?",
      description:
        '<p style="margin-left:48px">W tym quizie dowiecie się w jaki spos&oacute;b stworzyć sobie okazję do dosprzedaży tej usługi w oparciu o Standard Obsługi Klienta.</p>\r\n\r\n<p style="margin-left:48px">[pdf src="https://komptown.pl/api/files/download/e01b6e01-6a25-44fa-abfa-8627684f2e64/us%C5%82ugi%20serwisowe.pdf"]</p>',
      location: 4,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 90,
      content_at_finish: "<p>Dziękujemy</p>",
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2021-02-23T19:02:24+01:00",
        questions: [
          {
            qa_question_id: 213,
            content:
              "Podczas sprzedaży laptopa klient wspomniał o tym, że ma w domu kilka laptopów, które nie działają jak należy. W jaki sposób zaproponujesz mu usługę Reaktywacja MAX?",
            answers: [],
            is_open: true,
            type: "survey",
            selected_answer: null,
            qa_text_answer:
              "Wie Pan, ze Pana komputery, ktore ma Pan w domu mozna jeszcze przywrocic i beda dzialac? Nasz salon wykonuje takie uslugi i daje drugie zycie takim laptopom.",
          },
        ],
        started_at: "2021-02-23T19:00:33+01:00",
      },
      can_answer_from: "2021-02-23T08:00:00+01:00",
      can_answer_to: "2021-02-26T23:59:00+01:00",
      name: "Usługi serwisowe - Dzielimy się praktykami!",
      description:
        "<p><em>Cześć! 😊 Chcąc zbudować narzędzie do skutecznego sposobu sprzedaży usług serwisowych, z kt&oacute;rego będą mogli korzystać wszyscy, dzielimy się najlepszymi zwrotami i praktykami 😊 </em></p>",
      location: 4,
      product_prizes: [],
      currency_prizes: [{ currency_id: 1, currency_type: "player", amount: 2700 }],
      qa_id: 93,
      content_at_finish: "<p>Dziękujemy za podzielenie się swoim doświadczeniem! 😊</p>",
      type: "survey",
    },
    {
      last_attempt: {
        finished_at: "2021-03-23T14:55:32+01:00",
        questions: [
          {
            qa_question_id: 225,
            content: "Czy zapoznałeś się z materiałem ?",
            answers: [
              {
                qa_answer_id: 630,
                content: "Tak",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 633, content: "Nie", is_correct: false, image_url: null, explanation: null, type: "quiz" },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: { qa_answer_id: 630, content: "Tak", is_correct: false, image_url: null, explanation: null, type: "quiz" },
            qa_text_answer: null,
          },
        ],
        started_at: "2021-03-23T14:55:25+01:00",
        player_points: 0,
        max_points: 1,
        currency_prizes_for_answers: [],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2021-03-23T09:45:00+01:00",
      can_answer_to: "2021-03-27T00:00:00+01:00",
      name: "Sprzedaż folii – porady!",
      description:
        '<p>Cześć, dzisiaj mamy dla Was coś specjalnego. Zrobiliśmy małe tournee po Polsce i zebraliśmy kilka przykładów działań, które doskonale wspierają sprzedaż folii ochronnych. Zapoznajcie się z udostępnionym materiałem i wcielajcie te protipy u siebie już dzisiaj. To naprawdę działa!</p><p>Czas na zapoznanie się z materiałem - do 26.03</p><p>Kiedy otrzymacie cegły? - do 31.03</p><p>Powodzenia!</p><p>[pdf src="https://komptown.pl/api/files/download/8afe5977-3a61-4eb3-b639-70e13ec15dad/Marzec%20-%20Quiz%20nr2.pdf"]</p>',
      location: 4,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 99,
      content_at_finish: null,
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2021-03-30T12:55:13+02:00",
        questions: [
          {
            qa_question_id: 228,
            content: "Czy zapoznałeś się z materiałem ?",
            answers: [
              {
                qa_answer_id: 636,
                content: "Tak",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 639, content: "Nie", is_correct: false, image_url: null, explanation: null, type: "quiz" },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: { qa_answer_id: 636, content: "Tak", is_correct: false, image_url: null, explanation: null, type: "quiz" },
            qa_text_answer: null,
          },
        ],
        started_at: "2021-03-30T12:55:08+02:00",
        player_points: 0,
        max_points: 1,
        currency_prizes_for_answers: [],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2021-03-30T10:35:08+02:00",
      can_answer_to: "2021-04-02T23:59:59+02:00",
      name: "Rzecz o proponowaniu ratonamentu!",
      description:
        '<p>Cześć, zebraliśmy dla Was kilka przykładów działań, które doskonale wspierają sprzedaż ratonamentu. Zapoznajcie się z udostępnionym materiałem, gdyż wskazane w nim praktyki mogą pomóc Wam zwiększyć sprzedać ratonamentu już dzisiaj! To naprawdę działa!</p><p>Co trzeba zrobić? – pobrać plik i zapoznać się z materiałem.</p><p>Do kiedy można to zrobić? - do 02.04</p><p>Kiedy otrzymacie cegły? - do 07.04</p><p>Powodzenia!</p><p>[pdf src="https://komptown.pl/api/files/download/d809488f-010e-4064-8f75-39fa33a59374/QUIZ%203%20-%20ratonament.pdf"]</p>',
      location: 4,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 102,
      content_at_finish: null,
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2021-05-11T12:47:36+02:00",
        questions: [
          {
            qa_question_id: 252,
            content: "Czym jest ratonament?",
            answers: [
              {
                qa_answer_id: 714,
                content: "formą kredytu",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 717,
                content: "formą dzierżawy",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 720, content: "pożyczką leasingową", is_correct: false, image_url: null, explanation: null, type: "quiz" },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 717,
              content: "formą dzierżawy",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 255,
            content: "Co jest przedmiotem ratonamentu?",
            answers: [
              {
                qa_answer_id: 723,
                content: "Laptop ze wsparciem serwisowym i konfiguracyjnym, oprogramowaniem i ubezpieczeniem",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 726,
                content: "Komputer ze wsparciem serwisowym i konfiguracyjnym, oprogramowaniem i ubezpieczeniem",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 729,
                content: "Obie odpowiedzi są prawidłowe",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 729,
              content: "Obie odpowiedzi są prawidłowe",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 258,
            content: "Jakie są korzyści dla klienta:",
            answers: [
              {
                qa_answer_id: 732,
                content: "bezpieczeństwo, kompleksowe rozwiązanie, niska rata dzierżawy",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 735,
                content: "niska rata leasingu",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 738,
                content: "co pół roku nowy sprzęt",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 732,
              content: "bezpieczeństwo, kompleksowe rozwiązanie, niska rata dzierżawy",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2021-05-11T12:47:11+02:00",
        player_points: 3,
        max_points: 3,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 2550 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2021-05-11T08:34:21+02:00",
      can_answer_to: "2021-05-14T23:59:59+02:00",
      name: "Czym jest Ratonament?",
      description:
        '<p>Cześć!</p><p>W tym miesiącu zwracamy szczególną uwagę na usługę „Ratonament”. Pragniemy zapoznać Cię z tym produktem lepiej oraz chcemy, żebyś wiedział, jakie korzyści przyniesie jego zakup Klientowi, a Tobie - &nbsp;jego sprzedaż. A więc udanej lektury, dużo cegieł i owocnej sprzedaży!&nbsp;😊&nbsp;</p><p>[pdf src="https://komptown.pl/api/files/download/47a195cd-ddcb-43dd-889f-fa6c65dd643c/RatonamentV2%20%28002%29.pdf"]</p>',
      location: 4,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 111,
      content_at_finish: null,
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2021-05-18T10:10:08+02:00",
        questions: [
          {
            qa_question_id: 261,
            content: "Kiedy instalujemy antywirusa?",
            answers: [
              {
                qa_answer_id: 741,
                content: "w dowolnym momencie",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 744,
                content: "po zaktualizowaniu systemu",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 747,
                content: "nie instalujemy, jest już w komputerze Windows Defender",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 744,
              content: "po zaktualizowaniu systemu",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 264,
            content: "Jakie usługi serwisowe klient otrzymuje w ramach ratonamentu?",
            answers: [
              {
                qa_answer_id: 750,
                content: "Naprawy zalanej piwem klawiatury, wymiany zasilacza",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 753,
                content: "tylko pakiet konfiguracji premium",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 756,
                content: "pakiet konfiguracji premium i przegląd komputera",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 756,
              content: "pakiet konfiguracji premium i przegląd komputera",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 267,
            content: "Ile usług przeglądu komputera otrzymuje klient, w ramach zakupu w Ratonamencie na 24 miesiące?",
            answers: [
              {
                qa_answer_id: 759,
                content: "dwie usługi",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 762, content: "jedna", is_correct: false, image_url: null, explanation: null, type: "quiz" },
              {
                qa_answer_id: 765,
                content: "nielimitowana ilość usług",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 759,
              content: "dwie usługi",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2021-05-18T10:09:01+02:00",
        player_points: 3,
        max_points: 3,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 2550 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2021-05-18T08:46:12+02:00",
      can_answer_to: "2021-05-21T23:59:59+02:00",
      name: "Ratonament: usługi konfiguracyjne i serwisowe w pakiecie",
      description:
        '<p>Cześć! 😊&nbsp;<br>Już poznaliście, czym jest „Ratonament”. Teraz chcemy, żebyście przypomnieli sobie o usługach konfiguracyjnych i serwisowych w ramach tego produktu. A zatem zapraszamy do czytania i oglądania filmików 😊&nbsp;</p><p>[pdf src="https://komptown.pl/api/files/download/03993941-c9da-4b7a-a2e8-6e518c0188fd/Ratonament_PB.pdf"]</p>',
      location: 4,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 114,
      content_at_finish: null,
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
    {
      last_attempt: {
        finished_at: "2021-05-25T14:09:06+02:00",
        questions: [
          {
            qa_question_id: 269,
            content: "Rozmawiasz na temat rat 0% w Alior Bank, jak zaproponujesz dodatkowy produkt bankowy?",
            answers: [
              {
                qa_answer_id: 767,
                content: "Pytam, czy chce Pan płacić dodatkowo 5 złotych do raty za Assistance PZU",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 770,
                content:
                  "Przedstawię ofertę łącznie z korzyściami pakietu usług dodatkowych np: bezpieczeństwo dzięki Alerty BIK, wygoda z przypomnień SMS o racie",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 773,
                content: "Nie będę o tym mówił, po prostu je dorzucę",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 776,
                content: "Powiem: „A do tego kredyt jest bezpieczny, jak już się coś wydarzy rodzina otrzyma środki na spłatę kredytu”",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 770,
              content:
                "Przedstawię ofertę łącznie z korzyściami pakietu usług dodatkowych np: bezpieczeństwo dzięki Alerty BIK, wygoda z przypomnień SMS o racie",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 272,
            content: "Sprzedajesz kredyt w Credit Agricole, jakie produkty dodatkowe w kredycie możesz zaproponować:",
            answers: [
              {
                qa_answer_id: 779,
                content: "ubezpieczenie kredytu LIFE",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 782,
                content: "pakiet Bezpieczny lub Komfort",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 785,
                content: "pakiet usług bankowych Premium Plus",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 788,
                content: "pakiet usług bankowych Premium, Rozszerzony lub Podstawowy",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 788,
              content: "pakiet usług bankowych Premium, Rozszerzony lub Podstawowy",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 275,
            content: "Pojawiły się pierwsze obiekcje dotyczące ubezpieczenia LIFE, co robisz?",
            answers: [
              {
                qa_answer_id: 791,
                content: "Przyznaje klientowi rację i proponuję coś innego",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 794,
                content:
                  "Rozmawiam z klientem, aby poznać obiekcje, próbuję go przekonać, ewentualnie zmieniam pakiet usług bankowych lub ubezpieczenie LIFE na inne",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 797,
                content: "Zmieniam kredyt na tańszy, a dodatkowe produkty bankowe zostawiam takie same",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 800,
                content: "Wyrzucam pakiety usług bankowych",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 794,
              content:
                "Rozmawiam z klientem, aby poznać obiekcje, próbuję go przekonać, ewentualnie zmieniam pakiet usług bankowych lub ubezpieczenie LIFE na inne",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 278,
            content: "Klient przyszedł zakupić wybrany wcześniej sprzęt za gotówkę, co robisz w kontekście kredytów i PUB PUD Life?",
            answers: [
              {
                qa_answer_id: 803,
                content: "Za gotówkę zatem - nic, klient nie zechce kupić na raty",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 806,
                content: "Wspominam, że jest możliwość kredytowania",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 809,
                content:
                  "Na różnych etapach SOK zarzucam „haczyk” do rat wraz z PUD PUB LIFE, tak aby finalnie zwiększyć szansę sprzedaży za pomocą rat z dodatkami zamiast gotówki",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 812,
                content: "Przedstawię jeszcze lepszą ofertę zakupu wraz z Gwarancją Beztroski",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 809,
              content:
                "Na różnych etapach SOK zarzucam „haczyk” do rat wraz z PUD PUB LIFE, tak aby finalnie zwiększyć szansę sprzedaży za pomocą rat z dodatkami zamiast gotówki",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
        ],
        started_at: "2021-05-25T14:07:44+02:00",
        player_points: 4,
        max_points: 4,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 2600 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2021-05-25T08:53:56+02:00",
      can_answer_to: "2021-05-28T23:59:59+02:00",
      name: "Produkty dodatkowe w kredycie: PUB, PUD, LIFE",
      description:
        '<p>Mieszkańcy Komp Town coraz chętniej wybierają płatność ratalną za realizowane zakupy. Tak wygodna forma znalazła już wielu zwolenników, natomiast część z nich chciałaby więcej. Słyszeli o możliwości otrzymywania powiadomień o zbliżających się ratach oraz pewnej ochronie na czas spłaty kredytu.</p><p>Ptaszki ćwierkają, że chodzi o PUB, PUD i LIFE. Warto o tym wspominać w trakcie każdej rozmowy z klientem. Aby wesprzeć Ciebie w tych działaniach, przygotowaliśmy praktyczny materiał. Zapoznaj się, proponuj produkty dodatkowe i zarabiaj na kredycie! 😊&nbsp;</p><p>[pdf src="https://komptown.pl/api/files/download/9b89002a-f0f3-45f8-81c4-e715ac83bbf5/PUB_PUD_LIFE_V1.pdf"]</p>',
      location: 4,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 116,
      content_at_finish: null,
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
    },
  ],
  ref: {
    location: 0,
    qa: {
      last_attempt: {
        finished_at: "2020-07-07T14:52:33+02:00",
        questions: [
          {
            qa_question_id: 50,
            content: "Co wchodzi w zakres ubezpieczenia Life oferowanego przez Santander ?",
            answers: [
              {
                qa_answer_id: 146,
                content: "Śmierć Ubezpieczonego",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 149,
                content: "Poważne zachorowanie",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 152,
                content: "Śmierć Ubezpieczonego i poważne zachorowanie",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 152,
              content: "Śmierć Ubezpieczonego i poważne zachorowanie",
              is_correct: false,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 53,
            content: "Co wchodzi w zakres pakietu Bezpiecznego  „Pakietu Usług dodatkowych”  oferowanego przez Alior Bank ?",
            answers: [
              {
                qa_answer_id: 155,
                content: "Assistance PZU",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              {
                qa_answer_id: 158,
                content: "Alerty BIK",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 161, content: "Wakacje kredytowe", is_correct: false, image_url: null, explanation: null, type: "quiz" },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: {
              qa_answer_id: 158,
              content: "Alerty BIK",
              is_correct: true,
              image_url: null,
              explanation: null,
              type: "quiz",
            },
            qa_text_answer: null,
          },
          {
            qa_question_id: 56,
            content: "Jaki jest miesięczny koszt pakietu Bezpiecznego w „Pakiecie Usług dodatkowych” oferowanego przez Alior Bank ?",
            answers: [
              {
                qa_answer_id: 164,
                content: "10 zł",
                is_correct: false,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
              { qa_answer_id: 167, content: "3 zł", is_correct: false, image_url: null, explanation: null, type: "quiz" },
              {
                qa_answer_id: 170,
                content: "5 zł",
                is_correct: true,
                image_url: null,
                explanation: null,
                type: "quiz",
              },
            ],
            image_url: null,
            image: null,
            type: "quiz",
            selected_answer: { qa_answer_id: 170, content: "5 zł", is_correct: true, image_url: null, explanation: null, type: "quiz" },
            qa_text_answer: null,
          },
        ],
        started_at: "2020-07-07T14:48:39+02:00",
        player_points: 2,
        max_points: 3,
        currency_prizes_for_answers: [{ currency_id: 1, amount: 3000 }],
        product_prizes_for_answers: [],
      },
      can_answer_from: "2020-07-07T10:39:00+02:00",
      can_answer_to: "2020-07-10T23:00:00+02:00",
      name: "O dodatkowych produktach kredytowych",
      description:
        '<p>Produkty dodatkowe sprzedawane w kredycie to nie tylko bezpieczeństwo finansowe Klient&oacute;w i ich rodzin.<br />\r\nZ jednej strony, to dostęp do nowoczesnych usług bankowych podczas całego okresu kredytowania.<br />\r\nZ drugiej zaś, to r&oacute;wnież spos&oacute;b na zwiększenie swojej premii za sprzedaż kredytu.</p>\r\n\r\n<p>Przejrzyj materiał i zacznij zarabiać więcej!</p>\r\n\r\n<p>[pdf src="https://komptown.pl/api/files/download/ecb2df7c-b5f7-4976-82e4-f0bb62f12183/O%20dodatkowych%20produktach%20kredytowych.pdf"]</p>',
      location: 1,
      product_prizes: [],
      currency_prizes: [],
      qa_id: 14,
      content_at_finish: "<p>Dziękujemy za wypełnienie quizu!</p>",
      max_attempts: 1,
      done_attempts: 1,
      type: "quiz",
      hide_answers_until_quiz_finish: true,
      rank_edition_id: null,
      all_questions: null,
      category: "Raty",
    },
    index: -1,
    isOpenByQaListView: true,
  },
};
