import { Component, OnInit } from "@angular/core";
import { forkJoin } from "rxjs";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { AbstractMessagesComponent } from "../../../../abstract/core/abstract-messages.component";
import { prepareFiltersValues } from "../../../../helpers/core/message.helper";
import { MessageFilterValue } from "../../../../interfaces/core/message-filter-value.interface";
import { MessageService } from "../../../../services/core/message.service";

@Component({
  selector: "app-messages",
  templateUrl: "./messages.component.html",
})
export class MessagesComponent extends AbstractMessagesComponent implements OnInit {
  @OwInject(MessageService) messagesService: MessageService;

  ngOnInit() {
    this.dispatchClearNewMessagesToRead();
    this.getMessages();

    forkJoin([
      this.apiMessageService.getMessagesFilters({ filter: "categories" }),
      this.apiMessageService.getMessagesFilters({ filter: "sender-groups" }),
    ]).subscribe(([categoryFilterValues, senderFilterValues]: [MessageFilterValue[], MessageFilterValue[]]) => {
      this.filterCategory.values = prepareFiltersValues(categoryFilterValues, "category_name");
      this.filterSender.values = prepareFiltersValues(senderFilterValues, "sender_name");
    });
  }

  config = {
    slidesPerView: "auto",
    centeredSlides: true,
    freeMode: true,
  };
}
