import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { select, Store } from "@ngrx/store";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { LINKS } from "../../../../../../../../core/consts/core/links.const";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { PlayerSelectors } from "../../../../../../../../store/player";
import { AppState } from "../../../../../../../../store/state";
import { UserActions } from "../../../../../../../../store/user";
import { Player } from "../../../../../../../player/interfaces/player";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { UserService } from "../../../../../../../user/providers/user.service";

@Component({
  selector: "app-other-player-settings",
  templateUrl: "./other-player-settings.component.html",
})
export class OtherPlayerSettingsComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(UserService) userService: UserService;
  @OwInject(FormBuilder) fb: FormBuilder;
  @OwInject(Store) store: Store<AppState>;

  LINKS = LINKS;
  formPush: FormGroup;
  player: Player;
  subs = {
    player: null,
  };
  ngOnInit(): void {
    this.subscribePlayer();
    this.clearForm();
  }

  clearForm() {
    this.formPush = this.fb.group({
      pushEnabled: this.fb.control(this.userService.me.push_enabled),
    });
  }

  subscribePlayer() {
    this.subs.player = this.store.pipe(select(PlayerSelectors.selectPlayer)).subscribe(player => {
      this.player = player;
    });
  }

  changePush() {
    const pushEnabled = this.formPush.value.pushEnabled;

    this.userService.changePush(pushEnabled).subscribe(
      () => {
        this.store.dispatch(new UserActions.FetchUser());
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
        this.clearForm();
      }
    );
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
