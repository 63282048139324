<!-- SEPARATOR -->
<ng-container *ngIf="separator">
  <m-ui-separator [title]="separator"></m-ui-separator>
</ng-container>

<!-- REQUIRED TEXT -->
<div class="texts-container" *ngIf="textsList.length">
  <span *ngFor="let item of textsList; let last = last" class="text" [class.text-error]="!item.valid">
    {{ item.text }}{{ !last ? "," : "" }}
  </span>
</div>

<!-- CURRENCIES OR PRODUCTS -->
<ng-container *ngIf="currencies || products">
  <m-ui-costs [currencies]="currencies" [products]="products" (eventEmitter)="checkCompleted($event)"></m-ui-costs>
</ng-container>
