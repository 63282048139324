import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

import { clamp } from "../../../helpers/common.helpers";

@Pipe({
  name: "owDuration",
})
export class OWDurationPipe implements PipeTransform {
  transform(seconds: number, numberOfValues = 3, minNumberOfValues = 2, skipValues = 0): any {
    if (seconds < 0) {
      return null;
    }
    const duration = moment.duration(seconds, "seconds");

    const valueNames = ["d", "h", "m", "s"];
    const values = [duration.days(), duration.hours(), duration.minutes(), duration.seconds()];

    if (minNumberOfValues < 4 && values[0] === 0) {
      skipValues++;
    }

    if (minNumberOfValues < 3 && skipValues === 1 && values[1] === 0) {
      skipValues++;
    }

    return values
      .slice(skipValues, clamp(skipValues + numberOfValues, skipValues + numberOfValues, values.length))
      .reduce((prev, curr, index) => `${prev} ${curr}${valueNames[index + skipValues]}`, "")
      .trim();
  }
}
