import { INFO_MESSAGE_STATUS } from "../../core/consts/core/info-message.constants";
import { getToken } from "../../core/utility/token";
import { Actions, ActionTypes } from "./actions";
import { UtilityState } from "./state";

const initialState: UtilityState = {
  player: {
    activePlayerId: null,
    mePlayerId: null,
    hasMissionsToCollect: null,
    hasNewMessagesToRead: null,
    missionsToOpen: [],
    newProductsInStorage: [],
  },
  token: {
    isExchanging: null,
    tokenObject: getToken(),
  },
  infoMessage: {
    status: null,
  },
};

export function utilityReducer(state = initialState, action: Actions): UtilityState {
  switch (action.type) {
    case ActionTypes.UPDATE_ACTIVE_PLAYER_ID:
      return {
        ...state,
        player: {
          ...state.player,
          activePlayerId: action.payload.playerId,
        },
      };

    case ActionTypes.UPDATE_ME_PLAYER_ID:
      return {
        ...state,
        player: {
          ...state.player,
          mePlayerId: action.payload.playerId,
        },
      };

    case ActionTypes.FETCH_NEW_TOKEN_START:
      return {
        ...state,
        token: {
          ...state.token,
          isExchanging: true,
        },
      };

    case ActionTypes.FETCH_NEW_TOKEN_FINISH:
      return {
        ...state,
        token: {
          ...state.token,
          isExchanging: false,
        },
      };

    case ActionTypes.SET_TOKEN:
      return {
        ...state,
        token: {
          ...state.token,
          tokenObject: action.payload,
        },
      };

    case ActionTypes.REMOVE_TOKEN:
      return {
        ...state,
        token: null,
      };

    case ActionTypes.SET_HAS_MISSIONS_TO_COLLECT:
      return {
        ...state,
        player: {
          ...state.player,
          hasMissionsToCollect: action.payload,
        },
      };

    case ActionTypes.ADD_MISSION_TO_OPEN:
      return {
        ...state,
        player: {
          ...state.player,
          missionsToOpen: [...state.player.missionsToOpen, action.payload],
        },
      };

    case ActionTypes.REMOVE_MISSION_TO_OPEN:
      return {
        ...state,
        player: {
          ...state.player,
          missionsToOpen: state.player.missionsToOpen.filter(mission => {
            return mission.player_mission_id !== action.payload.player_mission_id;
          }),
        },
      };

    case ActionTypes.ADD_NEW_PRODUCTS_IN_STORAGE:
      return {
        ...state,
        player: {
          ...state.player,
          newProductsInStorage: [...state.player.newProductsInStorage, ...action.payload],
        },
      };

    case ActionTypes.CLEAR_NEW_PRODUCTS_IN_STORAGE:
      return {
        ...state,
        player: {
          ...state.player,
          newProductsInStorage: [],
        },
      };

    case ActionTypes.SET_HAS_NEW_MESSAGES_TO_READ:
      return {
        ...state,
        player: {
          ...state.player,
          hasNewMessagesToRead: action.payload,
        },
      };

    case ActionTypes.CLEAR_NEW_MESSAGES_TO_READ:
      return {
        ...state,
        player: {
          ...state.player,
          hasNewMessagesToRead: false,
        },
      };

    case ActionTypes.ERROR_PRELOAD_ASSETS_MAP:
      return {
        ...state,
        infoMessage: {
          status: INFO_MESSAGE_STATUS.ERROR_PRELOAD_ASSETS_MAP,
        },
      };

    case ActionTypes.DIFFERENT_VERSION_GAME:
      return {
        ...state,
        infoMessage: {
          status: INFO_MESSAGE_STATUS.DIFFERENT_VERSION_GAME,
        },
      };

    default:
      return state;
  }
}
