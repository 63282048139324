import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiService } from "../../../../../../../core/providers/api.service";
import { ShoppingRequest } from "../../../interfaces/custom/event-shop-requests.interface";
import { GetEventShopsResponse } from "../interfaces/response/get-event-shops.interface";
import { GetEventShopsProductsResponse } from "../interfaces/response/get-event-shops-products.interface";
import { GetEventShopsRankDetailsResponse } from "../interfaces/response/get-event-shops-rank-details.interface";
import { GetEventShopsRanksResponse } from "../interfaces/response/get-event-shops-ranks.interface";

@Injectable({
  providedIn: "root",
})
export class ApiEventShopService {
  constructor(protected apiService: ApiService) {}

  getEventShops(): Observable<GetEventShopsResponse> {
    return this.apiService.get("event-shops");
  }

  getEventShopsProducts({ event_shop_id }: { event_shop_id: number }): Observable<GetEventShopsProductsResponse> {
    return this.apiService.get(`event-shops/${event_shop_id}/products`);
  }

  buyProduct(shopping: ShoppingRequest) {
    return this.apiService.post(`event-shops/${shopping.shopID}/products/${shopping.productID}`, {
      body: {
        amount: shopping.amount,
        phone: shopping.phoneNumber,
      },
    });
  }

  eventShopsRanks({ event_shop_id }: { event_shop_id: number }): Observable<GetEventShopsRanksResponse> {
    return this.apiService.get(`event-shops/${event_shop_id}/ranks`);
  }

  eventShopsRankDetails({
    event_shop_id,
    rank_id,
  }: {
    event_shop_id: number;
    rank_id: number;
  }): Observable<GetEventShopsRankDetailsResponse> {
    return this.apiService.get(`event-shops/${event_shop_id}/ranks/${rank_id}`);
  }
}
