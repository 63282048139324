import { Injectable } from '@angular/core';
import { UserService } from '../../../../../user/providers/user.service';
import { SynchronizeTimeService } from '../../../../../../core/providers/synchronize-time.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class NewEditionInvitationService {
  invitationNotification: boolean;
  invitationKey = `${this.userService.me.id}-new-edition-notification`;

  constructor(
    private userService: UserService,
    private synchronizeTimeService: SynchronizeTimeService,
  ) {
  }

  clearInvitationNotification() {
    this.invitationNotification = false;
    const currentDate = moment(this.synchronizeTimeService.getActualLocalTime()).startOf('day').toString();
    if (currentDate) {
      localStorage.setItem(this.invitationKey, currentDate);
    }
  }

  checkInvitationNotification() {
    const me = this.userService.me['next_edition_consent'];
    const dateFromLS = localStorage.getItem(this.invitationKey);
    if (me) {
      this.invitationNotification = false;
      return;
    }
    const currentDate = moment(this.synchronizeTimeService.getActualLocalTime()).startOf('day');
    const isSameDate = moment(dateFromLS).startOf('day').isSame(currentDate);
    if (dateFromLS && isSameDate) {
      this.invitationNotification = false;
    } else {
      this.invitationNotification = !isSameDate;
    }
  }
}
