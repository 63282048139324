export const MOCK_BUILDING_PRODUCTION_CIRCLE_KEY = "building-production-circle";

export const MOCK_BUILDING_PRODUCTION = {
  api: {
    production: [
      {
        has_required_invention: false,
        currency_prizes: [],
        product_prizes: [
          {
            product_id: 1,
            category: { storage_id: 1, trading_currency_id: null, id: 2, name: "Produkty", description: "Produkty", icon: "produkty" },
            name: "Drewno",
            description:
              "Odpowiednio obrobione i wyselekcjonowane kawałki drewna posłużą Ci do produkcji mebli oraz budowy budynk&oacute;w. Miejsce obr&oacute;bki: Tartak.",
            icon: "wood",
            required_level: 1,
            amount: 20,
          },
        ],
        production_recipe_id: 1,
        time_in_days: 1,
        time_in_minutes: null,
        invention: null,
        alternative_icon: null,
        alternative_name: null,
        production_product_prices: [],
        production_currency_prices: [{ currency_id: 2, amount: 100 }],
        fast_production_currency_prices: [],
        order: 1,
      },
    ],
    productionCircle: { done: 0, total: 25 },
  },
  ref: { playerTileId: 55149 },
};
