import { createSelector } from "@ngrx/store";

import { AppState } from "../state";
import { SocketState } from "./state";

export const selectState = (state: AppState) => state.application.socket;

export const selectIsConnecting = createSelector(selectState, (state: SocketState): boolean => state.isConnecting);

export const selectSocketStatus = createSelector(selectState, (state: SocketState) => state.socketStatus);
