<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- BUTTON EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <div class="title">
      QUIZY I ANKIETY
    </div>
  </div>

  <ng-container *ngIf="allQa">
    <div class="container loading-opacity">
      <ow-perfect-scrollbar scrollGradient>
        <table>
          <thead>
          <tr>
            <th class="th-name"></th>
            <th class="th-check-icon"></th>
            <th class="th-category">Kategoria</th>
            <th class="th-prize">Nagroda</th>
            <th class="th-score">Wynik</th>
          </tr>
          </thead>
          <tbody>
          <!-- NO QA AVAILABLE-->
          <ng-container *ngIf="allQa.length === 0">
            <tr>
              <td class="td-name">
                <div class="title margin-top">{{ 'qa.qa-list.no-qa-available' | myTranslate }}</div>
              </td>
            </tr>
          </ng-container>

          <tr *ngFor="let qa of allQa" (click)="openQaList(qa)"
              [class.opacity]="qa.last_attempt && qa.last_attempt.finished_at">
            <td class="td-name">
              <div>
                <span class="name">{{ qa.name }}</span>
                <span *ngIf="qa.can_answer_from && qa.can_answer_to" class="date">
                   {{ (qa.type === QA_TYPE.QUIZ ? 'qa.qa-list.expiry-date-quiz' : 'qa.qa-list.expiry-date-survey') | myTranslate:[qa.can_answer_from | date:'dd-MM-y', qa.can_answer_to | date:'dd-MM-y'] }}
                  </span>
              </div>
            </td>
            <td class="td-check-icon">
              <img *ngIf="qa.last_attempt && qa.last_attempt.is_finished_by_player" [src]="'icon-check.png' | asset: 'ui'"/>
            </td>
            <td class="td-category">{{ qa['category'] }}</td>
            <td class="td-prize">
              <div>
                <!-- PRIZES FOR THE RIGHT ANSWER -->
                <ng-container *ngIf="qa.last_attempt && qa.last_attempt.finished_at">
                  <ng-container *ngFor="let prize of qa.last_attempt.currency_prizes_for_finish">
                    <m-ui-currency [item]="prize"
                                   *ngIf="qa.last_attempt.is_finished_by_player"
                                   [stockView]="STOCK_VIEW.G"
                                   [lack]="false"
                    ></m-ui-currency>
                  </ng-container>

                  <ng-container *ngIf="qa.type === QA_TYPE.QUIZ">
                    <ng-container *ngFor="let prize of qa.last_attempt.currency_prizes_for_answers">
                      <m-ui-currency [item]="prize"
                                     [stockView]="STOCK_VIEW.G"
                                     [lack]="false"
                      ></m-ui-currency>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngFor="let prize of qa.last_attempt.product_prizes_for_finish">
                    <m-ui-product [item]="prize"
                                  *ngIf="qa.last_attempt.is_finished_by_player"
                                  [stockView]="STOCK_VIEW.G"
                                  [lack]="false"
                    ></m-ui-product>
                  </ng-container>
                  <ng-container *ngIf="qa.type === QA_TYPE.QUIZ">
                    <ng-container *ngFor="let prize of qa.last_attempt.product_prizes_for_answers">
                      <m-ui-product [item]="prize"
                                    [stockView]="STOCK_VIEW.G"
                                    [lack]="false"
                      ></m-ui-product>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </td>
            <td class="td-score">
              <ng-container *ngIf="qa.last_attempt && qa.last_attempt.finished_at">
                <ng-container *ngIf="qa.type === QA_TYPE.QUIZ">
                  {{ getScore(qa) }}%
                </ng-container>
                <ng-container *ngIf="qa.type === QA_TYPE.SURVEY && qa.last_attempt.is_finished_by_player">
                  100%
                </ng-container>
              </ng-container>

            </td>
          </tr>
          </tbody>
        </table>
      </ow-perfect-scrollbar>
    </div>

    <!-- CATEGORY -->
    <div class="container-filter">
      <ng-container *ngFor="let filter of filters">
        <div
          class="each-filter"
          (click)="changeFilter(filter)"
        >
          <!-- CHECKBOX -->
          <button
            class="checkbox"
            [class.active]="filter.location == activeFilter"
          ></button>

          <!-- LABEL -->
          <span>{{ filter.label }}</span>
        </div>
      </ng-container>
    </div>

  </ng-container>
</div>
