import { BUILDINGS_PATH, TileTooltipType } from "../../constants";
import { BoardTile } from "../classes/custom/BoardTile.class";
import * as R from "ramda";

const ANIMATION_LIST = ["rocket_1", "rocket_2", "rocket_3", "rocket_4", "rocket_5"];

export function handleBuildingAnimation(boardTile: BoardTile) {
  if (!boardTile.tileData.player_building?.icon) {
    return;
  }

  if (ANIMATION_LIST.includes(boardTile.tileData.player_building.icon)) {
    createBuildingAnimations(boardTile);
  }
}

export function createBuildingAnimations(tile: BoardTile) {
  let assets = tile.gameService.assetsService.getAssetsByFilenamePart(`${tile.playerBuildingData.icon}`, BUILDINGS_PATH);
  if (assets.length < 2) {
    return;
  }

  // slow down animation downloading
  setTimeout(() => {
    const frames = getAnimationFrames(assets, tile.playerBuildingData.icon);
    frames.forEach(frame => {
      tile.myScene.load.image(frame.key, tile.gameService.assetsService.getAssetPath(`buildings/${frame.key}.png`));
    });
    tile.myScene.load.start();

    tile.myScene.load.on(
      "fileerror",
      e => {
        throw Error(e);
      },
      tile.myScene
    );

    tile.myScene.load.on("complete", () => {
      createRocketLaunchAnimations(tile, frames);
    });
  }, 5000);
}

export function triggerRocketLaunch(tile: BoardTile) {
  checkRocketLaunchConditions(tile);
}

function getAnimationFrames(frames: string[], icon: string): { key: string; frame: string | number }[] {
  const reg = /[a-z]*_[0-9]-[0-9]-[0-9]*/;
  let res = frames
    .filter(url => url.includes(icon))
    .map(url => ({ key: url.match(reg)?.[0] ?? "", frame: null }))
    .filter(x => x.key);
  res = R.sortBy(R.prop("key"), res);
  return res;
}

// ANIMATIONS

function createRocketLaunchAnimations(boardTile: BoardTile, frames: { key: string; frame: string | number }[]) {
  if (boardTile.myScene.anims.exists("rocket_start_engine")) {
    return;
  }

  const startEngineFrames = frames.filter((frame, i) => i < 4);
  const startEngineWarmUpFrames = frames.filter((frame, i) => i >= 4 && i < 18);
  const startFlyFrames = frames.filter((frame, i) => i >= 18 && i < 28);
  const endFlyFrames = frames.filter((frame, i) => i >= 28);
  boardTile.myScene.anims.create({
    key: "rocket_start_engine",
    frames: startEngineFrames,
    repeat: 0,
  });

  boardTile.myScene.anims.create({
    key: "rocket_start_warm_up",
    frames: startEngineWarmUpFrames,
    repeat: 0,
  });

  boardTile.myScene.anims.create({
    key: "rocket_start_flying",
    frames: startFlyFrames,
    repeat: 0,
  });

  boardTile.myScene.anims.create({
    key: "rocket_end_flying",
    frames: endFlyFrames,
    repeat: 0,
  });
}

// check if rocket animation should be started
export function checkRocketLaunchConditions(boardTile: BoardTile) {
  // triggerCountdown(boardTile);
  if (boardTile.tileData["recently_updated"]) {
    if (
      (boardTile.tileData.player_building.level === 4 &&
        boardTile.tileData.player_building.icon === "rocket_1" &&
        boardTile.tileData.player_building.status == 1) ||
      (boardTile.tileData.player_building.level === 5 &&
        boardTile.tileData.player_building.icon.includes("rocket") &&
        boardTile.tileData.player_building.status == 1)
    ) {
      animateRocket(boardTile);
    }
  }
}

// export function triggerCountdown(boardTile: BoardTile) {
//   // add timer
//   const currentDate = boardTile.gameService.synchronizeTimeService.getActualLocalTime().getTime();
//   boardTile.createTooltip(TileTooltipType.UPGRADE);
//   boardTile.tooltip.counter = {
//     percent: 0,
//     intervalRef: null,
//     stringTime: "",
//     startTime: currentDate,
//     finishTime: moment(currentDate).add(10, 'seconds'),
//   };
//   const buildHintTextObject = boardTile.myScene.add.text(-150, -110, "Rakieta wystartuje za", boardTile.tooltip.config.textStyles);
//   const buildTimeTextObject = boardTile.myScene.add.text(0, 10, "", boardTile.tooltip.config.textStyles);
//   addTextShadow(buildTimeTextObject);
//   buildTimeTextObject.setOrigin(0.5, -1);
//   boardTile.tooltip.setTimer(buildTimeTextObject);
//   boardTile.tooltip.add(buildTimeTextObject);
//   boardTile.tooltip.add(buildHintTextObject);
//
//   // set base texture
//   const baseTexture = boardTile.myScene.anims.get('rocket_start_engine').frames[0].textureKey;
//   console.log(baseTexture, 'added');
//   console.log(boardTile.baseSprite['anims'], 'anims');
//   // prevent errors on scene transition
//   if (baseTexture && boardTile.baseSprite['anims']) {
//     boardTile.baseSprite.setTexture(baseTexture);
//     console.log('set texture triggered');
//   }
//
//   setTimeout(() => {
//     animateRocket(boardTile);
//     boardTile.tooltip.destroy();
//   }, 10000);
// }

export function animateRocket(boardTile: BoardTile) {
  boardTile.myScene.anims.play("rocket_start_engine", boardTile.baseSprite);
  boardTile.baseSprite.on("animationcomplete", event => {
    if (event.key === "rocket_start_engine") {
      boardTile.myScene.anims.play("rocket_start_warm_up", boardTile.baseSprite);
    }

    if (event.key === "rocket_start_warm_up") {
      boardTile.myScene.anims.play("rocket_start_flying", boardTile.baseSprite);
    }

    if (event.key === "rocket_start_flying") {
      boardTile.myScene.anims.play("rocket_end_flying", boardTile.baseSprite);

      setTimeout(() => {
        if (boardTile && boardTile.baseSprite && boardTile.baseSprite["anims"]) {
          console.log("stopped");
          boardTile.baseSprite["anims"].stop();
        }
      }, 10000);
    }
  });
}
