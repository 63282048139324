import { combineReducers } from "@ngrx/store";

import { ApplicationActionTypes } from "./actions";
import { authReducer } from "./auth";
import { gameReducer } from "./game";
import { playerReducer } from "./player";
import { primaryReducer } from "./primary";
import { socketReducer } from "./socket";
import { userReducer } from "./user";
import { utilityReducer } from "./utility";

export const appReducer = combineReducers({
  auth: authReducer,
  game: gameReducer,
  user: userReducer,
  player: playerReducer,
  utility: utilityReducer,
  primary: primaryReducer,
  socket: socketReducer,
});

export function rootReducer(state, action) {
  switch (action.type) {
    case ApplicationActionTypes.CLEAR_STATE:
      state = undefined;
      break;
    case ApplicationActionTypes.MOCK_STATE:
      return {
        ...state,
        ...action.payload,
      };
  }

  return appReducer(state, action);
}
