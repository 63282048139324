<div class="card-container" *ngIf="cardTask">
  <div
    class="card"
    [class.parametrize]="!cardTask.selected"
    (click)="actionCard()"
    [matTooltip]="cardTask.selected.tooltip"
    [ngClass]="cardTask?.selected.taskClasses"
  >
    <ng-container *ngIf="cardTask.selected as selectedCard">
      <div class="logo-promo title" [class.shrink]="selectedCard.has_rank">
        <ng-container *ngIf="selectedCard.task_definition.show_logo && selectedCard.task_definition.vendor?.logo; else taskNameTpl">
          <img [src]="selectedCard.task_definition.vendor.logo + '-logo.png' | asset: 'vendors'"/>
        </ng-container>

        <ng-template #taskNameTpl>
          <h4>{{ selectedCard.task_definition.name }}</h4>
        </ng-template>
      </div>

      <div class="card-content">
        <div class="promo-prize">
          <m-ui-currency
            [item]="cardTask.selected.task_definition.vendor.prize_currency"
            [stockView]="STOCK_VIEW.A"
            [lack]="false"
            [hideValue]="true">
          </m-ui-currency>
          <div class="prize-value">{{ selectedCard.task_definition.vendor.title }}</div>
        </div>

        <span>{{ selectedCard.task_definition.string_format }}</span>
      </div>
    </ng-container>
  </div>
</div>
