import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HammerModule } from "@angular/platform-browser";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import * as Sentry from "@sentry/angular-ivy";
import { storeFreeze } from "ngrx-store-freeze";

import { environment } from "../environments/environment";
import { getDPI } from "../scripts/dpi-checker";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { CUSTOM_DECLARATIONS } from "./consts/custom/components.const";
import { CUSTOM_IMPORTS } from "./consts/custom/imports.const";
import { CUSTOM_PROVIDERS } from "./consts/custom/providers.const";
import { CoreModule } from "./core/core.module";
import { MockDialogModule } from "./modules/mock-dialogs/mock-dialog.module";
import { GameModule } from "./modules/new-game/module/core/game.module";
import { PlayerModule } from "./modules/player/player.module";
import { SharedModule } from "./modules/shared/shared.module";
import { UtilityMobileModule } from "./modules/utility/mobile/utility-mobile.module";
import { AuthEffects } from "./store/auth";
import { PrimaryEffects } from "./store/primary";
import { rootReducer } from "./store/reducer";
import { UtilityEffects } from "./store/utility";

export const metaReducers = !environment.production ? [storeFreeze] : [];
const sentryConfig: Readonly<any> = {
  envChanged: process.env.envChanged,
  environment: process.env.ENVIRONMENT,
  sentryRelease: process.env.SENTRY_RELEASE,
  sentryRelay: process.env.SENTRY_RELAY,
  sentryDSN: process.env.SENTRY_DSN,
};

Sentry.init({
  dsn:
    sentryConfig.sentryRelay == 1
      ? sentryConfig.sentryDSN.replaceAll("sentry.oskarwegner.pl", window.location.host + "/error-logs")
      : sentryConfig.sentryDSN,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [`https://${process.env.DOMAIN}/api`],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  // environment: process.env.CI_ENVIRONMENT_NAME,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  debug: false,
  environment: sentryConfig.environment,
  release: sentryConfig.sentryRelease,
  ignoreErrors: [
    "Non-Error exception captured",
    "The notification permission was not granted and blocked instead",
    "A problem occurred while unsubscribing the user from FCM",
  ],
  beforeSend(event, hint) {
    // 400 is normal status for some mechanisms in the game(building upgrade for instance)
    if (hint?.originalException?.['response']?.status === 400 ||
        (hint?.originalException.toString()).includes('400') && (hint?.originalException.toString().toLowerCase()).includes('http failure response')
    ) {
      return null;
    }

    const errorAssets = JSON.stringify(Array.from((window["errorAssets"] ?? []).entries()));
    const modifiedEvent = {
      ...event,
      extra: {
        phaserRenderMode: JSON.stringify(window["renderMode"]),
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        devicePixelRatio: window.devicePixelRatio.toFixed(2),
        dpi: getDPI(),

        isPhaserLoaded: !!window["Phaser"],
        errorAssets: errorAssets,
      },
    };
    return modifiedEvent;
  },
});

@NgModule({
  declarations: [AppComponent, ...CUSTOM_DECLARATIONS],
  imports: [
    CommonModule,
    FormsModule,
    CoreModule,
    AppRoutingModule,
    PlayerModule,
    StoreModule.forRoot(
      {
        application: rootReducer,
      },
      { metaReducers }
    ),
    EffectsModule.forRoot([...AuthEffects, UtilityEffects, PrimaryEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !environment.production,
    }),
    SharedModule,
    UtilityMobileModule,
    GameModule,
    MockDialogModule,
    HammerModule,
    ...CUSTOM_IMPORTS,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [AppRoutingModule],
    },
    ...CUSTOM_PROVIDERS,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
