import * as R from "ramda";

import { Actions, ActionTypes } from "./actions";
import { LoginState } from "./state";

const initialState: LoginState = {
  error: null,
  isTokenRefreshing: false,
  isAuthenticating: false,
};

export function loginReducer(state = initialState, action: Actions): LoginState {
  switch (action.type) {
    case ActionTypes.AUTH_START:
      return {
        ...state,
        isAuthenticating: true,
      };
    case ActionTypes.AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        error: null,
      };
    case ActionTypes.AUTH_FAIL:
      return {
        ...state,
        isAuthenticating: false,
        error: action.payload,
      };
    case ActionTypes.AUTH_LOGOUT:
      return R.clone(initialState);
    default:
      return state;
  }
}
