import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import * as R from "ramda";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { translate } from "../../../../../../core/helpers/translate.helper";
import { PlayerSelectors } from "../../../../../../store/player";
import { PrimarySelectors } from "../../../../../../store/primary";
import { AppState } from "../../../../../../store/state";
import { ProductDetails, ProductDetailsBalance } from "../../../../../player/interfaces/product.interface";
import { PlayerService } from "../../../../../player/providers/player.service";
import { ProductPlayerService } from "../../../../../player/providers/product-player.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../shared-ui/mobile/consts/stock-view.const";
import { ApiStorageService } from "../../api/core/services/api-storage.service";
import { WarehouseDetailsData } from "../../interfaces/core/dialogs/warehouse-details-data.interface";

@Injectable()
export class AbstractWarehouseDetailsComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractWarehouseDetailsComponent>;
  @OwInject(ProductPlayerService) productPlayerService: ProductPlayerService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiStorageService) apiStorageService: ApiStorageService;
  @OwInject(FormBuilder) fb: FormBuilder;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(MAT_DIALOG_DATA) public data: WarehouseDetailsData;
  STOCK_VIEW = STOCK_VIEW;
  product: ProductDetailsBalance;
  tradingCurrencyId: number;
  TRANSACTION = {
    BUY: "buy",
    SELL: "sell",
  };

  transactionBuyForm: FormGroup;
  transactionSellForm: FormGroup;
  productDefinition;
  subs = {
    player: null,
    primary: null,
  };
  maxLengthAmount = 7;

  subscribePlayer() {
    this.subs.player = this.store.pipe(select(PlayerSelectors.selectPlayer)).subscribe(() => {
      this.parseProduct();
    });
  }

  subscribeDefaultTradingCurrencyId() {
    this.subs.primary = this.store.pipe(select(PrimarySelectors.selectDefaultTradingCurrencyId)).subscribe(tradingCurrencyId => {
      this.setTradingCurrencyId(tradingCurrencyId);
    });
  }

  setTradingCurrencyId(tradingCurrencyId: number | null) {
    if (tradingCurrencyId) {
      this.tradingCurrencyId = tradingCurrencyId;
    }
  }

  parseProduct() {
    const productBalance = this.productPlayerService.getProduct({ product_id: this.data.product_id });
    // @ts-ignore
    this.product = R.mergeDeepRight(this.productDefinition, productBalance);
  }

  getProductDetails() {
    this.apiStorageService.productDetails({ productId: this.data.product_id }).subscribe((product: ProductDetails) => {
      this.productDefinition = product;
      this.setTradingCurrencyId(product.category.trading_currency_id);
      this.parseProduct();
      this.createFormBuy();
      this.createFormSell();
    });
  }

  createFormBuy() {
    this.transactionBuyForm = this.fb.group({
      amount: [null, [Validators.required, Validators.min(1), Validators.pattern("^[0-9]*$")]],
      haveAmountSum: [null, [Validators.requiredTrue]],
    });

    this.subscribeAmountChange(this.transactionBuyForm);
  }

  createFormSell() {
    this.transactionSellForm = this.fb.group({
      amount: [null, [Validators.required, Validators.min(1), Validators.pattern("^[0-9]*$")]],
      haveAmountSum: [null, [Validators.requiredTrue]],
    });

    this.subscribeAmountChange(this.transactionSellForm);
  }

  subscribeAmountChange(form: FormGroup) {
    form.get("amount").valueChanges.subscribe(value => {
      const stringValue = String(value);

      if (stringValue.length > this.maxLengthAmount) {
        const newValue = Number(stringValue.slice(0, this.maxLengthAmount));
        form.get("amount").patchValue(newValue);
      }
    });
  }

  transaction(action: string, amount: number) {
    let apiObservable;
    const alertConfirmData = {
      title: undefined,
      description: undefined,
    };

    switch (action) {
      case this.TRANSACTION.BUY:
        apiObservable = this.productPlayerService.buyProduct(this.product.product_id, amount);
        alertConfirmData.title = translate("warehouse.alert-confirm.buy.title");
        alertConfirmData.description = translate("warehouse.alert-confirm.buy.description");
        break;

      case this.TRANSACTION.SELL:
        apiObservable = this.productPlayerService.sellProduct(this.product.product_id, amount);
        alertConfirmData.title = translate("warehouse.alert-confirm.sell.title");
        alertConfirmData.description = translate("warehouse.alert-confirm.sell.description");
        break;
    }

    if (apiObservable) {
      this.dialogService.openConfirm(alertConfirmData, confirm => {
        if (!confirm) {
          return;
        }

        apiObservable.subscribe(() => {
          this.getProductDetails();

          this.dialogService.openAlert({
            title: translate("warehouse.alert-success.title"),
            description: translate("warehouse.alert-success.description"),
          });
        });
      });
    }
  }

  changeAmountSum(item, transaction: string) {
    if (!item || !transaction) {
      return;
    }

    let form: FormGroup;

    switch (transaction) {
      case this.TRANSACTION.BUY:
        form = this.transactionBuyForm;
        break;

      case this.TRANSACTION.SELL:
        form = this.transactionSellForm;
        break;
    }

    form.get("haveAmountSum").patchValue(item.have);
  }
}
