import { TILE_HEIGHT_HALF, TILE_WIDTH_HALF } from "../../constants/iso.constants";
import GameObject = Phaser.GameObjects.GameObject;
import * as R from "ramda";

import { WaveEmitter } from "../classes/core/WaveEmitter";

export function getObjectImage(objectGID: number, imagesCollection: any[]) {
  let objectImage;
  imagesCollection.forEach(collection =>
    collection.images.forEach(image => {
      if (!objectImage && image.gid === objectGID) {
        objectImage = image.image;
      }
    })
  );
  objectImage = objectImage && objectImage.indexOf("/") ? objectImage.split("/").reverse()[0] : objectImage;
  return objectImage;
}

/**
 * Calculate screen positions.
 * @param isoX
 * @param isoY
 */
export function isoToScreen(isoX: number, isoY: number) {
  return {
    x: (isoX - isoY) * TILE_WIDTH_HALF,
    y: (isoX + isoY) * TILE_HEIGHT_HALF,
  };
}

export function screenToIso(x: number, y: number) {
  return {
    x: Math.floor((x / TILE_WIDTH_HALF + y / TILE_HEIGHT_HALF) / 2),
    y: Math.floor((y / TILE_HEIGHT_HALF - x / TILE_WIDTH_HALF) / 2),
  };
}

/**
 * Process assets in tiled json data.
 * @param tilemapData
 */
export function getTilemapAssets(tilemapData) {
  const tilesArray = {};
  const tilesProps = [];
  const tilesOffsets = [];
  tilemapData.tilesets.forEach(function (el, i) {
    const firstgid = el.firstgid;
    const tiles = el.tiles;
    for (const key in tiles) {
      tilesArray["" + (firstgid + parseInt(key))] = removeAssetsDirectoryFromAssetPath(tiles[key]);
      if (el.tileoffset) {
        tilesOffsets["" + (firstgid + parseInt(key))] = el.tileoffset;
      }
    }
    const tilesprops = el.tileproperties;
    for (const key in tilesprops) {
      tilesProps["" + (firstgid + parseInt(key))] = tilesprops["" + key];
    }
  });

  return {
    tilesArray,
    tilesProps,
    tilesOffsets,
  };
}

/**
 * Found atlas name by asset name.
 * Return null if not found.
 * @param assetName
 * @param atlases
 */
export function findAtlasNameByAssetName(assetName: string, atlases: any[]) {
  let foundedAtlasName = null;

  atlases.forEach(atlas => {
    atlas.images.find((image: string) => {
      if (image === assetName) {
        foundedAtlasName = atlas.name;
      }
    });
  });

  return foundedAtlasName;
}

export function rgbToHex(r, g, b) {
  return "0x" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

/**
 * Keep object scale value 1.
 * @param object
 * @param cameraZoom
 * @param maxScale
 */
export function keepScale1(object: any, cameraZoom: number, maxScale?: number) {
  if (object && object.setScale) {
    let targetScale = 1 / cameraZoom;
    if (maxScale && targetScale > maxScale) {
      targetScale = maxScale;
    }
    object.setScale(targetScale);
  }
}

export function openFullscreen(element) {
  // @ts-ignore
  const fullscreenEnabled = document.fullscreenEnabled || document.mozFullScreenEnabled || document.documentElement.webkitRequestFullScreen;

  function requestFullscreen() {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    }
  }

  if (fullscreenEnabled) {
    requestFullscreen();
  }
}

export function enableWaves() {
  this.input.on("pointerdown", pointer => {
    if (pointer.event.altKey) {
      if (this.waveStart) {
        new WaveEmitter({
          targetX: pointer.worldX,
          targetY: pointer.worldY,
          x: this.waveStart.x,
          y: this.waveStart.y,
          isRipple: false,
          emitInterval: 8000,
          imageKey: "wave",
          scene: this,
        });

        this.waveStart = null;
      } else {
        this.waveStart = {
          x: pointer.worldX,
          y: pointer.worldY,
        };
      }
    }
  });
}

function removeAssetsDirectoryFromAssetPath(assetObject) {
  assetObject.image = assetObject.image.replace("assets/", "");
  return assetObject;
}

export function addTextShadow(textObject) {
  textObject.setStroke("#000", 5);
  textObject.setShadow(2, 2, "#00000011", 2, true, true);
}
