import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { DeviceDetectorService } from "ngx-device-detector";

import { AssetsService } from "../../../core/providers/assets.service";
import { CurrencyService } from "../../../core/providers/currency.service";
import { GlobalService } from "../../../core/providers/global.service";
import { SynchronizeTimeService } from "../../../core/providers/synchronize-time.service";
import { AppState } from "../../../store/state";
import { PlayerService } from "../../player/providers/player.service";
import { ProductPlayerService } from "../../player/providers/product-player.service";
import { DialogService } from "../../shared/providers/dialog.service";
import { ToHourPipe } from "../game-gui/directives/to-hour.pipe";
import { ApiWarehouseService } from "../game-ui/warehouse/api/custom/services/api-warehouse.service";
import { BoardService } from "./board.service";
import { BuildingsService } from "./buildings.service";
import { GameService } from "./game.service";
import { GuiService } from "./gui.service";
import { PhaserGameService } from "./phaser-game.service";
import { ProductionService } from "./production.service";
import { WaterAttractionsService } from "./water-attractions.service";

@Injectable({
  providedIn: "root",
})
export class Kt3GameService extends GameService {
  constructor(
    public assetsService: AssetsService,
    public buildingsService: BuildingsService,
    public dialogService: DialogService,
    public store: Store<AppState>,
    public router: Router,
    public deviceDetector: DeviceDetectorService,
    public globalService: GlobalService,
    public hoursPipe: ToHourPipe,
    public currencyService: CurrencyService,
    public playerService: PlayerService,
    public boardService: BoardService,
    public synchronizeTimeService: SynchronizeTimeService,
    public productionService: ProductionService,
    public productPlayerService: ProductPlayerService,
    public phaserGameService: PhaserGameService,
    public waterAttractionsService: WaterAttractionsService,
    public guiService: GuiService,
    public ngZone: NgZone,
    public apiWarehouseService: ApiWarehouseService
  ) {
    super(
      assetsService,
      buildingsService,
      dialogService,
      store,
      router,
      deviceDetector,
      globalService,
      hoursPipe,
      currencyService,
      playerService,
      boardService,
      synchronizeTimeService,
      productionService,
      productPlayerService,
      phaserGameService,
      waterAttractionsService,
      guiService,
      ngZone
    );
    this.isLowQuality = !!localStorage.getItem("low-quality");
  }
}
