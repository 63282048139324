import { ChangeDetectorRef, Directive, ElementRef, Injectable, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as moment from "moment";
import { forkJoin, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Swiper, SwiperOptions } from "swiper/types";

import { swiperInjectionStyles } from "../../../../../../../styles/swiper/swiper-injection-styles";
import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { translate } from "../../../../../../core/helpers/translate.helper";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { BuildingDetailsConfig } from "../../../../game-engine/interfaces/building-details-config";
import { BuildingsService } from "../../../../services/buildings.service";
import { STOCK_VIEW } from "../../../shared-ui/mobile/consts/stock-view.const";
import { BUILDING_TYPES } from "../../consts/core/buidling-types.const";
import { BuildingBuildData } from "../../interfaces/core/dialogs/building-build-data.interface";

@Directive()
@Injectable()
export abstract class AbstractBuildingBuildComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractBuildingBuildComponent>;
  @OwInject(MAT_DIALOG_DATA) data: BuildingBuildData;
  @OwInject(BuildingsService) buildingsService: BuildingsService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef;

  BUILDING_TYPES = BUILDING_TYPES;
  STOCK_VIEW = STOCK_VIEW;
  buildingDetails: BuildingDetailsConfig;
  unlockedBuildings: any[];
  @ViewChild("sliderBuilding") sliderBuilding;
  requirementsStatus: { valid: boolean; requirementsList: any[] };

  initSwiper = false;
  swiper: Swiper = null;
  @ViewChild("swiperRef", { static: false })
  swiperRef: ElementRef | undefined;
  currentSlideIndex = 0;
  config: SwiperOptions = {
    slidesPerView: 1,
    initialSlide: 0,
    centeredSlides: true,
    pagination: {
      dynamicBullets: true,
      clickable: true,
    },
    injectStyles: [swiperInjectionStyles],
  };

  getData() {
    forkJoin([this.getBuildingDetails(this.data.buildingId), this.getUnlockedBuildings(this.data.buildingId)]).subscribe(() => {
      this.afterGetDataRequests();
    });
  }

  afterGetDataRequests() {
    this.setSwiper();
  }

  getBuildingDetails(buildingId: number) {
    return this.buildingsService.getBuildingDetails(buildingId).pipe(
      tap(buildingDetails => {
        this.buildingDetails = buildingDetails;
        console.log(this.buildingDetails);
      })
    );
  }

  getUnlockedBuildings(buildingId: number) {
    return this.buildingsService.getUnlockedBuildings(buildingId).pipe(
      tap((buildings: any[]) => {
        this.unlockedBuildings = buildings;
        if (this.unlockedBuildings.length === 0) {
          this.unlockedBuildings = null;
        }
      }),
      catchError(error => {
        if (error.status === 404) {
          error.defaultHandler.unsubscribe();
        }
        return of(error);
      })
    );
  }

  build({ fastBuild }: { fastBuild?: boolean } = {}) {
    this.buildingsService.build(this.data.playerTileId, this.data.buildingId, fastBuild).subscribe(() => {
      this.dialogService.closeAll();
    });
  }

  openFastBuildConfirm() {
    let description = translate("fast-action.description");
    if (this.buildingDetails.fast_building_time_in_seconds) {
      const time = moment.utc(this.buildingDetails.fast_building_time_in_seconds * 1000).format("HH[h] mm[m] ss[s]");
      const extendDescription = translate("building-build.fast-action-extend-description", [time]);
      description += extendDescription;
    }

    this.dialogService.openConfirm(
      {
        title: translate("fast-action.title"),
        description,
        costs: {
          separatorTitle: translate("fast-action.separator-title"),
          currencies: this.buildingDetails.fast_build_currency_prices,
          products: this.buildingDetails.fast_build_product_prices,
        },
      },
      confirm => {
        if (confirm) {
          this.build({ fastBuild: true });
        }
      }
    );
  }

  setRequirementsStatus(status) {
    this.requirementsStatus = status;
  }

  setSwiper() {
    this.initSwiper = false;
    this.swiper = null;
    this.changeDetectorRef.detectChanges();
    this.initSwiper = true;
    this.changeDetectorRef.detectChanges();
    if (this.swiperRef?.nativeElement) {
      this.swiper = this.swiperRef.nativeElement.swiper;
      this.swiper.on("slideChange", swiper => {
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  prevSlide() {
    this.swiper.slidePrev();
    this.currentSlideIndex = this.swiper.activeIndex;
  }

  nextSlide() {
    this.swiper.slideNext();
    this.currentSlideIndex = this.swiper.activeIndex;
  }
}
