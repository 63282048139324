<!-- PLAYER BAR -->
<app-hud-player
  [allowHover]="false"
  [changeProfileImage]="true"
  [player]="player"
></app-hud-player>

<!-- EXPERIENCE -->
<span class="experience">
  {{ player.points_balance | NumberWithSpaces }} {{ 'player-details.exp' | myTranslate }}
</span>

<div class="name">
  {{ playerService.player.first_name }}
  {{ playerService.player.last_name }}
</div>

<div class="separator" *ngIf="playerService.player.email || playerService.isActiveMe"></div>

<div class="email">
  {{ playerService.player.email }}
</div>

<div class="buttons" *ngIf="playerService.isActiveMe">
  <app-edit-user-settings></app-edit-user-settings>

  <!-- CHANGE PASSWORD BUTTON -->
  <m-ui-change-password-button (clicked)="close()"></m-ui-change-password-button>

  <!-- LOGOUT -->
  <button
    id="logout"
    (click)="logout()"
    matTooltip="Wyloguj"
    matTooltipPosition="above"
    matTooltipShowDelay="300"
  >
    <i class="fas fa-power-off"></i>
  </button>
</div>
