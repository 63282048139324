<ng-container *ngIf="building">
  <!-- TIME IN DAYS -->
  <ng-container *ngIf="building.building_time_in_days as days; else otherTimeTpl">
    &nbsp;{{ "global.time-in-days" | myTranslate : [days] }}
  </ng-container>
  <!-- OTHER TIME  -->
  <ng-template #otherTimeTpl>
    <ng-container *ngIf="building.building_time_in_seconds"> &nbsp;{{ building.building_time_in_seconds | owDuration }} </ng-container>
  </ng-template>
</ng-container>
