import { Injectable } from "@angular/core";

import { CITY_SCENE_CONFIG_KEY } from "../../../modules/game/game-engine/consts/core/game-map.const";
import { BASIC_SCENE_CONFIG } from "../../../modules/game/game-engine/scenes-basic/basic-scene.config";
import { EventShopEventDialogsService } from "../../../modules/game/game-ui/event-shop/services/custom/event-dialogs.service";
import { RankingsEventDialogsService } from "../../../modules/game/game-ui/rankings/services/custom/event-dialogs.service";

@Injectable({
  providedIn: "root",
})
export class RegisterCustomEventDialogsService {
  constructor(
    private rankingsEventDialogsService: RankingsEventDialogsService,
    private eventShopEventDialogsService: EventShopEventDialogsService
  ) {
    BASIC_SCENE_CONFIG[CITY_SCENE_CONFIG_KEY] = BASIC_SCENE_CONFIG.default;
  }
}
