import { Injectable } from "@angular/core";

import { AbstractEventDialogsService } from "../../../../../../core/abstracts/abstract-event-dialogs.service";
import { EVENT_DIALOGS_HUD } from "../../const/core/event-dialogs/event-dialogs.const";
import { EVENT_DIALOGS_HUD_CUSTOM } from "../../const/custom/event-dialogs/event-dialogs.const";

@Injectable({
  providedIn: "root",
})
export class HudEventDialogsService extends AbstractEventDialogsService {
  getEventDialogs() {
    return {
      coreEventDialogs: EVENT_DIALOGS_HUD,
      customEventDialogs: EVENT_DIALOGS_HUD_CUSTOM,
    };
  }
}
