import { BoardCore } from "../core/BoardCore.class";
import { BoardTileState } from "../../../../../store/game/interfaces/board-tile.state";
import { BoardViewMode, CAMERA_START_TILE } from "../../../constants";
import { TileHover } from "../tile-hover/custom/TileHover";
import { select } from "@ngrx/store";
import { selectImportantMessages, selectLastUpdateTile } from "../../../../../store/game/selectors";
import { filter } from "rxjs/operators";
import { BoardTile } from "./BoardTile.class";

export class Board extends BoardCore {
  subs = {
    message: null,
  };
  rocket = {
    messageReceived: false,
    messageClosed: false,
    rocketStarted: false,
  };

  createBoard(boardData: BoardTileState[], viewMode = BoardViewMode.Isometric) {
    this.viewMode = viewMode;
    this.tileHover = new TileHover(this.myScene);
    boardData.forEach(tileData => this.createTile(tileData));

    if (this.phaserGameService.lastUpdatedTile$) {
      this.phaserGameService.lastUpdatedTile$.unsubscribe();
    }

    this.phaserGameService.lastUpdatedTile$ = this.gameService.store
      .pipe(
        select(selectLastUpdateTile),
        filter(state => !!state)
      )
      .subscribe((updatedTile: BoardTileState) => {
        this.updateTile(updatedTile);

        if (updatedTile.player_building?.group === "management") {
          {
            this.checkRoads(boardData, updatedTile);
          }
        }

        if (
          ((updatedTile?.player_building.icon === "rocket_1" && updatedTile?.player_building.level === 4) ||
            (updatedTile?.player_building.group.toLowerCase().includes("rocket_cosmos") && updatedTile?.player_building.level === 5)) &&
          updatedTile?.player_building.status === 1
        ) {
          {
            // this.triggerRocketAnimation();
            // trigger listening for messages
            this.listenForMessages();

            // setTimeout which triggers the message after 7s(case where message wasn't received, 3s - building time, 4s - delay time)
            setTimeout(() => {
              this.triggerRocketAnimation();
            }, 7000);
          }
        }
      });
  }

  createTile(tileData: BoardTileState, isUpdate?: boolean) {
    const tileToDestroy = this.getTileByTileId(tileData.tile_id);
    if (!tileToDestroy && isUpdate) {
      console.log("No tile to update. From other board?", tileData);
      return true;
    }

    if (
      tileData.player_building &&
      (tileData.player_building.group.includes("hidden") || tileData.player_building.group.includes("hiden"))
    ) {
      console.log("Hidden building", tileData);
      return true;
    }

    this.destroyTile(tileData.tile_id);

    const boardTile = new BoardTile({
      scene: this.myScene,
      x: tileData.x_pos,
      y: tileData.y_pos * this.yAxisSign,
      tileData: tileData,
      viewMode: this.viewMode,
      offset: this.offset,
    });

    if (
      (boardTile?.tileData?.player_building?.icon === "rocket_1" && boardTile?.tileData?.player_building?.level === 3) ||
      (boardTile?.tileData?.player_building?.group.toLowerCase().includes("rocket_cosmos") && boardTile?.tileData?.player_building?.level === 4)
    ) {
      boardTile.triggerAnimationStart();
      console.log("animation download triggered");
    }

    if (this.myScene.buildingsLayer) {
      boardTile.depthIndex = this.myScene.buildingsLayer.depthIndex;
      boardTile.calculateDepth();
      this.myScene.buildingsLayer.add(boardTile);
    }

    if (tileData.x_pos === CAMERA_START_TILE.x && tileData.y_pos === CAMERA_START_TILE.y) {
      this.myScene.cameraStartPos = {
        x: boardTile.x,
        y: boardTile.y - 100,
      };
    }
    this.boardTiles.push(boardTile);
  }

  checkRoads(boardData: BoardTileState[], management: BoardTileState) {
    const clonedBoardData = JSON.parse(JSON.stringify(boardData));
    const roads = clonedBoardData.filter(x => x.player_building?.group.includes("road"));

    for (const [index, road] of roads.entries()) {
      road.player_building.level = management.player_building?.level;

      setTimeout(() => {
        this.updateTile(road);
      }, index * 20);
    }
  }

  // listen for message with congratulations
  listenForMessages() {
    this.subs.message = this.gameService.store.pipe(select(selectImportantMessages)).subscribe(res => {
      if (res && !res.importantMessageDisplayed && res.messages.length === 0 && this.rocket.messageReceived) {
        this.rocket.messageClosed = true;
        this.triggerRocketAnimation();
      }

      // this one will prevent fallback animation start
      if (res && res.importantMessageDisplayed) {
        this.rocket.messageReceived = true;
      }
    });
  }

  // trigger animation on message dialog close
  triggerRocketAnimation() {
    // if we received massage but it wasnt closed (prevent setTimeout fallback) return
    if ((this.rocket.messageReceived && !this.rocket.messageClosed) || this.rocket.rocketStarted) {
      return;
    }

    const tile = this.boardTiles.find(x => x?.tileData?.player_building?.group.toLowerCase().includes("rocket_cosmos"));
    if (tile) {
      let body = document.getElementsByTagName("body");
      if (body.length) {
        body[0].classList.add("hide-cdk");

        setTimeout(() => {
          body[0].classList.remove("hide-cdk");
        }, 3700);
      }

      tile.triggerRocketStart();
      this.rocket.rocketStarted = true;
      this.rocket.messageReceived = false;
      this.rocket.messageClosed = false;

      if (this.subs.message) {
        this.subs.message.unsubscribe();

        // test only - reset flag; it'll never happen on prod
        setTimeout(() => {
          this.rocket.rocketStarted = false;
        }, 15000);
      }
    }
  }
}
