<div class="others-container loading-opacity">
  <!-- TERMS -->
  <a
    [href]="LINKS.TERMS"
    target="_blank"
  >
    <button class="base primary">
      {{ 'player-details.terms' | myTranslate }}
    </button>
  </a>

  <app-tutorial-button></app-tutorial-button>

  <app-training-materials-button></app-training-materials-button>

  <app-faq-button></app-faq-button>

  <!-- PUSH MOBILE -->
  <form [formGroup]="formPush" *ngIf="userService.me.has_installed_mobile_app">
    <div class="control control-checkbox">
      <div class="custom-checkbox">
        <input type="checkbox" formControlName="pushEnabled" (change)="changePush()"/>
        <span class="custom-checkbox-fill">
          <i class="fas fa-check"></i>
        </span>
      </div>

      <label>{{ 'player-details.push' | myTranslate }}</label>
    </div>
  </form>
</div>
