import * as R from "ramda";

import { CustomDialogEvent } from "../../../../../../../core/classes/custom-dialog-event.class";
import { EventDialog } from "../../../../../../../core/interfaces/event-dialog.interface";
import { HudTabsComponent } from "../../../../hud/base/custom/dialogs/hud-tabs/hud-tabs.component";
import { MissionDetailsComponent } from "../../../base/core/dialogs/mission-details/mission-details.component";
import { MissionsComponent } from "../../../base/custom/dialogs/missions/missions.component";
import { EVENT_DIALOGS_NAMES_MISSION } from "../../core/event-dialogs/event-names";

export const EVENT_DIALOGS_MISSION_CUSTOM: EventDialog = {
  [EVENT_DIALOGS_NAMES_MISSION.MISSION_DETAILS]: new CustomDialogEvent(({ data }) => {
    return R.path(["mission", "slot", "mission_slot_id"], data) === 1 ? HudTabsComponent : MissionDetailsComponent;
  }),
  [EVENT_DIALOGS_NAMES_MISSION.MISSIONS]: MissionsComponent,
};
