import { SOCKET_STATUS } from "../../core/consts/core/socket.constants";
import { Actions, ActionTypes } from "./actions";
import { SocketState } from "./state";

const initialState: SocketState = {
  isConnecting: false,
  socketStatus: null,
  attempt: 0,
};

export function socketReducer(state = initialState, action: Actions): SocketState {
  switch (action.type) {
    case ActionTypes.SOCKET_CLEAR_STATUS:
      return {
        ...state,
        isConnecting: false,
        socketStatus: SOCKET_STATUS.CLEAR_STATUS,
      };

    case ActionTypes.SOCKET_CONNECT:
      return {
        ...state,
        isConnecting: false,
        socketStatus: SOCKET_STATUS.CONNECT,
      };

    case ActionTypes.SOCKET_CONNECTING:
      return {
        ...state,
        isConnecting: true,
        socketStatus: SOCKET_STATUS.CONNECTING,
      };

    case ActionTypes.SOCKET_CONNECTED:
      return {
        ...state,
        isConnecting: false,
        socketStatus: SOCKET_STATUS.CONNECTED,
        attempt: 0,
      };

    case ActionTypes.SOCKET_AUTHENTICATE:
      return {
        ...state,
        socketStatus: SOCKET_STATUS.AUTHENTICATE,
      };

    case ActionTypes.SOCKET_AUTHENTICATING:
      return {
        ...state,
        socketStatus: SOCKET_STATUS.AUTHENTICATING,
      };

    case ActionTypes.SOCKET_AUTHENTICATED:
      return {
        ...state,
        socketStatus: SOCKET_STATUS.AUTHENTICATED,
      };

    case ActionTypes.SOCKET_DISCONNECT:
      return {
        ...state,
        isConnecting: false,
        socketStatus: SOCKET_STATUS.DISCONNECT,
      };

    case ActionTypes.SOCKET_DISCONNECTING:
      return {
        ...state,
        isConnecting: false,
        socketStatus: SOCKET_STATUS.DISCONNECTING,
      };

    case ActionTypes.SOCKET_DISCONNECTED:
      return {
        ...state,
        isConnecting: false,
        socketStatus: SOCKET_STATUS.DISCONNECTED,
      };

    case ActionTypes.SOCKET_RECONNECT_ATTEMPT:
      return {
        ...state,
        isConnecting: false,
        socketStatus: SOCKET_STATUS.RECONNECT_ATTEMPT,
        attempt: action.payload.attempt,
      };

    case ActionTypes.SOCKET_OTHER_SESSION:
      return {
        ...state,
        socketStatus: SOCKET_STATUS.OTHER_SESSION,
      };

    case ActionTypes.SOCKET_CONNECTION_ERROR:
      return {
        ...state,
        isConnecting: false,
        socketStatus: SOCKET_STATUS.CONNECTION_ERROR,
      };

    default:
      return state;
  }
}
