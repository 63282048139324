import { Action } from "@ngrx/store";

import { Primary } from "../../core/interfaces/primary";

export enum ActionTypes {
  FETCH_PRIMARY_START = "[primary] Fetch Start",
  FETCH_PRIMARY_SUCCESS = "[primary] Fetch Success",
  FETCH_PRIMARY_FAILURE = "[primary] Fetch Failure",
}

export class FetchStart implements Action {
  readonly type = ActionTypes.FETCH_PRIMARY_START;

  constructor() {}
}

export class FetchSuccess implements Action {
  readonly type = ActionTypes.FETCH_PRIMARY_SUCCESS;

  constructor(public payload: Primary) {}
}

export class FetchFailure implements Action {
  readonly type = ActionTypes.FETCH_PRIMARY_FAILURE;

  constructor(public payload: any) {}
}

export type Actions = FetchStart | FetchSuccess | FetchFailure;
