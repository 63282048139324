import { Component, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { ApiService } from "../../../../../../../../core/providers/api.service";
import { VideoComponent } from "../../../../../../../shared/components/video/video.component";
import { AbstractQaListComponent } from "../../../../abstract/core/abstract-qa-list.component";
import { EVENT_DIALOGS_NAMES_QA } from "../../../../consts/core/event-dialogs/event-names.const";
import { Qa } from "../../../../interfaces/core/qa.interface";

@Component({
  selector: "app-qa-list-custom",
  templateUrl: "./qa-list.component.html",
})
export class QaListComponent extends AbstractQaListComponent implements OnInit {
  @OwInject(ApiService) apiService: ApiService;
  @OwInject(MAT_DIALOG_DATA) data: {
    location?: number;
    qa?: Qa;
    type?: string;
    eachQaId?: number;
    isShowBackButton?: boolean;
    isOpenFromMiniGame: boolean;
  };

  VIEW_FILE_TYPE = {
    VIDEO: 1,
    PDF: 2,
  };

  REGEX_TAGS: {
    [key: string]: {
      regex: RegExp;
      replaceValue: string;
      source: string;
    };
  } = {
    PDF: {
      regex: /\[pdf src="([^"]*)"\]/g,
      replaceValue: "",
      source: null,
    },
    YOUTUBE: {
      regex: /\[youtube id="([^"]*)"\]/g,
      replaceValue: "",
      source: null,
    },
    VIDEO: {
      regex: /\[video src="([^"]*)"\]/g,
      replaceValue: "",
      source: null,
    },
  };

  ngOnInit() {
    this.baseInit();
  }

  afterQaList() {
    super.afterQaList();
    if (this.data["isOpenByQaListView"]) {
      this.setQaByIndex(this.data["index"]);
    }
    this.checkRegex();
  }

  checkRegex() {
    Object.keys(this.REGEX_TAGS).forEach(key => {
      const tag = this.REGEX_TAGS[key];

      tag.source = this.getRegexGroup(this.activeQa.description, tag.regex);
      this.activeQa.description = this.activeQa.description.replace(tag.regex, tag.replaceValue);
    });
  }

  getRegexGroup(string: string, regex: RegExp, group?: number) {
    group = group || 1;

    let matchGroup: string;

    const matches = regex.exec(string);
    if (matches) {
      matchGroup = matches[group];
    }

    return matchGroup;
  }

  openQaDetails() {
    if (this.data.isOpenFromMiniGame) {
      this.close();
    }

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_QA.QA_DETAILS,
      config: {
        disableClose: true,
        data: {
          qa: this.activeQa,
          prevState: {
            location: this.data.location,
            type: this.data.type,
            eachQaId: this.data.eachQaId,
          },
        },
      },
    });
  }

  openVideo() {
    this.dialogService.open(VideoComponent, {
      data: {
        src: this.REGEX_TAGS.VIDEO.source,
        youtubeId: this.REGEX_TAGS.YOUTUBE.source,
      },
    });
  }

  putViewFile(type: number) {
    this.apiService.put(`/qa/${this.activeQa.qa_id}/view-file/${type}`).subscribe();
  }
}
