import { GameObjects } from "phaser";
import * as R from "ramda";

import { translate } from "../../../../../../core/helpers/translate.helper";
import { AUTO_PRODUCTION_VALUE_ATTRIBUTE_NAME, PHASER_CAMERA_ZOOM } from "../../../../constants";
import { PlayerBuilding } from "../../../interfaces/player-building.config";
import { ProductOrCurrencyCombinedData } from "../../../interfaces/shared";
import { TileMenuConfig } from "../../../interfaces/tile-menu-config.interface";
import { TILE_MENU_ATLAS } from "../../../scenes-main/main.constants";
import { keepScale1 } from "../../../utils/utils";
import { MyScene } from "../../core/MyScene";
import { BoardTile } from "../../custom/BoardTile.class";
import { TileMenuButton } from "../custom/TileMenuButton";

export class TileMenuCore extends GameObjects.Container {
  isDestroyed: boolean;
  myScene: MyScene;
  building: PlayerBuilding;
  background: GameObjects.Sprite;
  atlasName = TILE_MENU_ATLAS;
  config: TileMenuConfig;
  buttons: TileMenuButton[] = [];
  mainTextObject: Phaser.GameObjects.Text;
  mainText: string;
  additionalTextObject: Phaser.GameObjects.Text;

  autoProductionCombined: ProductOrCurrencyCombinedData;
  limitedResourceCombined: ProductOrCurrencyCombinedData;

  hasAutoproduction: boolean;
  shadow: Phaser.GameObjects.Sprite;

  // CORE FILE OVERRIDE
  constructor(
    public targetTile: BoardTile | any,
    config: any
  ) {
    super(targetTile.myScene, targetTile.x, targetTile.y);

    this.config = this.provideConfig(config);
    this.myScene = targetTile.myScene;

    this.beforeGenerate();

    this.depth = targetTile.depth + 1000;
    this.y -= Math.min(targetTile.baseSprite.height / 2, 250);
    this.building = targetTile.tileData.player_building;
    this.mainText = this.building.name;

    this.generateAll();
    this.myScene.add.existing(this);
    this.animateTextObjectsAndBackground();
    this.scalingListener();
    this.sceneClickHandler();

    this.afterGenerate();
  }

  beforeGenerate() {}

  afterGenerate() {}

  /**
   * Override this method to provide custom logic for config providing.
   * Default config is passed when this method is executed on a class constructor.
   */
  provideConfig(defaultConfig?: TileMenuConfig) {
    return defaultConfig;
  }

  /**
   * Grouping function for generating tile menu elements such as
   * background, text and buttons. Fired up in the constructor function.
   */
  generateAll() {
    this.generateBackground("frame.png");
    this.generateButtons();
    this.generateText();
    this.handleAutoproduction();
    this.processLimitedResourceData();
  }

  /**
   * Generate background sprite sheet for tile menu.
   * Clicking on the background element fires up the destructor function, which closes currently opened tile menu.
   * Atlas from which the background frame is used, is based on the atlasName property.
   * @param frame - name of the atlas frame used as background.
   * @param shadowFrame - frame used for background shadow
   */
  generateBackground(frame = "frame.png", shadowFrame = "schadow_1.png") {
    if (shadowFrame) {
      this.shadow = this.myScene.add.sprite(0, 0, this.atlasName, shadowFrame);
      this.shadow.scale = this.config.scaleFactor;
      this.shadow.setOrigin(0.5);
      this.shadow.alpha = 1;
      this.add(this.shadow);
    }
    this.background = this.myScene.add.sprite(0, 0, this.atlasName, frame);
    this.background.scale = this.config.scaleFactor;
    this.background.setOrigin(0.5);
    this.background.alpha = 0;
    this.add(this.background);

    this.background.setInteractive({ cursor: "pointer" });
    this.background.on("pointerup", () => {
      this.targetTile.closeTileMenu();
    });
  }

  /**
   * Generate TileMenu buttons based on the configuration of tile-menu.config.ts file,
   * which provides information about the name, position, and states of a specific button.
   */
  generateButtons() {
    Object.values(this.config.buttons).forEach(buttonConfig => {
      const button = new TileMenuButton(this as any, buttonConfig);
      // @ts-ignore
      this.buttons.push(button);
    });
  }

  /**
   * Generate main and additional text objects, based on configuration contained in function,
   * and styles from tile-menu.config.
   */
  generateText() {
    const mainTextConfig = {
      x: 0,
      y: -this.config.mainTextOffset,
      text: this.mainText,
      origin: 0.5,
      depth: this.depth + 200,
      style: this.config.mainTextStyles,
    };

    this.mainTextObject = this.scene.make.text(mainTextConfig);
    this.addShadow(this.mainTextObject);
    this.mainTextObject.alpha = 0;
    this.add(this.mainTextObject);

    const additionalTextConfig = {
      x: 0,
      y: this.config.mainTextOffset,
      text: translate("tile-menu.level-text", [this.building.level]),
      origin: 0.5,
      depth: this.depth + 200,
      style: this.config.levelTextStyles,
    };
    this.additionalTextObject = this.scene.make.text(additionalTextConfig);
    this.addShadow(this.additionalTextObject);
    this.additionalTextObject.alpha = 0;
    this.add(this.additionalTextObject);
  }

  addShadow(textObject) {
    textObject.setStroke("#000", 5);
    textObject.setShadow(2, 2, "#00000011", 2, true, true);
  }

  /**
   * Animates text objects with smooth alpha transition.
   * Parameters such as duration, delay, repeat and so on can be changed in the function.
   */
  animateTextObjectsAndBackground() {
    this.myScene.tweens.add({
      targets: [this.background, this.mainTextObject],
      alpha: { from: 0, to: 1 },
      duration: 250,
      ease: Phaser.Math.Easing.Expo.Out,
      repeat: 0,
      delay: 0,
    });

    this.myScene.tweens.add({
      targets: [this.additionalTextObject],
      alpha: { from: 0, to: this.building && this.building.hide_level_information_on_front ? 0 : 1 },
      duration: 250,
      ease: Phaser.Math.Easing.Expo.Out,
      repeat: 0,
      delay: 0,
    });
  }

  /**
   * Changes the text objects displayed in the tile menu.
   * Fired up on the tile menu button pointerover event.
   * Sets a new text value and position for the main text object, also hides the additional text object by setting it's alpha to 0.
   * @param text - new text value for the main text object.
   */
  changeMenuTextAndHideSecondLine(text: string) {
    this.mainTextObject.text = text;
    this.mainTextObject.setY(0);
    this.additionalTextObject.alpha = 0;
  }

  /**
   * Changes the text objects displayed in the tile menu into their previous states.
   * Fired up on the tile menu button pointerout event.
   */
  setBasicMenuText() {
    this.mainTextObject.text = this.mainText;
    const mainTextOffset = this.hasAutoproduction ? 0 : -this.config.mainTextOffset;
    this.mainTextObject.setY(mainTextOffset);
    this.additionalTextObject.alpha = this.building && this.building.hide_level_information_on_front ? 0 : 1;
  }

  /**
   * Sets the pointerup event listener on a current scene.
   * Fires up the shouldCancelClickEvent from myScene that checks if the user has clicked, or dragged the mouse button.
   * If a click is detected, the tile menu is closed.
   */
  sceneClickHandler() {
    this.myScene.input.on("pointerup", (pointer: Phaser.Input.Pointer) => {
      if (!this.myScene.shouldCancelClickEvent(pointer.position)) {
        this.myScene.board.closeTileMenus();
      }
    });
  }

  scalingListener() {
    keepScale1(this, this.myScene.cameras.main.zoom, this.config.maxZoomScaleValue);
    (this.scene as MyScene).phaserEvents.on(PHASER_CAMERA_ZOOM, (zoom: number) => {
      keepScale1(this, zoom, this.config.maxZoomScaleValue);
    });
  }

  /**
   * Sets the property isDestroyed to true, and destroys currently opened tile menu.
   */
  destructor() {
    this.isDestroyed = true;
    this.destroy();
    if (this.targetTile.buildingLevelLayer && this.myScene.board.isVisibleBuildingsLevelLayer) {
      this.targetTile.buildingLevelLayer.show();
    }
  }

  async processAutoProduction() {
    this.autoProductionCombined = {
      currency: this.building.automatic_currency
        ? this.targetTile.gameService.productionService.currencyService.getCurrencyDefinition(this.building.automatic_currency)
        : null,
      product: this.building.automatic_product
        ? this.targetTile.gameService.productionService.productsService.getProduct(this.building.automatic_product)
        : null,
      amount: null,
    };
    if (this.autoProductionCombined.currency || this.autoProductionCombined.product) {
      this.hasAutoproduction = true;
      this.autoProductionCombined.amount = R.path(["additional_board_data", AUTO_PRODUCTION_VALUE_ATTRIBUTE_NAME], this.building) || 0;
    }

    const param = this.autoProductionCombined.currency || this.autoProductionCombined.product;
    if (param) {
      const autoprodContainer = this.myScene.add.container(0, 0);
      const autoprodIcon = this.myScene.add.image(0, -40, param.iconUrl);
      await this.targetTile.gameService.safeSetSpriteTexture(autoprodIcon, param.iconUrl);
      autoprodIcon.setOrigin(0.5);
      autoprodIcon.setDisplaySize(50, 50);
      autoprodContainer.add(autoprodIcon);
      const autoprodText = this.myScene.add.text(
        25,
        -40,
        `${this.autoProductionCombined.amount.toString()}/h`,
        this.config.levelTextStyles
      );
      autoprodText.setOrigin(0, 0.5);
      this.addShadow(autoprodText);
      this.add(autoprodContainer);
      autoprodContainer.add(autoprodText);
      this.mainTextObject.y += 15;
      this.additionalTextObject.y += 15;
      autoprodContainer.x = -(autoprodIcon.width / 2 + autoprodText.width) / 2;
    }
  }

  /**
   * Prepare combined values from tile limited resource data
   */
  async processLimitedResourceData() {
    this.limitedResourceCombined = {
      currency: this.targetTile.tileData.limited_resource_production_currency_prize
        ? this.targetTile.gameService.currencyService.getCurrencyDefinition(
            this.targetTile.tileData.limited_resource_production_currency_prize
          )
        : null,
      product: this.targetTile.tileData.limited_resource_production_product_prize
        ? this.targetTile.gameService.productionService.productsService.getProduct(
            this.targetTile.tileData.limited_resource_production_product_prize
          )
        : null,
      amount: null,
    };
    if (this.limitedResourceCombined.currency || this.limitedResourceCombined.product) {
      this.limitedResourceCombined.amount = this.targetTile.tileData.resource_left;
    }

    const param = this.limitedResourceCombined.currency || this.limitedResourceCombined.product;
    if (param) {
      const autoprodContainer = this.myScene.add.container(0, 0);
      const autoprodIcon = this.myScene.add.image(0, -40, param.iconUrl);
      await this.targetTile.gameService.safeSetSpriteTexture(autoprodIcon, param.iconUrl);
      autoprodIcon.setOrigin(0.5);
      autoprodIcon.setDisplaySize(50, 50);
      autoprodContainer.add(autoprodIcon);
      const autoprodText = this.myScene.add.text(25, -40, `${this.limitedResourceCombined.amount.toString()}`, this.config.levelTextStyles);
      autoprodText.setOrigin(0, 0.5);
      this.addShadow(autoprodText);
      this.add(autoprodContainer);
      autoprodContainer.add(autoprodText);
      this.mainTextObject.y += 15;
      this.additionalTextObject.y += 15;
      autoprodContainer.x = -(autoprodIcon.width / 2 + autoprodText.width) / 2;
    }
  }

  handleAutoproduction() {
    this.processAutoProduction();
  }
}
