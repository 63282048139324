import { createSelector } from "@ngrx/store";

import { AppState } from "../state";
import { BoardState } from "./interfaces/board.state";
import { BoardTileState } from "./interfaces/board-tile.state";
import { ImportantMessagesState } from "./interfaces/ImportantMessages.state";
import { GameState } from "./state";

export const selectGame = (state: AppState) => state.application.game;

const getBoard = (state: GameState): BoardState => state.boardState;
export const selectGameBoard = createSelector<AppState, object, BoardState>(selectGame, getBoard);

const getGameReady = (state: GameState): boolean => state.ready;
export const selectGameReady = createSelector<AppState, object, boolean>(selectGame, getGameReady);

const getCurrentScene = (state: GameState): string => state.currentScene;
export const selectCurrentScene = createSelector<AppState, object, string>(selectGame, getCurrentScene);

const getBoardTile = (state: GameState, props: { playerTileId?: number; tileId?: number }): BoardTileState => {
  let comparator = tile => props.playerTileId && tile.player_tile_id === props.playerTileId;
  if (props.tileId !== undefined) {
    comparator = tile => props.tileId && tile.tile_id === props.tileId;
  }
  return state.boardState.board.find(comparator);
};
export const selectGameBoardTile = createSelector(selectGame, getBoardTile);

const getLastUpdateTile = (state: GameState): BoardTileState => state.boardState.lastBoardTileUpdate;
export const selectLastUpdateTile = createSelector<AppState, object, BoardTileState>(selectGame, getLastUpdateTile);

const getBoardTilesByBuildingGroup = (state: GameState, props: { group: string }): BoardTileState[] => {
  return state.boardState.board.filter(tile => {
    return tile.player_building && tile.player_building.group === props.group; // check if player id is not null
  });
};
export const selectBoardTilesByBuildingGroup = createSelector(selectGame, getBoardTilesByBuildingGroup);

const getImportantMessages = (state: GameState): ImportantMessagesState => state.importantMessages;
export const selectImportantMessages = createSelector<AppState, object, ImportantMessagesState>(selectGame, getImportantMessages);

const getSomeBoardTileHideLevelInformation = (boardState: BoardState): boolean => {
  return boardState.board
    .filter(boardTile => boardTile.player_building)
    .some(boardTile => !boardTile.player_building.hide_level_information_on_front);
};
export const selectSomeBoardTileHideLevelInformation = createSelector(selectGameBoard, getSomeBoardTileHideLevelInformation);
