<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <ng-container *ngIf="trainingMaterials">
    <div class="materials loading-opacity" *ngIf="trainingMaterials.length > 0">
      <ow-perfect-scrollbar scrollGradient>
        <div class="material" *ngFor="let material of trainingMaterials">
          <p class="name">
            {{ material.name }}
          </p>
          <div class="download-icon">
            <a
              [href]="material.url"
              target="_blank"
            >
              <i
                class="fas fa-download"
                [matTooltip]="'Pobierz'"
                matTooltipPosition="right"
                matTooltipShowDelay="300"
              ></i>
            </a>
          </div>
        </div>
      </ow-perfect-scrollbar>
    </div>

    <ng-container *ngIf="trainingMaterials.length === 0">
      <p>Brak materiałów szkoleniowych.</p>
    </ng-container>
  </ng-container>
</div>
