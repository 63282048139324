import { Injectable } from "@angular/core";
import { filter } from "rxjs/operators";

import { GlobalService } from "../../../../../../../core/providers/global.service";
import { PlayerService } from "../../../../../../player/providers/player.service";
import { GAME_EVENTS } from "../../../../../constants";
import {
  CUSTOM_BRANCH_SCENE_CONFIG_KEY,
  CUSTOM_TECH_TREE_SCENE_CONFIG_KEY,
} from "../../../../../game-engine/consts/custom/game-map-custom.const";
import { BASIC_BOOT_SCENE } from "../../../../../game-engine/scenes-basic/basic.constants";
import { ChangeMapService } from "../core/change-map.service";

@Injectable({
  providedIn: "root",
})
export class ChangeMapCustomService extends ChangeMapService {
  branchPlayerIslandId: number;
  techTreeIslandId: number;

  constructor(
    public globalService: GlobalService,
    private playerService: PlayerService
  ) {
    super(globalService);
    this.subscribePlayerRegions();
  }

  subscribePlayerRegions() {
    this.subs.playerRegions = this.playerService.playerRegions
      .pipe(filter(playerRegions => playerRegions != null))
      .subscribe(playerRegions => {
        const islands = playerRegions.regions.reduce((_islands, region) => {
          _islands.push(...region.islands);
          return _islands;
        }, []);

        // BRANCH
        const branch = islands.find(_island => _island.config_name === CUSTOM_BRANCH_SCENE_CONFIG_KEY);
        if (branch) {
          this.branchPlayerIslandId = branch.player_island_id;
        }

        const techTree = islands.find(_island => _island.config_name === CUSTOM_TECH_TREE_SCENE_CONFIG_KEY);
        if (branch) {
          this.techTreeIslandId = techTree.player_island_id;
        }
      });
  }

  goToBranch() {
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: BASIC_BOOT_SCENE,
      data: {
        sceneConfigKey: CUSTOM_BRANCH_SCENE_CONFIG_KEY,
        playerIslandId: this.branchPlayerIslandId,
      },
    });
  }

  goToTechTree() {
    this.globalService.globalEvents.emit({
      name: GAME_EVENTS.START_SCENE,
      value: BASIC_BOOT_SCENE,
      data: {
        sceneConfigKey: CUSTOM_TECH_TREE_SCENE_CONFIG_KEY,
        playerIslandId: this.techTreeIslandId,
      },
    });
  }
}
