import { Action } from "@ngrx/store";

import { User } from "../../modules/user/interfaces/user";

export enum ActionTypes {
  FETCH_USER = "[user] fetch user",
  FETCH_USER_SUCCESS = "[user] fetch user success",
  FETCH_USER_FAILURE = "[user] fetch user failure",
  UPDATE_USER = "[user] update user",
  UPDATE_USER_SUCCESS = "[user] update user success",
  UPDATE_USER_CURRENCY_BALANCES = "[user] update user currency balances",
  UPDATE_USER_DISCOUNT = "[user] update user discount",
  NULL_ACTION = "[user] null action",
}

class UserFetchPayload {}

export class FetchUser implements Action {
  readonly type = ActionTypes.FETCH_USER;

  constructor(public payload?: UserFetchPayload) {}
}

export class FetchUserSuccess implements Action {
  readonly type = ActionTypes.FETCH_USER_SUCCESS;

  constructor(public payload: User) {}
}

export class UpdateUser implements Action {
  readonly type = ActionTypes.UPDATE_USER;

  constructor(public payload: User) {}
}

export class UpdateUserSuccess implements Action {
  readonly type = ActionTypes.UPDATE_USER_SUCCESS;

  constructor(public payload: User) {}
}

export class FetchUserFailure implements Action {
  readonly type = ActionTypes.FETCH_USER_FAILURE;

  constructor(public payload: any) {}
}

export class UpdateUserCurrenciesBalances implements Action {
  readonly type = ActionTypes.UPDATE_USER_CURRENCY_BALANCES;

  constructor(public payload: any) {}
}

export class UpdateUserDiscount implements Action {
  readonly type = ActionTypes.UPDATE_USER_DISCOUNT;

  constructor(public payload: number) {}
}

export class NullAction implements Action {
  readonly type = ActionTypes.NULL_ACTION;

  constructor(public payload?: any) {}
}

export type Actions =
  | FetchUser
  | FetchUserSuccess
  | FetchUserFailure
  | UpdateUser
  | UpdateUserSuccess
  | NullAction
  | UpdateUserCurrenciesBalances
  | UpdateUserDiscount;
