import { Component } from "@angular/core";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { LINKS } from "../../../../../../../../core/consts/core/links.const";

@Component({
  selector: "app-tutorial-button",
  templateUrl: "./tutorial-button.component.html",
})
export class TutorialButtonComponent extends AbstractInjectBaseComponent {
  LINKS = LINKS;
}
