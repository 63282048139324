import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import * as moment from "moment";

import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { SynchronizeTimeService } from "../../../../../../../../core/providers/synchronize-time.service";
import { EventEmitterDialogsService } from "../../../../../../../../core/services/core/event-emitter-dialogs.service";
import { AppState } from "../../../../../../../../store/state";
import { UserActions } from "../../../../../../../../store/user";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { UserService } from "../../../../../../../user/providers/user.service";
import { EventShop } from "../../../../api/custom/interfaces/response/get-event-shops.interface";
import { EventShopProduct } from "../../../../api/custom/interfaces/response/get-event-shops-products.interface";
import { ApiEventShopService } from "../../../../api/custom/services/api-event-shop.service";
import { ShoppingRequest } from "../../../../interfaces/custom/event-shop-requests.interface";
import { EventShopAlertConfirm } from "../event-shop-alert-confirm/event-shop-alert-confirm.component";

@Component({
  selector: "event-shop-product-details",
  templateUrl: "./event-shop-product-details.html",
})
export class EventShopProductDetails extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(SynchronizeTimeService) synchronizeTimeService: SynchronizeTimeService;
  @OwInject(ApiEventShopService) apiEventShopService: ApiEventShopService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<EventShopProductDetails>;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(UserService) userService: UserService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(MAT_DIALOG_DATA) data: {
    eventShop: EventShop;
    product: EventShopProduct;
  };
  hideInputField = false;
  isLimitOverrun: boolean;
  isLowLevel: boolean;
  isLowStar: boolean;

  isPhoneNumberDialogVisible = false;
  form = new FormGroup({
    amount: new FormControl(null, [Validators.required, this.availableAmountValidator()]),
  });

  ngOnInit() {
    this.checkLimit();
    this.setMaxValidator();
    this.checkLevel();
    this.checkStar();

    this.setEdenredCardInput();
  }

  availableAmountValidator() {
    return control => {
      const availableAmount = this.data.product.available_amount;
      if (availableAmount === null || control.value <= availableAmount) {
        return null;
      } else {
        return { availableAmount: true };
      }
    };
  }

  setEdenredCardInput() {
    const edenred = this.data.product.product_type === "edenred-card";
    if (edenred) {
      this.form.controls["amount"].patchValue(1);
      this.hideInputField = true;
    }
  }

  buyProduct(phoneNumber?: string) {
    const params: ShoppingRequest = {
      amount: this.form.value.amount,
      shopID: this.data.eventShop.event_shop_id,
      productID: this.data.product.shop_product_id,
      phoneNumber,
    };
    this.isPhoneNumberDialogVisible = false;
    this.apiEventShopService.buyProduct(params).subscribe(
      () => {
        if (this.data.product.product_type === "edenred-card") {
          this.store.dispatch(new UserActions.FetchUser());
        }
        this.matDialogRef.close("reload");
        setTimeout(() => {
          this.dialogService.openAlert({
            title: "Gratulacje",
            description: "Produkt został zakupiony",
          });
        });
      },
      errResp => {
        this.dialogService.openAlertErrorApi({ errResp });
      }
    );
  }

  submit() {
    const type = this.data.product.product_type;

    if (type === "custom") {
      this.dialogService.open(EventShopAlertConfirm, {}, (phone?: string) => {
        if (phone) {
          this.buyProduct(phone);
        }
      });
    } else {
      this.buyProduct();
    }
  }

  checkLevel() {
    this.isLowLevel = !this.playerService.allowRequiredLevel(this.data.product.required_level);
  }

  checkStar() {
    this.isLowStar = false;

    if (this.data.product.required_star) {
      this.isLowStar = this.data.product.required_star.star_id > this.playerService.player.star.star_id;
    }
  }

  checkLimit() {
    this.isLimitOverrun = this.data.product.limit_per_person_left === 0;
  }

  isActive() {
    return (
      this.synchronizeTimeService.getActualLocalTime() >= moment(this.data.eventShop.open_start).startOf("days").toDate() &&
      this.synchronizeTimeService.getActualLocalTime() <= moment(this.data.eventShop.open_end).endOf("days").toDate()
    );
  }

  setTooltip() {
    const deactivated = !this.isActive();
    return deactivated ? "W tej chwili nie możesz wymienić żetonów" : null;
  }

  setMaxValidator() {
    if (this.data.product.limit_per_person_left !== null) {
      this.form.get("amount").setValidators(Validators.max(this.data.product.limit_per_person_left));
    }
  }

  openAlertImage(imagePath: string) {
    this.dialogService.openAlertImage({
      image: imagePath,
    });
  }

  isCurrencyEnough(amount) {
    return (this.form.value.amount || 0) * this.data.product.price <= amount;
  }
}
