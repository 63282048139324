import { createSelector } from "@ngrx/store";

import { AppState } from "../state";
import { UtilityPlayer } from "./interfaces/utility-player";
import { InfoMessageState, TokenState, UtilityState } from "./state";

export const selectState = (state: AppState) => state.application.utility;

const getUtilityPlayer = (state: UtilityState): UtilityPlayer => state.player;
export const selectUtilityPlayer = createSelector<AppState, object, UtilityPlayer>(selectState, getUtilityPlayer);

export const selectActivePlayerId = createSelector(selectUtilityPlayer, (state: UtilityPlayer) => state.activePlayerId);

export const selectMePlayerId = createSelector(selectUtilityPlayer, (state: UtilityPlayer) => state.mePlayerId);

export const selectToken = createSelector(selectState, (state: UtilityState) => state.token);

export const selectTokenObject = createSelector(selectToken, (state: TokenState) => state.tokenObject);

export const selectHasMissionsToCollect = createSelector(selectUtilityPlayer, (state: UtilityPlayer) => state.hasMissionsToCollect);

export const selectMissionToOpen = createSelector(selectUtilityPlayer, (state: UtilityPlayer) => state.missionsToOpen);

export const selectNewProductInStorage = createSelector(selectUtilityPlayer, (state: UtilityPlayer) => state.newProductsInStorage);

export const selectHasNewMessagesToRead = createSelector(selectUtilityPlayer, (state: UtilityPlayer) => state.hasNewMessagesToRead);

export const selectInfoMessage = createSelector(selectState, (state: UtilityState): InfoMessageState => state.infoMessage);
