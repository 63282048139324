import { AfterViewInit, Component, OnInit } from "@angular/core";

import { AbstractBuildingBuildComponent } from "../../../../abstract/core/abstract-building-build.component";
import {getNotOutcomeParameters, getOutcomeParameters} from '../../../../helpers/custom/outcome-parameters.helper';

@Component({
  selector: "app-building-build",
  templateUrl: "./building-build.component.html",
})
export class BuildingBuildComponent extends AbstractBuildingBuildComponent implements OnInit, AfterViewInit {
  notOutcomeParameters: any[];
  outcomeParameters: any[];

  ngOnInit() {
    this.getData();
  }

  ngAfterViewInit() {
    if (!this.swiper) {
      this.setSwiper();
    }
  }

  afterGetDataRequests() {
    setTimeout(() => {
      this.swiper.updateSlides();
      this.setParameters();
    }, 10)
  }

  setParameters() {
    this.notOutcomeParameters = getNotOutcomeParameters(this.buildingDetails.parameters);
    this.outcomeParameters = getOutcomeParameters(this.buildingDetails.parameters);
  }
}
