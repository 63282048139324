<div class="card-container" *ngIf="cardTask">
  <div
    class="card {{cardTask?.selected.taskClasses}}"
    [class.parametrize]="!cardTask.selected"
    (click)="actionCard()"
    [matTooltip]="cardTask?.selected.tooltip"
  >

    <ng-container *ngIf="cardTask.selected as selectedCard">
      <i *ngIf="selectedCard.has_rank" class="fas fa-star star-icon"></i>

      <!-- CARDS NOT PROMO NOT SANTA -->
      <ng-container
        *ngIf="selectedCard.card_template !== CARD_TEMPLATE.PROMO && selectedCard.card_template !== CARD_TEMPLATE.SANTA"
      >
        <!-- LOGO -->
        <div class="logo-promo title" [class.shrink]="selectedCard.has_rank"
             *ngIf="selectedCard.task_definition.logo; else titleTpl">
          <img [src]="selectedCard.task_definition.logo + '-logo.png' | asset: 'vendors'"/>
        </div>
        <!-- TITLE -->
        <ng-template #titleTpl>
          <div class="title">
            {{ selectedCard.task_definition.name }}
          </div>
        </ng-template>

        <ng-container *ngIf="selectedCard.performance !== null; else noBusinessResultTpl">
          <div class="card-content" *ngIf="selectedCard.baseScope">
            <!-- PRIZES -->
            <div class="prizes">
              <!-- SCOPE PRIZES 100% -->
              <div class="prize" *ngFor="let currency of selectedCard.baseScope.currency_prizes">
                <m-ui-stock-other
                  *ngIf="currency.currency_id === 1 && selectedCard.replaceBrickToMoney; else defaultCurrencyTpl"
                  [item]="{icon: 'money_1', amount: currency.amount}"
                  [stockView]="STOCK_VIEW.H"
                  [lack]="false"
                ></m-ui-stock-other>

                <ng-template #defaultCurrencyTpl>
                  <m-ui-currency
                    [item]="currency"
                    [stockView]="STOCK_VIEW.H"
                    [lack]="false"
                  ></m-ui-currency>
                </ng-template>
              </div>
            </div>

            <div class="progress-content">
              <!-- PROGRESS BLOCK -->
              <div class="progress-block" *ngIf="selectedCard.performance !== null">
                <!-- PROGRESSBAR -->
                <div class="progress-container">
                  <div
                    class="fill one"
                    [ngClass]="selectedCard.taskClasses"
                    [style.width]="selectedCard.performance/selectedCard.baseScope.target_from * 100 + '%'"
                  ></div>
                </div>
              </div>

              <!-- VALUE -->
              <div
                *ngIf="!selectedCard.is_cleared"
                class="value-progressbar"
                [style.left]="(selectedCard.performance/selectedCard.baseScope.target_from >= 1) ? '100%' : (selectedCard.performance/selectedCard.baseScope.target_from * 100 + '%')"
              >
                {{ selectedCard.performance | floor:'float' | percent }}
              </div>
            </div>
            <div class="progress-content shrink-margin"
                 *ngIf="(selectedCard.card_template === CARD_TEMPLATE.HARD_WITH_ESTIMATED ||
                 selectedCard.card_template === CARD_TEMPLATE.SOFT_WITH_ESTIMATED) && selectedCard.show_estimated_performance">
              <!-- PROGRESS BLOCK -->
              <div class="progress-block">
                <!-- PROGRESSBAR -->
                <div class="progress-container">
                  <div
                    class="fill two"
                    [ngClass]="selectedCard.taskClasses"
                    [style.width]="selectedCard.estimated_performance * 100 + '%'"
                  ></div>
                </div>
              </div>

              <!-- VALUE -->
              <div
                *ngIf="!selectedCard.is_cleared"
                class="value-progressbar"
                [style.left]="selectedCard.estimated_performance * 100 + '%'"
              >
                {{ selectedCard.estimated_performance | floor:'float' | percent }}
              </div>
            </div>
          </div>

          <!-- RIBBON CLEARED-->
          <div class="cleared" *ngIf="selectedCard.is_cleared">
            Rozliczone
          </div>
        </ng-container>
      </ng-container>

      <!-- PROMO -->
      <ng-container *ngIf="selectedCard.card_template === CARD_TEMPLATE.PROMO">
        <ng-container *ngIf="selectedCard.performance !== null; else noBusinessResultTpl">

          <!-- LOGO -->
          <div class="logo-promo title" [class.shrink]="selectedCard.has_rank">
            <ng-container *ngIf="selectedCard.task_definition.show_logo; else taskNameTpl">
              <img [src]="selectedCard.task_definition.vendor.logo + '-logo.png' | asset: 'vendors'"/>
            </ng-container>

            <ng-template #taskNameTpl>
              {{ selectedCard.task_definition.name }}
            </ng-template>
          </div>

          <div class="card-content">
            <div class="promo-prize">
              <img [src]="'coin_2.png' | asset: 'ui'"/>
              <div class="prize-value">{{ selectedCard.task_definition.vendor.title }}</div>
            </div>

            <span>za sztukę</span>
          </div>
        </ng-container>
      </ng-container>

      <!-- SANTA -->
      <ng-container *ngIf="selectedCard.card_template === CARD_TEMPLATE.SANTA">
        <div class="santa-icon">
          <img [src]="'santa.png' | asset: 'ui'"/>
        </div>
      </ng-container>

      <!-- NO RESULTS -->
      <ng-template #noBusinessResultTpl>
        <div class="title">Dostępne wkrótce</div>
        <div class="parametrize">
          {{ 'business.card-task.info-no-business-result' | myTranslate }}
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>
