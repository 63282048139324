import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractRequirementsComponent } from '../../../../../shared-ui/abstract/abstract-requirements.component';
import { unsubscribeObject } from '../../../../../../../../core/utility/unsubscribe-array';
import { STOCK_VIEW } from '../../../../../shared-ui/mobile/consts/stock-view.const';
import * as moment from 'moment';
import { select } from '@ngrx/store';
import { selectPrimary } from '../../../../../../../../store/primary/selectors';
import { take } from 'rxjs/operators';
import { Primary } from '../../../../../../../../core/interfaces/primary';
import { BuildingConfig } from '../../../../../../game-engine/interfaces/building-config';
import * as R from 'ramda';

@Component({
  selector: 'app-building-requirements',
  templateUrl: './building-requirements.component.html',
})
export class BuildingRequirementsComponent extends AbstractRequirementsComponent implements OnInit, OnDestroy {
  @Input('requireTitle') title: string;
  @Input() building: BuildingConfig;
  @Input() parametersOutcome: any[];
  parametersOutcomeFormatted: any[];
  STOCK_VIEW = STOCK_VIEW;
  primary: Primary;
  textErrors: string[] = [];

  ngOnInit() {
    this.subscribePlayer();
  }

  subscribePrimary() {
    this.subs['primary'] = this.store
      .pipe(
        select(selectPrimary),
        take(1),
      )
      .subscribe((primary) => {
        this.primary = primary;
        this.checkRequireDay();
      });
  }

  buildWaitingList() {
    super.buildWaitingList();

    this.waitingList['buildings-day'] = {progress: true};
    this.subscribePrimary();

    this.waitingList['parameters-outcome'] = {progress: true};
    this.checkParametersOutcome();
  }

  checkRequireDay() {
    const action_block_dates = this.primary['action_block_dates'];

    let isTodayBlock;

    if (action_block_dates.build) {
      isTodayBlock = action_block_dates.build.some(date => {
        return moment(this.playerService.getRealTime()).isSame(date, 'days');
      });
    }

    const event = {
      action: 'buildings-day',
      value: {
        valid: !isTodayBlock,
        textError: 'Twoi pracownicy mają dziś wolne. Spróbuj ponownie w najbliższy dzień roboczy.',
      }
    };

    this.checkCompleted(event);
  }

  createTextsList() {
    super.createTextsList();
    this.createTextsError();
  }

  createTextsError() {
    this.textErrors = [];

    Object.keys(this.waitingList)
      .filter((key) => {
        return this.waitingList[key].value && !this.waitingList[key].value.valid;
      })
      .forEach((key) => {
        if (this.waitingList[key].value.textError) {
          this.textErrors.push(this.waitingList[key].value.textError);
        }
      });
  }

  emitStatus() {
    const valid = Object.keys(this.waitingList).filter((key) => {
      return this.waitingList[key].value.valid === false;
    }).length === 0;

    this.requirementsStatus.emit({
      valid: valid,
      requirementsList: this.waitingList,
      textErrors: this.textErrors
    });
  }

  checkParametersOutcome() {
    let valid = true;
    if (this.parametersOutcome) {
      this.parametersOutcomeFormatted = R.clone(this.parametersOutcome);

      this.parametersOutcomeFormatted.forEach((parameter) => {
        const parameterBalance = this.parametersService.getParameterBalanceBy({
          key: 'name',
          value: parameter.name
        })?.['balance'] || 0;

        parameter.have = parameterBalance >= parameter.outcome;
        parameter.amount = parameter.outcome;
      });

      this.parametersOutcomeFormatted = this.parametersOutcomeFormatted.filter(parameter => parameter.amount > 0);

      valid = this.parametersOutcomeFormatted.every(parameter => parameter.have);
    }

    const event = {
      action: 'parameters-outcome',
      value: {
        valid
      }
    };

    this.checkCompleted(event);
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}

